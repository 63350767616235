<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Mini Pauta Geral</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="pesquisaForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" >
              <mat-label>Ano</mat-label>
              <mat-select  name="Ano"  formControlName="Ano">
              <mat-option *ngFor="let tipo of anoLetivo | async " [value]="tipo.Codigo">
                  {{tipo.Descricao}}
              </mat-option>
              </mat-select>
              <mat-error *ngIf="pesquisaForm.get('Ano')?.hasError('required')">Ano é obrigatório</mat-error>
          </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" >
            <mat-label>Turma</mat-label>
            <mat-select  name="IdTurma"  formControlName="Turma">
            <mat-option *ngFor="let tipo of turmas " [value]="tipo.IdTurma">
                {{tipo.Turma1}}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="pesquisaForm.get('Turma')?.hasError('required')">Turma é obrigatório</mat-error>
        </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>Disciplina</mat-label>
        <mat-select name="Disciplina" formControlName="Disciplina">
          <mat-option *ngFor="let tipo of disciplina " [value]="tipo.IdDisciplina">
            {{tipo.Disciplina.Disciplina1}}
          </mat-option>
          </mat-select>
        <mat-error *ngIf="pesquisaForm.get('Disciplina')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
      </mat-form-field>
    </div>

      </div>
      <!--  {{ alunoForm.value | json }}  -->
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <button  type="submit" class="btn btn-success" [disabled]="!pesquisaForm.valid ">Gerar Relatório</button>
  </div>
</form>





