
<div class="main-container afastar" style="background-color: white;">

  <div class="row">


      <div class="col-md-7 col-sm-2 afastar">

          <div class="box-flex space col-md-12 col-sm-2 ">

              <div class="header header-document">
                  <div class="row hfluid"><div class="col-md-9 col-xs-9 hfluid">
                      <div class="header-flex-title">
                          <div class="doc-header">
                              <div class="doc-type-circle doc-type FT texto-negrito">Turma:  &nbsp; | &nbsp; Ano Lectivo: </div>
                             <!--  <div class="texto-negrito">FT T01P2022/5</div> -->
                            <!--   <div class="doc-date texto-negrito">Data: {{data | date:"dd/MM/yyyy"}}</div> -->
                          </div>
                      </div>
                  </div>
                   <div class="col-md-3 col-xs-3 hfluid">
                      <div class="bar doc-header-right ">
                          <div class="office-payment paid">
                              <span class="icon-check icon">

                              </span> Data: {{data | date:"dd/MM/yyyy"}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="content">
              <div class="row">
                  <div class="col-md-12">
                    <div class="container">
                    <!--   <form [formGroup]="ClienteForm" (ngSubmit)="onSubmit()"> -->
                      <form [formGroup]="ClienteForm">
                        <br>

                         <div *ngIf="isDone; else loading">
                        <mat-form-field appearance="outline" class="example-form-field">

                          <mat-label>Cliente</mat-label>
                          <mat-select formControlName="IdCliente" required>
                            <!-- <div *ngIf="isLoading"><img src="/assets/gif/Chronos.gif" class="center"/></div> -->
                            <app-consulta-drop-down  (Texto)="applyFilter($event)" ></app-consulta-drop-down>

                            <mat-option  *ngFor="let state of options" [value]="state.IdAluno">{{state.Nome}}</mat-option>
                            <br>

                            <div class="col-md-12" style="background-color: #f6f6f6">
                              <ngb-pagination
                              [(page)]="page"
                              [pageSize]="pageSize"
                              [maxSize]="10"

                              [collectionSize]="collectionSize" [ellipses]="true" (pageChange)="pageChanged($event)" ></ngb-pagination>
                            </div>

                          </mat-select>

                          <mat-error *ngIf="ClienteForm.get('IdCliente')?.hasError('required')"> Cliente é obrigatório. </mat-error>

                        </mat-form-field>
                    </div>
                    <ng-template #loading class="loading-spinner">
                      <div ><img src="/assets/gif/Spinner-3.gif" class="center"/></div>
                    </ng-template>
                     <!--    <mat-form-field>
                          <mat-label>
                            Employee
                          </mat-label>
                          <input #inputField formControlName="employee" matInput type="text"  data-toggle="modal" data-target="employeeModal"  />
                           <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let item of filteredOptions" [value]="item.Nome">
                              {{item.Nome}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field> -->




                        <div class="modal fade" id="employeeModal2" tabindex="-1" role="dialog" aria-labelledby="employeeModalLabel">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="employeeModalLabel">Pesquisar Employee</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <!-- Conteúdo do modal de pesquisa -->
                                <!-- Você pode adicionar uma lista de funcionários aqui -->
                                <!-- Por exemplo, uma tabela com os detalhes dos funcionários -->
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="button" class="btn btn-primary">Selecionar</button>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div class="modal" [ngClass]="{'open': isOpen}" [ngStyle]="modalStyle">
                          <span class="close" (click)="closeModal()">&times;</span>
                          <p>This is the modal content.</p>
                        </div>
                      <!--   <button class="btn  btn-light boderleft mr-2" id="" data-toggle="modal" data-target="#employeeModal" style="float: right;"><i class="fa fa-bars"></i>Serviços</button> -->
                        <div class="modal fade bs-example-modal-sm" tabindex="-1" role="dialog"   aria-labelledby="mySmallModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                              Testing 1-2-3.
                            </div>
                          </div>
                        </div>
                      </form>

                  </div>
                  </div>
                  <div class="col-md-6">

                  </div>
              </div>
          </div>
      </div>

          <div class="card">
              <div class="card-header">
                  <div class="row">
                      <div class="col-md-9">
                          <label class="texto-negrito">Venda</label>
                         <!--  <span class="cart-span-title">Meu Perfil</span> -->
                      </div>
                      <div class="col-md-3 doc-header-right">
                          <button class="btn  btn-lightmr-2" id="" data-toggle="modal" data-target="#information_modal" ><i class="fa fa-bars"></i>Produtos</button>

                      </div>
                  </div>

                  <!-- <a class="cart-a-button btn btn-secondary btn-sm" name="" id="" (click)="editProfile()" role="button"><i class="fa fa-edit" aria-hidden="true"></i></a> -->
              </div>
              <div class="card-body custom-table-first">

                  <div class="form-row">
                      <div class="col-md-12 col-sm-12 ">
                          <div class="list-group list-group-flush" >
                              <table class="table table-hover table-inverse">
                                  <thead class="thead-inverse">
                                      <tr>
                                          <th>Produto</th>
                                          <th>Preço</th>
                                         <!--  <th  >Dias Atrazado</th> -->

                                          <th class="text-right">Quant.</th>
                                          <th class="text-right">Valor Total</th>
                                          <th>Opções</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let persDoc of products">

                                          <td scope="row">{{persDoc.Conta}}</td>
                                          <td scope="row">{{persDoc.Valor }}</td>
                                          <td class="text-right">1</td>
                                          <td class="text-right">{{persDoc.Valor | currency:" ":"symbol" }}</td>
                                          <td>
                                              <a name="" id="" class="btn m-btn m-btn--icon btn-sm mr-2" role="button" (click)="removeItem(persDoc)" ><i class="fa fa-trash" style="color: #e81126;" aria-hidden="true"></i></a>
                                          </td>
                                      </tr>
                                  </tbody>
                                  <tfoot>
                                      <tr >
                                          <td></td>
                                          <td></td>

                                          <td class="total texto-negrito ">Total </td>
                                          <td class="text-right texto-negrito total " > {{grandTotal | currency:" ":"symbol" }}</td>
                                          <td></td>
                                      </tr>
                                  </tfoot>
                              </table>

                          </div>
                      </div>
                  </div>

              </div>

          </div>
      </div>

      <div class="col-md-5 col-sm-8">
          <form [formGroup]="MensalidadeForm" (ngSubmit)="onSubmit()">
          <div class="card afastar" style="width: 100%; height:500px">
              <div class="card-header">
                  <span class="cart-span-title texto-negrito">Pagamentos</span>
                  <a class="cart-a-button" name="" id=""  role="button"></a>
              </div>
              <br>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline" >
                      <mat-label>Data de Pagamento</mat-label>
                      <input matInput [matDatepicker]="DataPagamento" readonly name="DataPagamento" formControlName="DataPagamento">
                      <mat-datepicker-toggle matSuffix [for]="DataPagamento"></mat-datepicker-toggle>
                      <mat-datepicker [startAt]="date"  #DataPagamento></mat-datepicker>
                      <mat-error *ngIf="MensalidadeForm.get('DataPagamento').hasError('required')"> Data de pagamento é obrigatório.  </mat-error>

                  </mat-form-field>
                </div>
              <div class="col-md-12">

                  <mat-form-field appearance="outline">
                    <mat-label class="texto-negrito">Tipo de Pagamentos</mat-label>
                    <mat-select name="IdTipoPagamento" readonly formControlName="IdTipoPagamento" required>
                      <mat-option>-seleccione-</mat-option>
                      <mat-option *ngFor="let n of TipoPagamento | async" [value]="n.IdTipoPagamento">
                        {{n.TipoPagamento1}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="MensalidadeForm.get('IdTipoPagamento')?.hasError('required')" > Tipo de Pagamentos é obrigatório. </mat-error>

                 <!--   <mat-error *ngIf="trabalhadorForm.get('Id_Nacionalidade').hasError('required')">Seleccione o genero</mat-error>
                     <mat-hint>{{Id_Sexo.value?.sound}}</mat-hint> -->
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label class="texto-negrito">Banco</mat-label>
                    <mat-select name="Banco" readonly formControlName="Banco" required>
                      <mat-option>-seleccione-</mat-option>
                      <mat-option *ngFor="let n of Bancos | async" [value]="n.IdBanco">
                        {{n.Sigla}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="MensalidadeForm.get('Banco')?.hasError('required')" > Banco é obrigatório. </mat-error>

                 <!--   <mat-error *ngIf="trabalhadorForm.get('Id_Nacionalidade').hasError('required')">Seleccione o genero</mat-error>
                     <mat-hint>{{Id_Sexo.value?.sound}}</mat-hint> -->
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field appearance="outline" >
                      <mat-label class="texto-negrito">Desconto</mat-label>
                      <input matInput formControlName="Desconto" maxlength="9" type="number" />
                      <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                      <mat-error *ngIf="MensalidadeForm.get('Desconto')?.hasError('required')"> Desconto é obrigatório. </mat-error>
                  </mat-form-field>
                </div>

                 <div class="col-md-12">
                  <mat-form-field appearance="outline" >
                      <mat-label class="texto-negrito">Total a Pagar</mat-label>
                      <input matInput formControlName="Valor" maxlength="9" type="number"  class="texto-negrito"/>
                      <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                      <mat-error *ngIf="MensalidadeForm.get('Valor')?.hasError('required')"> Total a Pagar é obrigatório. </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field appearance="outline" >
                      <mat-label class="texto-negrito">Borderoux</mat-label>
                      <input matInput formControlName="Borderoux" maxlength="7" type="text"/>
                      <mat-icon matSuffix class="secondary-text" >money</mat-icon >
                      <mat-error *ngIf="MensalidadeForm.get('Borderoux')?.hasError('required')"> Borderoux é obrigatório. </mat-error>
                  </mat-form-field>
                </div>

                 <div class="col-md-12">
                  <mat-form-field appearance="outline" >
                      <mat-label class="texto-negrito">Saldo</mat-label>
                      <input matInput formControlName="Saldo" maxlength="9" type="number"/>
                      <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                      <mat-error *ngIf="MensalidadeForm.get('Saldo')?.hasError('required')"> Saldo é obrigatório. </mat-error>
                  </mat-form-field>
                </div>

              </div>
                <button type="submit"  class="btn btn-success" [disabled]="!MensalidadeForm.valid || !ClienteForm.valid" >Criar Factura</button>
               <!--  <ngx-extended-pdf-viewer [src]="pdfSource" [useBrowserLocale]="true"> </ngx-extended-pdf-viewer> -->
              </div>
      </form>
      </div>

  </div>
  <br>
</div>



<!-- left modal -->
<div class="modal fade" id="modal_Search" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" >
  <div class="modal-dialog" role="document">
     <form method="post"  id="get_quote_frm">
      <div class="modal-content ">
          <!-- <input type="hidden" name="email_e" value="admin@filmscafe.in"> -->
          <div class="modal-header">
            <h2 class="modal-title">Serviços/Produtos:</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  style=" height:480px">
          <div class="modal-body get_quote_view_modal_body">
             <div class="card-body table-responsive custom-table">
              <table class="table table-hover table-inverse">
                  <thead class="thead-inverse">
                      <tr>
                          <th>Produto</th>
                          <th>Valor</th>
                      </tr>
                  </thead>
                  <tbody>

                      <tr *ngFor="let persDoc of mensalidade">

                          <td scope="row" (click)="addtocart(persDoc)">{{persDoc.Conta}}</td>
                          <td scope="row" (click)="addtocart(persDoc)">{{persDoc.Valor}}</td>
                         <!--  <td>
                              <button [hidden]="persDoc.Situacao=='LIQUIDADA'" (click)="addtocart(persDoc)"  type="button" name="" id="" class="btn btn-light
                              btn-sm"> <i class="fas fa-check"></i></button>
                              <button *ngIf="persDoc.Situacao=='LIQUIDADA'" (click)="printOrOpenPdf(persDoc.MovimentosCaixas.Documento,'Print')"  type="button" name="" id="" class="btn btn-light
                              btn-sm"> <i class="fas fa-print"></i></button>
                          </td> -->
                      </tr>
                  </tbody>
              </table>
          </div>
          </div>
          </div>

      </div><!-- modal-content -->
    </form>
  </div><!-- modal-dialog -->
</div><!-- modal -->




<!-- left modal -->
<div class="modal modal_outer right_modal fade" id="information_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" >
  <div class="modal-dialog" role="document">
     <form method="post"  id="get_quote_frm">
      <div class="modal-content ">
          <!-- <input type="hidden" name="email_e" value="admin@filmscafe.in"> -->
          <div class="modal-header">
            <h2 class="modal-title">Serviços/Produtos:</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  style=" height:480px">
          <div class="modal-body get_quote_view_modal_body">
             <div class="card-body table-responsive custom-table">
              <table class="table table-hover table-inverse">
                  <thead class="thead-inverse">
                      <tr>
                          <th>Produto</th>
                          <th>Valor</th>
                      </tr>
                  </thead>
                  <tbody>

                      <tr *ngFor="let persDoc of mensalidade">

                          <td scope="row" (click)="addtocart(persDoc)">{{persDoc.Conta}}</td>
                          <td scope="row" (click)="addtocart(persDoc)">{{persDoc.Valor}}</td>
                         <!--  <td>
                              <button [hidden]="persDoc.Situacao=='LIQUIDADA'" (click)="addtocart(persDoc)"  type="button" name="" id="" class="btn btn-light
                              btn-sm"> <i class="fas fa-check"></i></button>
                              <button *ngIf="persDoc.Situacao=='LIQUIDADA'" (click)="printOrOpenPdf(persDoc.MovimentosCaixas.Documento,'Print')"  type="button" name="" id="" class="btn btn-light
                              btn-sm"> <i class="fas fa-print"></i></button>
                          </td> -->
                      </tr>
                  </tbody>
              </table>
          </div>
          </div>
          </div>

      </div><!-- modal-content -->
    </form>
  </div><!-- modal-dialog -->
</div><!-- modal -->



