import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Matricula, Mensalidade } from '@core/models/index';
import { NgbModal,ModalDismissReasons, NgbActiveModal, NgbDateStruct,} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { TipoPagamento, Banco } from '@core/models/index';
import { Observable, Subject, of } from 'rxjs';
import { tap, catchError, map, debounceTime, takeUntil } from 'rxjs/operators';
import { UtilsService, MensalidadeService, MatriculaService, ReportServerService,AuthService,TurmaService } from '@service/index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { BasePage } from 'app/shared/base-page';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportComponent } from '@component/index';
import { CurrencyPipe } from '@angular/common';
import { Publico } from '@shared/publico';

@Component({
  selector: 'app-propina',
  templateUrl: './propina.component.html',
  styleUrls: ['./propina.component.scss']
})
export class PropinaComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    private _formBuilder: FormBuilder,
    //  private activeModal: NgbActiveModal,
    private utilservice: UtilsService,
    private userService: AuthService,
    private matriculaservice: MatriculaService,
    private mensalidadeService: MensalidadeService,
    private modalService: NgbModal,
   // private currentRoute: ActivatedRoute,
    private cp: CurrencyPipe,
    private turmaService: TurmaService,
    public activeModal: NgbActiveModal,
    private reportserver: ReportServerService,
    protected sanitizer: DomSanitizer,
    private publico: Publico,
    private http: HttpClient
  ) {
    super(injector);
  //  this.dadosdoaluno();
  }

  //@ViewChild('leftSidebar') leftSidebar: SidebarComponent;
  //  @ViewChild('rightSidebar') rightSidebar: SidebarComponent;

  MensalidadeForm = this._formBuilder.group({
    IdTipoPagamento: ['', Validators.required],
    Banco: ['', Validators.required],
    Desconto: [0, Validators.required],
    Borderoux: ['', Validators.required],
    Valor: ['', Validators.required],
    ValorTotal: [0, Validators.required],
    Saldo: [0],
    IdMensalidade: [''],
    Multa: [''],
    Quantidade: ['1'],
    InvoiceId: [],
    DiasAtrazado: [''],
    Juros: [''],
    Mora: [''],
    Documento: [''],
    IdTipoDoc: [''],
    Descricao: [''],
    Serie: [''],
    DataPagamento: [new Date, Validators.required],
  });

  cadastroForm = this._formBuilder.group({
    Turma: ['', Validators.required],
    Aluno: ['', Validators.required],
  });

  public cartItemList: any = [];
  localmensalidade: Mensalidade[] = [];
  public page = 1;
  public pageSize = 15;
  collectionSize = 0;
  closeResult = '';
  config: any;
  filter = new FormControl('');
  mensalidade2: Mensalidade[] = [];
  searchText: string = '';
  mes: any;
  display = false;
  public products: any = [];
  public grandTotal!: number;
  public totalItem: number = 0;
  currentUser: any;
  profileData: any;
  profileImage = 'assets/img/user.jpg';
  termoBuscaChanged: Subject<string> = new Subject<string>();
  aluno?: any;
  tipoRecibo: any;
  anolectivo: any;
  acesso: any;
  Idmatricula: any;
  turmas: any[]=[];
  turmaslocal: any[]=[];
  dados: any;
  mensalidade: Mensalidade[] = [];
  activityHistory: any = [];
  TipoPagamento: Observable<TipoPagamento[]>;
  Bancos: Observable<Banco[]>;
  data: any;
  date;
  pdfSource: any;
  matriculas: any;
  matriculaslocal: any;
  desconto = 0;
  fatura: any;
  checkAnoAnteriordivida: any;
  checkMesAnteriordivida: any; //boolean = false;

  multa: any ={
    IdMatricula: null,
    IdMes: 0,
    ValorApagar: 0

  }

  ngOnInit(): void {
    this.param()
    this.publico.verificaLincenca();



    this.mes = '';
   // this.Idmatricula = this.currentRoute.snapshot.paramMap.get('id');
    this.TipoPagamento = this.utilservice.BuscaTipoPagamentos();
    this.Bancos = this.utilservice.BuscaBanco();
    this.data = Date();
    this.checkMesAnteriordivida = false;
    this.acesso = atob(this.userService.loadUserPermition());

    console.log(this.acesso);

     this.date = new Date((new Date().getTime() - 3888000000));


     this.cadastroForm.get('Turma')?.valueChanges.subscribe((res: any) =>{
           console.log(res);

           this.config.IdTurma = res;

           this.carregaAlunosmatriculas()

     } )

     this.cadastroForm.get('Aluno')?.valueChanges.subscribe(res => {
           this.Idmatricula = res;
           this.loadMatricula();
     })


    this.mensalidadeService.getProducts().pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
      this.products = res;
      this.totalItem = res.length;
      this.grandTotal = this.mensalidadeService.getTotalPrice();
      this.MensalidadeForm.get('Valor')?.setValue(this.grandTotal);
      this.MensalidadeForm.get('ValorTotal')?.setValue(this.grandTotal);
    });
  }
  onPress() {
    //this.display = true;

    //To toggle the component
    this.display = !this.display;
  }
  removeItem(item: any) {
    this.mensalidadeService.removeCartItem(item);
  }
  emptycart() {
    this.mensalidadeService.removeAllCart();
  }

  fillTurma() {
    this.config.ItemsPerPage= 80;
    this.config.AnoLectivo = this.anolectivo;
    console.log(this.config);

    this.turmaService.turmaAnoLectivo(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.turmas =  response.data;
      this.turmaslocal = response.data;
      console.log(response);
      console.log(this.config);
      })
  }

  onKey(value: string) {
    let filter = value.toLowerCase();
/*     if(value.length === 0){
      this.loadData();
    } */
    this.turmas =  this.turmaslocal.filter((option: any) =>  option.Turma1.toLowerCase().includes(filter))
  }

  filtrarMatricula(value: string) {
    let filter = value.toLowerCase();
    this.matriculas =  this.matriculaslocal.filter((option: any) =>  option.Aluno.Nome.toLowerCase().includes(filter))
  }

  onReport(pdfSource: any) {
    const modalref = this.modalService.open(ReportComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    modalref.componentInstance.pdfSource = pdfSource;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log('Modal close result', this.closeResult);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log('Modal close result', this.closeResult);
      }
    );
  }

  printOrOpenPdf(id: string, tipo: string) {
    this.reportserver.getInvoice(id, this.tipoRecibo).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      const fileURL = URL.createObjectURL(data);
      this.loadMatricula();
      this.MensalidadeForm.get('Borderoux')?.setValue('0');
      if (tipo == 'Print') {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        //    this.onReport(data)
      } else {
        // window.open(fileURL, "_blank"); //,  "_blank"
        //  const url = window.URL.createObjectURL(data);
        // window.open(url);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `FT_${id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    });
  }

  getCalculadesconto(){
     if (this.MensalidadeForm.get('Desconto')?.value > 0 || (this.MensalidadeForm.get('ValorTotal')?.value > 0) ){
      this.desconto = (this.MensalidadeForm.get('ValorTotal')?.value * (this.MensalidadeForm.get('Desconto')?.value /100))
      this.MensalidadeForm.get('Valor')?.setValue( this.MensalidadeForm.get('ValorTotal')?.value-(this.MensalidadeForm.get('ValorTotal')?.value * (this.MensalidadeForm.get('Desconto')?.value /100)))
     // this.MensalidadeForm.get('ValorTotal')?.setValue(this.MensalidadeForm.get('Valor')?.value );
    }

  }

  getInvoiceId() {
    //  this.showLoadingView();
    this.mes = '';
    this.mensalidadeService.getInvoceId('FR', this.aluno.Anolectivo).subscribe(
      (response: any) => {
        this.fatura = response;
        console.log(this.fatura);
        this.MensalidadeForm.get('InvoiceId')?.setValue(response);
        console.log('Invoice carregado com sucesso', response);
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }

  onSubmit() {
    this.mensalidadeService.getInvoceId('FR', this.aluno.Anolectivo).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.fatura = response;
        this.MensalidadeForm.get('InvoiceId')?.setValue(response);
        this.parcelaMulta(response);
        console.log('Invoice carregado com sucesso', response);
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
      }
    );
  }

  parcelaMulta(dados: any){

    this.MensalidadeForm.get('Quantidade')?.setValue(this.totalItem);
    this.MensalidadeForm.get('Documento')?.setValue('FR ' + this.aluno.Anolectivo + '/' + dados);
    this.MensalidadeForm.get('IdTipoDoc')?.setValue('FR');
    this.MensalidadeForm.get('Serie')?.setValue(this.aluno.Anolectivo);
    this.MensalidadeForm.get('Multa')?.setValue(this.mensalidadeService.getTotalMulta());
    let valor = this.MensalidadeForm.get('Valor')?.value;
    let valoTotal = this.MensalidadeForm.get('ValorTotal')?.value;
    this.MensalidadeForm.get('DataPagmento')?.setValue(new Date(this.MensalidadeForm.get('DataPagmento')?.value).toUTCString());

    if (valor < (valoTotal - this.desconto)){
      Swal.fire({ title: 'Deseja criar uma parcela para a multa?', icon: 'warning', showCancelButton: true, confirmButtonText: 'Sim', cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          Swal.fire('Parcela criado com sucesso!','','success')
          this.multa.IdMatricula = this.Idmatricula;
          this.multa.ValorApagar = this.MensalidadeForm.get('Multa')?.value;
          this.cartItemList.forEach((m: any) => { this.multa.IdMes = m.Mes.IdMes;});
          this.MensalidadeForm.get('Multa')?.setValue(0);
          this.mensalidadeService.criarMulta(this.multa).subscribe(response => {
           console.log(response);
         //  this.pagamento(dados);
         })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.MensalidadeForm.get('Multa')?.setValue(0);
        }
        this.pagamento(dados);
      })
    }else{
      this.pagamento(dados);
    }


  }

  pagamento(Factura: any) {
    console.log(this.MensalidadeForm.value);

    let Recibo = 'FR ' + this.aluno.Anolectivo + '/' + Factura;
    Swal.fire({
      title: 'Deseja efetuar o pagamento da Factura nº. FR ' + this.aluno.Anolectivo + '/' + Factura, //this.mensalidadeService.getTotalPrice(),
     // text: 'Total: ' + this.cp.transform(this.mensalidadeService.getTotalPrice(),'Kz ','symbol','1.2-2'), //this.MensalidadeForm.get('Valor')?.value
     text: 'Total: ' + this.cp.transform(this.MensalidadeForm.get('Valor')?.value,'Kz ','symbol','1.2-2'),
     icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Emitir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.MensalidadeForm.value);
        this.MensalidadeForm.get('Desconto')?.setValue(this.desconto);
        this.mensalidadeService.pagamento(this.MensalidadeForm.value).pipe(takeUntil(this.destroySubject)).subscribe(
          (response: any) => {
            this.mensalidadeService.cartItemList.forEach((mensalidade: any) => {
              mensalidade.IdMovimentoCaixa = response.IdMovimentoCaixa;
              this.mensalidadeService.actualizar(mensalidade).pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
                  this.mes = '';
                });
                this.MensalidadeForm.get('Desconto')?.setValue(0);
            });
            Swal.fire({
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonColor: 'green',
              cancelButtonColor: '#6c757d',
              denyButtonColor: 'red',
              confirmButtonText: '<i class="fa fa-print" ></i>Imprimr',
              denyButtonText: '<i class="fa fa-file-pdf "></i>Download PDF',
              cancelButtonText: '<i class="fa fa-envelope" ></i>Enviar/E-mail',
              title: 'Factura FR ' + this.aluno.Anolectivo + '/' + Factura, // this.MensalidadeForm.get('IdSerie')?.value,
              text: 'Total Pago: ' + this.cp.transform(this.MensalidadeForm.get('Valor')?.value,'Kz ','symbol',),
              icon: 'success',
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire('Documento impresso com sucesso!', '', 'success');
                this.printOrOpenPdf(Recibo, 'Print');
                this.loadMatricula();
              } else if (result.isDenied) {
                Swal.fire('Documento aberto com sucesso', '', 'info');
                this.printOrOpenPdf(Recibo, 'Open');
                this.loadMatricula();
              } else {
                const { value: email } = await Swal.fire({
                  title: 'Informe o email',
                  input: 'email',
                  inputLabel: 'Informe o email',
                  inputPlaceholder: 'Digite o email ',
                });

                if (email) {
                  this.reportserver.enviarReciboViaEmail(Recibo, email).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => console.log(response));
                  Swal.fire(`Entered email: ${email}`);
                  this.loadMatricula();
                }

                this.loadMatricula();
              }
            });

            //    this.showContentView();
            this.mensalidadeService.removeAllCart();
            //this.loadMatricula();
          },
          (error: any) => {
            console.log('Error create Matricula', error);
            this.showMessage( 'Erro', 'Ocorreu um erro ao realizar o pagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
          }
        );
      }
    });
  }

  /*
  num(){

        var chars = "0123456789";
        var string_length = 7;
        var randomstring = '';
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        return randomstring;
      } */

      confirmBox(){
        Swal.fire({
          title: 'Are you sure want to remove?',
          text: 'You will not be able to recover this file!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your imaginary file is safe :)',
              'error'
            )
          }
        })
      }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'custom-class',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dadosdoaluno() {
    this.matriculaservice.loadById(this.Idmatricula).pipe(takeUntil(this.destroySubject)).subscribe((message: any) => {
        this.aluno = message;
        console.log(this.aluno);
      });
  }

  applyFilter(filterValue: string) {

    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
        //  this.config.Nome = filtrarPor;
        //  this.carregaAlunosmatriculas();
        this.matriculas = this.matriculaslocal.filter(item =>{ item.Aluno.Nome.toString().toLowerCase().includes(filtrarPor.toString().toLowerCase())} )

        });
    }
    this.termoBuscaChanged.next(filterValue);
 }

  carregaAlunosmatriculas() {
    this.showLoadingView();
    this.matriculaservice.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.matriculas = response.data;
        this.matriculaslocal = response.data;
        console.log(response);

        this.matriculas = response.data;
        this.pagination = response.pagination;
        this.config = {
                      currentPage: response.currentPage,
                      page: 1,
                      totalItems: response.total
                      }
       this.collectionSize = response.total;

       if (response.total > 0 ){
        this.aluno = this.matriculas[0].Aluno;
       }

        this.showContentView();
      }, error => {
        console.log('Error a efetuar requisicao', error);
      });
  }


  loadMatricula() {
    //  this.showLoadingView();

    this.mensalidadeService.loadByIdMatricula(this.Idmatricula).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.localmensalidade = response;
        this.mensalidade = response;
        if (this.localmensalidade && this.localmensalidade.length) {
          this.collectionSize = this.localmensalidade.length;
        }
        this.dadosdoaluno();
        this.showContentView();
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }
  filtrarMensalidade(value: string) {
    if (value =='161'){
      this.mensalidade2 = this.localmensalidade.filter((option: any) =>  option.ContaCaixa.IdContaCaixa == value);
      console.log(this.mensalidade2);

    } else{
      this.mensalidade2 = this.localmensalidade.filter((option: any) =>  option.ContaCaixa.IdContaCaixa !== '161');
      console.log(this.mensalidade2);
    }

    // this.options = this.filteredOptions.filter((employee: { Nome: string; }) => employee.Nome.toLowerCase().includes(filter))
  }
  param(){
    this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.anolectivo = response[0].AnoLectivo;
      this.tipoRecibo = response[0].TipoRecibo;
      this.config = {
        ItemsPerPage: 80,
        currentPage: 1,
        page: 1,
        AnoLectivo : this.anolectivo,
        IdTurma:0 ,
        Nome: "",
        }
        this.fillTurma();
}, (error: any) => {
  console.log('Error on request', error);
});
  }

  AnularPagamento(dados: any,Tipo: number) {
  //  let Recibo = 'FR ' + this.aluno.Anolectivo + '/' + Factura;
  console.log(dados);
let Doc="0";
  let titulo= "Anular"

  titulo = Tipo==1? 'Eliminar': 'Anular';
  Doc = dados.Situacao === "ABERTA" ? '0':dados.MovimentosCaixas.Documento;

  /* if (dados.Situacao == "ABERTA"){
    Doc= '0';
   } else
   {
     Doc=  dados.MovimentosCaixas.Documento;
   }
 */
   /*  if(Tipo == 1){
      titulo= "Eliminar";
    }else{
      titulo= "Anular";
    }
 */
    Swal.fire({
      title: 'Deseja ' + titulo + ' a Factura nº. ' + Doc, //this.mensalidadeService.getTotalPrice(),
      text: 'Total: ' + this.cp.transform(dados.ValorApagar,'Kz ','symbol','1.2-2'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: titulo,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {

        let idMensalidade = 0;

      //  idMensalidade = Tipo==0? dados.IdMensalidade : 0;

        this.mensalidadeService.ApagarPagamento(dados.IdMensalidade, Doc, Tipo).pipe(takeUntil(this.destroySubject)).subscribe(
          (response: any) => {
            this.loadMatricula();
            this.filtrarMensalidade('161');
            this.showMessage( 'success', 'Processo de ' + titulo + ' realizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));

          },
          (error: any) => {
            console.log('Error create Matricula', error);
            this.showMessage( 'Erro', 'Ocorreu um erro ao ' + titulo + ' o pagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
          }
        );
      }
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  addtocart(item: any) {
    this.mensalidadeService
      .checkdividaDoAnoAnterior(item.Matricula.IdAluno)
      .subscribe((res: any) => {
        this.checkAnoAnteriordivida = res;
      });

    this.mensalidadeService
      .checkdividaDoMesAnterior(item.IdMensalidade)
      .subscribe((res: any) => {
        this.checkMesAnteriordivida = res;
      });

    if (this.checkMesAnteriordivida) {
      Swal.fire({
        icon: 'warning',
        title: `Caro Operador você não podera liquidar o mês de ${item.Mes.Mes}`,
        text: 'Porque existem dividas do ano passado em Aberto, por favor queira liquidar as dividas ',
      });
    } else {
      this.mes="";
      this.cartItemList.push(item);
      this.cartItemList.forEach((m: any) => {
        this.mes += m.Mes.Mes + ',';
        this.MensalidadeForm.get('Descricao')?.setValue('Renda(s) de : ' + this.mes.substring(0, this.mes.length - 1)
        );
      });
      this.mensalidadeService.addtoCart(item);
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
