<div>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
         <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>

    <form [formGroup]="matriculaForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
        <div class="modal-body">
<!--
            <div class="form-group text-center">
                <img
                [src]="imageUrl"
                class="rounded-circle"

                name="aboutme" width="120" height="120"
                style="cursor: pointer;">
            </div> -->

             <div class="text-center">
                <h2> <b>{{nome }} </b></h2>
           </div>
           <br>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Ano exercicio</mat-label>
                        <input matInput formControlName="Anolectivo" maxlength="50" />
                        <mat-icon matSuffix class="secondary-text" >line_style</mat-icon >
                        <mat-error *ngIf="matriculaForm.get('Anolectivo')?.hasError('required')"> Ano exercicio é obrigatório. </mat-error>
                    </mat-form-field>
                </div>
               <!--  <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Tipo Aluno</mat-label>
                        <mat-select  name="IdTipoAluno"  formControlName="IdTipoAluno">
                        <mat-option *ngFor="let tipo of TipoAlunos | async" [value]="tipo.IdTipoAluno">
                            {{tipo.TipoAluno1}}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="matriculaForm.get('IdTipoAluno')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
                    </mat-form-field>
                </div> -->
                <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Apartamento</mat-label>
                        <mat-select  name="IdTurma"  formControlName="IdTurma">
                          <app-consulta-drop-down (Texto)="applyFilter($event)" ></app-consulta-drop-down>
                        <mat-option *ngFor="let tipo of Turmas " [value]="tipo.IdTurma">
                            {{tipo.Turma1}}
                        </mat-option>
                        <ngb-pagination
                        [(page)]="page"
                        [pageSize]="pageSize"
                        [maxSize]="15"

                        [collectionSize]="collectionSize" (pageChange)="pageChanged($event)" ></ngb-pagination>
                        </mat-select>
                        <mat-error *ngIf="matriculaForm.get('IdTurma')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" >
                        <mat-label>Número de Parcelas</mat-label>
                        <input matInput formControlName="NumParcelas" maxlength="50" />
                        <mat-icon matSuffix class="secondary-text" >line_style</mat-icon >
                        <mat-error *ngIf="matriculaForm.get('NumParcelas')?.hasError('required')"> Número é obrigatório. </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" >
                        <mat-label>Primeiro Mes</mat-label>
                        <mat-select  name="PrimeiroMes"  formControlName="PrimeiroMes">
                        <mat-option *ngFor="let tipo of Meses | async " [value]="tipo.IdMes">
                            {{tipo.Mes}}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="matriculaForm.get('PrimerioMes')?.hasError('required')">Primeiro mês é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Situação</mat-label>
                        <mat-select  name="IdSituacaoMatricula"  formControlName="IdSituacaoMatricula">
                        <mat-option *ngFor="let tipo of SituacaoMatriculas | async " [value]="tipo.IdSituacaoMatricula">
                            {{tipo.SituacaoMatricula}}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="matriculaForm.get('IdSituacaoMatricula')?.hasError('required')">Situação do Aluno é obrigatório</mat-error>
                    </mat-form-field>
                </div>



            </div>
    </div>



    <!--  {{matriculaForm.value | json }}     -->



    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')" >Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!matriculaForm.valid"  >Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!matriculaForm.valid"  >Atualizar</button>
    </div>

    </form>
</div>
