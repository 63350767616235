<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Registrar Bairro</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<!-- <hr> -->

<form [formGroup]="BairroForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Bairro</mat-label>
                    <input matInput formControlName="Bairro1" maxlength="50" />
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="BairroForm.get('Bairro1')?.hasError('required')"> Bairro é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <!--  {{ BairroForm.value | json }}  -->
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!BairroForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success"
            [disabled]="!BairroForm.valid ">Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success"
            [disabled]="!BairroForm.valid">Atualizar</button>
    </div>
</form>