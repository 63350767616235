import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Perfil', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/Perfil', formContent);
  }

/*   update(id:any, formContent: any){
    return this.api.update('/Bairro', id, formContent);
  } */
  update(formContent: any){
    return this.api.actualizar('/Perfil', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Perfil/${id}`);
  }

  delete(id: any) {
    return this.api.apagar('/Perfil' , id);
  }
}
