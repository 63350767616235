import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import { ReportServerService, UtilsService, ContaCaixaService } from '@service/index';
import { Publico } from '@shared/publico';
import { AnoLectivoActual,ContaCaixa,Turma, Classe  } from '@core/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-demostrativo-mensal',
  templateUrl: './demostrativo-mensal.component.html',
  styleUrls: ['./demostrativo-mensal.component.scss']
})
export class DemostrativoMensalComponent  extends BasePage implements OnInit {
  tipo: Observable<ContaCaixa[]>;
  localTipos: any;
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private utilservice: UtilsService, private contaServico: ContaCaixaService,
    private _formBuilder: FormBuilder,
    private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }


    pesquisaForm  =  this._formBuilder.group({
      Ano: ['', Validators.required],
      IdTurma: [, Validators.required],
      Conta: ['', Validators.required],
      Tipo: ['', Validators.required],
    });
    anoLetivo: Observable<AnoLectivoActual[]>;
    turma: Observable<Classe[]>;
    @Input() public Tipo: any;

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.pesquisaForm  =  this._formBuilder.group({
      Ano: ['', Validators.required],
      IdTurma: [, Validators.required],
      Conta: ['', Validators.required],
      Tipo: ['', Validators.required],
    });
    this.pesquisaForm.get('Tipo')?.setValue(this.Tipo);
    this.anoLetivo = this.utilservice.BuscaAnoLectivo();
  //  this.turma = this.utilservice.CarregaTurmas();
  this.turma = this.utilservice.BuscaClasse();


    this.contaServico.loadByTipo('S').subscribe((res: any) => {
      this.tipo = res;
      this.localTipos= res;
    })
  }


  onKey(value: string) {
    let filter = value.toLowerCase();
   /*  if(value.length === 0){
      this.loadData();
    } */
    this.tipo =  this.localTipos.filter((option: any) =>  option.Conta.toLowerCase().includes(filter))
  }

  onSubmit() {
    this.pesquisaForm.get('Tipo')?.setValue(this.Tipo);
       this.reportService.demostrativoMensal(this.pesquisaForm.value).subscribe((response: Blob | MediaSource) => {
        const fileURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'CaixaServico.pdf';
          link.click();
          window.URL.revokeObjectURL(link.href);

      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }


}
