import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-consulta-drop-down',
  templateUrl: './consulta-drop-down.component.html',
  styleUrls: ['./consulta-drop-down.component.scss']
})
export class ConsultaDropDownComponent implements OnInit {

  constructor() { }
  @Input() Titulo: String;
  @Input() msgErro: string;
  @Input() filter: FormControl;
  @Input() labelButton: string;
  @Input() type = 'text';
  @Input() onAdd: any;
  @Input() isReadOnly = false;

  @Output() sendDataToParent = new EventEmitter<string>();
  @Output() Texto = new EventEmitter<string>();
  @Output() loader = new EventEmitter<string>();



 // data-loader="IdCliente1"
  ngOnInit(): void {
console.log(this.Titulo);

  }

  EnviarDataToParent() {
    this.sendDataToParent.emit();
  }

  EnviarTexto(data:any){
   this.Texto.emit(data);
 //  console.log(data);

  }
  dataloader(data:any){
    this.loader.emit(data);
  //  console.log(data);

   }

}
