

import { Perfil } from './../../core/models/model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Injector, ViewChild, HostListener,  } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { delay, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '@service/auth/auth.service';
import { BasePage } from '@shared/base-page';
import { UtilsService, SerialService, MenuService ,ParamService, AlertService} from '@service/index';
import { User, SerialSistema, Menu } from '@core/models/index';
import { map, take } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaixaPesquisaComponent } from '@component/caixa-pesquisa/caixa-pesquisa.component';
import { RelatorioPesquisaComponent } from '@component/relatorio-pesquisa/relatorio-pesquisa.component';
import { CaixaServicoComponent } from '@component/relatorio-pesquisa/caixa-servico/caixa-servico.component';
import { ResumoClasseComponent } from '@component/relatorio-pesquisa/resumo-classe/resumo-classe.component';
import { DemostrativoMensalComponent } from '@component/relatorio-pesquisa/demostrativo-mensal/demostrativo-mensal.component';
import { BalancoComponent } from '@component/relatorio-pesquisa/balanco/balanco.component';
import { AlunoConfirmaMatriculaComponent } from '@component/relatorio-pesquisa/aluno-confirma-matricula/aluno-confirma-matricula.component';
import 'bootstrap';
import * as $ from 'jquery';
import { TooltipPosition } from '@shared/tooltip/tooltip.enums';
import { PesquisaAlunoDividaTurmaComponent } from '@component/relatorio-pesquisa/pesquisa-aluno-divida-turma/pesquisa-aluno-divida-turma.component';
import { PesquisaCartaDevedorComponent } from '@component/relatorio-pesquisa/pesquisa-carta-devedor/pesquisa-carta-devedor.component';
import { CONSTS } from '@shared/const';
import { MiniPautaComponent } from '@component/relatorio-pesquisa/mini-pauta/mini-pauta.component';
import { MiniPautaGeralComponent } from '@component/relatorio-pesquisa/mini-pauta-geral/mini-pauta-geral.component';
import { PautaComponent } from '@component/relatorio-pesquisa/pauta/pauta.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends BasePage implements OnInit {
  [x: string]: any;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  //user : User;

  _serial: SerialSistema;
  titulo: any;
  perc: any;
  userRoutes: any[] = [];
  result: any;
  closeResult = '';
  anolectivo:any;

  user: Observable<User>;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    private observer: BreakpointObserver,
    private router: Router,
    public _utilSrvcs: UtilsService,
    public _authSrvcs: AuthService,
    private param: ParamService,
    private modalService: NgbModal,
    private serial: SerialService,
    private menuService: MenuService,
   // private snackBar: MatSnackBar,
   private alertService: AlertService,
    private routerCtrl: Router
  ) {
    super(injector);
    this.loadMenu();

  }
  title = 'O Inquilino - Software de Gestão de Rendas';
  perfil?: string;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  countdown : number = 60;
  intervalId: any;
  x = 0;
  y = 0;
  coordinates = "";
  permissao: boolean=false;
  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
      this.x = $event.clientX;
      this.y = $event.clientY;
      this.coordinates = `${this.x},${this.y}`;
  }

  ngOnInit(): void {
    this.user = this._authSrvcs.currentUser$;
    this.perfil = atob(localStorage.getItem(CONSTS.USER_PERMITION_LABEL)).toString().trim();
    this.loadParam();

  /*   this.startTimer(); */

    this._authSrvcs.updatemenu.subscribe((res:any) => {
      this.loadMenu();

    });


   // this.loadMenu();
    this.serial.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
      this._serial = res;
      this.perc = res.Prazo - res.contador;
      this.result = (100 * this.perc) / res.Prazo;
      this.titulo = this.formatSubtitle(this.result);
    });

  }


/*   startTimer(){
    this.intervalId = setInterval(() => {
      if (this.countdown > 0){
        this.countdown--;
      } else {
          this.showAlert();
          this.resetTimer();

        }

    },1000);

  } */

 /*  showAlert(){
    console.log('o tempo passou');

    this.alertService.openSnackBar('Temos Apartamentos com divida','successful','Close')

  }
  resetTimer(){
    this.countdown = 60;
  }
 */
  loadParam(){
    this.param.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      console.log(response);

      this.anolectivo = response[0].AnoLectivo;
     /*  this.tiporecibo = response[0].TipoRecibo;
      this.numeroParcela = response[0].Parcela;
      this.dataInicio = new Date(response[0].DataInicio);
      this.mes = this.dataInicio.getMonth()+1; */
    })
  }

   loadMenu() {
    const role = localStorage.getItem('USER_PERMITION') ;
    this.menuService.menuByRole(atob(role)).pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
      this.userRoutes = res;
    });
  }




  pesquisaMovimento() {this.modalService.open(CaixaPesquisaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
      })
      .result.then( (result) => {
          this.closeResult = `Closed with: ${result}`;
          console.log('Modal close result', this.closeResult);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          console.log('Modal close result', this.closeResult);
        }
      );
  }

  miniPauta() {this.modalService.open(MiniPautaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

miniPautaGeral() {this.modalService.open(MiniPautaGeralComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

pauta() {this.modalService.open(PautaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}


  pesquisaRelatorio() {this.modalService.open(RelatorioPesquisaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

pesquisaDividaTurma() {this.modalService.open(PesquisaAlunoDividaTurmaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}
cartaDevedor() {this.modalService.open(PesquisaCartaDevedorComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

pesquisaCaixaServico() {this.modalService.open(CaixaServicoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

balanco() {this.modalService.open(BalancoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}


resumoClasse(tipo: any) {const modalref = this.modalService.open(ResumoClasseComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md',
})
modalref.componentInstance.Tipo = tipo;
modalref.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

demostrativo(tipo: string) {const modalref = this.modalService.open(DemostrativoMensalComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md'})
modalref.componentInstance.Tipo = tipo;
modalref.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}

alunoMatriculados(tipo: string) {const modalref = this.modalService.open(AlunoConfirmaMatriculaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md'})
modalref.componentInstance.Tipo = tipo;
var title;
if (tipo =='1'){
  title = 'Lista de Inquilinos'
} else if (tipo = '2'){
  title = 'Lista de Inquilinos que Confirmarm'
}else if (tipo = '2'){
  title = 'Lista de Inquilinos que não Confirmarm'
}

modalref.componentInstance.Titulo = title;

modalref.result.then( (result) => {
    this.closeResult = `Closed with: ${result}`;
    console.log('Modal close result', this.closeResult);
  },
  (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    console.log('Modal close result', this.closeResult);
  }
);
}


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  formatSubtitle(percent: number) {
    console.log(percent);

    if (percent >= 100) {
      return 'A sua lincença expirou';
      /*  }else if(percent >= 50){
         return "Half" */
    } else if (percent == 99) {
      return 'A sua Lincênça termina Hoje';
    } else if (percent == 98) {
      return 'A sua Lincênça termina amanhã';
    } else {
      return 'Lincênça termina em ' + this._serial.contador + ' dias';
    }
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
  }

  showMenu(): boolean {
    this.sidenav.mode = 'push';
    this.sidenav.ngOnDestroy();
    return this.router.url !== '/login';
  }

  logout() {
    this._authSrvcs.clearUserToken();
    this.routerCtrl.navigateByUrl('/login');
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
    clearInterval(this.intervalId)
  }
}
