<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Lista de Disciplinas</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div>
  <input class="form-control " placeholder="Pesquisa" type="text" [formControl]="filter" />
</div>

<form [formGroup]="ClasseForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">

      <!--             <div class="accordion" id="accordionExample">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Disciplina</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let D of disciplina | slice: (page-1) * pageSize : page * pageSize">
                            <td><input type="checkbox" [(ngModel)]="D.checked"
                               >{{ D.IdDisciplina }}</td>
                            <td> {{ D.Disciplina1}} </td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-between p-2">

           <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="disciplina.length"></ngb-pagination>

                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadDisciplina()">
                        <option [ngValue]="5">5 items por pagina</option>
                        <option [ngValue]="10">10 items por pagina</option>
                        <option [ngValue]="20">20 items por pagina</option>
                    </select>
                </div>
            </div> -->


      <section>
        <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
        <!--   <div>
            <ng-multiselect-dropdown formArrayName="disc" [placeholder]="'Pesquisar'" (onDeSelect)="onItemChange($event)"
              [settings]="dropdownSettings" [data]="disciplina" [value]="IdDisciplina" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
            </ng-multiselect-dropdown>
          </div> -->
          <br>
          <div *ngFor="let country of disciplina; let i=index">
            <input type="checkbox" formArrayName="ClasseDisciplinas" [value]="country.IdDisciplina"
              (change)="onCheckboxChange($event)" />
            {{ country.Disciplina1}}
          </div>
<br>

          <button mat-raised-button class="btn btn-success" type="submit">Submit</button>
        </form>

        <!--      <p>{{ form.value | json }}</p>
                <p>{{ TEMPERATURES | json }}</p> -->
      </section>

    </div>
    <!-- <select class="custom-select" id="basic" multiple="multiple">
          <option value="cheese" selected>Cheese</option>
          <option value="tomatoes">Tomatoes</option>
          <option value="mozarella">Mozzarella</option>
          <option value="mushrooms">Mushrooms</option>
          <option value="pepperoni">Pepperoni</option>
          <option value="onions">Onions</option>
       </select>
 -->



  </div>

</form>



<!-- {{ClasseForm.value | json}} -->


<!-- <div class="contaniner-fluild" >

    <div class="row">
        <div class="col-10">
            <form>



            </form>
        </div>

    </div>
  <br>

</div> -->
