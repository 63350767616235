<app-cabecario Titulo='Funcionários' labelButton="Novo" (sendDataToParent) = "onAddAluno()"  (Texto)="applyFilter($event)"></app-cabecario>

<table class="content-table">
        <thead >
            <tr>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col">Data de Nascimento</th>
                <th scope="col">Opções</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let alu of dados ">

                <td>{{alu.IdFuncionario}} </td>
                <td>{{alu.Nome}}</td>
                <td>{{alu.DataNascimento | date: "dd/MM/yyyy"}}</td>

                <td>
                    <button type="button" name="" id="" title="Editar Dados do Aluno" class="btn btn-warning btn-sm mr-2" [routerLink]="'/funcionarioForm/' + alu.IdFuncionario" > <i class="fas fa-edit"></i></button>
                    <button type="button" name="" id="" title="Eliminar Aluno" (click)="onDelete(alu.IdAluno)" class="btn btn-danger btn-sm"> <i class="fas fa-trash"></i></button>
              </td>
            </tr>
        </tbody>
</table>

<div class="d-flex justify-content-between p-2">
<ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="15" [collectionSize]="collectionSize" (pageChange)="pageChanged($event)" ></ngb-pagination>
<select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadData()">
  <option [ngValue]="5">5 items por pagina</option>
  <option [ngValue]="10">10 items por pagina</option>
  <option [ngValue]="20">20 items por pagina</option>
</select>
</div>
<router-outlet></router-outlet>
