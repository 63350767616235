import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TipoClasseService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/TipoClasse', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/TipoClasse', formContent);
  }

/*   update(id:any, formContent: any){
    return this.api.update('/Bairro', id, formContent);
  } */
  update(formContent: any){
    return this.api.actualizar('/TipoClasse', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/TipoClasse/${id}`);
  }

  delete(id: any) {
    return this.api.apagar('/TipoClasse' , id);
  }
}
