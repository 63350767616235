import { Component, OnInit } from '@angular/core';
import { Publico } from '@shared/publico';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  pdfSource: any;
  constructor(private publico: Publico) { }

  ngOnInit(): void {
    this.publico.verificaLincenca();
  }

}

