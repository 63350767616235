import {
  Component,
  Injector,
  Input,
  OnInit,
  PipeTransform,
} from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import {
  UtilsService,
  ClasseService,
  ClasseDisciplinaService,
  DisciplinaService,
  TipoClasseService,
} from '@service/index';
import {
  Disciplina,
  Classe,
  ClasseLingua,
  ClasseOpcao,
  NivelAcademico,
  Curso,
  NivelEnsino,
  ClasseDisciplina,
} from '@core/models/index';
import { BasePage } from '@shared/base-page';
import { Observable, Subject } from 'rxjs';
import { ListDisciplinaComponent } from '../list-disciplina/list-disciplina.component';
import { takeUntil } from 'rxjs/operators';

//import { DisciplinaService } from 'src/app/core/services/disciplina.service';
//import { ClasseDisciplinaService } from 'src/app/core/services';

export interface ServerResponse {
  books: any;
}

@Component({
  selector: 'app-classe-form',
  templateUrl: './classe-form.component.html',
  styleUrls: ['./classe-form.component.scss'],
})
export class ClasseFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private tipoclasseService: TipoClasseService,
    private classeService: ClasseService,
    private disciplinaService: DisciplinaService,
    private classeDisciplinaService: ClasseDisciplinaService,
    private utilservice: UtilsService
  ) {
    super(injector);
  }

  ClasseForm = this._formBuilder.group({
    IdClasse: [''],
    Classe: ['', Validators.required],
    IdCurso: [''],
    IdNivelEnsino: ['', Validators.required],
    CodigoLingua: [''],
    CodigoOpcao: [''],
    IdTipoClasse: [''],
  });
  dropform: FormGroup;

  discForms: FormArray = this.fb.array([]);
  isEditable: boolean = false;
  isVisible: boolean = false;
  classedisciplinas: any;
  lingua: Observable<ClasseLingua[]>;
  nivelensino: Observable<NivelEnsino[]>;
  opcao: Observable<ClasseOpcao[]>;
  curso: Observable<Curso[]>;
  bankAccountForms: FormArray = this.fb.array([]);
  userTable: FormGroup;
  control: FormArray;
  mode: boolean;
  closeResult = '';
  touchedRows: any;
  titulo="Inserir Edificio";
  classeDisciplinas: FormArray;
  dropdownSettings = {};
  disciplina: Disciplina[] = [];
  tableData = {};
  tipoClasse: any[] = [];

  @Input() public classe: any;
  @Input() public isReadOnly: boolean = false;
  public usersForm!: FormGroup;

  ngOnInit(): void {
    this.nivelensino = this.utilservice.listadeNivelEnsino();
    this.lingua = this.utilservice.listadeClasseLingua();
    this.opcao = this.utilservice.listadeClasseOpcao();
    this.curso = this.utilservice.listadeCurso();
    this.carregaTipoClasse();
    this.dropform = this._formBuilder.group({
      dropDisciplina: [''],
    });

    if (this.isReadOnly) {
      console.log(this.classe);
      this.loadById();
    }
    this.loadDisciplina();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'IdDisciplina',
      textField: 'Disciplina1',
      selectAllText: 'Selecionar tudo',
      unSelectAllText: 'Desmarcar tudo',
      // itemsShowLimit: 10,
      searchPlaceholderText: 'Pesquisar',
      allowSearchFilter: true,
    };

    this.touchedRows = [];
    this.userTable = this.fb.group({
      tableRows: this.fb.array([]),
    });
    this.addRow();

    this.ToDoListForm = new FormGroup({
      items: new FormArray([]),
    });

    this.carregarDisciplinaClasse();
  }

  onAddDisciplina() {
    const modalref = this.modalService.open(ListDisciplinaComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    modalref.componentInstance.classe = this.classe;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log('Modal close result', this.closeResult);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log('Modal close result', this.closeResult);
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngAfterOnInit() {
    this.control = this.userTable.get('tableRows') as FormArray;
  }

  addRow() {
    const control = this.userTable.get('tableRows') as FormArray;
    // control.push(this.initiateForm());
  }

  deleteRow(index: number, id: any) {
    const control = this.userTable.get('tableRows') as FormArray;
    control.removeAt(index);
    this.showLoadingView();
    this.classeDisciplinaService.delete(id).subscribe(
      (response: any) => {
        this.carregarDisciplinaClasse();
        this.showMessage(
          'Sucesso',
          'disciplina apagado com sucesso',
          'success'
        ).then(() => this.activeModal.close('Save click'));
      },
      (error: any) => {
        console.log('Error create employee', error);
        this.showMessage(
          'Erro',
          'Ocorreu um erro ao apagar Disciplina Tente novamente',
          'error'
        ).then(() => this.activeModal.close('Save click'));
      }
    );
  }

  editRow(group: FormGroup) {
    console.log(group);
    group.get('isEditable')?.setValue(true);
  }

  doneRow(group: FormGroup) {
    console.log(group);

    this.classeDisciplinaService
      .update(group.value).pipe(takeUntil(this.destroySubject))
      .subscribe((res: any) => console.log(res));
    this.isEditable = false;
    group.get('isEditable')?.setValue(false);
  }

  get getFormControls(): FormArray {
    const control = this.userTable.get('tableRows') as FormArray;
    return control;
  }

  toggleTheme() {
    this.mode = !this.mode;
  }

  loadById() {
    console.log(this.classe.IdTipoClasse);
    this.titulo="Alterar Edificio"
    this.classedisciplinas = this.classe.ClasseDisciplinas;
    this.ClasseForm.get('IdClasse')?.setValue(this.classe.IdClasse);
    this.ClasseForm.get('Classe')?.setValue(this.classe.Classe);
    this.ClasseForm.get('IdCurso')?.setValue(0);
    this.ClasseForm.get('IdNivelEnsino')?.setValue(this.classe.IdNivelEnsino);
    this.ClasseForm.get('CodigoLingua')?.setValue(1);
    this.ClasseForm.get('CodigoOpcao')?.setValue(1);
    this.ClasseForm.get('IdTipoClasse')?.setValue(1);
  }

  toggleReadOnly(id: any) {
    var fieldElement = <HTMLInputElement>document.getElementById(id);
    fieldElement.readOnly = false;
    console.log(id);
  }

  desabilitar(id: any) {
    var fieldElement = <HTMLInputElement>document.getElementById(id);
    fieldElement.readOnly = true;
  }
  ReadOnlyOff(id: any) {
    var fieldElement = <HTMLInputElement>document.getElementById('A1' + id);
    var fieldElement2 = <HTMLInputElement>document.getElementById('A2' + id);
    fieldElement.readOnly = true;
    fieldElement2.readOnly = true;
  }

  carregaTipoClasse() {
    this.tipoclasseService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((res) => {
      console.log(res);

      this.tipoClasse = res;
    });
  }
  ///
  adicionarDisciplina() {
    this.showLoadingView();
    this.classeDisciplinaService.addClasseDisciplinas(this.tableData).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        console.log('Disciplina adicionado', response);
        this.carregarDisciplinaClasse();
        this.showMessage(
          'Sucesso',
          'Disciplina(s) adicionado(s) com sucesso',
          'success'
        ).then(() => this.activeModal.close('Save click'));
      },
      (error: any) => {
        console.log('Error create employee', error);
        this.showMessage(
          'Erro',
          'Ocorreu um erro ao adicionar disciplina(s) Tente novamente',
          'error'
        ).then(() => this.activeModal.close('Save click'));
      }
    );
  }

  //

  onSubmit() {
    this.showLoadingView();

    console.log('Form Value to submmit', this.ClasseForm.value);

    this.ClasseForm.get('IdCurso')?.setValue(0);
    this.ClasseForm.get('CodigoLingua')?.setValue(1);
    this.ClasseForm.get('CodigoOpcao')?.setValue(1);
    this.ClasseForm.get('IdTipoClasse')?.setValue(1);

    this.classeService.create(this.ClasseForm.value).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.showMessage('Sucesso', 'Edificio Criado com sucesso', 'success').then(
          () => this.activeModal.close('Save click')
        );
      },
      (error: any) => {
        console.log('Error create employee', error);
        this.showMessage(
          'Erro',
          'Ocorreu um erro ao criar Edificio Tente novamente',
          'error'
        ).then(() => this.activeModal.close('Save click'));
      }
    );
  }
  editDomain() {
    this.isVisible = true;
  }

  onUpdate() {
    this.showLoadingView();
    console.log('Form Value to submmit', this.ClasseForm.value);
    this.classeService.update(this.ClasseForm.value).pipe(takeUntil(this.destroySubject))
    .subscribe(
      (response: any) => {
        this.showMessage(
          'Sucesso',
          'Edificio atualizar com sucesso',
          'success'
        ).then(() => this.activeModal.close('Update click'));
      },
      (error: any) => {
        console.log('On error ocurr', error);
        this.showMessage(
          'Erro',
          'Ocorreu um erro ao atualizar Edificio, Tente novamente',
          'error'
        ).then(() => this.activeModal.close('Update click'));
      }
    );
  }

  onEditContacto(persDoc: any) {
    console.log(persDoc);
  }

  formGroup: FormGroup;
  get userFormGroups() {
    return this.formGroup.get('classeDisciplinas') as FormArray;
  }

  loadDisciplina() {
    this.showLoadingView();
    this.disciplinaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        // this.localDisciplina = response;
        this.disciplina = response;

        //   this.addCheckboxes();
        console.log('Disciplina carregado com sucesso', response);

        this.showContentView();
      },
      (error) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }

  // prio = [0, 1, 2];
  ToDoListForm: any;
  vdisciplina2: { IdClasse: any; IdDisciplina: [] }[] = [];
  // Form: { IdClasse: any; IdDisciplina: [] }[] = [];
  // ListForm: { IdClasse: any; IdDisciplina: any }[] = [];

  add() {
    this.ToDoListForm.get('items').push(
      new FormGroup({
        IdClasseDisciplina: new FormControl({ disabled: true }, [
          Validators.required,
        ]),
        completed: new FormControl(false, [Validators.required]),
        IdClasse: new FormControl('', [Validators.required]),
        IdDisciplina: new FormControl('', [Validators.required]),
        Ordem: new FormControl(0, [Validators.required]),
      })
    );
  }

  onItemSelect(item: any): void {
    this.vdisciplina2.push({
      IdClasse: this.classe.IdClasse,
      IdDisciplina: item.IdDisciplina,
    });

    this.tableData = {
      IdClasse: this.classe.IdClasse,
      ClasseDisciplinas: this.vdisciplina2,
    };
    console.log(this.tableData);
  }

  onItemDeSelect(dado: any) {
    this.vdisciplina2.splice(this.vdisciplina2.indexOf(dado.IdDisciplina), 1);
    // this.vdisciplina2=     this.vdisciplina2.filter(s => s.IdDisciplina != dado.IdDisciplina)
    this.tableData = {
      IdClasse: this.classe.IdClasse,
      ClasseDisciplinas: this.vdisciplina2,
    };
  }

  carregarDisciplinaClasse() {
    if (this.ToDoListForm.get('items').length > 0) {
      for (let x in this.ToDoListForm.get('items')) {
        this.ToDoListForm.get('items').removeAt(x);
      }
    }
    let dat = this.classedisciplinas; // this.getmyData();
    if (dat.length > 0) {
      for (let x in dat) {
        this.ToDoListForm.get('items').push(
          new FormGroup({
            IdClasseDisciplina: new FormControl(dat[x].IdClasseDisciplina, [
              Validators.required,
            ]),
            completed: new FormControl(dat[x].Disciplina.Disciplina1, [
              Validators.required,
            ]),
            IdClasse: new FormControl(dat[x].IdClasse, [Validators.required]),
            IdDisciplina: new FormControl(dat[x].IdDisciplina, [
              Validators.required,
            ]),
            Ordem: new FormControl(dat[x].Ordem, [Validators.required]),
            isEditable: new FormControl(false, [Validators.required]),
          })
        );
      }
    }
  }

  deleteItem(i: number) {
    this.ToDoListForm.get('items').removeAt(i);
  }
  track(item: any, index: number) {
    return index;
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
