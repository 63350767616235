<app-nav></app-nav>
<!-- <router-outlet></router-outlet> -->




<!--  <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>  
 <app-sidenav></app-sidenav>  -->
<!-- 
 <mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
     
      <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
       <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header> 
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>  -->