import {  Component, Injector, Input, OnInit, PipeTransform } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, BairroService } from '@service/index';
import {Bairro } from '@core/models/index';
import {BasePage} from '@shared/base-page';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bairro-form',
  templateUrl: './bairro-form.component.html',
  styleUrls: ['./bairro-form.component.scss']
})
export class BairroFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private bairroService: BairroService,
    private utilservice: UtilsService) { super(injector);  }

    BairroForm   =  this._formBuilder.group({
      IdBairro: [''],
      Bairro1: ['', Validators.required]
    });


    @Input() public bairro: any;
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {

      if (this.isReadOnly) {
        this.loadById();
      }
  }

  loadById() {
    console.log(this.bairro);
    this.BairroForm.get('IdBairro')?.setValue(this.bairro.IdBairro);
    this.BairroForm.get('Bairro1')?.setValue(this.bairro.Bairro1);
  }

  onSubmit() {
      this.showLoadingView();
      this.bairroService.create(this.BairroForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessage('Sucesso', 'Bairro Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Bairro Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.showLoadingView();
      this.bairroService.update(this.BairroForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessage('Sucesso', 'Bairro atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizado Bairro, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}

