import { Component, Input, OnInit, Injector  } from '@angular/core';
import { NgbModal, NgbActiveModal,  NgbDateStruct, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators,  AbstractControl,FormGroupDirective, ValidatorFn, ValidationErrors, } from '@angular/forms';
import {UtilsService, AlunoDocumentoService} from '@service/index';
import { Observable, Subject } from 'rxjs';
import {TipoContacto, TipoDocumento} from '@core/models/index';
import { BasePage } from '@shared/base-page';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-documento-aluno',
  templateUrl: './documento-aluno.component.html',
  styleUrls: ['./documento-aluno.component.scss']
})
export class DocumentoAlunoComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector ,
              public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder,
              private documentoservice: AlunoDocumentoService,
              private utilservice: UtilsService) {
                super(injector);

                }

  @Input() public isReadOnly: boolean = false;
  @Input() public documento: any;

  documentoForm  =  this._formBuilder.group({
    IdDocumentoAluno: [''],
    IdTipoDocumento: ['', Validators.required],
    Numero: ['', Validators.required],
    DataEmissao: ['', Validators.required],
    DataValidade: ['', Validators.required],
    LocalEmissao: ['', Validators.required],
    IdAluno: ['', Validators.required]
  });

   TipoDocumentos: Observable<TipoDocumento[]>;

  ngOnInit() {
    this.TipoDocumentos = this.utilservice.BuscaTipoDocumento();
    console.log(this.documento);
    this.documentoForm.get('IdAluno')?.setValue(this.documento.IdAluno);
     if (this.isReadOnly) {
      this.loadById();
    }
  }

 onSubmit(){
       this.showLoadingView();
       const datevalidade = moment(this.toModel(this.documentoForm.get('DataValidade')?.value)).format("YYYY-MM-DD");
       const newDate = moment(this.toModel(this.documentoForm.get('DataEmissao')?.value)).format("YYYY-MM-DD");
       this.documentoForm.get('DataEmissao')?.setValue(newDate);
       this.documentoForm.get('DataValidade')?.setValue(datevalidade);
       console.log(this.documentoForm.value);
       this.showLoadingView();
       this.documentoservice.create(this.documentoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
         this.showMessage('Sucesso', 'Contacto Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log('Error create Contacto', error);
        this.showMessage('Erro', 'Ocorreu um erro ao criar Contacto Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  loadById() {
    const date = moment(this.documento.DataEmissao).format('DD/MM/yyyy');
    const ngbDate: NgbDateStruct = {
      year: parseInt(date.split('/')[2]), month: parseInt(date.split('/')[1]), day: parseInt(date.split('/')[0]),
    }
    const datevalidade = moment(this.documento.DataValidade).format('DD/MM/yyyy');
    const ngbDatevalidade: NgbDateStruct = {
      year: parseInt(date.split('/')[2]), month: parseInt(date.split('/')[1]), day: parseInt(date.split('/')[0]),
    }
      this.documentoForm.get('IdAluno')?.setValue(this.documento.IdAluno);
      this.documentoForm.get('IdTipoDocumento')?.setValue(this.documento.TipoDocumento.IdTipoDocumento);
      this.documentoForm.get('Numero')?.setValue(this.documento.Numero);
      this.documentoForm.get('DataEmissao')?.setValue(ngbDate);
      this.documentoForm.get('DataValidade')?.setValue(ngbDatevalidade);
      this.documentoForm.get('IdDocumentoAluno')?.setValue(this.documento.IdDocumentoAluno);
      this.documentoForm.get('LocalEmissao')?.setValue(this.documento.LocalEmissao);
  }
  onUpdate() {
      this.showLoadingView();

       const datevalidade = moment(this.toModel(this.documentoForm.get('DataValidade')?.value)).format("YYYY-MM-DD");
       const newDate = moment(this.toModel(this.documentoForm.get('DataEmissao')?.value)).format("YYYY-MM-DD");
       this.documentoForm.get('DataEmissao')?.setValue(newDate);
       this.documentoForm.get('DataValidade')?.setValue(datevalidade);
       this.documentoservice.actualizar(this.documentoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Contacto actualizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log('Error create Contacto', error);
        this.showMessage('Erro', 'Ocorreu um erro ao actualizar Contacto Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

 }
