import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AlunoDocumentoService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/AlunoDocumento', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/AlunoDocumento', formContent);
  }

  update(id:any, formContent: any){
    return this.api.update('/AlunoDocumento', id, formContent);
  }

  actualizar(formContent: any){
    return this.api.actualizar('/AlunoDocumento', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/AlunoDocumento/${id}`);
  }
  loadByIdAluno(id: any) {
    return this.api.get(`/AlunoDocumento/aluno/${id}`);
  }
}
