import { DecimalPipe } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnInit,
  PipeTransform,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  NgbActiveModal,
  ModalDismissReasons,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Disciplina, ClasseDisciplina } from '@core/models/index';
import { DisciplinaService, ClasseDisciplinaService } from '@service/index';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { BasePage } from '@shared/base-page';
import { Subject, of } from 'rxjs';

@Component({
  selector: 'app-list-disciplina',
  templateUrl: './list-disciplina.component.html',
  styleUrls: ['./list-disciplina.component.scss'],
})
export class ListDisciplinaComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public pipe: DecimalPipe,
    private disciplinaService: DisciplinaService,
    private classeDisciplinaService: ClasseDisciplinaService
  ) {
    super(injector);
    this.form = this.fb.group({
      IdClasse: [''],
      ClasseDisciplinas: new FormArray([]),
      disc: new FormArray([]),
    });
  }
  TEMPERATURES: { IdDisciplina: any; IdClasse: any }[] = [];

  onCheckboxChange(event: any) {
    const orders = this.form.controls.ClasseDisciplinas as FormArray;
    if (event.target.checked) {
      //   this.TEMPERATURES= [];
      //   orders.push(new FormControl(IdClasse: new FormControl(Number(event.target.value)) ));

      orders.push(
        new FormGroup({
          IdClasse: new FormControl(this.classe.IdClasse),
          IdDisciplina: new FormControl(Number(event.target.value)),
        })
      );

      let o = this.form.controls.ClasseDisciplinas.value;
      this.TEMPERATURES = [];
      this.form.controls.ClasseDisciplinas.value.forEach((i: any) => {
        this.TEMPERATURES.push({
          IdDisciplina: i,
          IdClasse: this.classe.IdClasse,
        });
      });
    } else {
      const index = orders.controls.findIndex(
        (x) => x.value === event.target.value
      );
      orders.removeAt(index);
      this.ToDoListForm.get('items').removeAt(index);
      this.TEMPERATURES.splice(index, 1);
      let ind = index;
    }
  }



  /*   function RemoveElementFromObjectArray(key: number) {
      objectArray.forEach((value,index)=>{
          if(value.id==key) objectArray.splice(index,1);
      }); */
  //  MyArrayType = Array<{id: number, text: string}>;
  ClasseForm = this.fb.group({
    IdClasse: [''],
    Classe: [''],
    IdCurso: [''],
    IdNivelEnsino: [''],
    CodigoLingua: [''],
    CodigoOpcao: [''],
    grocery: '',
  });

  ToDoListForm: any;
  ListForm: { IdClasse: any; IdDisciplina: any }[] = [];
  disciplina: Disciplina[] = [];
  localDisciplina: Disciplina[] = [];
  CountryData: ClasseDisciplina[] = [];
  classeDisciplina: ClasseDisciplina[] = [];
  checkboxesDataList: ClasseDisciplina[] = [];
  disciplinas = new Array();
  //tableData = [];
  //selectedIds = [];
  form: FormGroup;
  public page = 1;
  public pageSize = 10;
  collectionSize = 0;
  closeResult = '';
  filter = new FormControl('');

  //vdisciplinas: [] = [];
  ordersData: ClasseDisciplina[] = [];
  dropdownSettings = {};
  @Input() public classe: any;
  vdisciplina: { IdDisciplina: any; Disciplina: any }[] = [];
  vdisciplina2: { IdClasse: any; IdDisciplina: [] }[] = [];

  ngOnInit(): void {
    /* this.dropdownSettings = {
      singleSelection: false,
      idField: 'IdDisciplina',
      textField: 'Disciplina1',
      selectAllText: 'Marcar tudo',
      unSelectAllText: 'Desmarcar tudo',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
 */
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'IdDisciplina',
      textField: 'Disciplina1',
      selectAllText: 'Selecionar tudo',
      unSelectAllText: 'Desmarcar tudo',
      // itemsShowLimit: 10,
      searchPlaceholderText: 'Pesquisar',
      allowSearchFilter: true,
    };

    this.loadDisciplina();
    this.filter.valueChanges
      .pipe(
        startWith(' '),
        map((text) => this.search(text, this.pipe))
      )
      .subscribe((res) => {
        this.disciplina = res;
        this.collectionSize = res.length;
      });
    this.form.get('IdClasse')?.setValue(this.classe.IdClasse);
    // this.fetchSelectedItems();
    // this.fetchCheckedIDs();
    this.ToDoListForm = new FormGroup({
      items: new FormArray([]),
    });
  }
  onSubmit() {}

  onItemSelect(item: any) {
    this.vdisciplina2.push({
      IdClasse: this.classe.IdClasse,
      IdDisciplina: item.IdDisciplina,
    });
    let t = [
      { IdClasse: this.classe.IdClasse },
      { ClasseDisciplinas: this.vdisciplina2 },
    ];

    this.ListForm = [...this.vdisciplina2];
  }

  onItemDeSelect(dado: any) {
    this.vdisciplina2 = this.vdisciplina2.filter(
      (s) => s.IdDisciplina != dado.IdDisciplina
    );
    let t = {
      IdClasse: this.classe.IdClasse,
      ClasseDisciplinas: this.vdisciplina2,
    };
  }

  onItemChange($event: any) {
    this.vdisciplina = this.vdisciplina.filter((s) => s.IdDisciplina != event);
    this.vdisciplina.push($event);
  }

  loadDisciplina() {
    this.showLoadingView();
    this.disciplinaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.localDisciplina = response;
        this.disciplina = response;
        this.ordersData = response;
        this.checkboxesDataList = response;
        if (this.localDisciplina && this.localDisciplina.length) {
          this.collectionSize = this.localDisciplina.length;
        }
        this.showContentView();
      },
      (error) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }

  search(text: string, pipe: PipeTransform): Disciplina[] {
    return this.localDisciplina.filter((disc) => {
      const term = text.toLowerCase();
      return (
        disc.Disciplina1.toLowerCase().includes(term) ||
        disc.Sigla.toLowerCase().includes(term)
      );
    });
  }
  toControl(absCtrl: AbstractControl): FormControl {
    const ctrl = absCtrl as FormControl;
    // if(!ctrl) throw;
    return ctrl;
  }
  submitForm() {
    console.log(this.TEMPERATURES);
  }

  submit() {
    let t = {
      IdClasse: this.classe.IdClasse,
      ClasseDisciplinas: this.vdisciplina2,
    };
    this.showLoadingView();
    this.classeDisciplinaService.addClasseDisciplinas(t).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.showMessage(
          'Sucesso',
          'Disciplina(s) adicionado(s) com sucesso',
          'success'
        ).then(() => this.activeModal.close('Save click'));
      },
      (error: any) => {
        console.log('Error create employee', error);
        this.showMessage(
          'Erro',
          'Ocorreu um erro ao adicionar disciplina(s) Tente novamente',
          'error'
        ).then(() => this.activeModal.close('Save click'));
      }
    );
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  selectedItemsList: any[];
  checkedIDs: any[];

  /* changeSelection() {
    this.fetchSelectedItems()
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      return value.isChecked
    });
  }

  fetchCheckedIDs() {
    this.checkedIDs = []
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.IdClasseDisciplina);
      }
    });
  }
 */
}

/* function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
} */
