import { Component, Input, OnInit, Injector } from '@angular/core';
import { BasePage } from '@shared/base-page';
import {MatSelectModule} from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, Validators,  AbstractControl,
  FormGroupDirective,
  ValidatorFn,
  ValidationErrors, } from '@angular/forms';
//import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal,  NgbDateStruct, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {AlunoService, UtilsService, TipodocService, MatriculaService, DocumentoSecretariaService} from '@service/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';






@Component({
  selector: 'app-documento-secretaria-fom',
  templateUrl: './documento-secretaria-fom.component.html',
  styleUrls: ['./documento-secretaria-fom.component.scss']
})
export class DocumentoSecretariaFomComponent extends BasePage implements OnInit {
  [x: string]: any;

  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder, private tipoDocService: TipodocService, private matriculaService: MatriculaService,
              private modalService: NgbModal, private docSecretarioService: DocumentoSecretariaService,
              private alunoservice: AlunoService,
              private utilservice: UtilsService) { super(injector);  }


  cadastroForm  =  this._formBuilder.group({
    AnoLectivo: ['', Validators.required],
    Codigo: [0],
    IdTipoDoc: ['', Validators.required],
    Esteve: [''],
    Transferencia: [''],
    Obs: [''],
    Encarregado: [''],
    IdMatricula: [1, Validators.required],
    Embaixada: [''],
    Classificacao: [''],
    Livro: ['']

  });

  config: any;

  matriculas: any[] = [];
  matriculas2: any[] = [];
  tipoDocumentos: any[] = [];
  closeResult = '';
  selected = 'option2';

  anoLetivo: any; //Observable<AnoLectivoActual[]>;


  certificado: boolean = false;
  visto: boolean= false;
  guia: boolean = false

  @Input() public aluno: any;
  @Input() public isReadOnly: boolean = false;
  @Input() public titulo: any  = "Registar Documentos Secretaria";

  //"strictPropertyInitialization": false
  ngOnInit(): void {

   // this.anoLetivo = this.utilservice.BuscaAnoLectivo();
    this.config = {
      itemsPerPage: 50,
      filter: "",
      page: 1,
    }

    this.loadData();
    if (this.isReadOnly) {
      console.log(this.aluno);

      this.loadById();
    }

     this.cadastroForm.get('AnoLectivo')?.valueChanges.subscribe(data => {
      if (this.config.filter == null){
        this.config.filter = ""
      }
      this.config.Anolectivo= data;
      this.loadMatriculas();
    });

    this.cadastroForm.get('IdTipoDoc')?.valueChanges.subscribe(data => {
      console.log(data);
      this.removeItem();
      this.handleDocForm(data);
    });
  }

  handleDocForm(data: any){
    this.removeItem();
    if(data == 3){
      this.visto = true;
      this.cadastroForm.addControl('Embaixada',new FormControl('', Validators.required))
    }
    else
    {
      this.visto = false;
    }

    if(data == 1003){
      this.guia = true;
      this.cadastroForm.addControl('Transferencia',new FormControl('', Validators.required))
      this.cadastroForm.addControl('Encarregado',new FormControl('', Validators.required))
      this.cadastroForm.addControl('Obs',new FormControl('', Validators.required))
       }
    else
    {
      this.guia = false;
    }

    if(data == 1002){
      this.certificado = true;
      this.cadastroForm.addControl('Classificacao',new FormControl('', Validators.required))
      this.cadastroForm.addControl('Livro',new FormControl('', Validators.required))
       }
    else
    {
      this.certificado = false;
    }

  }

  loadMatriculas(){
    this.docSecretarioService.filtro2(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.matriculas = response.data;
      this.matriculas2 = response.data;
    }, (error: any) => {
      console.log('Error on request', error);
    });
  }

  removeItem(){
    (<FormGroup>this.cadastroForm).removeControl('Embaixada');
    (<FormGroup>this.cadastroForm).removeControl('Transferencia');
    (<FormGroup>this.cadastroForm).removeControl('Encarregado');
    (<FormGroup>this.cadastroForm).removeControl('Classificacao');
    (<FormGroup>this.cadastroForm).removeControl('Livro');
    (<FormGroup>this.cadastroForm).removeControl('Obs');
  }

  get Embaixada() {
    return this.cadastroForm.get('Embaixada') as FormControl;
  }

  get Transferencia() {
    return this.cadastroForm.get('Transferencia') as FormControl;
  }

  get Encarregado() {
    return this.cadastroForm.get('Encarregado') as FormControl;
  }

  get Obs() {
    return this.cadastroForm.get('Obs') as FormControl;
  }

  get Classificacao() {
    return this.cadastroForm.get('Classificacao') as FormControl;
  }

  get Livro() {
    return this.cadastroForm.get('Livro') as FormControl;
  }





  loadById() {

    this.config.Anolectivo= this.aluno.Matriculas.Anolectivo;
    this.loadMatriculas();
    this.handleDocForm(this.aluno.IdTipoDoc);

    this.cadastroForm.get('AnoLectivo')?.setValue(this.aluno.Matriculas.Anolectivo);
    this.cadastroForm.get('Codigo')?.setValue(this.aluno.Codigo);
    this.cadastroForm.get('IdTipoDoc')?.setValue(this.aluno.IdTipoDoc);
    this.cadastroForm.get('Esteve')?.setValue(this.aluno.Esteve);
    this.cadastroForm.get('Transferencia')?.setValue(this.aluno.Transferencia);
    this.cadastroForm.get('Obs')?.setValue(this.aluno.Obs);
    this.cadastroForm.get('Encarregado')?.setValue(this.aluno.Encarregado);
    this.cadastroForm.get('IdMatricula')?.setValue(this.aluno.IdMatricula);
    this.cadastroForm.get('Embaixada')?.setValue(this.aluno.Embaixada);
    this.cadastroForm.get('Classificacao')?.setValue(this.aluno.Classificacao.trim());
    this.cadastroForm.get('Livro')?.setValue(this.aluno.Livro);

    console.log(this.cadastroForm.get('AnoLectivo')?.value);


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  onSubmit() {
      this.showLoadingView();
      console.log('Form Value to submmit', this.cadastroForm.value);
      this.cadastroForm.controls.Esteve.setValue((this.cadastroForm.get('Esteve')?.value == true ? 1 : 0))
      this.docSecretarioService.create(this.cadastroForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Documento criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log(error.message);

        this.showMessage('Erro', 'Ocorreu um erro ao criar documento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });


  }

  onUpdate() {
    this.showLoadingView();
    console.log(this.cadastroForm.value);

      this.docSecretarioService.update(this.cadastroForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Funcionários atualisar com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar aluno, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });

  }

  onKey(value: string) {
    let filter = value.toLowerCase();
   /*  if(value.length === 0){
      this.loadMatriculas();
    } */
    this.matriculas =  this.matriculas2.filter((option: any) =>  option.Aluno.Nome.toLowerCase().includes(filter)  ||  option.IdMatricula.toLowerCase().includes(filter)
    ||  option.Turma.Turma1.toLowerCase().includes(filter))
  }


  loadData() {

    if (this.config.filter == null){
      this.config.filter = ""
    }

      this.tipoDocService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.tipoDocumentos = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });
this.utilservice.loadAnoLectivos({}).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
this.anoLetivo = data;
} )

    }

    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

}

