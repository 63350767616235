import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMyTootip]'
})
export class MyTootipDirective {

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
@Input('appTooltip') tootipContent: string = '';


createTooçTip(): HTMLElement{
  const tooltip = this.renderer.createElement('div');
  const text = this.renderer.createText(this.tootipContent);
  this.renderer.appendChild(tooltip, text);
  this.renderer.addClass(tooltip, 'toolTipMy');
  this.renderer.setStyle(tooltip, 'postion', 'absolute')
  return tooltip;
}

  @HostListener('mouseover')
  onMouseOver(){
    const myToolTip = this.createTooçTip();
    this.renderer.appendChild(this.elRef.nativeElement, myToolTip);
    console.log('Mouse in...');

  }

  @HostListener('mouseout')
  onMouseOut(){
    setTimeout(() =>{
      const tooltip = this.elRef.nativeElement.querySelector('.toolTipMy');
      this.renderer.removeChild(this.elRef.nativeElement, tooltip);
    }, 300)


  }


}
