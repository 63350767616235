<br>
<div class="container">
  <div class="input-group">
    <div class="input-group-prepend">
        <div class="input-group-text">
           <i class="fas fa-search"></i>
         </div>
    </div>
    <input type="text" #box  data-loader=Titulo (keyup)="EnviarTexto(box.value)"  placeholder="Pesquisa....." class="form-control" name="search" />
  </div>
</div><br>
{{Titulo}}
