import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import { ReportServerService } from '@service/index';
import { Publico } from '@shared/publico';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-caixa-pesquisa',
  templateUrl: './caixa-pesquisa.component.html',
  styleUrls: ['./caixa-pesquisa.component.scss']
})
export class CaixaPesquisaComponent extends BasePage implements OnInit {
  [x: string]: any;

  constructor(injector: Injector , public activeModal: NgbActiveModal, private datepipe: DatePipe,
    private _formBuilder: FormBuilder,private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }


    pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
    });

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
    });
  }

  onSubmit() {
   // this.pesquisaForm.get('DataInicio')?.setValue(new Date(this.pesquisaForm.get('DataInicio')?.value));

   var datePipe = new DatePipe("en-US");
   let dt = datePipe.transform(this.pesquisaForm.get('DataFim')?.value,'yyyy/MM/dd');
   console.log(dt);


     let datefim = this.pesquisaForm.get('DataFim')?.value;
    console.log(this.datepipe.transform(datefim,'yyyy/MM/dd'));
    this.pesquisaForm.get('DataInicio')?.setValue(this.datepipe.transform(this.pesquisaForm.get('DataInicio')?.value,'yyyy-MM-dd'));
  //  this.pesquisaForm.get('DataFim')?.setValue(new Date(this.pesquisaForm.get('DataFim')?.value));
   this.pesquisaForm.get('DataFim')?.setValue(this.datepipe.transform(this.pesquisaForm.get('DataFim')?.value,'yyyy-MM-dd'));
       this.reportService.movimentoCaixa(this.pesquisaForm.value).subscribe((response: Blob | MediaSource) => {
        this.pesquisaForm.get('DataInicio')?.setValue(new Date());
        this.pesquisaForm.get('DataFim')?.setValue(new Date());
        const fileURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'Caixa.pdf';
          link.click();
          window.URL.revokeObjectURL(link.href);

      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }


}
