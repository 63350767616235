import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '@env';
import {Sexo} from '@core/models/sexo';
import { Observable } from 'rxjs';

import * as staticData from 'assets/static/local-data.json';
import {Bairro} from '@core/models/bairro';
import { HttpClient } from '@angular/common/http';
import {TipoContacto, TipoDocumento, TipoAluno, Parametros, Meses, SituacaoMatricula, AnoLectivoActual, TipoPagamento , Banco, Curso , TaxType, TipoProduto, GrupoCxa} from '@core/models/index';
import { Classe, ClasseLingua, ClasseOpcao, ContaCaixa, NivelAcademico, NivelEnsino, TaxAccountingBasis, TaxExemptionReason, TaxTableEntry, Turma } from '../../models';
import { catchError, map } from 'rxjs/operators';
import { ParamService } from '../param.service';
//import 'rxjs/add/operator/map';
const fullUrl = `${environment.API_URL_BASE}${environment.API_URL_PATH}`;
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private notifications: number = 0;
  constructor(private api: ApiService,private http: HttpClient, private param: ParamService) { }


  anoLectivo: any;
  mes: any;
  tiporecibo: any;
  numeroParcela: any;
  dataInicio: any;

  ngOnInit() {
    this.setGlobalParam();
  }


  loadProfiles(params: any) {
    return this.api.get('/NiveisAcessos', params);
    // return this.api.get('/Perfil', params);
  }

  fileUpload(formDataContent: File | any) {
     const httpOptions = {
       responseType: 'text'
     };
  //  const endpoint = '/Foto';
    const formData: FormData = new FormData();
    formData.append('files', formDataContent, formDataContent.name);
    return this.api.post('/Foto', formData, httpOptions); //, {responseType: 'text'}
  }

  getNotification(): number {
    return this.notifications;
  }

  setNotification(newValue: number) {
    this.notifications = newValue;
  }


  loadDepartments(params: any) {
    return this.api.get('/Areas', params);
  }

  createDepartments(formContent: any) {
    return this.api.post('/Areas', formContent);
  }

  createFunctions(formContent: any) {
    return this.api.post('/Funcoes', formContent);
  }

  updateDepartments(id: any, formContent: any) {
    return this.api.update(id,'/Areas', formContent);
  }

  updateFunctions(id: any, formContent: any) {
    return this.api.update(id,'/Funcoes', formContent);
  }

  loadFunctions(params: any) {
    return this.api.get('/Funcoes', params);
  }

  loadNotification(params: any) {
    return this.api.get('/AtivaNotificacao', params);
  }

  loadBairros(params: any) {
    return this.api.get('/Bairros', params);
  }
  loadGeneros(params: any) {
    return this.api.get('/Motivos', params);
  }
  loadClasses(params: any) {
    return this.api.get('/Classes', params);
  }
  BuscaClasse(): Observable<Classe[]> {
     return this.http.get<Classe[]>(fullUrl + '/Classes');
    }
  loadCursos(params: any) {
    return this.api.get('/Curso', params);
  }
  loadDisciplina(params: any) {
    return this.api.get('/Disciplina', params);
  }
  loadSexo(params: any) {
    return this.api.get('/Sexo', params);
  }
   BuscaSexo() {
    return  this.http.get<Sexo[]>(fullUrl + '/Sexo');
  }
  BuscaBairro() {
    return  this.http.get<Bairro[]>(fullUrl + '/Bairros');
  }
  BuscaTipoContacto() {
    return  this.http.get<TipoContacto[]>(fullUrl + '/TipoContacto');
  }
  BuscaTipoDocumento() {
    return  this.http.get<TipoDocumento[]>(fullUrl + '/TipoDocumento');
  }
  BuscaTipoAluno() {
    return  this.http.get<TipoAluno[]>(fullUrl + '/TipoAluno');
  }
  BuscaParametros() {
    return  this.http.get<Parametros[]>(fullUrl + '/Parametros');
  }
  loadParametros(params: any) {
    return this.api.get('/Parametros', params);
  }
  BuscaMeses() {
    return  this.http.get<Meses[]>(fullUrl + '/Meses');
  }

  BuscaSituacaoMatricula() {
    return  this.http.get<SituacaoMatricula[]>(fullUrl + '/MatriculaSituacao');
  }

  BuscaTipoPagamentos() {
    return  this.http.get<TipoPagamento[]>(fullUrl + '/TipoPagamentos');
  }
  BuscaBanco() {
    return  this.http.get<Banco[]>(fullUrl + '/Banco');
  }

  BuscaAnoLectivoActual() {
    return  this.http.get<AnoLectivoActual[]>(fullUrl + '/AnoLectivoActual');
  }

   ListaUsuario(): Observable<AnoLectivoActual[]> {
    //    console.log(localStorage.getItem('id'));
     return this.http.get<AnoLectivoActual[]>(fullUrl + '/AnoLectivoActual')
    // .map(res => res)
     ;
    }

    BuscaAnoLectivo(): Observable<AnoLectivoActual[]> {
    //    console.log(localStorage.getItem('id'));
     return this.http.get<AnoLectivoActual[]>(fullUrl + '/AnoLectivos')
    // .map(res => res)
     ;
    }

    CarregaTurmas(): Observable<Turma[]> {
      //    console.log(localStorage.getItem('id'));
       return this.http.get<Turma[]>(fullUrl + '/Turma')
      // .map(res => res)
       ;
      }


  loadAnoLectivoActual(params: any) {
    return  this.api.get('/AnoLectivoActual', params);
  }

  loadAnoLectivos(params: any) {
    return  this.api.get('/AnoLectivos', params);
  }
  loadTurma(params: any) {
    return this.api.get('/Turma', params);
  }
  loadPais(params: any) {
    return this.api.get('/Pais', params);
  }
/*
   getData(): Observable<Parametros[]> {
        return this.http.get<Parametros>(fullUrl + '/Parametros')
        .map((res: Parametros ) => res);

    } */
  loadNotificationa(params: any) {
    return this.api.get('/AtivaNotificacao', params);
  }

  
/*   buscaTurno() {
    return staticData.turno;
  }
  listaTipoMulta() {
    return staticData.tipoMulta;
  }
  listaTipoFactura() {
    return staticData.tipoFactura;
  }
  listaTipoEstabelecimento() {
    return staticData.tipoEstabelecimento;
  } */


  listadeNivelAcademico(): Observable<NivelAcademico[]> {
     return this.http.get<NivelAcademico[]>(fullUrl + '/NivelAcademico');
  }
  listadeNivelEnsino(): Observable<NivelEnsino[]> {
    return this.http.get<NivelEnsino[]>(fullUrl + '/NivelEnsino');
 }
  listadeCurso(): Observable<Curso[]> {
      return this.http.get<Curso[]>(fullUrl + '/Curso');
  }

  listadeClasseLingua(): Observable<ClasseLingua[]> {
    return this.http.get<ClasseLingua[]>(fullUrl + '/ClasseLingua');
  }
  listadeClasseOpcao(): Observable<ClasseOpcao[]> {
  return this.http.get<ClasseOpcao[]>(fullUrl + '/ClasseOpcao');
}

listadeTipoSistema(): Observable<TaxAccountingBasis[]> {
  return this.http.get<TaxAccountingBasis[]>(fullUrl + '/TaxAccountingBasis');
}
listadeRegime(): Observable<TaxExemptionReason[]> {
  return this.http.get<TaxExemptionReason[]>(fullUrl + '/TaxExemptionReason');
}
listadeTaxTableEntry(): Observable<TaxTableEntry[]> {
  return this.http.get<TaxTableEntry[]>(fullUrl + '/TaxTableEntry');
}

listaGrupoCaixa(): Observable<GrupoCxa[]> {
  return this.http.get<GrupoCxa[]>(`${fullUrl}/GrupoCaixa`);
}
loadcaixa() {
  return this.api.get(`/GrupoCaixa`);
}
getTipoImposto() {
  return this.api.get(`/TaxType`);
}
getImposto() {
 // console.log(params);

  return this.api.get(`/TaxTableEntry`);
}

reason(params: any) {
  console.log(params);
  return this.api.get('/TaxExemptionReason/'+ params);
}

/* ListaImposto(id: string) {

  return  this.api.get('/TaxTableEntry')
  .pipe(
   map((mun: TaxTableEntry[]) => {
     return mun.filter(c => c.TaxType === id);
   })
 //  tap(console.log),
   // catchError(this.api.handleError<any>('getPessoas', []))
  )}
 */
listaTipoProduto(): Observable<TipoProduto[]> {
  return this.http.get<TipoProduto[]>(fullUrl + '/TiposProdutos');
}

listaTipoImposto(): Observable<TaxType[]> {
  return this.http.get<TaxType[]>(fullUrl + '/TaxType');
}

setGlobalParam(){
  console.log('global');

  this.param.loadAll({}).subscribe((response: any) => {
    console.log(response);

    this.anoLectivo = response[0].AnoLectivo;
    this.tiporecibo = response[0].TipoRecibo;
    this.numeroParcela = response[0].Parcela;
    this.dataInicio = new Date(response[0].DataInicio);
    this.mes = this.dataInicio.getMonth()+1;
  })
}

getAnoLectivo(){
  return this.anoLectivo;
}

getTipoRecibo(){
  return this.tiporecibo;
}

getNumeroParcela(){
  return this.numeroParcela;
}

getDataInicio(){
  return this.dataInicio;
}

getMesParcela(){
  return this.mes;
}

getFunc(){
  return localStorage.getItem('Func');
}

}

