import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { MenuService } from '../services';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  currentrole: any;
  respdata: any;
  constructor(private service: AuthService, private route: Router, private menuServ: MenuService, private matsnackbar: MatSnackBar) {
   // console.log(activatedRoute.snapshot.url[0].path);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.service.hasLogedUser()) {
        this.currentrole = this.service.loadUserPermition();
         this.menuServ.HaveAccess(atob(this.currentrole), route.url[0].path).subscribe(result => {
          if (result) {
            return true;
          } else {
            this.route.navigate(['']);
         //   alert('unauthorized access');
            this.matsnackbar.open('Acesso não autorizado')
            return false;
          }
        });

        return true;
      } else {
        this.route.navigate(['login']);
        return false;
      }

  }

}
