// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL_BASE: 'https://api.oinquilino.ao/',//'https://inquilinoapi.azurewebsites.net',//'https://localhost:44313',//'https://api-gescola.azurewebsites.net',// 'https://api-gescola.benysys.com', //'http://www.intranet.api.local',//'https://gescola-001-site1.htempurl.com', // 'http://www.api.gescola.com',//'https://api-gescola.benysys.com',   //'http://gescolar.local',//


  //API_URL_BASE: 'http://gescolar.local',


 // API_URL_BASE: 'https://localhost:44313', 'http://www.recibo.api.com',//
  API_RECIBO_URL_BASE: 'https://recibo.oinquilino.ao',//'https://localhost:44369',//'https://recibo.benysys.com', //'http://www.intranet.recibo.local', //'http://recibo.local', //'https://gescola-001-site1.htempurl.com', //'http://recibo.benysys.com', //'http://recibo.local',


  // reportServer=   API_RECIBO_URL_BASE ,//   'http://www.recibo.api.com',//'https://recibo.benysys.com', // 'http://recibo.local',//        'https://gescola-001-site1.htempurl.com', //'http://recibo.benysys.com', //  'http://recibo.local',  //'https://localhost:44369', //
  serverUrl: '/api',
  API_URL_PATH: '/api'
};





/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
