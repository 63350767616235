import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { BasePage } from '@shared/base-page';
import { ReportServerService, UtilsService, ContaCaixaService,TurmaService,TrimestreService, ClasseDisciplinaService } from '@service/index';
import { Publico } from '@shared/publico';
import { AnoLectivoActual,ContaCaixa,Turma  } from '@core/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pauta',
  templateUrl: './pauta.component.html',
  styleUrls: ['./pauta.component.scss']
})
export class PautaComponent extends BasePage implements OnInit {
  [x: string]: any;
  tipo: Observable<ContaCaixa[]>;
  localTipos: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,  private trimestreService: TrimestreService,
    private utilservice: UtilsService, private contaServico: ContaCaixaService,private classeDisciplinaService: ClasseDisciplinaService,
    private _formBuilder: FormBuilder,private turmaService: TurmaService,
    private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }


    pesquisaForm  =  this._formBuilder.group({
      Ano: ['', Validators.required],
      Turma: [, Validators.required],
    });
    anoLetivo: Observable<AnoLectivoActual[]>;
 //   turma: Observable<Turma[]>;
 turmas: any[]=[];
 turmaslocal: any[]=[];
 anolectivo: any;
 trimestre: any;
 disciplina: any;
 config: any;
    @Input() public Tipo: any;

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.param();
    this.anoLetivo = this.utilservice.BuscaAnoLectivo();

    this.pesquisaForm.get('Ano')?.valueChanges.subscribe(res => {
      this.config.AnoLectivo = res;
      this.turmaService.turmaAnoLectivo(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.turmas =  response.data;
        this.turmaslocal = response.data;
        console.log(response);
        console.log(this.config);
        })
    } )

  }

  param(){
    this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.anolectivo = response[0].AnoLectivo;
    //  this.tipoRecibo = response[0].TipoRecibo;
      this.config = {
        ItemsPerPage: 80,
        currentPage: 1,
        page: 1,
        AnoLectivo : this.anolectivo,
        IdTurma:0 ,
        Nome: "",
        }
     //   this.fillTurma();
}, (error: any) => {
  console.log('Error on request', error);
});
  }

  onSubmit() {

       this.reportService.pauta(this.pesquisaForm.value).subscribe((response: Blob | MediaSource) => {
        const fileURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'MiniPauta.pdf';
          link.click();
          window.URL.revokeObjectURL(link.href);

      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }
    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

}
