import { Component, OnInit } from '@angular/core';
import { BasePage } from '@shared/base-page';
@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent  implements OnInit {

  constructor() { 
    
  }
  title = 'Gescola';
  sideBarOpen = true;
  ngOnInit(): void {
  }
  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
