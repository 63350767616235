import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';

@Component({
  selector: 'app-cabecario',
  templateUrl: './cabecario.component.html',
  styleUrls: ['./cabecario.component.scss']
})
export class CabecarioComponent implements OnInit {

  constructor(private titleService: Title, private breadcrumbService: BreadcrumbService) { }
  @Input() Titulo: String;
  @Input() msgErro: string;
  @Input() filter: FormControl;
  @Input() labelButton: string;
  @Input() type = 'text';
  @Input() onAdd: any;
  @Input() isReadOnly = false;

  @Output() sendDataToParent = new EventEmitter<string>();
  @Output() Texto = new EventEmitter<string>();

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe( crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    })
  }

  EnviarDataToParent() {
    this.sendDataToParent.emit();
  }

  EnviarTexto(data:any){
   this.Texto.emit(data);
 //  console.log(data);

  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Sistema de Gestão Escolar';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
}

private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
        return `${curr.displayName} - ${prev}`;
    }, '');
}

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


}
