import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private messageSource = new BehaviorSubject<any>({});
  currentMessage = this.messageSource.asObservable();
  dados;
  constructor() { }

  changeMessage(message: any){
    console.log(message);

   this.messageSource.next(message);
  }
  setMessage(data: any){
    this.dados = data;
  }
  getMessage(){
    return this.dados;
  }
}
