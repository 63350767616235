export * from './documento-aluno/documento-aluno.component';

export * from './aluno-form/aluno-form.component';

export * from './contacto-aluno/contacto-aluno.component';

export * from './matricula/matricula-form/matricula-form.component';

export * from './matricula/matricula-list/matricula-list.component';

export * from './tipodoc/tipodoc-form/tipodoc-form.component';

export * from './tipodoc/tipodoc-list/tipodoc-list.component';

export * from './report/report.component';
export * from './aluno-form/alunoservico-form/alunoservico-form.component';
export * from './servico-aluno/servico-aluno.component';
export * from './formulario.module';

