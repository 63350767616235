import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessorService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Professor', params);
  }

  ListaProfessorDisciplina(params: any){
    return this.api.get('/Professor/ListaProfessorDisciplina?id=' + params);
  }

  ProfessorListaDeTurmasLecionadas(params: any){
    return this.api.get('/Professor/ProfessorListaDeTurmasLecionados?id=' + params);
  }

  ProfessorListaDeDisciplinaLecionados(id: any,params: any){
    return this.api.get(`/Professor/ProfessorListaDeDisciplinaLecionados?id=${id}&IdTurma=${params}`);
  }



  addProfessorDisciplina(formContent: any){
    return this.api.post('/Professor/ProfessorDisciplina', formContent);
  }

  create(formContent: any){
    return this.api.post('/Professor', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Professor', formContent);
  }
  apagarDisciplina(formContent: any){
    return this.api.apagar('/Professor/ApagarDisciplina' ,formContent)
  }

  loadById(id: any) {
    return this.api.get(`/Professor/${id}`);
  }
}
