import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from '@core/models/index';
import { MenuService, UtilsService } from '@service/index';
import { BasePage } from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss']
})
export class MenuFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private menuService: MenuService,
    private utilservice: UtilsService) { super(injector);  }

    MenuForm  =  this._formBuilder.group({
      IdMenu: [''],
      Nome: ['', Validators.required],
      Path: ['', Validators.required],
      PreIcon: ['', Validators.required],
      PostIcon: ['', Validators.required]
    });


    menus: Menu[];
    @Input() public dados: any;
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {
      if (this.isReadOnly) {
        console.log(this.dados);
        this.loadById();
      }
  }

  loadById() {
    this.MenuForm.get('IdMenu')?.setValue(this.dados.IdMenu);
    this.MenuForm.get('Nome')?.setValue(this.dados.Nome);
    this.MenuForm.get('Path')?.setValue(this.dados.Path);
    this.MenuForm.get('PreIcon')?.setValue(this.dados.PreIcon);
    this.MenuForm.get('PostIcon')?.setValue(this.dados.PostIcon);
  }

  onSubmit() {
      this.showLoadingView();
      this.menuService.create(this.MenuForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Menu Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Menu Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.showLoadingView();
      this.menuService.update(this.MenuForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Menu atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar Menu, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
