import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import {UtilsService, MatriculaService, ContaCaixaService} from '@service/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-alunoservico-form',
  templateUrl: './alunoservico-form.component.html',
  styleUrls: ['./alunoservico-form.component.scss']
})
export class AlunoservicoFormComponent  extends BasePage implements OnInit {

  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder, private matriculaservice: MatriculaService,
    private contaCaixaService: ContaCaixaService,
   // private alunoservice: AlunoService,
    private utilservice: UtilsService) {
       super(injector);

      }

    @Input() public Idmatricula: any;
    @Input() public isReadOnly: boolean = false;
    @Input() public titulo: any  = "Registar Aluno";
    aluno: any;
    contaCaixa: any[] = [];

    ServiceForm  =  this._formBuilder.group({
      Tipo: ['', Validators.required],
      Valor: ['', Validators.required],
      MesParcela: ['', Validators.required],
      DiaVencimento: ['', Validators.required],
      NumeroParcela: [1, Validators.required],

    });

  ngOnInit(): void {
    this.dadosDoAluno();
      this.loadData();
  }

  dadosDoAluno() {
    this.matriculaservice.loadById(this.Idmatricula)
    .pipe(takeUntil(this.destroySubject))
      .subscribe((message: any) => {
        this.aluno = message;
      });
  }

  loadData() {
    this.contaCaixaService.loadByTipo('S').pipe(takeUntil(this.destroySubject))
    .subscribe((response: any[]) => {
      this.contaCaixa = response;
    }, (error: any) => {
      console.log('Error on request', error);
    });
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
