<!-- <app-cabecario Titulo='Alunos da {{titulo}}'  labelButton="Novo" (sendDataToParent) = "onAddAluno()"  (Texto)="applyFilter($event)"></app-cabecario>
 -->

 <br>
 <div class="col-md-12">
   <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Transferir Inquilino de Apartamento</h4>
   <ol class="breadcrumb">
       <li class="breadcrumb-item"><a >Lista de Inquilinos:  {{titulo}}</a></li>
      <!--  <li class="breadcrumb-item active">Lista de {{Titulo}}</li> -->
     </ol>
 </div>
 <br>

 <form [formGroup]="cadastroForm" >
 <div class="row">
  <div class="col-md-3 " >
      <mat-form-field appearance="outline" >
          <mat-label>Apartamento a transferir</mat-label>
          <mat-select  name="AnoLectivo" formControlName="TurmaDestino"  >
            <app-consulta-drop-down (Texto)="onKey($event)" ></app-consulta-drop-down>
          <mat-option *ngFor="let s of turmadestino "  [value]="s.IdTurma">{{s.Turma1}}</mat-option>
          </mat-select>
          <mat-error *ngIf="cadastroForm.get('TurmaDestino')?.hasError('required')">Apartamento de destino é obrigatório</mat-error>
      </mat-form-field>
</div>
<div class="col-md-2">
  <mat-form-field appearance="outline" >
      <mat-label>Número de mensalidades</mat-label>
      <input matInput formControlName="NumeroDeParcelas"  />
     <!--  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon > -->
      <mat-error *ngIf="cadastroForm.get('NumeroDeParcelas')?.hasError('required')"> Número de parcela é obrigatório. </mat-error>
  </mat-form-field>
</div>
<div class="col-md-2">
  <mat-form-field appearance="outline" >
      <mat-label>Primeiro Mês</mat-label>
      <mat-select  name="PrimeiroMes"  formControlName="PrimeiroMes">
      <mat-option *ngFor="let tipo of Meses | async " [value]="tipo.IdMes">
          {{tipo.Mes}}
      </mat-option>
      </mat-select>
      <mat-error *ngIf="cadastroForm.get('PrimeiroMes')?.hasError('required')">Primeiro Mês é obrigatório</mat-error>
  </mat-form-field>
</div>
 <div class="col-md-3 text-right" >
      <div >
          <input class="form-control ml-2 " #box  (keyup)="applyFilter(box.value)" placeholder="Pesquisar..." type="text"  />
      </div>
 </div>
 <div class="col-md-2 text-right" style="padding-right: 10px;">
       <div  >
           <button mat-raised-button class="btn btn-success" [disabled]="!cadastroForm.valid || (!isChecked)" (click)="onAddAluno()" > Processar</button>
       </div>
 </div>
 </div>
</form>



<table class="content-table">
  <thead>
      <tr>
        <th scope="col">
          <div class="form-check form-check-inline">
              <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="this.isMasterSel" (change)="checkAll($event)">
              </label>
          </div>
      </th>
          <th scope="col">Nome</th>
          <th scope="col">Ano Exercicio</th>
          <th scope="col">Apartamento</th>
          <th scope="col">Edificio</th>

      </tr>
  </thead>
  <tbody>
    <!--  <tr *ngFor="let alu of matriculas | slice: (page-1) * pageSize : page * pageSize"> -->
      <tr *ngFor="let alu of matriculas ">
          <td scope="row">
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input name="" class="form-check-input" type="checkbox" [(ngModel)]="this.arrayEmployes[alu.IdMatricula]" [checked]="getIndexOf(alu) > -1"
                        (change)="onChecked(alu, $event)" >
                </label>
            </div>
        </td>
          <td> {{ alu.Aluno.Nome}} </td>
          <td> {{alu.Anolectivo }}</td>
          <td>{{alu.Turma.Turma1}}</td>
          <td>{{alu.Turma.Classe.Classe}}</td>
      </tr>
  </tbody>
</table>
<div class="alert alert-danger" style="text-align: center;" *ngIf="!matriculas.length">
  <strong>Nenhum registo encontrado.</strong>
</div>

<div class="d-flex justify-content-between p-2">

<ngb-pagination
[(page)]="page"
[maxSize]="15"
[pageSize]="pageSize"
[boundaryLinks]="true" [directionLinks]="true" [ellipses]="true"
[collectionSize]="collectionSize" (pageChange)="pageChanged($event)"  ></ngb-pagination>

  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="filtro()">
      <option [ngValue]="5">5 items por pagina</option>
      <option [ngValue]="10">10 items por pagina</option>
      <option [ngValue]="20">20 items por pagina</option>
  </select>
</div>


