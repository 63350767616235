import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, snackBarClass: string ="successful", action?: string){
   this.snackBar.open(message, action,  {
    duration: 5000,
    verticalPosition: 'top',
    panelClass: snackBarClass

   });
  }
}
