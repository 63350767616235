import { Injectable } from '@angular/core';
import { ApiService } from '.';
import { Turma } from '../models';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TurmaService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Turma', params);
    // return this.api.get('/employeeCards.json', params);
  }
  filtro(params: any){
    return this.api.get('/Turma/Filtro', params);
    // return this.api.get('/employeeCards.json', params);
  }

  turmaAnoLectivo(formContent: any){
    return this.api.get('/Turma/TurmaAnoLectivo', formContent);
  }
  create(formContent: any){
    return this.api.post('/Turma', formContent);
  }

  listaConselhoTurma(formContent: any){
    return this.api.get(`/Turma/ListaConselhoTurma/${formContent}`);
  }

  apagarConselhoTurma(formContent: any){
    return this.api.delete('/Turma/apagarConselho',formContent);
  }

  addTurmaComissao(formContent: any){
    return this.api.post('/Turma/AddTurmaComissao', formContent);
  }

  trocarAlunoTurma(formContent: any){
    return this.api.post('/Turma/trocarAlunoTurma', formContent);
  }
  transitar(formContent: any){
    return this.api.post('/Turma/transitarAlunoClasse', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Turma', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Turma/${id}`);
  }

  /* BuscaActividadeSecPorIdPrimaria(id: any): Observable<Turma[]> {
    return this.api.get<Turma[]>('/Turma').pipe(
      tap(heroes => console.log('Actividade Secundaria carregado com sucesso')),
        map((actividadeSecundaria: Turma[]) => actividadeSecundaria.filter(c => c.IdTurma = id))
    );
  } */
}
