import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TipoPagamentosService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/TipoPagamentos', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/TipoPagamentos', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/TipoPagamentos', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/TipoPagamentos/${id}`);
  }
  delete(id: any) {
    return this.api.apagar('/TipoPagamentos' , id);
  }
}

