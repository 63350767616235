import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrocarAlunoTurmaListComponent } from './trocar-aluno-turma-list.component';
import { Breadcrumb } from 'angular-crumbs';

const routes: Routes = [{
  path:'', component: TrocarAlunoTurmaListComponent, data: { breadcrumb: "Dados pessoais" }
}];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrocarAlunoTurmaRoutingModule { }
