import { CurrencyPipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MovimentosCaixa } from '@core/models/index';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovimentoCaixaService, MensalidadeService }  from '@service/index';
import {BasePage} from '@shared/base-page'
import { Publico } from '@shared/publico';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';


@Component({
  selector: 'app-caixa',
  templateUrl: './caixa.component.html',
  styleUrls: ['./caixa.component.scss']
})
export class CaixaComponent extends BasePage implements OnInit {
  [x: string]: any;

  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector, private caixaService: MovimentoCaixaService, private publico: Publico, private mensalidadeService: MensalidadeService,
    private modalService: NgbModal,
   // private currentRoute: ActivatedRoute,
    private cp: CurrencyPipe,
    public activeModal: NgbActiveModal,


    ) {
    super(injector);
  }
    movimentoCaixa : any ; //MovimentosCaixa[] = [];
    mov: any [] = [];
    alunoDocumento: any[] = [];
    AlunoContactos: any[] = [];
    public page = 1;
    public pageSize = 5;
    collectionSize = 0;
    closeResult = '';
  ngOnInit(){
    this.publico.verificaLincenca();
    this.loadMov();
  }

  loadMov() {
    this.showLoadingView();
      this.caixaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
          this.movimentoCaixa = response;
          this.mov = response.Movimento;
          this.showContentView();
        }, error => {
          console.log('Error a efetuar requisicao', error);
        });
    }

    AnularPagamento(dados: any,Tipo: number) {
      //  let Recibo = 'FR ' + this.aluno.Anolectivo + '/' + Factura;
      console.log(dados);

      let titulo= "Anular"

      titulo = Tipo==1? 'Eliminar': 'Anular';

        Swal.fire({
          title: 'Deseja ' + titulo + ' a Factura nº. ' + dados.Documento, //this.mensalidadeService.getTotalPrice(),
          text: 'Total: ' + this.cp.transform(dados.Valor,'Kz ','symbol','1.2-2'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: titulo,
          cancelButtonText: 'Cancelar',
        }).then((result: { isConfirmed: any; }) => {
          if (result.isConfirmed) {

            let idMensalidade = 0;

            idMensalidade = Tipo==0? dados.Mensalidades[0].IdMensalidade : 0;

            this.mensalidadeService.ApagarPagamento(idMensalidade, dados.Documento, Tipo).pipe(takeUntil(this.destroySubject)).subscribe(
              (response: any) => {
                this.loadMov();
                this.showMessage( 'success', 'Processo de ' + titulo + ' realizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));

              },
              (error: any) => {
                console.log('Error create Matricula', error);
                this.showMessage( 'Erro', 'Ocorreu um erro ao ' + titulo + ' o pagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
              }
            );
          }
        });
      }

      ngOnDestroy() {
        // Unsubscribe from all observables
        this.destroySubject.next();
        this.destroySubject.complete();
      }
}
