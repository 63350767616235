<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>

          <!--   <br >
            <div >
              <input type="file" #file (change)="onFileChange($event)" style="display: none;">
            </div>
            <div class="form-group text-center">
                <img
                [src]="imageUrl"
                class="rounded-circle"
                (click)="file.click()"
                name="aboutme" width="120" height="120"
                style="cursor: pointer;">
            </div>
            <br > -->


<form [formGroup]="alunoForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">

            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="Nome"  />
                  <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Nome')?.hasError('required')"> Nome é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Nacionalidade</mat-label>
                  <mat-select  name="idPais"  formControlName="idPais">
                    <mat-option *ngFor="let s of pais" [value]="s.IdPais">
                      {{s.Nacionalidade}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="alunoForm.get('idPais')?.hasError('required')">Nacionalidade é obrigatório</mat-error>
                </mat-form-field>
            </div>
             <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Genero</mat-label>
                  <mat-select  name="idSexo"  formControlName="idSexo">
                    <mat-option *ngFor="let s of sexo | async" [value]="s.IdSexo">
                      {{s.Sexo1}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="alunoForm.get('idSexo')?.hasError('required')">Genero é obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Data de nascimento</mat-label>
                  <input matInput [matDatepicker]="DataNascPicker" readonly name="DataNasc" formControlName="DataNasc">
                  <mat-datepicker-toggle matSuffix [for]="DataNascPicker"></mat-datepicker-toggle>
                  <mat-datepicker #DataNascPicker></mat-datepicker>
                  <mat-error *ngIf="alunoForm.get('DataNasc').hasError('required')"> Data de nascimento é obrigatório.  </mat-error>

              </mat-form-field>
            </div>
           <!--  <div class="form-group col-6">
              <label for="DataNasc">Data de Nascimento</label>
              <div class="input-group">
                  <input id="DataNasc" formControlName="DataNasc" class="form-control" placeholder="yyyy-mm-dd" name="DataNasc" ngbDatepicker #dp="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
                          <i class="fas fa-calendar    "></i>
                      </button>
                  </div>
              </div>
          </div> -->


           <!--  <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Data de Nascimento</mat-label>
                  <input matInput [matDatepicker]="DataNascPicker" readonly name="DataContracto" formControlName="DataContracto">
                  <mat-datepicker-toggle matSuffix [for]="DataNascPicker"></mat-datepicker-toggle>
                  <mat-datepicker #DataNascPicker></mat-datepicker>
                  <mat-error *ngIf="alunoForm.get('DataContracto')?.hasError('required')"> Data do contracto é obrigatório.  </mat-error>

              </mat-form-field>
              </div> -->

            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <mat-select name="idBairro" readonly formControlName="idBairro" required>
                  <mat-option>-seleccione-</mat-option>
                  <mat-option *ngFor="let n of Bairro2 | async" [value]="n.IdBairro">
                    {{n.Bairro1}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="alunoForm.get('idBairro')?.hasError('required')" > Bairro é obrigatório. </mat-error>

             <!--   <mat-error *ngIf="trabalhadorForm.get('Id_Nacionalidade').hasError('required')">Seleccione o genero</mat-error>
                 <mat-hint>{{Id_Sexo.value?.sound}}</mat-hint> -->
              </mat-form-field>
            </div>


             <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Endereço</mat-label>
                  <input matInput formControlName="Endereco"  />
                  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Endereco')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Pai</mat-label>
                  <input matInput maxlength="100" />
                  <mat-icon matSuffix class="secondary-text" >supervised_user_circle</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Telefone')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Mãe</mat-label>
                  <input matInput  maxlength="100" />
                  <mat-icon matSuffix class="secondary-text" >supervisor_account</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Telefone')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div> -->
              <!--             <div class="row">
                          <div class="col-md-11">
                            <mat-form-field appearance="outline" >
                                <mat-label>Filhação</mat-label>
                                <mat-select  name="idFilhacao"  formControlName="IdFilhacao">
                                <app-consulta-drop-down (Texto)="onKey($event)" ></app-consulta-drop-down>

                                  <mat-option *ngFor="let s of filhacao" [value]="s.IdFilhacao">
                                <b>  {{s.Pai}} e de {{s.Mae}}</b>
                                  </mat-option>
                                </mat-select>
                                <mat-error *ngIf="alunoForm.get('idFilhacao')?.hasError('required')">Filhação é obrigatório</mat-error>
                              </mat-form-field>

                          </div>
                          <div class=" col-md-1">
                            <button type="button" name="" id="5" (click)="addFilhacao()" title="Inserir Filhação" style="float:right" class="btn btn-success btn-xm" > <i class="fa fa-plus"></i></button>
                          </div>
                        </div> -->


        <div class="col-md-12">
          <div class="card" style="width: 100%;">
              <div class="card-header">
                  <span class="cart-span-title">Contactos</span>
                  <button type="button" name="" id="5" (click)="onAddContacto(aluno)" title="Inserir Contacto" style="float:right" class="btn btn-primary btn-sm mr-2 " [disabled]="!isReadOnly"> <i class="fa fa-plus"></i></button>
                </div>
              <div class="card-body table-responsive custom-table">
                  <table class="table table-hover table-inverse">
                      <thead class="thead-inverse">
                          <tr>
                              <th>Tipo Contacto</th>
                              <th>Nº do Telefone</th>
                              <th>Pessoa a Contactar</th>
                              <th>Opções</th>
                          </tr>
                      </thead>
                      <!-- aluno.AlunoContactos -->
                      <tbody>
                          <tr *ngFor="let persDoc of AlunoContactos">
                           <!--  {{persDoc.TipoContacto | json}} -->
                              <td scope="row">{{persDoc.TipoContacto.Contacto}}</td>
                              <td>{{persDoc.Numero}}</td>
                              <td>{{persDoc.Descricao}}</td>
                              <td>
                                <!-- (click)="onEditContacto(persDoc)" -->
                                  <a name="" id="" class="btn btn-sm btn-success" role="button" (click)="onEditContacto(persDoc)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <br>
        </div>
        <div class="col-md-12">
          <div class="card" style="width: 100%;">
              <div class="card-header">
                  <span class="cart-span-title">Documentos</span>
                  <button type="button" name="" id="6" (click)="onAddDocumento(aluno)" title="Inserir Documento" style="float:right" class="btn btn-primary btn-sm mr-2 " [disabled]="!isReadOnly" > <i class="fa fa-plus"></i></button>              </div>
              <div class="card-body table-responsive custom-table">
                  <table class="table table-hover table-inverse">
                      <thead class="thead-inverse">
                          <tr>
                              <th>Tipo Documento</th>
                              <th>Nº do Documento</th>
                              <th>Data Emissão</th>
                              <th>Data validade</th>
                              <th>Opções</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let persDoc of alunoDocumento">
                              <td scope="row">{{persDoc.TipoDocumento.TipoDocumento1}}</td>
                              <td>{{persDoc.Numero}}</td>
                              <td>{{persDoc.DataEmissao | date: "dd/MM/yyyy"}}</td>
                              <td>{{persDoc.DataValidade | date: "dd/MM/yyyy"}}</td>
                              <td>
                                <!-- (click)="ImprimirDocumento(persDoc.NomeFicheiro)" -->
                                  <a name="" id="" class="btn btn-sm btn-success" role="button" (click)="onEditDocumento(persDoc)" ><i class="fa fa-eye" aria-hidden="true"></i></a>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <br>
        </div>
       <!--   {{ alunoForm.value }}
         {{ alunoForm.value | json }} -->
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid">Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid">Atualizar</button>
    </div>
</form>
