import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import { ReportServerService, UtilsService, ContaCaixaService } from '@service/index';
import { Publico } from '@shared/publico';
import { AnoLectivoActual,ContaCaixa  } from '@core/models';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-caixa-servico',
  templateUrl: './caixa-servico.component.html',
  styleUrls: ['./caixa-servico.component.scss']
})
export class CaixaServicoComponent extends BasePage implements OnInit {
  tipo: Observable<ContaCaixa[]>;
  localTipos: any;
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private utilservice: UtilsService, private contaServico: ContaCaixaService,
    private _formBuilder: FormBuilder,
    private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }


    pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
      Tipo: ['', Validators.required],
    });

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
      Tipo: ['S', Validators.required],
    });

    this.contaServico.loadByTipo('S').subscribe((res: any) => {
      this.tipo = res;
      this.localTipos= res;
    })
  }

  onKey(value: string) {
    let filter = value.toLowerCase();
   /*  if(value.length === 0){
      this.loadData();
    } */
    this.tipo =  this.localTipos.filter((option: any) =>  option.Conta.toLowerCase().includes(filter))
  }

  onSubmit() {
    this.pesquisaForm.get('DataInicio')?.setValue(new Date(this.pesquisaForm.get('DataInicio')?.value).toLocaleDateString());
    this.pesquisaForm.get('DataFim')?.setValue(new Date(this.pesquisaForm.get('DataFim')?.value).toLocaleDateString());
       this.reportService.caixaServico(this.pesquisaForm.value).subscribe((response: Blob | MediaSource) => {
        this.pesquisaForm.get('DataInicio')?.setValue(new Date());
        this.pesquisaForm.get('DataFim')?.setValue(new Date());
        const fileURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'CaixaServico.pdf';
          link.click();
          window.URL.revokeObjectURL(link.href);

      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }


}
