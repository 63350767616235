import { Injectable } from '@angular/core';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root'
})
export class TaxTableEntryService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/TaxTableEntry', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/TaxTableEntry', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/TaxTableEntry', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/TaxTableEntry/${id}`);
  }
}
