import { Disciplina } from './../models/model';
import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotasService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Nota', params);
    // return this.api.get('/employeeCards.json', params);
  }

  ConsultaNotasTurma(Turma: any, Disciplina: any, Trimestre: any){
    return this.api.get(`/Nota/ListaTurmaNota2?Turma=${Turma}&Disciplina=${Disciplina}&Trimestre=${Trimestre}`);

  }
  atualizaNotas(formContent: any){
    return this.api.post('/Nota/AtualizaNotaAluno', formContent);
  }
  create(formContent: any){
    return this.api.post('/Nota', formContent);
  }

/*   update(id:any, formContent: any){
    return this.api.update('/Nota', id, formContent);
  } */
  update(formContent: any){
    return this.api.actualizar('/Nota', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Nota/${id}`);
  }

  delete(id: any) {
    return this.api.delete('/Nota' , id);
  }
}
