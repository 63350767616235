import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Utilizadores/All', params);
    // return this.api.get('/employeeCards.json', params);
  }


  create(formContent: any){
    return this.api.post('/Utilizadores', formContent);
  }

  update(id:any, formContent: any){
    return this.api.update('/Utilizadores', id, formContent);
  }
  actualizar(formContent: any){
    console.log(formContent);

    return this.api.actualizar('/Utilizadores', formContent);
  }
  updatePassword(formContent: any){
    return this.api.post('/Usuarios/change-password', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Utilizadores/${id}`);
  }

}
