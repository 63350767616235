import { Router } from "@angular/router";
import { SerialService } from "../core/services";
import { Component, Injectable, Injector, OnInit, PipeTransform } from '@angular/core';

@Injectable()
export class Publico {
constructor(injector: Injector,private serialService: SerialService,
         private route: Router) {}

public verificaLincenca() {
  let cont= 0;
  this.serialService.loadAll({}).subscribe((data: any) =>{
    if (data.contador  == 0) {
     this.route.navigate(['tela']);
    }
  })
}

}
