import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import { ReportServerService, UtilsService, ContaCaixaService } from '@service/index';
import { Publico } from '@shared/publico';
import { AnoLectivoActual,ContaCaixa, Meses  } from '@core/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-balanco',
  templateUrl: './balanco.component.html',
  styleUrls: ['./balanco.component.scss']
})
export class BalancoComponent  extends BasePage implements OnInit {
  @Input() public Tipo: any;
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private utilservice: UtilsService, private contaServico: ContaCaixaService,
    private _formBuilder: FormBuilder,
    private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }

    tipo: Observable<ContaCaixa[]>;
    anoLetivo: Observable<AnoLectivoActual[]>;
    Meses: Observable<Meses[]>;
    ano: any [];

    pesquisaForm  =  this._formBuilder.group({
      Ano: ['', Validators.required]
    });

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.pesquisaForm  =  this._formBuilder.group({
      Ano: ['', Validators.required]
    });

    this.anoLetivo = this.utilservice.BuscaAnoLectivo();

  }

  onSubmit() {

    this.reportService.balanco(this.pesquisaForm.get('Ano')?.value).subscribe((response: Blob | MediaSource) => {
      const fileURL = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'ResumoCalsse.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);

    }, (error: any) => {
      console.log('Error create employee', error);
      this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

    }
}
