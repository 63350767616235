import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisciplinaListComponent } from './disciplina-list/disciplina-list.component';

const routes: Routes = [

/*   {path:'disciplina', component: DisciplinaListComponent } */
  {path:'', component: DisciplinaListComponent, data: { breadcrumb: "Disciplina" }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisciplinaRoutingModule { }
