import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { UtilsService } from 'src/app/core/services';
import { BasePage } from '@shared/base-page';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent extends BasePage implements OnInit {
pdf: any;
  constructor(injector: Injector ,
              public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder,
          //    private documentoservice: AlunoDocumentoService,
            //  private utilservice: UtilsService
              ) {
                super(injector);

                }

  //@Input() public isReadOnly: boolean = false;
  @Input() public pdfSource: any;

  ngOnInit(): void {
     this.pdf = this.pdfSource;
  }

}
