import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendaService {

  constructor(private api: ApiService) { }
  public cartItemList : any =[]
  public productList = new BehaviorSubject<any>([]);


  loadAll(params: any){
    return this.api.get('/Vendas', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/Vendas', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Vendas', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Vendas/${id}`);
  }



  pagamento(formContent: any){
    return this.api.post('/Mensalidade/Pagamentos', formContent);
  }



  actualizar(formContent: any){
    return this.api.actualizar('/Mensalidade', formContent);
  }

  getInvoceId(TipoDoc: any, Serie: any ) {
    return this.api.get(`/Mensalidade/InvoiceId?TipoDoc=${TipoDoc}&Serie=${Serie}`);
  }

/* ?TipoDoc=FR&Serie=2022" */
   getProducts(){
    return this.productList.asObservable();
  }

  setProduct(product : any){
    this.cartItemList.push(...product);
    this.productList.next(product);
  }

  addtoCart(product : any){
    this.cartItemList.push(product);
    console.log(product);

    this.productList.next(this.cartItemList);
    this.getTotalPrice();
   // console.log(this.cartItemList);
  }

  getTotalPrice() : number{
    let grandTotal = 0;
    this.cartItemList.map((a:any)=>{
      grandTotal += (a.Valor);
    })
    return grandTotal;
  }

  getTotalMulta() : number{
    let Multa = 0;
    this.cartItemList.map((a:any)=>{
      Multa += (a.Multa);
    })
    return Multa;
  }
  removeCartItem(product: any){
    this.cartItemList.map((a:any, index:any)=>{
      if(product.IdContaCaixa=== a.IdContaCaixa){
        this.cartItemList.splice(index,1);
      }
    })
    this.productList.next(this.cartItemList);
  }
  removeAllCart(){
    this.cartItemList = []
    this.productList.next(this.cartItemList);
  }
}





