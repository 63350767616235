import {  Component, Injector, Input, OnInit, PipeTransform } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, DisciplinaService } from '@service/index';
import {Disciplina, Classe, DadosPessoais } from '@core/models/index';
import {BasePage} from '@shared/base-page';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-disciplina-form',
  templateUrl: './disciplina-form.component.html',
  styleUrls: ['./disciplina-form.component.scss']
})
export class DisciplinaFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private disciplinaService: DisciplinaService,
    private utilservice: UtilsService) { super(injector);  }

    DisciplinaForm  =  this._formBuilder.group({
      IdDisciplina: [''],
      Disciplina1: ['', Validators.required],
      Sigla: ['', Validators.required],
      Carga: ['', Validators.required]
    });


    disciplinas: Disciplina[];
  //  dadosPessoais: DadosPessoais[]= [];
  //  classe: Observable<Classe[]>;
    @Input() public disciplina: any;
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {
      if (this.isReadOnly) {
        console.log(this.disciplina);
        this.loadById();
      }
  }

  loadById() {
    this.DisciplinaForm.get('IdDisciplina')?.setValue(this.disciplina.IdDisciplina);
    this.DisciplinaForm.get('Disciplina1')?.setValue(this.disciplina.Disciplina1);
    this.DisciplinaForm.get('Sigla')?.setValue(this.disciplina.Sigla);
    this.DisciplinaForm.get('Carga')?.setValue(this.disciplina.Carga);
  }

  onSubmit() {
      this.showLoadingView();

      console.log('Form Value to submmit', this.DisciplinaForm.value);
      this.disciplinaService.create(this.DisciplinaForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
          this.showMessage('Sucesso', 'Aluno Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao criar Aluno Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.showLoadingView();
      console.log('Form Value to submmit', this.DisciplinaForm.value);
      this.disciplinaService.update(this.DisciplinaForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Funcionários atualisar com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        console.log('On error ocurr', error);
        this.showMessage('Erro', 'Ocorreu um erro ao atualisar Funcionário, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
