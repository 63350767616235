<br>
<mat-card>
<div class="col-lg-12">
  <tabset>
    <tab class="border border-top-0 p-3">
      <ng-template tabHeading>
        <i class="fa fa-address-card mr-2"></i><span>Dados Pessoais</span>
      </ng-template>
      <div class="form-group">
        <h3 class="border-bottom">Dados Pessoais</h3>
      </div>
      <app-funcionario-dados-pessoais [func] ="dados"></app-funcionario-dados-pessoais>
     <!--  <app-perfil-detalhe (changeFormValue)="setFormValue($event)"></app-perfil-detalhe> -->
    </tab>
    <tab  class="border border-top-0 p-3">
      <ng-template tabHeading>
        <i class="fa fa-user mr-2"></i><span>Documento e Contactos</span>
      </ng-template>
      <div class="form-group">
        <h3 class="border-bottom">Documento e Contactos</h3>
      </div>
      <app-documentos-contactos></app-documentos-contactos>

    </tab>
    <tab  class="border border-top-0 p-3">
      <ng-template tabHeading>
        <i class="fa fa-user mr-2"></i><span>Vinculo</span>
      </ng-template>
      <div class="form-group">
        <h3 class="border-bottom">Vinculo</h3>
      </div>

      <app-palestrante-detalhe></app-palestrante-detalhe>
    </tab>
    <tab class="border border-top-0 p-3">
      <ng-template tabHeading>
        <i class="fa fa-share-square mr-2"></i><span>Disciplinas Lecionadas</span>
      </ng-template>
      <div class="form-group">
        <h3 class="border-bottom">Disciplinas Lecionadas</h3>
      </div>
      <app-professor-disciplinas [idFuncionario]="idFunc"></app-professor-disciplinas>
<!--       <app-redesSociais></app-redesSociais> -->
    </tab>
  </tabset>
  </div>

</mat-card>
