import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/MenuItem ', params);
    // return this.api.get('/employeeCards.json', params);
  }
  loadAcesso(params: any){
    return this.api.get(`/MenuItem /Acesso?id=${params}`);
    // return this.api.get('/employeeCards.json', params); Acesso?id=1
  }
  create(formContent: any){
    return this.api.post('/MenuItem', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/MenuItem', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/MenuItem /${id}`);
  }
  delete(id: any) {
    return this.api.apagar(`/MenuItem`, id);
  }
  menuByRole(id: any) {
    return this.api.get(`/MenuItem /MenuByRole?perfil=${id}`);
  }
  HaveAccess(role: any, menu: any) {
    return this.api.get('/MenuItem /HaveAccess?Menu=' + menu + '&Perfil=' + role);
  }

}
