import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { catchError, map } from 'rxjs/operators';
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authSrvc: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.authSrvc.loadUserToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        if (!request.headers.has('Content-Type')) {
            if (request.body instanceof FormData) {
                console.log('Não tem content e é form data', request.headers);
                // request = request.clone({
                //     setHeaders: {
                //         'content-type': 'multipart/form-data'
                //     }
                // });
            } else {
                request = request.clone({
                    setHeaders: {
                         'content-type': 'application/json' // 'content-type': 'application/json'
                    }
                });
            }
        }
        request = request.clone({
            headers: request.headers.set('Accept', 'text/plain')
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                   //  console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                if (error.status === 401) {
                    this.router.navigate(['/login']);
                    Swal.hideLoading();
                    Swal.close();
                }
                if (error.status === 400) {
                    // console.log(error.error);
                }
                return throwError(error);
            }));
    }
}
