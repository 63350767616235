
<div class="row">
  <div class="col-md-10">
      <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Pagamentos</h4>
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a >você esta em: Mensalidade</a></li>

        </ol>
  </div>

  <div class="col-md-2 text-right" style="padding-right: 10px;">
       <button mat-raised-button class="btn btn-success" [routerLink]="'/matricula'" style="margin:5px;"> Voltar</button>
  </div>
</div>



<div class="main-container " style="background-color: white;">

    <div class="row">

        <div class="col-md-7 col-sm-2 afastar">

            <div class="box-flex space col-md-12 col-sm-2 ">
                <div class="header header-document">
                    <div class="row hfluid"><div class="col-md-9 col-xs-9 hfluid">
                        <div class="header-flex-title">

                            <div class="doc-header" >
                                <div  class="doc-type-circle doc-type FT texto-negrito">Ano exercicio: {{anoLectivo}}</div>
                               <!--  <div class="texto-negrito">FT T01P2022/5</div> -->
                              <!--   <div class="doc-date texto-negrito">Data: {{data | date:"dd/MM/yyyy"}}</div> -->
                            </div>
                        </div>
                    </div>
                     <div class="col-md-3 col-xs-3 hfluid">
                        <div class="bar doc-header-right ">
                            <div class="office-payment paid">
                                <span class="icon-check icon">

                                </span> Hoje: {{data | date:"dd/MM/yyyy"}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="content">
              <form [formGroup]="cadastroForm" >

              <br>

                  <div class="col-md-12 " >
                    <mat-form-field appearance="outline" >
                        <mat-label>apartamento</mat-label>
                        <mat-select  name="Turma" formControlName="Turma"  >
                          <app-consulta-drop-down (Texto)="onKey($event)" ></app-consulta-drop-down>
                        <mat-option *ngFor="let s of turmas"  [value]="s.IdTurma">{{s.Turma1}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="cadastroForm.get('Turma')?.hasError('required')">Apartamento é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            <!--   <div *ngIf="isDone; else loading"> -->
              <div class="col-md-12 " >
                <mat-form-field appearance="outline" class="example-form-field">

                  <mat-label>Inquilino</mat-label>
                  <mat-select formControlName="Aluno" required>
                    <!-- <div *ngIf="isLoading"><img src="/assets/gif/Chronos.gif" class="center"/></div> -->
                    <app-consulta-drop-down  (Texto)="filtrarMatricula($event)" ></app-consulta-drop-down>

                    <mat-option  *ngFor="let state of matriculas" [value]="state.IdMatricula">{{state.Aluno.Nome}}</mat-option>
                    <br>
                  </mat-select>

                  <mat-error *ngIf="cadastroForm.get('Aluno')?.hasError('required')"> Inquilino é obrigatório. </mat-error>

                </mat-form-field>
              </div>
            <!-- </div> -->

            <ng-template #loading class="loading-spinner">
              <div ><img src="/assets/gif/Spinner-3.gif" class="center"/></div>
            </ng-template>

              </form>
            </div>
        </div>


            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <label class="texto-negrito">Mensalidade(s)</label>
                           <!--  <span class="cart-span-title">Meu Perfil</span> -->
                        </div>
                        <div class="col-md-4 ">
                       <!--  <mat-menu #animals="matMenu">
                          <button class="btn  btn-light boderleft mr-2" mat-menu-item >Movimentações</button>
                          <button mat-menu-item >Administrativo</button>
                        </mat-menu> -->
                        <button mat-button data-toggle="modal" (click)="filtrarMensalidade('161')" data-target="#information_modal"  style="float: right;"><i class="fa fa-bars"></i>Rendas</button>
                        <!-- <mat-menu #menu="matMenu">
                          <button mat-menu-item data-toggle="modal" (click)="filtrarMensalidade('161')" data-target="#information_modal">Propinas</button>
                          <button mat-menu-item data-toggle="modal" (click)="filtrarMensalidade('0')" data-target="#information_modal">Transp/multa/serviços</button>
                        </mat-menu> -->

                      </div>

                        <!-- <div class="col-md-3 doc-header-right">
                            <button class="btn  btn-light boderleft mr-2" id="" data-toggle="modal" data-target="#information_modal" style="float: right;"><i class="fa fa-bars"></i>Serviços</button>
                        </div> -->
                    </div>

                    <!-- <a class="cart-a-button btn btn-secondary btn-sm" name="" id="" (click)="editProfile()" role="button"><i class="fa fa-edit" aria-hidden="true"></i></a> -->
                </div>
                <div class="card-body custom-table-first">

                    <div class="form-row">
                        <div class="col-md-12 col-sm-12 ">
                            <div class="list-group list-group-flush" >
                                <table class="table table-hover table-inverse">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th>Serviço</th>
                                            <th>Mês</th>
                                           <!--  <th  >Dias Atrazado</th> -->
                                            <th class="text-right">Multa</th>
                                            <th class="text-right">Valor</th>
                                            <th class="text-right">Valor Total</th>
                                            <th>Opções</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let persDoc of products">
                                            <td scope="row">{{persDoc.ContaCaixa.Conta}}</td>
                                            <td scope="row">{{persDoc.Mes.Mes }}</td>
                                            <!-- <td class="text-center" scope="row">{{persDoc.DiasAtrazado }}</td> -->
                                            <td class="text-right" scope="row">{{persDoc.Multa |currency:" ":"symbol" }}</td>
                                            <td class="text-right">{{persDoc.ValorApagar | currency:" ":"symbol"}}</td>

                                            <td class="text-right">{{persDoc.ValorApagar + persDoc.Multa | currency:" ":"symbol" }}</td>
                                            <td>
                                                <a name="" id="" class="btn m-btn m-btn--icon btn-sm mr-2" role="button" (click)="removeItem(persDoc)" ><i class="fa fa-trash" style="color: #e81126;" aria-hidden="true"></i></a>
                                                <!--  <button type="button" name="" id="" title="Eliminar Aluno" class="btn m-btn m-btn--icon btn-sm mr-2"> <i class="fas fa-trash" style="color: #e81126;"></i></button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                            <!-- <td colspan="2" class="total">Total</td> -->
                                           <!--  <td colspan="4"></td> -->
                                            <td class="total texto-negrito ">Total </td>
                                            <td class="text-right texto-negrito total " > {{grandTotal | currency:" ":"symbol" }}</td>
                                            <td></td>

                                             <!-- text-right -->
<!--                                 <td><span>Subtotal for {{grandTotal | currency:" ":"symbol" }}%</span></td>
                                <td>{{grandTotal / 100.0}}</td> -->
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="col-md-5 col-sm-8">
            <form [formGroup]="MensalidadeForm" (ngSubmit)="onSubmit()">
            <div class="card afastar" style="width: 100%; height:500px">
                <div class="card-header">
                    <span class="cart-span-title texto-negrito">Pagamentos</span>
                    <a class="cart-a-button" name="" id=""  role="button"></a>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Data de Pagamento</mat-label>
                        <input matInput [matDatepicker]="DataPagamento" readonly name="DataPagamento" formControlName="DataPagamento">
                        <mat-datepicker-toggle matSuffix [for]="DataPagamento"></mat-datepicker-toggle>
                        <mat-datepicker [startAt]="date"  #DataPagamento></mat-datepicker>
                        <mat-error *ngIf="MensalidadeForm.get('DataPagamento').hasError('required')"> Data de pagamento é obrigatório.  </mat-error>

                    </mat-form-field>
                  </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label class="texto-negrito">Tipo de Pagamentos</mat-label>
                      <mat-select name="IdTipoPagamento" readonly formControlName="IdTipoPagamento" required>
                        <mat-option>-seleccione-</mat-option>
                        <mat-option *ngFor="let n of TipoPagamento | async" [value]="n.IdTipoPagamento">
                          {{n.TipoPagamento1}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="MensalidadeForm.get('IdTipoPagamento')?.hasError('required')" > Tipo de Pagamentos é obrigatório. </mat-error>

                   <!--   <mat-error *ngIf="trabalhadorForm.get('Id_Nacionalidade').hasError('required')">Seleccione o genero</mat-error>
                       <mat-hint>{{Id_Sexo.value?.sound}}</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label class="texto-negrito">Banco</mat-label>
                      <mat-select name="Banco" readonly formControlName="Banco" required>
                        <mat-option>-seleccione-</mat-option>
                        <mat-option *ngFor="let n of Bancos | async" [value]="n.IdBanco">
                          {{n.Sigla}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="MensalidadeForm.get('Banco')?.hasError('required')" > Banco é obrigatório. </mat-error>

                   <!--   <mat-error *ngIf="trabalhadorForm.get('Id_Nacionalidade').hasError('required')">Seleccione o genero</mat-error>
                       <mat-hint>{{Id_Sexo.value?.sound}}</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label class="texto-negrito">Desconto em %</mat-label>
                        <input matInput formControlName="Desconto" maxlength="9" type="number"  (blur)="getCalculadesconto()"  />
                        <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                        <mat-error *ngIf="MensalidadeForm.get('Desconto')?.hasError('required')"> Desconto é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>

                   <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label class="texto-negrito">Total a Pagar</mat-label>
                        <input matInput formControlName="Valor" maxlength="9" type="number"  class="texto-negrito"/>
                        <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                        <mat-error *ngIf="MensalidadeForm.get('Valor')?.hasError('required')"> Total a Pagar é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label class="texto-negrito">Borderoux</mat-label>
                        <input matInput formControlName="Borderoux" maxlength="7" type="text"/>
                        <mat-icon matSuffix class="secondary-text" >money</mat-icon >
                        <mat-error *ngIf="MensalidadeForm.get('Borderoux')?.hasError('required')"> Borderoux é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>

                   <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label class="texto-negrito">Saldo</mat-label>
                        <input matInput formControlName="Saldo" maxlength="9" readonly type="number"/>
                        <mat-icon matSuffix class="secondary-text" >money_off</mat-icon >
                        <mat-error *ngIf="MensalidadeForm.get('Saldo')?.hasError('required')"> Saldo é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>

                </div>
                  <button type="submit"  class="btn btn-success" [disabled]="!MensalidadeForm.valid" >Criar Factura</button>
                 <!--  <ngx-extended-pdf-viewer [src]="pdfSource" [useBrowserLocale]="true"> </ngx-extended-pdf-viewer> -->
                </div>
        </form>
        </div>

    </div>
    <br>


</div>



  <!-- left modal  modal_outer right_modal -->
<div class="modal modal_outer right_modal fade" id="information_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" >
    <div class="modal-dialog" role="document">
       <form method="post"  id="get_quote_frm">
        <div class="modal-content ">
            <!-- <input type="hidden" name="email_e" value="admin@filmscafe.in"> -->
            <div class="modal-header">
              <h2 class="modal-title">Mensalidades:</h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div  style=" height:480px">
            <div class="modal-body get_quote_view_modal_body">
               <div class="card-body table-responsive custom-table">
                <table class="table table-hover table-inverse">
                    <thead class="thead-inverse">
                        <tr>
                            <th>Tipo</th>
                            <th>Mês</th>
                            <th>Data Venc</th>
                            <th>Valor</th>
                          <!--   <th>Situacão</th> -->
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let persDoc of mensalidade2">

                            <td scope="row">{{persDoc.ContaCaixa.Conta}}</td>
                            <td scope="row">{{persDoc.Mes.Mes}}</td>
                            <td scope="row">{{persDoc.DataVencimento | date: "dd/MM/yyyy"}}</td>

                            <td scope="row">{{persDoc.ValorApagar |currency:" ":"symbol" }}</td>
                           <!--  <td>{{persDoc.DataVencimento | date: "dd/MM/yyyy"}}</td> -->

                           <!--  <td>{{persDoc.Situacao}}</td> -->
                            <td>
                               <!--  <a name="" id="" class="btn btn-sm btn-light" role="button" (click)="addtocart(persDoc)"  ><i class="fa fa-cart-plus" aria-hidden="true"></i></a> -->
                                <button [hidden]="persDoc.Situacao=='LIQUIDADA'" (click)="addtocart(persDoc)"  type="button" name="" id="" class="btn btn-light
                                btn-sm"> <i class="fas fa-check"></i></button>
                                <button *ngIf="persDoc.Situacao=='LIQUIDADA'" (click)="printOrOpenPdf(persDoc.MovimentosCaixas.Documento,'Print')"  type="button" name="" id="" class="btn btn-light
                                btn-sm"> <i class="fas fa-print"></i></button>


                                <button *ngIf="persDoc.Situacao=='LIQUIDADA'" (click)="AnularPagamento(persDoc, 0)" title="Anular" type="button" name="" id="" class="btn btn-light
                                btn-sm"> <i class="fa fa-ban" style="color: #e8e111;"></i></button>
                                <button  (click)="AnularPagamento(persDoc, 1)" title="Eliminar" type="button" name="" id="" class="btn btn-light
                                btn-sm"> <i class="fa fa-trash" style="color: #e81126;"></i></button>
                                <!-- <button type="button" name="" id="" class="btn
                            btn-warning btn-sm mr-2" title="Alterar Matricula" > <i class="fas fa-edit"></i></button>
                        <button type="button" name="" id="" class="btn btn-dark
                            btn-sm" title="Apagar Matricula"> <i class="fas fa-trash"></i></button> -->
                            </td>



                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            </div>

        </div><!-- modal-content -->
      </form>
    </div><!-- modal-dialog -->
</div><!-- modal -->

