import { ProdutosModule } from './produtos/produtos.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, forwardRef } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProdutoFormComponent } from './produtos/produto-form/produto-form.component';
import { AlunoFormComponent } from './aluno-form/aluno-form.component';
import { DisciplinaFormComponent } from './disciplina/disciplina-form/disciplina-form.component';
import { ContactoAlunoComponent } from './contacto-aluno/contacto-aluno.component';
import { DocumentoAlunoComponent } from './documento-aluno/documento-aluno.component';
//import { HomeComponent } from '../pages/home/home.component';
import { ClasseFormComponent } from './classe/classe-form/classe-form.component';
import { ListDisciplinaComponent } from './classe/list-disciplina/list-disciplina.component';
import { MatriculaFormComponent } from './matricula/matricula-form/matricula-form.component';
import { VendaListComponent } from './venda/venda-list/venda-list.component';
import { ReportComponent } from './report/report.component';
import { VendaFormComponent } from './venda/venda-form/venda-form.component';
import { CaixaPesquisaComponent } from './caixa-pesquisa/caixa-pesquisa.component';
import { MenuFormComponent } from './menu/menu-form/menu-form.component';
import { UtilizadorFormComponent } from '../pages/Utilizador/utilizador-form/utilizador-form.component';
import { AlunoservicoFormComponent } from './aluno-form/alunoservico-form/alunoservico-form.component';
import { CursoFormComponent } from './curso/curso-form/curso-form.component';
import { AlunoConfirmaMatriculaComponent } from './relatorio-pesquisa/aluno-confirma-matricula/aluno-confirma-matricula.component';
import { ServicoAlunoComponent } from './servico-aluno/servico-aluno.component';
import { RelatorioPesquisaComponent } from './relatorio-pesquisa/relatorio-pesquisa.component';
import { CaixaServicoComponent } from './relatorio-pesquisa/caixa-servico/caixa-servico.component';
import { ResumoClasseComponent } from './relatorio-pesquisa/resumo-classe/resumo-classe.component';
import { DemostrativoMensalComponent } from './relatorio-pesquisa/demostrativo-mensal/demostrativo-mensal.component';
import { BalancoComponent } from './relatorio-pesquisa/balanco/balanco.component';
import { AlunoFilhacaoFormComponent } from './aluno-filhacao-form/aluno-filhacao-form.component';
import { PesquisaCartaDevedorComponent } from './relatorio-pesquisa/pesquisa-carta-devedor/pesquisa-carta-devedor.component';
import { PesquisaAlunoDividaTurmaComponent } from './relatorio-pesquisa/pesquisa-aluno-divida-turma/pesquisa-aluno-divida-turma.component';
import { DocumentoSecretariaFomComponent } from './DocumentosSecretaria/documento-secretaria-fom/documento-secretaria-fom.component';
//import { DocumentosSecretariaFomComponent } from './DocumentosSecretaria/documentos-secretaria-fom/documentos-secretaria-fom.component';
import { BancoFormComponent } from './banco/banco-form/banco-form.component';
import { TipoPagamentoFormComponent } from './tipopagamento/tipo-pagamento-form/tipo-pagamento-form.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { BairroFormComponent } from './bairro/bairroForm/bairro-form/bairro-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormularioRoutingModule } from './formulario-routing.module';
import { DisciplinaModule } from './disciplina/disciplina.module';
import { TrocarAlunoTurmaModule } from './trocar-aluno-turma-list/trocar-aluno-turma.module';
import { TrocarAlunoTurmaListComponent } from './trocar-aluno-turma-list/trocar-aluno-turma-list.component';
import { CaixaComponent } from './caixa/caixa.component';
import { MiniPautaComponent } from './relatorio-pesquisa/mini-pauta/mini-pauta.component';
import { MiniPautaGeralComponent } from './relatorio-pesquisa/mini-pauta-geral/mini-pauta-geral.component';
import { PautaComponent } from './relatorio-pesquisa/pauta/pauta.component';
//import { ApartamentoRackComponent } from './ApartamentoRack/apartamento-rack/apartamento-rack.component';
//import { TrocarAlunoTurmaModule } from './trocar-aluno-turma-list/trocar-aluno-turma.module';


const FORMS_COMPONENTS = [BairroFormComponent,TrocarAlunoTurmaListComponent, CaixaComponent,MiniPautaGeralComponent,PautaComponent,
 ProdutoFormComponent , AlunoFormComponent, ClasseFormComponent, ListDisciplinaComponent, MatriculaFormComponent,VendaListComponent,
 ContactoAlunoComponent, DocumentoAlunoComponent,DisciplinaFormComponent, ReportComponent, VendaFormComponent, BancoFormComponent, TipoPagamentoFormComponent,
CaixaPesquisaComponent, MenuFormComponent,  UtilizadorFormComponent, AlunoservicoFormComponent,CursoFormComponent,AlunoConfirmaMatriculaComponent,
ServicoAlunoComponent, RelatorioPesquisaComponent, CaixaServicoComponent, ResumoClasseComponent, DemostrativoMensalComponent, BalancoComponent,
AlunoFilhacaoFormComponent,MiniPautaComponent,  DocumentoSecretariaFomComponent, PesquisaAlunoDividaTurmaComponent, PesquisaCartaDevedorComponent,

];
const MODULOS = [SharedModule, NgbModule, FormsModule, BreadcrumbModule,FormularioRoutingModule, TrocarAlunoTurmaModule];



@NgModule({
  declarations: [FORMS_COMPONENTS  ],
  imports: [MODULOS, NgMultiSelectDropDownModule.forRoot()],
  exports:[FORMS_COMPONENTS,MODULOS],
  entryComponents:[ FORMS_COMPONENTS ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers:[{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgMultiSelectDropDownModule),
    multi: true,
  },]
})
export class FormularioModule { }



