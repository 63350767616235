import { Component, Injector, OnInit, Input } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlunoDocumentoService,AlunoContactoService,UtilsService, AlunoService } from '@core/services/index';
import { BasePage } from '@shared/base-page';
import { ActivatedRoute } from '@angular/router';
import { ContactoAlunoComponent } from '../../contacto-aluno/contacto-aluno.component';
import { DocumentoAlunoComponent } from '../../documento-aluno/documento-aluno.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-documentos-contactos',
  templateUrl: './documentos-contactos.component.html',
  styleUrls: ['./documentos-contactos.component.scss']
})
export class DocumentosContactosComponent extends BasePage implements OnInit {
  [x: string]: any;
  @Input() IdFuncionario: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private modalService: NgbModal, private documentoservice: AlunoDocumentoService,
    private alunoservice: AlunoService, private contactoservice: AlunoContactoService,
    private currentRoute: ActivatedRoute,
    private utilservice: UtilsService) { super(injector); }

  ngOnInit(): void {
  }

  onAddDocumento(documento: any) {
    const modalref =  this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
       this.loadDoc();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.loadDoc();
    });
  }

  onEditDocumento(documento: any){
    const modalref = this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
 //     this.loadDoc();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     this.loadDoc();
    });
  }

  onAddContacto(contacto: any) {
    console.log(contacto);
    const modalref =  this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = contacto;
    modalref.result.then((result) => {
      this.loadContacto();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEditContacto(employee: any){
    const modalref = this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = employee;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadContacto();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  loadContacto() {
      this.contactoservice.loadByIdAluno(this.dados.IdFuncionario).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.AlunoContactos = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  loadDoc() {
      this.documentoservice.loadByIdAluno(this.dados.IdFuncionario).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.alunoDocumento = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

}
