import { Component, Injector, OnInit, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal,  ModalDismissReasons,  } from '@ng-bootstrap/ng-bootstrap';
import { Aluno, Matricula, AnoLectivoActual, Pagination } from '@core/models/index';
import {UtilsService,  MatriculaService,  ReportServerService} from '@service/index';
import {MatriculaFormComponent,  ServicoAlunoComponent} from '@component/index';
import { HttpClient,  } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BasePage } from 'app/shared/base-page';
import { FormBuilder, FormControl } from '@angular/forms';
import { map, takeUntil,  } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { Publico } from '@shared/publico';



@Component({
  selector: 'app-matricula-list',
  templateUrl: './matricula-list.component.html',
  styleUrls: ['./matricula-list.component.scss']
})
export class MatriculaListComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector, private matriculaservice: MatriculaService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private routerCtrl: Router,
              private _formBuilder: FormBuilder,
              private publico: Publico,
              private reportService: ReportServerService,
              private httpClient: HttpClient,
              private utilservice: UtilsService,
              public pipe: DecimalPipe) {super(injector);}

  matriculas : Matricula[] = [];
  localmatriculas : Matricula[] = [];
  aluno: any[] = [];
  Turmas: any[]=[];
  Anolectivos: any;
  anoLetivo: Observable<AnoLectivoActual[]>;
  data: Aluno[];
 filteredUsers: any[] = [];
  anolectivoActual: any;
  config: any;
  public page = 1;
  public pageSize = 5;
  collectionSize = 0;
  public pagination = {} as Pagination;
  closeResult = '';
  filter = new FormControl('');
  filter2 = new FormControl('');
 // panelOpenState = false;
  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.loadData();
     this.anoLetivo = this.utilservice.BuscaAnoLectivo();

    this.config = {
      ItemsPerPage: 5,
      currentPage: 1,
      page: 1,
      AnoLectivo : 0,
      IdTurma: 0,
      Nome: "",
      IdMatricula:"",
    //  totalItems: 5
    }
    this.loadamatriculas();
  }

form  =  this._formBuilder.group({
    IdMatricula: new FormControl(''),
    Nome: new FormControl(''),
    IdTurma: new FormControl(''),
    Anolectivo: new FormControl('')

  });

  loadamatriculas() {
    this.showLoadingView();
    this.matriculaservice.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.localmatriculas = response.data;
        this.matriculas = response.data;

        this.pagination = response.pagination;
         this.config = {
       //  itemsPerPage: 5,
         currentPage: response.currentPage,
         page: 1,
       //  filter: response.pagination.filter,
         totalItems: response.total
       }

       this.collectionSize = response.total;

       if (response.total > 0 ){
        this.aluno = this.matriculas[0].Aluno;
       }

        this.showContentView();
      }, error => {
        console.log('Error a efetuar requisicao', error);
      });
  }
  onAddService(Idmatricula: any) {
    const modalref =  this.modalService.open(ServicoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.Idmatricula = Idmatricula;
    modalref.componentInstance.isReadOnly = false;
    modalref.result.then((result) => {
      this.loadamatriculas();
      this.closeResult = `Closed with: ${result}`;
    }
    , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadamatriculas();
    });
  }

  extrato(matricula: any){
    this.reportService.extrato(matricula).pipe(takeUntil(this.destroySubject)).subscribe((response: Blob | MediaSource) => {
      const fileURL = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'Extrato.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);

    }, (error: any) => {
      console.log('Error create employee', error);
      this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

  }


  loadData() {
      this.utilservice.loadTurma({}).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
            this.Turmas = response;
          }, error => {
            console.log('Error on request', error);
          });
      this.utilservice.BuscaAnoLectivoActual().pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.anolectivoActual = response[0].Descricao;
          }, error => {
            console.log('Error on request', error);
          });
   }

  test(){
   this.matriculaservice.loadAll({}).pipe(takeUntil(this.destroySubject))
      .pipe(map(res =>({
        name: res
      }))).subscribe(luke => console.log(luke))
  }

  search(text: string, pipe: PipeTransform): Matricula[] {
    return this.localmatriculas.filter(matricula => {
      const term = text.toLowerCase();
      return matricula.IdMatricula.toLowerCase().includes(term)
           || matricula.Aluno.Nome.toLowerCase().includes(term)
           || matricula.Anolectivo.toLowerCase().includes(term)
           || matricula.Turma.Turma1.toLowerCase().includes(term)
           || matricula.Turma.Classe.Classe.toLowerCase().includes(term)

         ;
    });
  }


  pageChanged(event: any) {
    this.pagination.page = event;
    this.config.page = event;
    this.filtro();
   }


  filtro(){
    let IdMatricula =  this.form.get('IdMatricula')?.value == ""? '' : this.form.get('IdMatricula')?.value;
    let nome = this.form.get('Nome')?.value == ""? '' : this.form.get('Nome')?.value;
    let turma =  this.form.get('IdTurma')?.value == ""? 0 : this.form.get('IdTurma')?.value;
    let anolectivo =  this.form.get('Anolectivo')?.value == ""? 0 : this.form.get('Anolectivo')?.value;

    this.config.IdMatricula = IdMatricula;
    this.config.Nome = nome;
    this.config.IdTurma = turma;
    this.config.AnoLectivo = anolectivo
    this.config.ItemsPerPage = this.pageSize;
    this.loadamatriculas();
  }


  filterUserList(filters: any, users: any): void {
    this.filteredUsers = this.localmatriculas; //Reset User List
    const keys = Object.keys(filters); // Nome dos Campos enviados
    const filterUser = (user: any) => {
      let result = keys.map((key) => {
          if (user[key]) {
            return String(user[key])
              .toLowerCase()
              .startsWith(String(filters[key]).toLowerCase());
          } else {
            return false;

          }
      //  }
      });

      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)
      result = result.filter((it) => it !== undefined);
      // Filter the Age out from the other filters
     /*  if (filters["ageto"] && filters["agefrom"]) {
        if (user["age"]) {
          if (
            +user["age"] >= +filters["agefrom"] &&
            +user["age"] <= +filters["ageto"]
          ) {
            result.push(true);
          } else {
            result.push(false);
          }
        } else {
          result.push(false);
        }
      } */

      return result.reduce((acc, cur: any) => {
        return acc & cur;
      }, 1);
    };
    this.matriculas = this.localmatriculas.filter(filterUser);
  }

  onDelete(id: any) {
    this.showLoadingView();
    this.matriculaservice.delete(id).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessageWithConfirm('Sucesso', 'Matricula excluido com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      this.loadamatriculas();
    }, (error: any) => {
      this.showMessage('Erro', 'Ocorreu um erro ao excluir Matricula, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
    });
}

  performFilter(filterBy: string): any[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.matriculas.filter((product: any) =>
        product.productName.toLocaleLowerCase().indexOf(filterBy) !== -1);
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onAddMatricula(aluno: any) {
      const modalref =  this.modalService.open(MatriculaFormComponent, { ariaLabelledBy: 'modal-basic-title' });
      modalref.componentInstance.aluno = aluno;
      modalref.componentInstance.isReadOnly = false;
      modalref.result.then((result) => {
        this.loadamatriculas();
        this.closeResult = `Closed with: ${result}`;
      }
      , (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.loadamatriculas();
      });
    }

  onEditMatricula(aluno: any) {
      const modalref =  this.modalService.open(MatriculaFormComponent, { ariaLabelledBy: 'modal-basic-title' });
      modalref.componentInstance.aluno = aluno;
      modalref.componentInstance.isReadOnly = true;
      modalref.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        this.loadamatriculas();
      }
      , (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.loadamatriculas();
      });
    }

    payment(message: any){
      this.matriculaservice.changeMessage(message);
      this.routerCtrl.navigateByUrl("/Home");
    }

    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

  }
