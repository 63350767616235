
import {  Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, TipodocService } from '@service/index';
import {BasePage} from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tipodoc-form',
  templateUrl: './tipodoc-form.component.html',
  styleUrls: ['./tipodoc-form.component.scss']
})
export class TipodocFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
    private tipodocService: TipodocService,
    private utilservice: UtilsService) { super(injector);  }

     TipoDocForm     =  this._formBuilder.group({
      Codigo: [''],
      Descricao: ['', Validators.required],
      Cabecario: ['', Validators.required],
      Rodape: ['', Validators.required]

    });

    @Input() public data: any;
    @Input() public title: string = 'Registrar';
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {
       if (this.isReadOnly) {
        this.loadById();
      }
  }

  loadById() {
    this.TipoDocForm.get('Codigo')?.setValue(this.data.Codigo);
    this.TipoDocForm.get('Descricao')?.setValue(this.data.Descricao);
    this.TipoDocForm.get('Cabecario')?.setValue(this.data.Cabecario);
    this.TipoDocForm.get('Rodape')?.setValue(this.data.Rodape);
  }

  onSubmit() {
      this.showLoadingView();
        this.tipodocService.create(this.TipoDocForm .value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Tipo documento Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Tipo de documento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
     this.showLoadingView();
      this.tipodocService.update(this.TipoDocForm .value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Tipo de documento atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizado Tipo de documento, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}

