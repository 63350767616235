import { Component, OnInit, ViewChild, Injector, ElementRef, Input } from '@angular/core';
import { Mensalidade } from '@core/models/index';
import { NgbModal,ModalDismissReasons, NgbActiveModal,} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { TipoPagamento, Banco } from '@core/models/index';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UtilsService,  ReportServerService,ContaCaixaService,VendaService,AlunoService, VendaItemService } from '@service/index';
import { BasePage } from 'app/shared/base-page';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportComponent } from '@component/index';
import { CurrencyPipe } from '@angular/common';
import { Publico } from '@shared/publico';

declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-venda-list',
  templateUrl: './venda-list.component.html',
  styleUrls: ['./venda-list.component.scss']
})
export class VendaListComponent  extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    private _formBuilder: FormBuilder,
    //  private activeModal: NgbActiveModal,
    private utilservice: UtilsService,
    // private vendaService: vendaService,
    private modalService: NgbModal,
    private cp: CurrencyPipe,
    public activeModal: NgbActiveModal,
    private reportserver: ReportServerService,
    protected sanitizer: DomSanitizer,
    private publico: Publico,
    private contacaixa: ContaCaixaService,
    private vendaService: VendaService,
    private alunoService: AlunoService,
    private itemVendaService: VendaItemService  ) {
    super(injector);
  //  this.dadosdoaluno();
  }

  @ViewChild('inputField') inputField: ElementRef; // Reference to the input element
  @Input() isOpen: boolean = false; // Input to control modal visibility

  MensalidadeForm = this._formBuilder.group({
    IdTipoPagamento: ['', Validators.required],
    Banco: ['', Validators.required],
    Desconto: [0, Validators.required],
    Borderoux: ['', Validators.required],
    Valor: ['', Validators.required],
    Saldo: [0],
    IdMensalidade: [''],
    Multa: [''],
    Quantidade: ['1'],
    InvoiceId: [],
    DiasAtrazado: [''],
    Juros: [''],
    Mora: [''],
    Documento: [''],
    IdTipoDoc: [''],
    Descricao: [''],
    Serie: [''],
    DataPagamento: [new Date, Validators.required],
  });

  ClienteForm = this._formBuilder.group({
    IdCliente: ['', Validators.required],
  });

  vendas: any = {
    IdCliente: '',
    ValorTotal: 0,
    IdMovimentoCaixa: 0
  }

  itensVendas: any ={
    IdProduto: '',
    Quantidade: 1,
    Preco: 0,
    ValorTotal: 0,
    IdVenda: 0
  }


  public cartItemList: any = [];
  localmensalidade: Mensalidade[] = [];
  public page = 1;
  public pageSize = 15;
  collectionSize = 0;
  date;
  closeResult = '';
  termoBuscaChanged: Subject<string> = new Subject<string>();
  filter = new FormControl('');
  IdVenda: any;
  cartItemList2 : any =[]
  searchText: string = '';
  mes: any;
  public products: any = [];
  public grandTotal!: number;
  public totalItem: number = 0;
  isLoading = false;

  config: any;
  //Idmatricula: any;
  dados: any;
  anolectivo: any;
  mensalidade: Mensalidade[] = [];
  activityHistory: any = [];
  TipoPagamento: Observable<TipoPagamento[]>;
  Bancos: Observable<Banco[]>;
  data: any;

  fatura: any;
  filteredOptions: any;
  options: any =[];
  tipoRecibo: any;
  isDone = true;

  ngOnInit(): void {
    this.publico.verificaLincenca();

    this.config = {
      itemsPerPage: 5,
      filter: "",
      page: 1,
    }
    this.mes = '';
    this.TipoPagamento = this.utilservice.BuscaTipoPagamentos();
    this.Bancos = this.utilservice.BuscaBanco();
    this.data = Date();
    this.checkMesAnteriordivida = false;
    this.loadMatricula();
    this.loadalunos();
    this.date = new Date((new Date().getTime() - 3888000000));
     this.vendaService.getProducts().subscribe((res: any) => {
      console.log(res);
      this.products = res;
      this.totalItem = res.length;
      this.grandTotal = this.vendaService.getTotalPrice();
      this.MensalidadeForm.get('Valor')?.setValue(this.grandTotal);
    });

  }


  removeItem(item: any) {
    this.vendaService.removeCartItem(item);
  }
  emptycart() {
    this.vendaService.removeAllCart();
  }


  onReport(pdfSource: any) {
    const modalref = this.modalService.open(ReportComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    modalref.componentInstance.pdfSource = pdfSource;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log('Modal close result', this.closeResult);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log('Modal close result', this.closeResult);
      }
    );
  }

  printOrOpenPdf(id: string, tipo: string) {
    console.log(id);

    this.reportserver.getReciboVenda(id, this.tipoRecibo ).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      const fileURL = URL.createObjectURL(data);
      this.loadMatricula();
      this.MensalidadeForm.get('Borderoux')?.setValue('0');
      if (tipo == 'Print') {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        //    this.onReport(data)
      } else {
        // window.open(fileURL, "_blank"); //,  "_blank"
        //  const url = window.URL.createObjectURL(data);
        // window.open(url);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `FT_${id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    });
  }

  pageChanged(event: any) {
    this.config.page = event;
    this.loadalunos();
   }

  applyFilter(filterValue: string) {

    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
          this.config.filter = filtrarPor;
          this.loadalunos();
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }


loadalunos() {
 // this.showLoadingView();

 loadingServiceShow(1, 'IdCliente', false);
 this.isLoading= true;
 this.isDone = false;
  this.config.filter = this.config.filter== null ? this.config.filter = "" : this.config.filter;
  this.config.ItemsPerPage = this.pageSize;

    this.alunoService.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.isDone = true
        this.options = response.data;
        this.filteredOptions = response.data;
         this.config = {
         currentPage: response.currentPage,
         page: 1,
         filter: response.pagination.filter,
         totalItems: response.total
       }
       this.collectionSize = response.total;
       this.isLoading= false;

       loadingServiceHide('IdCliente');
        this.showContentView();
      }, error => {    console.log('Error a efetuar requisicao', error);});
  }

  getNames(){
    this.alunoService.loadAll({}).subscribe((response: any) => {
      this.options = response;
      console.log(response);

      this.filteredOptions = response;
    })
  }


  onSubmit() {

    console.log(this.anolectivo);


    this.vendaService.getInvoceId('VD', this.anolectivo).subscribe(
      (response: any) => {
        this.fatura = response;
        this.MensalidadeForm.get('InvoiceId')?.setValue(response);
        this.MensalidadeForm.get('Quantidade')?.setValue(this.totalItem);
        this.MensalidadeForm.get('Documento')?.setValue('VD ' + this.anolectivo + '/' + response
        );
        this.MensalidadeForm.get('IdTipoDoc')?.setValue('VD');
        this.MensalidadeForm.get('Serie')?.setValue(this.anolectivo);
        this.MensalidadeForm.get('Multa')?.setValue(this.vendaService.getTotalMulta()
        );
        this.pagamento(response);
        console.log('Invoice carregado com sucesso', response);
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
      }
    );
  }

  pagamento(Factura: any) {
    let Recibo = 'VD ' + this.anolectivo + '/' + Factura;
    Swal.fire({
      title: 'Deseja Imprimir a Factura nº. VD ' + this.anolectivo + '/' + Factura, //this.vendaService.getTotalPrice(),
      text: 'Total: ' + this.cp.transform( this.vendaService.getTotalPrice(), 'Kz ', 'symbol', '1.2-2' ),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Emitir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.vendaService.pagamento(this.MensalidadeForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {

          this.vendas.IdCliente = this.ClienteForm.get('IdCliente')?.value;
          this.vendas.IdMovimentoCaixa = response.IdMovimentoCaixa;
          this.vendas.ValorTotal = this.vendaService.getTotalPrice();
          this.cartItemList2 = this.vendaService.cartItemList;

          this.vendaService.create(this.vendas).subscribe((res: any) => {
            console.log('venda criado');
            this.IdVenda = res.IdVenda;


          this.cartItemList2.forEach((mensalidade: any) => {
                console.log('itens vendas criado');
                this.itensVendas.IdProduto= mensalidade.IdContaCaixa;
                this.itensVendas.Preco= mensalidade.Valor;
                this.itensVendas.ValorTotal = mensalidade.Valor;
                this.itensVendas.IdVenda =  this.IdVenda;
                this.itemVendaService.create(this.itensVendas).pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {console.log(res);})
               });

              });

            Swal.fire({
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonColor: 'green',
              cancelButtonColor: '#6c757d',
              denyButtonColor: 'red',
              confirmButtonText: '<i class="fa fa-print" ></i>Imprimr',

              denyButtonText: '<i class="fa fa-file-pdf "></i>Download PDF',
              cancelButtonText: '<i class="fa fa-envelope" ></i>Enviar/E-mail',
              title: 'Factura FR ' + this.anolectivo + '/' + Factura, // this.MensalidadeForm.get('IdSerie')?.value,
              text:
                'Total Pago: ' +
                this.cp.transform(this.vendaService.getTotalPrice(),'Kz ','symbol',),
              icon: 'success',
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire('Documento impresso com sucesso!', '', 'success');
                this.printOrOpenPdf(Recibo, 'Print');
              } else if (result.isDenied) {
                Swal.fire('Documento aberto com sucesso', '', 'info');
                this.printOrOpenPdf(Recibo, 'Open');
              } else {
                const { value: email } = await Swal.fire({
                  title: 'Informe o email',
                  input: 'email',
                  inputLabel: 'Informe o email',
                  inputPlaceholder: 'Digite o email ',
                });

                if (email) {
                  this.reportserver
                    .enviarReciboViaEmail(Recibo, email)
                    .subscribe((response: any) => console.log(response));
                  Swal.fire(`Entered email: ${email}`);
                }

                this.loadMatricula();
              }
            });

            //    this.showContentView();
            this.vendaService.removeAllCart();
            //    this.loadMatricula();
          },
          (error: any) => {
            console.log('Error create Matricula', error);
            this.showMessage(
              'Erro',
              'Ocorreu um erro ao realizar o pagamento Tente novamente',
              'error'
            ).then(() => this.activeModal.close('Save click'));
          }
        );
      }
    });
  }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'custom-class',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadMatricula() {

    this.anolectivo = this.utilservice.getAnoLectivo();
    this.tipoRecibo = this.utilservice.getTipoRecibo();

  this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.anolectivo = response[0].AnoLectivo;
      this.tipoRecibo = 4;
        }, (error: any) => { console.log('Error on request', error);
      });

    this.contacaixa.loadByTipo('P').pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.localmensalidade = response;
        this.mensalidade = response;
        console.log(response);

        if (this.localmensalidade && this.localmensalidade.length) {
          this.collectionSize = this.localmensalidade.length;
        }
        this.showContentView();

      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }

  addtocart(item: any) {
    console.log(item);
      this.mes="";
      this.cartItemList.push(item);
      this.MensalidadeForm.get('Descricao')?.setValue('Vendas');
      this.vendaService.addtoCart(item);

  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
