import { CONSTS, NiveisDeAcesso } from './const'
import { Injector } from "@angular/core";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { Router } from '@angular/router';
import { UtilsService } from '../core/services/utils/utils.service';
import { SerialService } from '../core/services';
import { SerialSistema } from '../core/models';
import { delay, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
export abstract class BasePage {

    durationInSeconds = 3;
    public countyList: any;
    private _dialogCtrl: NgbModal;
    invalidsCandidates: string = '';
    localUserRoles: Array<any> | undefined;
    permitions = NiveisDeAcesso;

    public isContentViewVisible = false;
    public isEmptyViewVisible = false;
    public isErroViewVisible = false;
    public _routerCtrl: Router;


    constructor(injector: Injector, ) {
        this._dialogCtrl = injector.get(NgbModal);
        this._routerCtrl = injector.get(Router);
        this.loadCurrentRoles();
        //this.totalDays();
    }

    getAgeFromDob(dob: any) {
        return moment().diff(dob, 'years');
    }

    onShowModalDialog(component: any, data?: any, minWidth: any = '800px', customCss?: any) {
        const modalResult = this._dialogCtrl.open(component,
            {}
        );
        return modalResult;
    }

    showContentView() {
        this.dismissLoadingView();
        this.isContentViewVisible = true;
        this.isEmptyViewVisible = false;
        this.isErroViewVisible = false;
    }

    showEmptyView() {
        this.dismissLoadingView();
        this.isContentViewVisible = false;
        this.isEmptyViewVisible = true;
        this.isErroViewVisible = false;
    }

    showErrorView(title: string, content: string = '') {
        this.dismissLoadingView();
        this.showMessage(title, content, 'error');
        this.isContentViewVisible = false;
        this.isEmptyViewVisible = false;
        this.isErroViewVisible = true;
    }

    showMessage(title: string, text: string, type: SweetAlertIcon, dialogRef?: NgbActiveModal) {
        return Swal.fire({
            title: title,
            html: text,
            icon: type,
            showConfirmButton: true,
            confirmButtonColor: '#007BFF',
            didOpen: () => {
                Swal.hideLoading();
            },
            didClose: () => dialogRef?.close()

        });
    }

    showSwalWithConfirmationAndExecuteFunction(functionName: string, title: string, text: string, type: SweetAlertIcon) {
      Swal.fire({
        title: title,
        text: text,
        icon: type,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          const functionToExecute = this[functionName];
          if (typeof functionToExecute === 'function') {
            functionToExecute();
          } else {
            Swal.fire('A função não existe.');
          }
        }
      });
    }

    showMessageWithConfirm(title: string, text: string, type: SweetAlertIcon, dialogRef?: NgbActiveModal) {

    return      Swal.fire({
            title: title,
            html: text,
            icon: type,
            showConfirmButton: true,
            confirmButtonColor: '#007BFF',
            didOpen: () => {
           //     Swal.hideLoading();
                Swal.fire({
  title: 'Tem certeza?',
  text: "Não podera reverter esta acção!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Sim, pode apagar!'
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(
      'Apagado!',
      'Registo apagado com sucesso.',
      'success'
    )
  }
});
            },
            didClose: () => dialogRef?.close()

        });



/*
return Swal.fire({
  title: title,
  text: text,
  icon: 'question',
  showCancelButton: true,
  confirmButtonText: 'Emitir',
  cancelButtonText: 'Cancelar',
  reverseButtons: true
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(

        {

        confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'


  }
    )
  } else if (
    // Read more about handling dismissals below
    result.dismiss === Swal.DismissReason.cancel
  ) {
    Swal.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    )
  }
})
*/



    }

/* const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})

swalWithBootstrapButtons.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, cancel!',
  reverseButtons: true
}).then((result) => {
  if (result.isConfirmed) {
    swalWithBootstrapButtons.fire(
      'Deleted!',
      'Your file has been deleted.',
      'success'
    )
  } else if (
    // Read more about handling dismissals below
    result.dismiss === Swal.DismissReason.cancel
  ) {
    swalWithBootstrapButtons.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    )
  }
}) */

/* Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(
      'Deleted!',
      'Your file has been deleted.',
      'success'
    )
  }
}) */


showLoadingViewSemTitulo() {
  return Swal.fire({
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
          Swal.showLoading();
      }
  });
}

    showLoadingView() {
        return Swal.fire({
            title: 'A Processar ...',
            html: 'A processar dados em segundo plano, aguarde!',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    // showToast(message: string, timer = this.durationInSeconds * 1000) {
    //     return this._snackBar.open(message, 'Ok', {
    //         duration: timer,
    //     });
    // }

    dismissLoadingView() {
        Swal.hideLoading();
        Swal.close();
    }

    onProvinceChange(event: any) {
        if (event) {
            this.countyList = event.counties;
        } else {
            this.countyList = [];
        }
    }

    compareFunction(o1: any, o2: any) {
        return (o1.name == o2.name && o1.id == o2.id);
    }

   /*  totalDays() : number {
      let cont= 0;
      this.serialService.loadAll({}).subscribe((data: any) =>{
        cont = 0 ; //data.contador;
        console.log(data.contador);
      return data.contador
      })
      return cont
    } */
    getAnswer(question: any) {
        if (question)
            return 'Sim';
        else
            return 'Não';
    }

    processErrorObjects(error: any, field: string): string {
        let result = '';
        if (error.objects && field) {
            result = `<div><div id="divContent" style="overflow: auto; max-height: 200px; margin-top: 1em;"> <ol>`;
            error.objects.forEach((element: any) => {
                result += `<li>Código: ${element[field]}</li>`
                const item = {
                    codigoDoCandidato: element[field]
                };
                this.invalidsCandidates += `Código do Candidato :: ${element[field]} ; \n`;
            });
            result += `</ol> </div></div>`;
        }
        return result;
    }

    copyToClipboard(showToast = true) {
        // this._clipboard.copy(JSON.stringify(this.invalidsCandidates));
        this.invalidsCandidates = '';
        // if (showToast) { this.showToast('Conteúdo copiado para área de transferência'); }
    }

    isArray(content: Array<any>): boolean {
        return Array.isArray(content);
    }

    isInstanceOfObject(content: any): boolean {
        return content instanceof Object;
    }

    loadCurrentRoles() {
        const auth: any = localStorage.getItem(CONSTS.USER_PERMITION_LABEL);
        this.localUserRoles = auth;
        return auth;
    }

    navigateTo(urlPath: string, params: any = '') {
        this._routerCtrl.navigateByUrl(urlPath + '/' + params);
    }

    onUpdateNotifications(utilService: UtilsService){
        utilService.loadNotification({}).subscribe((response: any) => {
            console.log('Success on load notification', response);
            utilService.setNotification(response);
            console.log('Call to validate card', utilService.getNotification());
          }, error => {
            console.log('Error on load notifications', error);

          });
    }
}

export class CrudGenerico<T> {

  constructor(protected http: HttpClient, private API_URL: any) {}
   fullUrl = `${environment.API_URL_BASE}${environment.API_URL_PATH}`;
   fullUrlRecibo = `${environment.API_RECIBO_URL_BASE}${environment.API_URL_PATH}`;
  list() {
    return this.http.get<T[]>(`${this.fullUrl}${this.API_URL}`)
      .pipe(
        delay(2000),
        tap(console.log)
      );
  }

  loadByID(id: any) {
    return this.http.get<T>(`${this.fullUrl}${this.API_URL}/${id}`).pipe(take(1));
  }

  private create(record: T) {
    return this.http.post(`${this.fullUrl}${this.API_URL}`, record).pipe(take(1));
  }

/*   private update(record: T) {
    return this.http.put(`${this.fullUrl}${this.API_URL}/${record['id']}`, record).pipe(take(1));
  }

  save(record: T) {
    if (record.id) {
      return this.update(record);
    }
    return this.create(record);
  } */

  remove(id: any) {
    return this.http.delete(`${this.fullUrl}${this.API_URL}/${id}`).pipe(take(1));
  }
}
