<div class="col-md-12">
  <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Lista de Disciplinas</h4>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a>você esta em: Disciplinas</a></li>
    <li class="breadcrumb-item active">Lista de Disciplinas</li>
  </ol>
</div>
<br>
<div class="row">
  <div class="col-md-4 text-right">
    <div>
      <input class="form-control ml-2 my_rounded_corners" placeholder="Pesquisar..." type="text"
        [formControl]="filter" />
    </div>
  </div>
  <div class="col-md-8 text-right" style="padding-right: 10px;">
    <div>
      <button mat-raised-button class="btn btn-success" (click)="onAddDisciplina()"> Novo</button>
    </div>
  </div>
</div>

<div class="contaniner-fluild">


  <div class="accordion" id="accordionExample">
    <table class="content-table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Disciplina</th>
          <th scope="col">Sigla</th>
          <th scope="col">Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let D of disciplina | slice: (page-1) * pageSize : page * pageSize">
          <td scope="row">{{D.IdDisciplina}}</td>
          <td> {{ D.Disciplina1}} </td>
          <td>{{D.Sigla}}</td>
          <td>
            <!-- *ngIf=" permitions.VALIDANTE | authcheck " -->

            <button type="button" name="" id="" title="Editar Disciplina" class="btn
                            btn-warning btn-sm mr-2" (click)="onEditDisciplina(D)"> <i
                class="fas fa-edit"></i></button>
            <button type="button" name="" id="" title="Eliminar Aluno" class="btn btn-dark btn-sm"> <i
                class="fas fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2">

      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="15" [collectionSize]="disciplina.length"></ngb-pagination>

      <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadDisciplina()">
        <option [ngValue]="5">5 items por pagina</option>
        <option [ngValue]="10">10 items por pagina</option>
        <option [ngValue]="20">20 items por pagina</option>
      </select>
    </div>
  </div>
</div>
