import { Component, OnInit, Input, Injector } from '@angular/core';
import { NgbActiveModal,  NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, } from '@angular/forms';
import {UtilsService, MatriculaService, TurmaService} from '@service/index';
import { Observable, Subject } from 'rxjs';
import {TipoAluno, Parametros, Meses, AnoLectivoActual, SituacaoMatricula} from '@core/models/index';
import { BasePage } from '@shared/base-page';
import { environment } from '@env';
import * as moment from 'moment';
import { debounceTime, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-matricula-form',
  templateUrl: './matricula-form.component.html',
  styleUrls: ['./matricula-form.component.scss']
})
export class MatriculaFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector ,
              public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder, private turmaservice: TurmaService,
              private matriculaservice: MatriculaService,
              private utilservice: UtilsService) {
                super(injector);

                }

  @Input() public isReadOnly: boolean = false;
  @Input() public aluno: any;

  matriculaForm  =  this._formBuilder.group({
    IdMatricula: [''],
    Anolectivo: ['', Validators.required],
    IdAluno: ['', Validators.required],
    IdTurma: ['', Validators.required],
    NumAlunoTurma: [''],
    DataMatricula: [''],
    IdSituacaoMatricula: ['', Validators.required],
    NumParcelas: ['', Validators.required],
    PrimeiroMes: ['', Validators.required],
    IdTipoAluno: ['']
  });

  public page = 1;
  public pageSize = 5;
  config: any;
  termoBuscaChanged: Subject<string> = new Subject<string>();
  collectionSize = 0;
  titulo="Inserir Contrato";
  TipoAlunos: Observable<TipoAluno[]>;
  ParamDoSistema: Parametros;
  Meses: Observable<Meses[]>;
  SituacaoMatriculas: Observable<SituacaoMatricula[]>;
  Turmas: any[] = [];
  localTurmas: any[] = [];
  Params: Parametros[];
  anolectivo: AnoLectivoActual[] = [];
  Params2: any[];
  nome: any;
  imageUrl =  './assets/img/perfil.png'; // '/assets/img/Foto.jpg';
  //sorteados = [];
  valorMaximo = 1000;

  ngOnInit(): void {


    this.config = {
      ItemsPerPage: 5,
      currentPage: 1,
      page: 1,
      filter: "",
    //  totalItems: 5
    }

    this.TipoAlunos = this.utilservice.BuscaTipoAluno();
//    this.ParametroDoSistema = this.utilservice.BuscaParametros();
    this.SituacaoMatriculas = this.utilservice.BuscaSituacaoMatricula();
    this.Meses = this.utilservice.BuscaMeses();
    this.loadData();
    this.fillTurmas();

    this.matriculaForm.get('IdAluno')?.setValue(this.aluno.IdAluno);
    this.Params2 = this.Params;

       if (this.isReadOnly) {
        console.log(this.aluno);
        this.titulo="Editar Contrato";
        this.loadById();
      } else {
        this.nome = this.aluno.Nome
        this.imageUrl = environment.API_URL_BASE + `/image/${this.aluno.PathPictur}`;
        this.matriculaForm.get('IdTipoAluno')?.setValue('N');
        this.matriculaForm.get('IdSituacaoMatricula')?.setValue(1);
      }
  }

  pageChanged(event: any) {
		this.config.page = event;
		this.filtro();
	   }

	applyFilter(filterValue: string) {
        if (this.termoBuscaChanged.observers.length === 0) {
          this.termoBuscaChanged
            .pipe(debounceTime(1000))
            .subscribe((filtrarPor) => {
              this.config.filter = filtrarPor;
              this.filtro();
            });
        }
        this.termoBuscaChanged.next(filterValue);
     }

	filtro(){
		  this.config.filter = this.config.filter == null ? this.config.filter = "" : this.config.filter
		  this.config.ItemsPerPage = this.pageSize;
		  this.fillTurmas();
		}

  fillTurmas() {
    this.showLoadingView();
  //  console.log(this.config);

    this.turmaservice.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.localTurmas = response.data;
        this.Turmas = response.data;
        this.config = {
                      currentPage: response.currentPage,
                      page: 1,
                      filter: response.pagination.filter,
                      ItemsPerPage: this.pageSize,
                      totalItems: response.total
                      }
       this.collectionSize = response.total;
        this.showContentView();
      }, error => {
        console.log('Error a efetuar requisicao', error);
       // this.showLoadingView();
      });
  }

  loadById() {

    this.config.ItemsPerPage= 80;
    this.config.filter =  this.aluno.Turma.Turma1;

    this.fillTurmas();
    const date = moment(this.aluno.DataNasc).format('DD/MM/yyyy');
    const ngbDate: NgbDateStruct = {
      year: parseInt(date.split('/')[2]),
      month: parseInt(date.split('/')[1]),
      day: parseInt(date.split('/')[0]),
    }

    this.matriculaForm.get('IdAluno')?.setValue(this.aluno.Aluno.IdAluno);
    this.matriculaForm.get('Nome')?.setValue(this.aluno.Aluno.Nome);
    this.nome = this.aluno.Aluno.Nome
    this.matriculaForm.get('IdMatricula')?.setValue(this.aluno.IdMatricula);
    this.matriculaForm.get('Anolectivo')?.setValue(this.aluno.Anolectivo);
    this.matriculaForm.get('IdTurma')?.setValue(this.aluno.Turma.IdTurma);
    this.matriculaForm.get('DataMatricula')?.setValue(this.aluno.DataMatricula);
    this.matriculaForm.get('NumAlunoTurma')?.setValue(this.aluno.NumAlunoTurma);
    this.matriculaForm.get('IdSituacaoMatricula')?.setValue(this.aluno.SituacaoMatricula.IdSituacaoMatricula);
    this.matriculaForm.get('NumParcelas')?.setValue(this.aluno.NumParcelas);
    this.matriculaForm.get('PrimeiroMes')?.setValue(this.aluno.PrimeiroMes);
    this.matriculaForm.get('IdTipoAluno')?.setValue('N');


    if (this.aluno.Aluno.PathPictur)
       this.imageUrl = environment.API_URL_BASE + `/image/${this.aluno.Aluno.PathPictur}`;
    else
      this.imageUrl = './assets/img/perfil.png';
//console.log(this.matriculaForm.controls);

  }

  onKey(value: string) {
    let filter = value.toLowerCase();
    if(value.length === 0){
      this.loadData();
    }
    this.Turmas =  this.localTurmas.filter((option: any) =>  option.Turma1.toLowerCase().includes(filter) || option.Ano.toLowerCase().includes(filter))
  }

  loadData() {
           this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
                var date = new Date(response[0].DataInicio);
                var month = date.getMonth()+1;
                this.anolectivo = response[0].AnoLectivo;
                this.matriculaForm.get('Anolectivo')?.setValue(response[0].AnoLectivo);
                this.matriculaForm.get('PrimeiroMes')?.setValue(month);
                this.matriculaForm.get('NumParcelas')?.setValue(response[0].Parcela);
          }, (error: any) => {
            console.log('Error on request', error);
          });
   }

    onSubmit()     {
      const newDate = moment(new Date).format("YYYY-MM-DD");
      this.matriculaForm.get('IdMatricula')?.setValue(this.num());
      this.matriculaForm.get('NumAlunoTurma')?.setValue(25);
      this.matriculaForm.get('DataMatricula')?.setValue(newDate);
      this.showLoadingView();
      this.matriculaservice.create(this.matriculaForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Contrato Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar contrato Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

    onUpdate()
    {
      this.matriculaservice.update(this.matriculaForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Contrato actualizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao actualizar contrato Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }


    toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

    num(){
        //var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        var chars = "0123456789";
        var string_length = 5;
        var randomstring = '';
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        return randomstring;
      }

      ngOnDestroy() {
        // Unsubscribe from all observables
        this.destroySubject.next();
        this.destroySubject.complete();
      }
}


