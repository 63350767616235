<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Movimento do Caixa por serviço</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="pesquisaForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Data Inicio</mat-label>
                <input matInput [matDatepicker]="DataInicioPicker" readonly name="DataInicio" formControlName="DataInicio">
                <mat-datepicker-toggle matSuffix [for]="DataInicioPicker"></mat-datepicker-toggle>
                <mat-datepicker #DataInicioPicker></mat-datepicker>
                <mat-error *ngIf="pesquisaForm.get('DataInicio').hasError('required')"> Data de Inicio é obrigatório.  </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Data Final</mat-label>
                <input matInput [matDatepicker]="DataFimPicker" readonly name="DataFim" formControlName="DataFim">
                <mat-datepicker-toggle matSuffix [for]="DataFimPicker"></mat-datepicker-toggle>
                <mat-datepicker #DataFimPicker></mat-datepicker>
                <mat-error *ngIf="pesquisaForm.get('DataFim').hasError('required')"> Data de fim é obrigatório.  </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Tipo</mat-label>
                <mat-select  name="Tipo"  formControlName="Tipo">
                  <app-consulta-drop-down (Texto)="onKey($event)" ></app-consulta-drop-down>
                  <mat-option *ngFor="let s of tipo " [value]="s.IdContaCaixa">
                    {{s.Conta}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="pesquisaForm.get('AnoLectivo')?.hasError('required')">Tipo é obrigatório</mat-error>
              </mat-form-field>
          </div>
          <!-- <div class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label class="texto-negrito">Ano Lectivo</mat-label>
                <input matInput formControlName="AnoLectivo" maxlength="4" type="text"/>
                <mat-icon matSuffix class="secondary-text" >money</mat-icon >
                <mat-error *ngIf="pesquisaForm.get('AnoLectivo')?.hasError('required')"> AnoLectivo. </mat-error>
            </mat-form-field>
          </div> -->
      </div>
      <!--  {{ alunoForm.value | json }}  -->
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <button  type="submit" class="btn btn-success" [disabled]="!pesquisaForm.valid ">Gerar Relatório</button>
  </div>
</form>



