import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AlunoService, UtilsService, AlunoContactoService, AlunoDocumentoService, AlunoFilhacaoService} from '@service/index';
import { BasePage } from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-aluno-filhacao-form',
  templateUrl: './aluno-filhacao-form.component.html',
  styleUrls: ['./aluno-filhacao-form.component.scss']
})
export class AlunoFilhacaoFormComponent extends BasePage implements OnInit {
  [x: string]: any;

  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,private alunoFilhacaoService: AlunoFilhacaoService,
    private modalService: NgbModal, private documentoservice: AlunoDocumentoService,
    private alunoservice: AlunoService, private contactoservice: AlunoContactoService,
    private utilservice: UtilsService) { super(injector);  }



    alunoForm  =  this._formBuilder.group({
      IdFilhacao: [''],
      Pai: ['', Validators.required],
      Mae: ['', Validators.required],


    });

    @Input() public aluno: any;
    @Input() public isReadOnly: boolean = false;
    @Input() public titulo: any  = "Registar Filhação";
    private destroySubject: Subject<void> = new Subject();

  ngOnInit(): void {
    if (this.isReadOnly) {
      this.loadById();
    }
  }


  loadById() {
    this.alunoForm.get('IdFilhacao')?.setValue(this.aluno.IdFilhacao);
    this.alunoForm.get('Pai')?.setValue(this.aluno.Pai);
    this.alunoForm.get('Mae')?.setValue(this.aluno.Mae);
  }
  onSubmit() {
      this.showLoadingView();
      this.alunoFilhacaoService.create(this.alunoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessage('Sucesso', 'Filhação criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: { message: any; }) => {
        console.log(error.message);
        this.showMessage('Erro', 'Ocorreu um erro ao criar filhação Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.alunoservice.update(this.alunoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Funcionários atualisar com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar aluno, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
