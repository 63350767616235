<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">{{title}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<!-- <hr> -->

<form [formGroup]="TipoDocForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">


           <!--  <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Codigo</mat-label>
                    <input matInput formControlName="IdTipoPagamento" maxlength="50" />
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="TipoDocForm.get('IdTipoPagamento')?.hasError('required')"> Código é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div> -->
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Descrição</mat-label>
                    <input matInput formControlName="Descricao" maxlength="50" />
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="TipoDocForm.get('Descricao')?.hasError('required')"> Descrição é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>
           
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Cabeçario</mat-label>
                    <textarea matInput placeholder="Cabecario" formControlName="Cabecario" cdkTextareaAutosize
                      cdkAutosizeMinRows="3"></textarea>
                      <mat-error *ngIf="TipoDocForm.get('Cabecario')?.hasError('required')"> Cabeçario é obrigatório.
                    </mat-error>
                  </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Rodape</mat-label>
                    <textarea matInput placeholder="Rodape" formControlName="Rodape" cdkTextareaAutosize
                      cdkAutosizeMinRows="3"></textarea>
                      <mat-error *ngIf="TipoDocForm.get('Rodape')?.hasError('required')"> Rodape é obrigatório.
                    </mat-error>
                  </mat-form-field>
            </div>
            <!-- <mat-form-field>
                <textarea matInput placeholder="Your Message" formControlName="message" cdkTextareaAutosize
                  cdkAutosizeMinRows="3"></textarea>
              </mat-form-field> -->

        </div>
        <!--  {{ TipoDocForm.value | json }}  -->
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!TipoDocForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success"
            [disabled]="!TipoDocForm.valid ">Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success"
            [disabled]="!TipoDocForm.valid">Atualizar</button>
    </div>
</form>



