import {  Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, TipoPagamentosService } from '@service/index';
import {BasePage} from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tipo-pagamento-form',
  templateUrl: './tipo-pagamento-form.component.html',
  styleUrls: ['./tipo-pagamento-form.component.scss']
})
export class TipoPagamentoFormComponent  extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
    private tipoPagamentoService: TipoPagamentosService,
    private utilservice: UtilsService) { super(injector);  }

     TipoPagamentoForm   =  this._formBuilder.group({
      IdTipoPagamento: ['', Validators.required],
      TipoPagamento1: ['', Validators.required]
    });

    @Input() public data: any;
    @Input() public title: string = 'Registrar';
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {
       if (this.isReadOnly) {
        this.loadById();
      }
  }

  loadById() {
    this.TipoPagamentoForm.get('IdTipoPagamento')?.setValue(this.data.IdTipoPagamento);
    this.TipoPagamentoForm.get('TipoPagamento1')?.setValue(this.data.TipoPagamento1);
  }

  onSubmit() {
      this.showLoadingView();

      this.tipoPagamentoService.create(this.TipoPagamentoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'TipoPagamento Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar TipoPagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
         this.showLoadingView();
      this.tipoPagamentoService.update(this.TipoPagamentoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'TipoPagamento atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizado TipoPagamento, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
