import { Injectable } from '@angular/core';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root'
})
export class ParamService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Parametros', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/Parametros', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Parametros', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Parametros/${id}`);
  }
  delete(id: any) {
    return this.api.apagar('/Parametros' , id);
  }
}

