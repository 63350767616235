import { Injectable } from '@angular/core';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root'
})
export class DadosPessoaisService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/DadosPessoais', params);
    // return this.api.get('/employeeCards.json', params);
  }

  filtro(params: any){
    return this.api.get('/DadosPessoais/Filtro', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/DadosPessoais', formContent);
  }

  update(id:any, formContent: any){
    return this.api.update('/DadosPessoais', id, formContent);
  }

  atualizar(formContent: any){
    return this.api.actualizar('/DadosPessoais',  formContent);
  }

  loadById(id: any) {
    return this.api.get(`/DadosPessoais/${id}`);
  }
}

