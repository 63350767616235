import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReportServerService } from './report-server.service';
//import {RequestOptions} from '@angular/http';

@Injectable({
  providedIn: 'root',
})
export class MensalidadeService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private reportserver: ReportServerService
  ) {}

  public cartItemList: any = [];
  public productList = new BehaviorSubject<any>([]);
  public search = new BehaviorSubject<string>('');

  loadAll(params: any) {
    return this.api.get('/Mensalidade', params);
    // return this.api.get('/employeeCards.json', params);
  }

  recibo(params: any) {
    return this.api.visualizarRecibo('/Reports/Recibo?recibo=' + params);
    // return this.api.get('/employeeCards.json', params); /recibo?=
  }

  /*  getTestPdfFile(): Observable<any> {
    let url = 'API_URL'
    return this.http.get(url)
                    .map((res: { blob: () => any; }) => res.blob())
                   // .catch(this.handleError)
} */

  downloadFile(param: any): Observable<Blob> {
    //  let url = this.url + '/' + companyGuid + '/Documents/' + orderDocumentGUID;
    let opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
        responseType: 'Blob',
      }),
      // options: new RequestOptions({responseType: ResponseContentType.Blob })
    };
    //  return this.http.get(url, opt);
    //return this.http.get(url, {responseType: "blob"});
    return this.http.get('http://recibo.local/Reports/Recibo?recibo=' + param, {
      responseType: 'blob',
    });
  }

  /* downloadNoteReceipt(notes_purchased_id: number):Observable<Blob>{
  return this.http.get(this.baseUrl + `receipt/notespurchasedreceipt/` + notes_purchased_id, { responseType: "blob" } );
} */

  create(formContent: any) {
    return this.api.post('/Mensalidade', formContent);
  }
  criarMulta(formContent: any) {
    return this.api.post('/Mensalidade/criaMulta', formContent);
  }
  pagamento(formContent: any) {
    return this.api.post('/Mensalidade/Pagamentos', formContent);
  }

  update(id: any, formContent: any) {
    return this.api.update('/Mensalidade', id, formContent);
  }

  actualizar(formContent: any) {
    return this.api.actualizar('/Mensalidade', formContent);
  }

  ApagarPagamento(IdMensalidade: number, Doc: string, Tipo: number) {
    return this.api.get(
      `/Mensalidade/ApagarAnulaPagamento?IdMensalidade=${IdMensalidade}&Doc=${Doc}&Tipo=${Tipo}`
    );
  }
  loadById(id: any) {
    return this.api.get(`/Mensalidade/${id}`);
  }

  loadByIdMensalidade(id: any) {
    return this.api.get(`/Mensalidade/View/${id}`);
  }
  checkdividaDoAnoAnterior(id: any) {
    return this.api.get(`/VerificaDivida/AnoAnterior/${id}`);
  }

  checkdividaDoMesAnterior(id: any) {
    return this.api.get(`/VerificaDivida/MesAnterior/${id}`);
  }

  loadByIdMatricula(id: any) {
    return this.api.get(`/Mensalidade/Matricula/${id}`);
  }

  getInvoceId(TipoDoc: any, Serie: any) {
    return this.api.get(
      `/Mensalidade/InvoiceId?TipoDoc=${TipoDoc}&Serie=${Serie}`
    );
  }

  /* ?TipoDoc=FR&Serie=2022" */
  getProducts() {
    return this.productList.asObservable();
  }

  setProduct(product: any) {
    this.cartItemList.push(...product);
    this.productList.next(product);
  }
  addtoCart(product: any) {
    this.loadByIdMensalidade(product.IdMensalidade).subscribe((res) => {
      this.cartItemList.push(res);
      console.log(res);
      this.productList.next(this.cartItemList);
      this.getTotalPrice();
      console.log(this.cartItemList);
      /*  console.log(this.getTotalPrice());
    console.log(JSON.stringify(this.cartItemList)); */
    });
  }
  getTotalPrice(): number {
    let grandTotal = 0;
    this.cartItemList.map((a: any) => {
      //   console.log(a.Multa);

      grandTotal += a.ValorApagar + a.Multa;
    });
    return grandTotal;
  }
  getTotalMulta(): number {
    let Multa = 0;
    this.cartItemList.map((a: any) => {
      //   console.log(a.Multa);

      Multa += a.Multa;
    });
    return Multa;
  }
  removeCartItem(product: any) {
    this.cartItemList.map((a: any, index: any) => {
      if (product.IdMensalidade === a.IdMensalidade) {
        this.cartItemList.splice(index, 1);
      }
    });
    this.productList.next(this.cartItemList);
  }
  removeAllCart() {
    this.cartItemList = [];
    this.productList.next(this.cartItemList);
  }
}

/* for (var i = 0; i < cart.length; i++) {
			let item = JSON.parse(cart[i]);
			this.items.push({
				product: item.product,
				quantity: item.quantity
			});
			this.total += item.product.price * item.quantity;
		} */
