import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TipodocService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/TipoDoc', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/TipoDoc', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/TipoDoc', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/TipoDoc/${id}`);
  }
  delete(id: any) {
    return this.api.apagar('/TipoDoc' , id);
  }
}


