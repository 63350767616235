<div class="modal-body">
    <div *ngIf="pdfSource != undefined">
        <ngx-extended-pdf-viewer
        [src]="pdfSource" 
        [showPrintButton]="false"
        [showBookmarkButton]="false"
        [showOpenFileButton]="false" 
        [showSidebarButton]="true"
        >
        </ngx-extended-pdf-viewer>
    </div>

    <!-- <pdf-viewer [src]="pdfSource"
    [render-text]="true"
    [original-size]="false"
    
></pdf-viewer> -->
    
</div>

