<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Registrar Disciplina</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>


<!-- <hr> -->

<form [formGroup]="DisciplinaForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">

            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Disciplina</mat-label>
                  <input matInput formControlName="Disciplina1" maxlength="50" />
                  <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                  <mat-error *ngIf="DisciplinaForm.get('Disciplina1')?.hasError('required')"> Disciplina é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Sigla</mat-label>
                    <input matInput formControlName="Sigla" maxlength="20" />
                    <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                    <mat-error *ngIf="DisciplinaForm.get('Sigla')?.hasError('required')"> Sigla é obrigatório. </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Carga</mat-label>
                    <input matInput formControlName="Carga" />
                    <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                    <mat-error *ngIf="DisciplinaForm.get('Carga')?.hasError('required')"> Carga é obrigatório. </mat-error>
                </mat-form-field>
              </div>
           

            </div>
        <!--  {{ DisciplinaForm.value | json }}  -->
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!DisciplinaForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!DisciplinaForm.valid ">Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!DisciplinaForm.valid">Atualizar</button>
    </div>
</form>

