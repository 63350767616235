import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService, UsersService } from '@core/services';
//import { ConfirmedValidator } from '@shared/matchpassword.validator';
import { ConfirmedValidator } from '@shared/matchpassword.validator';
import { BasePage } from '@shared/base-page';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomvalidationService } from '@shared/customvalidation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-altera-senha',
  templateUrl: './altera-senha.component.html',
  styleUrls: ['./altera-senha.component.scss']
})
export class AlteraSenhaComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector,private service: AuthService, public activeModal: NgbActiveModal,
    private utilizadorService: UsersService, private customValidator: CustomvalidationService,
    private _formBuilder: FormBuilder,) {super(injector) }

  UtilizadorForm  =  this._formBuilder.group({
    Senha: ['', Validators.required],
    ConfirmarSenha: ['', Validators.required]}
  ,{
//  validator: this.MustMatch('Senha', 'ConfirmarSenha')
validator: this.customValidator.MatchPassword('Senha', 'ConfirmarSenha'),
});


/*  { validator: this.checkPasswords } */
  user: any ={
    IdUtilizador: null,
    Utilizador:null,
    Perfil: null,
    Senha: null,
    ContaDesativada: false

  }

onSubmit(){

  this.user.Senha = this.UtilizadorForm.get('Senha')?.value
  this.showLoadingView();
  this.utilizadorService.actualizar(this.user).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
    this.showMessage('Sucesso', 'Senha atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
  }, (error: any) => {
    this.showMessage('Erro', 'Ocorreu um erro ao atualizar Senha, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
  });
}
  ngOnInit(): void {

      this.service.getCurrentUserData().pipe(takeUntil(this.destroySubject)).subscribe((m:any) => {
      this.user.IdUtilizador = m.PerfilNavigation.Utilizadores[0].IdUtilizador
      this.user.Utilizador = m.PerfilNavigation.Utilizadores[0].Utilizador
      this.user.Perfil = m.PerfilNavigation.Utilizadores[0].Perfil
      this.user.ContaDesativada = m.PerfilNavigation.Utilizadores[0].ContaDesativada
    }
      );
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
      return null;
  }

  const password = control.parent.get('Senha');
  const passwordConfirm = control.parent.get('ConfirmarSenha');

  if (!password || !passwordConfirm) {
      return null;
  }

  if (passwordConfirm.value === '') {
      return null;
  }

  if (password.value === passwordConfirm.value) {
      return null;
  }

  if (password.value !== passwordConfirm.value) {
    return { passwordsNotMatching: true };
}

  return { passwordsNotMatching: true };

};
