import { Component, Input, OnInit, Injector } from '@angular/core';
import { BasePage } from '@shared/base-page';
import {MatSelectModule} from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, Validators,  AbstractControl,
  FormGroupDirective,
  ValidatorFn,
  ValidationErrors, } from '@angular/forms';
//import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal,  NgbDateStruct, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
//import * as _moment from 'moment';
//import { Moment } from 'moment';
import {AlunoService, UtilsService, AlunoContactoService, AlunoDocumentoService, AlunoFilhacaoService, ProvinciaService,DadosPessoaisService} from '@service/index';
import {Sexo, Bairro} from '@core/models/index';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env';

import { formatDate } from '@angular/common';
import { AlunoFilhacaoFormComponent } from '../../aluno-filhacao-form/aluno-filhacao-form.component';
import { ContactoAlunoComponent } from '../../contacto-aluno/contacto-aluno.component';
import { DocumentoAlunoComponent } from '../../documento-aluno/documento-aluno.component';
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';




@Component({
  selector: 'app-funcionario-dados-pessoais',
  templateUrl: './funcionario-dados-pessoais.component.html',
  styleUrls: ['./funcionario-dados-pessoais.component.scss']
})
export class FuncionarioDadosPessoaisComponent  extends BasePage implements OnInit {
  [x: string]: any;
@Input() Func : any;
receivedChildMessage: any;
private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder,private alunoFilhacaoService: AlunoFilhacaoService,
              private modalService: NgbModal, private documentoservice: AlunoDocumentoService,
              private alunoservice: AlunoService, private contactoservice: AlunoContactoService,private funcService: DadosPessoaisService,
              private provinciaService: ProvinciaService,private shared: SharedService,private currentRoute: ActivatedRoute,
              private utilservice: UtilsService) { super(injector);  this.idFunc = this.currentRoute.snapshot.paramMap.get('id'); }


  Form  =  this._formBuilder.group({
    IdFuncionario: [0],
    Nome: ['', Validators.required],
    DataNascimento: ['', Validators.required],
    Sexo: ['', Validators.required],
    Naturalidade: ['', Validators.required],
    IdProvincia: ['', Validators.required],
    IdMunicipio: ['', Validators.required],
    Pai: ['', Validators.required],
    Mae: ['', Validators.required],

  });

  image1:any;
  imageUrl =  './assets/img/perfil.png'; // '/assets/img/Foto.jpg';
  public file: File;
  bairros: any[] = [];
  provincias: any[] = [];
  municipios: any[] = [];
 // sexo: any[] = [];
  pais: any[] = [];
  filhacao: any[] = [];
  filhacao2: any[] = [];
  alunoDocumento: any[] = [];
  AlunoContactos: any[] = [];
  fileToUpload: File | any = null;
  files: File[] = [];
  closeResult = '';
  sexo: Observable<Sexo[]>;
  Bairro2: Observable<Bairro[]>;
  selected = 'option2';
  @Input() public aluno: any;
  @Input() public isReadOnly: boolean = false;
  @Input() public titulo: any  = "Registar Aluno";
  idFunc;
dados: any;
  //"strictPropertyInitialization": false
  ngOnInit(): void {

    console.log(this.Func);

    this.sexo = this.utilservice.BuscaSexo();
    this.Bairro2 = this.utilservice.BuscaBairro();
    this.idFunc = this.currentRoute.snapshot.paramMap.get('id');
    this.loadData();

    console.log(this.currentRoute.snapshot.paramMap.get('id'));
    this.Form.get('IdProvincia')?.valueChanges.subscribe(res => {
      console.log(res);

      let mun = this.provincias.filter((option: any) => option.IdProvincia == res);
      this.municipios  = mun[0].Municipios;
    })

  /*   if (this.dados !== null) {
      this.loadById();
    } */

  }

  getMessage(message: any) {
    console.log(message);

    this.receivedChildMessage = message;
}

  loadById() {
    console.log(this.dados);

    const date = moment(this.dados.DataNascimento).format('DD/MM/yyyy');
    const ngbDate: NgbDateStruct = {
      year: parseInt(date.split('/')[2]),
      month: parseInt(date.split('/')[1]),
      day: parseInt(date.split('/')[0]),
    }
  //  this.AlunoContactos = this.aluno.AlunoContactos;
  //  this.alunoDocumento = this.aluno.Alunodocumentos;
    this.Form.get('IdFuncionario')?.setValue(this.dados.IdFuncionario);
    this.Form.get('Nome')?.setValue(this.dados.Nome);

//this.Form.get('DataNascimento')?.setValue(ngbDate);
    this.Form.get('DataNascimento')?.setValue(this.dados.DataNascimento );
    this.Form.get('Pai')?.setValue(this.dados.Pai);
    this.Form.get('Naturalidade')?.setValue(this.dados.Naturalidade);
    this.Form.get('Mae')?.setValue(this.dados.Mae);
    this.Form.get('Sexo')?.setValue(this.dados.Sexo);

    this.Form.get('IdProvincia')?.setValue(this.dados.ListadeMunicipios.ListadeProvincias.IdProvincia);
    let mun = this.provincias.filter((option: any) => option.IdProvincia == this.dados.ListadeMunicipios.ListadeProvincias.IdProvincia);
    console.log(mun);
    this.Form.get('IdMunicipio')?.setValue(this.dados.IdMunicipio);

  }

  onKey(value: string) {
    this.filhacao = this.search2(value);
  }

  search2(value: string) {
    let filter = value.toLowerCase();
     return this.filhacao2.filter((option: any) =>  option.Pai.toLowerCase().includes(filter)  ||  option.Mae.toLowerCase().includes(filter)
    );}

  onAddDocumento(documento: any) {
    const modalref =  this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
       this.loadDoc();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.loadDoc();
    });
  }

  onEditDocumento(documento: any){
    const modalref = this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadDoc();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.loadDoc();
    });
  }

  onAddContacto(contacto: any) {
    console.log(contacto);
    const modalref =  this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = contacto;
    modalref.result.then((result) => {
      this.loadContacto();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  addFilhacao() {
    const modalref =  this.modalService.open(AlunoFilhacaoFormComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.isReadOnly = false;
    modalref.result.then((result) => {
      this.loadData();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadData();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEditContacto(employee: any){
    const modalref = this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = employee;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadContacto();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  loadContacto() {
      this.contactoservice.loadByIdAluno(this.dados.IdFuncionario).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.AlunoContactos = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  loadDoc() {
      this.documentoservice.loadByIdAluno(this.dados.IdFuncionario).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.alunoDocumento = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  onFileChange(event:any) {
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileToUpload = this.files[0];
      reader.readAsDataURL(this.files[0]);

      reader.onload = () => {

        this.imageUrl = reader.result as string;
         this.Form.patchValue({
          pathPictur: this.fileToUpload.name
        });
      };

    }
  }


  onSubmit() {

      this.showLoadingView();

      this.Form.get('DataNascimento')?.setValue(new Date(this.Form.get('DataNascimento')?.value).toUTCString());

      console.log('Form Value to submmit', this.Form.value);
      this.funcService.create(this.Form.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Funcionário criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log(error.message);

        this.showMessage('Erro', 'Ocorreu um erro ao criar Funcionário Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

  }

  onUpdate() {

      this.showLoadingView();
      this.Form.get('DataNascimento')?.setValue(new Date(this.Form.get('DataNascimento')?.value).toUTCString());
      this.funcService.atualizar(this.Form.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Funcionário atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar Funcionário, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });

  }

  submitFileAndUpdateEmployee() {
    this.showLoadingView();
    this.utilservice.fileUpload(this.fileToUpload).pipe(takeUntil(this.destroySubject)).subscribe(response => {
      console.log(response);
       const fs = response as string;
       fs.replace('"',"");
      this.Form.get('pathPictur')?.setValue(fs);
      this.Form.get('DataNascimento')?.setValue(formatDate(this.Form.get('DataNascimento')?.value,'yyy-MM-dd','en-Us'));
      this.alunoservice.update(this.Form.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Aluno atualizdo com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualisar Aluno, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
    }, error => {
      console.log(error);
      this.showMessage('Erro', 'Ocorreu um erro ao enviar ficheiro para o servidor, Tente novamente', 'error');
    });
  }

  submitFileAndCreteEmployee() {
    this.showLoadingView();
    this.utilservice.fileUpload(this.fileToUpload).pipe(takeUntil(this.destroySubject)).subscribe(response => {
    this.Form.get('pathPictur')?.setValue(response);
    this.Form.get('DataNascimento')?.setValue(new Date(this.Form.get('DataNascimento')?.value).toUTCString());
    this.alunoservice.create(this.Form.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Aluno Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Aluno Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
    }, error => {
      this.showMessage('Erro', 'Ocorreu um erro ao enviar ficheiro para o servidor, Tente novamente', 'error');
    });

  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

  compareFunctionArea(o1: any, o2: any) {
    return (o1.IdArea == o2);
  }

  compareFunctionFunc(o1: any, o2: any) {
    return o1.IdFuncao == o2;
  }

    // begin Implementation of ng-dropZone
    onSelect(event: any) {
      this.files.push(...event.addedFiles);
    }

    onRemove(event: any) {
      this.files.splice(this.files.indexOf(event), 1);
    }
    // End of Dropzone

    loadData() {
      this.utilservice.loadBairros({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.bairros = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });


      this.provinciaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.provincias = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });

      this.funcService.loadById(this.idFunc).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.dados = response;
        this.loadById();
        this. isReadOnly= true;
      }, (error: any) => {
        console.log('Error on request', error);
      });

      this.alunoFilhacaoService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
       this.filhacao = response;
       this.filhacao2 = response;

      }
      , (error: any) => {
        console.log('Error on request', error);
      });

      this.utilservice.loadPais({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.pais = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });
    }

    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

}
