export const CONSTS = {
  USER_PERMITION_LABEL: 'USER_PERMITION',
  USER_TOKEN: 'HASH_TOKEN',
  USER_NAME: 'USER_NAME',
  PRAZO: 'PRAZO',
  CONTADOR: 'CONTADOR',
}

export enum MESSAGE_TYPE {
  INFO,
  ERROR,
  WARNING
}


export const NiveisDeAcesso: any = {
  /**
   * O nivel admin pode ver ver e acessar tudo sem execepção
   */
  ADMINISTRADOR : "ADMINISTRADOR",
  /**
   * O solicitante apenas ve e acessa os botoões de edição e solicitação e Impressão
   */
  SOLICITANTE : "SOLICITANTE",
  /**
   * Apenas pode validar, e visualizar os dados dos funcionários
   */
  VALIDANTE : "VALIDANTE"
}



export const ADMIN_VERSION_NUMBER = '1.0.0';
