
  <div class="col-md-12">
    <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Lista de Tipo de documentos</h4>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a>você esta em: Tipo de documentos</a></li>
      <li class="breadcrumb-item active">Lista de Tipo de documentos</li>
    </ol>
  </div>
  <br>
  <div class="row">
  <div class="col-md-4 text-right">
    <div>
      <input class="form-control ml-2 my_rounded_corners" placeholder="Pesquisar..." type="text"
        [formControl]="filter" />
    </div>
  </div>
  <div class="col-md-8 text-right" style="padding-right: 10px;">
    <div>
      <button mat-raised-button class="btn btn-success" (click)="onAdd()"> Novo</button>
    </div>
  </div>
</div>

<div>
  <table class="content-table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Descrição</th>
        <th scope="col">Cabeçario</th>
        <th scope="col">RodaPe</th>
        <th scope="col">Opções</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let D of tipoDoc | slice: (page-1) * pageSize : page * pageSize">
        <td scope="row">{{D.Codigo}}</td>
        <td> {{ D.Descricao}} </td>
        <td> {{ D.Cabecario}} </td>
        <td> {{ D.Rodape}} </td>


        <td>
          <!-- *ngIf=" permitions.VALIDANTE | authcheck " -->

          <button type="button" name="" id="" title="Editar" class="btn
                            btn-warning btn-sm mr-2" (click)="onEdit(D)"> <i class="fas fa-edit"></i></button>
          <button type="button" name="" (click)="onDelete(D.Codigo)" id="" title="Eliminar" class="btn btn-dark btn-sm">
            <i class="fas fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">

    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="15" [collectionSize]="tipoDoc.length"></ngb-pagination>

    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadData()">
      <option [ngValue]="5">5 items por pagina</option>
      <option [ngValue]="10">10 items por pagina</option>
      <option [ngValue]="20">20 items por pagina</option>
    </select>
  </div>
</div>
