import { Component, EventEmitter, Injector, OnInit, Output, PipeTransform } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import {AlunoFormComponent, MatriculaFormComponent} from '@component/index';
import { BasePage } from 'app/shared/base-page';
import { FormControl } from '@angular/forms';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { environment } from  '@env';
import { Route, Router } from '@angular/router';
import { Publico } from '@shared/publico';
import { Subject } from 'rxjs';

import { DadosPessoaisService } from '@service/index';
import { DocumentosSecretaria } from '@core/models/index';
import { FuncionarioFormComponent } from '../funcionario-form/funcionario-form.component';
import { SharedService } from '@shared/shared.service';



@Component({
  selector: 'app-funcionario-list',
  templateUrl: './funcionario-list.component.html',
  styleUrls: ['./funcionario-list.component.scss']
})
export class FuncionarioListComponent extends BasePage implements OnInit {
  [x: string]: any;
  @Output() messageToEmit = new EventEmitter<any>();
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector,private dadospessoaisService: DadosPessoaisService,
    private modalService: NgbModal, private route: Router, private shared: SharedService,
    private publico: Publico,  public activeModal: NgbActiveModal,
    public pipe: DecimalPipe) {super(injector);}

dados : DocumentosSecretaria[] = [];
dadosLocal  : DocumentosSecretaria[] = [];
public page = 1;
public pageSize = 5;
config: any;
collectionSize = 0;
closeResult = '';
termoBuscaChanged: Subject<string> = new Subject<string>();
filter = new FormControl('');

  ngOnInit(): void {

    this.config = {
      itemsPerPage: 5,
      filter: "",
      page: 1,
    }
    this.publico.verificaLincenca();
    this.loadData();

  }

  sendData(message: any){
    this.shared.changeMessage(message);
    this.shared.setMessage(message);
    this.route.navigate(['/funcionarioCadastro']);
  }

  pageChanged(event: any) {
    this.config.page = event;
    this.loadData();
   }

  loadData() {
  this.showLoadingView();
  if (this.config.filter == null){
    this.config.filter = ""
  }
    this.dadospessoaisService.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.dadosLocal = response.data;
        this.dados = response.data;
         this.config = {
         currentPage: response.currentPage,
         page: 1,
         filter: this.config.filter,
         totalItems: response.total
       }
       this.collectionSize = response.total;

        this.showContentView();
      }, error => { console.log('Error a efetuar requisicao', error);});
  }

  sendMessageToParent(message: any) {
    this.messageToEmit.emit(message)
}

  onDelete(id: any) {
    console.log(id);

   /*  this.showLoadingView();
    this.dadospessoaisService.delete(id).subscribe((response: any) => {
      this.showMessageWithConfirm('Sucesso', 'Documento excluido com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
    }, (error: any) => {
      this.showMessage('Erro', 'Ocorreu um erro ao excluir documento, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
    }); */
}

  applyFilter(filterValue: string) {

    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
          this.config.filter = filtrarPor;
          this.loadData();
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onAddAluno() {
    this.shared.changeMessage(null);
    this.route.navigate(['/funcionarioCadastro']);
  }

  onEditEmployee(employee: any){
    const modalref = this.modalService.open(FuncionarioFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalref.componentInstance.aluno = employee;
    modalref.componentInstance.isReadOnly = true;
    modalref.componentInstance.titulo = "Editar Aluno";
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadData();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadData();
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
