import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClasseDisciplinaService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/ClasseDisciplina', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/ClasseDisciplina', formContent);
  }

  addClasseDisciplinas(formContent: any){
    return this.api.post('/ClasseDisciplina/addClasseDisciplinas', formContent);
  }
  addClasseDisciplinasV2(formContent: any){
    return this.api.post('/ClasseDisciplina/addClasseDisciplinasV2', formContent);
  }
  update(formContent: any){
    return this.api.actualizar('/ClasseDisciplina', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/ClasseDisciplina/${id}`);
  }
  delete(id: any) {
    return this.api.apagar(`/ClasseDisciplina`, id);
  }
  loadByIdClasse(id: any) {
    return this.api.get(`/ClasseDisciplina/ViewClasseDisciplina/${id}`);
  }

  loadClasseDisciplinaByIdTurma(id: any) {
    return this.api.get(`/ClasseDisciplina/ViewClasseDisciplinabyIdTurma/${id}`);
  }
}
