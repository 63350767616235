import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DisciplinaRoutingModule } from '../components/disciplina/disciplina-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CabecarioComponent } from './cabecario/cabecario.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [CabecarioComponent],
  imports: [
    CommonModule,
    MatIconModule,
    DisciplinaRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialModule
  ],

  exports:[CabecarioComponent,
    CommonModule,
    RouterModule,
    MatIconModule,
    MaterialModule,
          ]
})
export class SharedModule { }

