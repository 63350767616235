
    <div class="row">
      <div class="col-md-10">
          <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Lista de Contratos</h4>
<!--           <ol class="breadcrumb">
              <li class="breadcrumb-item"><a >você esta em: Matriculas</a></li>
              <li class="breadcrumb-item active">Lista de Matriculas</li>
            </ol> -->
            <breadcrumb #parent>

              <ol class="breadcrumb">
                <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                  <li *ngIf="!route.terminal" class="breadcrumb-item">
                    <a href="" [routerLink]="[route.url]"> você esta em: {{ route.displayName }}</a>
                  </li>
                  <li *ngIf="route.terminal" class="breadcrumb-item active"> você esta em: {{ route.displayName }}</li>
                </ng-template>
              </ol>
            </breadcrumb>
      </div>

      <div class="col-md-2 text-right" style="padding-right: 10px;">
           <button mat-raised-button class="btn btn-success" [routerLink]="'/aluno'" style="margin:5px;"> Voltar</button>
      </div>
  </div>


  <div class="col-md-12 ">
    <div class="box-flex  col-md-12  ">

      <form novalidate [formGroup]="form">
        <div class="row">

            <div class="col-md-2">
              <mat-form-field appearance="outline" >
                  <mat-label>Código</mat-label>
                  <input matInput formControlName="IdMatricula"  />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" >
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="Nome" placeholder="Nome"  />
              </mat-form-field>
            </div>

              <div class="col-md-3">
                <mat-form-field appearance="outline" >
                    <mat-label>Apartamento</mat-label>
                    <mat-select  name="IdTurma"  formControlName="IdTurma">
                    <mat-option *ngFor="let s of Turmas" [value]="s.IdTurma">
                     <b>  {{s.Turma1}} </b>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>

  <!--                    <div class="col-md-2 col-sm-2">
                <select ngDefaultControl class="form-control form-control-lg" formControlName="Anolectivo">
                  <option Value=""> Ano Lectivo</option>
                  <option *ngFor="let level of anoLetivo | async" [ngValue]="level.Codigo" >{{level.Descricao}}</option>
                </select>
              </div> -->
              <div class="col-md-2">
                <mat-form-field appearance="outline" >
                    <mat-label>Ano exercicio</mat-label>
                    <mat-select  name="Anolectivo"  formControlName="Anolectivo">
                    <mat-option *ngFor="let s of anoLetivo | async" [value]="s.Codigo">
                     <b>  {{s.Descricao}} </b>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>

              <!-- search62   searchForm(form.value)-->
              <div class="col-md-2 ">
                <button class="btn btn-primary float-right" (click)="filtro()">Pesquisar</button>
              </div>
        </div>
      </form>

    </div>
    </div>


<div class="contaniner-fluild" >








<!--     <mat-accordion class="example-headers-align" multi>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="my-panel">
              <h3>Filtro</h3>

            </div>
          </mat-expansion-panel-header>



        </mat-expansion-panel>
      </mat-accordion> -->

        <table class="content-table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Ano exercicio</th>
                    <th scope="col">Apartamento</th>
                    <th scope="col">Edíficio</th>
                    <th scope="col">Opções</th>
                </tr>
            </thead>
            <tbody>
              <!--  <tr *ngFor="let alu of matriculas | slice: (page-1) * pageSize : page * pageSize"> -->
                <tr *ngFor="let alu of matriculas ">
<!--                     <th scope="row">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox"  [checked]="getIndexOf(employee) > -1"  (change)="onChecked(employee, $event)">
                        </label>
                        </div>
                    </th> -->
                    <td scope="row">{{alu.IdMatricula}}</td>
                    <td> {{ alu.Aluno.Nome}} </td>
                    <td> {{alu.Anolectivo }}</td>
                    <td>{{alu.Turma.Turma1}}</td>
                    <td>{{alu.Turma.Classe.Classe}}</td>


                    <td>
                        <!-- *ngIf=" permitions.VALIDANTE | authcheck "   (click)="payment(alu)"   -->
                        <button type="button" name="" id="" data-toggle="tooltip" data-placement="top" title="Novo contrato" class="btn btn-outline-success btn-sm mr-2" (click)="onAddMatricula(alu.Aluno)" > <i class="fas fa-id-card"></i></button>
                        <button type="button" name="" id="" class="btn
                            btn-outline-primary btn-sm mr-2" [routerLink]="'/home/' + alu.IdMatricula" title="Pagamentos"   > <i class="fas fa-money-check-alt"></i></button>
                           <!--  <button type="button" name="" id="" class="btn
                            btn-outline-danger btn-sm mr-2"  (click)="onAddService(alu.IdMatricula)" title="Serviços"   > <i class="fa fa-wrench"></i></button>
 -->
                            <button type="button" name="" id="" class="btn
                            btn-outline-info btn-sm mr-2"  (click)="extrato(alu.IdMatricula)" title="Extrato"> <i class="fa fa-tasks"></i></button>

                            <button type="button" name="" id="" class="btn
                            btn-outline-warning btn-sm mr-2" (click)="onEditMatricula(alu)" title="Editar contrato" > <i class="fas fa-edit"></i></button>
                        <button type="button" name="" id="" class="btn btn-outline-dark
                            btn-sm" title="Apagar Matricula" (click)="onDelete(alu.IdMatricula)" class="btn btn-danger btn-sm"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">

   <ngb-pagination
  [(page)]="page"
  [maxSize]="15"
  [pageSize]="pageSize"
  [boundaryLinks]="true" [directionLinks]="true" [ellipses]="true"
  [collectionSize]="collectionSize" (pageChange)="pageChanged($event)"  ></ngb-pagination>

            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="filtro()">
                <option [ngValue]="5">5 items por pagina</option>
                <option [ngValue]="10">10 items por pagina</option>
                <option [ngValue]="20">20 items por pagina</option>
            </select>
        </div>
    </div>



