import { Disciplina } from './../../../core/models/model';

import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Subject } from 'rxjs';
import { ClasseDisciplinaService, ProfessorService, TurmaService, UtilsService, TrimestreService, NotasService, } from '@core/services/index';
import { BasePage } from '@shared/base-page';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cadastro-nota-form',
  templateUrl: './cadastro-nota-form.component.html',
  styleUrls: ['./cadastro-nota-form.component.scss'],
})
export class CadastroNotaFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  @Input() idFuncionario: any;
  constructor(
    injector: Injector, private fb: FormBuilder,
    private turmaservice: TurmaService,
    private notasService: NotasService,
    private _formBuilder: FormBuilder,
    private trimestreService: TrimestreService,
    private classedisciplinaService: ClasseDisciplinaService,
    private professorService: ProfessorService,
    private utilservice: UtilsService
  ) {
    super(injector);
  }
  private destroySubject: Subject<void> = new Subject();
  matriculaForm = this._formBuilder.group({
    IdFuncionario: [''],
    IdTurma: ['', Validators.required],
    IdDisciplina: ['', Validators.required],
    IdTrimestre: ['', Validators.required],
  });
  Turmas: any[] = [];
  localTurmas;
  disciplina: any;
  trimestre: any;
  notas: any;
  notaslocal : any;
  public page = 1;
  public pageSize = 5;
  public page2 = 1;
  public pageSize2 = 5;
  config: any;
  termoBuscaChanged: Subject<string> = new Subject<string>();
  collectionSize = 0;
  collectionSize2 = 0;
  dropdownSettings = {};
  vdisciplina2: { IdFuncionario: any; IdTurma: any; IdDisciplina: [] }[] = [];
  tableData = {};
  notaForm: any;
  isEditable: boolean = false;
  isVisible: boolean = false;
  bankAccountForms: FormArray = this.fb.array([]);

  ngOnInit(): void {
    this.carregaTrimestre();
    this.carregaDisciplinaDoProfessor(this.utilservice.getFunc());

    this.matriculaForm.get('IdTurma')?.valueChanges.subscribe((res) => {
      console.log(res);
      this.ProfessorListaDeDisciplinaLecionados(this.utilservice.getFunc(), res);
    });

    this.matriculaForm.get('IdTrimestre')?.valueChanges.subscribe((res) => {
        if (this.matriculaForm.get('IdTurma')?.value > 0 && this.matriculaForm.get('IdDisciplina')?.value > 0 ){
          this.carregaAlunos(this.matriculaForm.get('IdTurma')?.value,this.matriculaForm.get('IdDisciplina')?.value,res);
        }
    });

    this.matriculaForm.get('IdTurma')?.valueChanges.subscribe((res) => {
        if (this.matriculaForm.get('IdTrimestre')?.value > 0 && this.matriculaForm.get('IdDisciplina')?.value > 0 ){
          this.carregaAlunos(res ,this.matriculaForm.get('IdDisciplina')?.value,this.matriculaForm.get('IdTrimestre')?.value);
        }
    });

    this.matriculaForm.get('IdDisciplina')?.valueChanges.subscribe((res) => {
       if (this.matriculaForm.get('IdTrimestre')?.value > 0 && this.matriculaForm.get('IdTurma')?.value > 0 && this.matriculaForm.get('IdDisciplina')?.value > 0){
        this.carregaAlunos(this.matriculaForm.get('IdTurma')?.value,res ,this.matriculaForm.get('IdTrimestre')?.value);
      }
  });


    this.notaForm = new FormGroup({
      items: new FormArray([]),
    });
  }

  get getFormControls() {
    const control = this.notaForm.get('items') as FormArray;
    return control;
  }


  filtro(filterValue: string) {

    let filterValueLower = filterValue.toLowerCase();
      this.notas = this.notaslocal.filter((employee) => employee.Nome.toLowerCase().includes(filterValueLower))
      this.carregarDisciplinaClasse();
 }

  applyFilter(filterValue: string) {
    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
          console.log(filtrarPor);

          this.filtro(filtrarPor);
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }

  onEdit(item: any) {
   // debugger;
    this.notas.forEach(element => {
      console.log(element);

      element.isEditable = false;
    });
    item.isEditable = true;
  }

  editRow(group: FormGroup, index: number) {
    console.log(group);

    /* this.notas.forEach(element => {
      element.isEditable = false;
    });
 */
    group.get('isEditable')?.setValue(true);
    this.isEditable = true;
    const control2 =  this.notaForm.get('items') as FormArray;
    control2.controls[index].get('MAC')?.enable();
    control2.controls[index].get('NPP')?.enable();
    control2.controls[index].get('NPT')?.enable();

    control2.controls[index].get('J')?.enable();
    control2.controls[index].get('I')?.enable();
  if (this.matriculaForm.get('IdTrimestre')?.value == 3){
    control2.controls[index].get('Exame')?.enable();
    control2.controls[index].get('Rec')?.enable();
  }


  }

  doneRow(group: FormGroup, index: number) {

    console.log(group.value);

    this.showLoadingView();
    this.notasService.atualizaNotas(group.value).pipe(
     

      takeUntil(this.destroySubject))
    .subscribe((response: any) => {
      console.log(response);

        this.showMessage('Sucesso', 'Nota atualizado com sucesso', 'success').then( () => this.activeModal.close('Save click')
        );
        this.carregaAlunos(this.matriculaForm.get('IdTurma')?.value,this.matriculaForm.get('IdDisciplina')?.value,this.matriculaForm.get('IdTrimestre')?.value
        );
      },
      (error: any) => {this.showMessage('Erro','Ocorreu um erro ao atualizar nota Tente novamente','error' ).then(() => this.activeModal.close('Save click'));
      }
    );

    this.isEditable = false;
    group.get('isEditable')?.setValue(false);
    const control2 =  this.notaForm.get('items') as FormArray;
    control2.controls[index].get('MAC')?.disable();
    control2.controls[index].get('NPP')?.disable();
    control2.controls[index].get('NPT')?.disable();

    control2.controls[index].get('J')?.disable();
    control2.controls[index].get('I')?.disable();
    control2.controls[index].get('Exame')?.disable();
    control2.controls[index].get('Rec')?.disable();

  }

  carregarDisciplinaClasse() {


//debugger;
    if (this.notaForm.get('items').length > 0) {
      for (let x in this.notaForm.get('items')) {
        this.notaForm.get('items').removeAt(x);
      }
    }

    console.log(this.notas);
let valor;
    let dat = this.notas; // this.getmyData();
    if (dat.length > 0) {
      console.log(dat);


      for (let x in dat) {
  //      console.log((dat[x].NPT.length));
        this.notaForm.get('items').push(

          new FormGroup({
            IdAlunoNota: new FormControl(dat[x].IdAlunoNota, [ Validators.required,]),
            Nome: new FormControl(dat[x].Nome, [Validators.required]),
            MAC: new FormControl(dat[x].MAC == null? 0 : dat[x].MAC  , [Validators.required,Validators.min(0), Validators.max(dat[x].VALORPROVA)] ),
            NPP: new FormControl(dat[x].NPP == null? 0 : dat[x].NPP , [Validators.required, Validators.min(0),Validators.max(dat[x].VALORPROVA)]),
            NPT: new FormControl(dat[x].NPT == null ? 0 :dat[x].NPT , [Validators.required, Validators.min(0),Validators.max(dat[x].VALORPROVA)]),
            MT: new FormControl(dat[x].MT == null ? 0 : dat[x].MT ),
            VALORPROVA: new FormControl(dat[x].VALORPROVA),
            Trimestre :new FormControl(this.matriculaForm.get('IdTrimestre')?.value),

            J: new FormControl(dat[x].J == null ? 0 :dat[x].J),
            I: new FormControl(dat[x].I == null ? 0 :dat[x].I),
            Exame: new FormControl(dat[x].EXAME == null ? 0 :dat[x].EXAME , [Validators.max(dat[x].VALORPROVA)]),
            Rec: new FormControl(dat[x].Rec == null ? 0 :dat[x].Rec , [Validators.max(dat[x].VALORPROVA)]),


            isEditable: new FormControl(false, [Validators.required]),
          })

        );
        console.log(dat);
      }
    }
  }

  enableToggle(id: any){
    const control =  this.notaForm.get('items') as FormArray;
    control.controls[id].get('MAC')?.disable();
    control.controls[id].get('NPP')?.disable();
    control.controls[id].get('NPT')?.disable();

   // control.controls[id].get('MT')?.disable();
  }

  cancelar(group: FormGroup, index: number){
    this.isEditable = false;
    group.get('isEditable')?.setValue(false);
    this.carregaAlunos(this.matriculaForm.get('IdTurma')?.value,this.matriculaForm.get('IdDisciplina')?.value,this.matriculaForm.get('IdTrimestre')?.value);

    const control2 =  this.notaForm.get('items') as FormArray;
    control2.controls[index].get('MAC')?.disable();
    control2.controls[index].get('NPP')?.disable();
    control2.controls[index].get('NPT')?.disable();
  }

  toggleReadOnly(id: any) {
   // var fieldElement = <HTMLInputElement>document.getElementById(id);
   // fieldElement.readOnly = false;

    const control2 =  this.notaForm.get('items') as FormArray;
    control2.controls[id].get('MAC')?.enable();
    control2.controls[id].get('NPP')?.enable();
    control2.controls[id].get('NPT')?.enable();
   // control.controls[id].get('MT')?.enable();
   // control.controls[id].enable()
  }


  carregaAlunos(Turma: any, Disciplina: any, Trimestre: any) {
    // this.showLoadingView();
    this.notasService
      .ConsultaNotasTurma(Turma, Disciplina, Trimestre).pipe(takeUntil(this.destroySubject))
      .subscribe(
        (response: any) => {
          this.notas = response.ConsultaNotasTurma;
          this.notaslocal = response.ConsultaNotasTurma;

          console.log(response.ConsultaNotasTurma);

         /*  (response.ConsultaNotasTurma as []).forEach((nota: any) => {
            this.bankAccountForms.push(this.fb.group({
              IdAlunoNota: [nota.IdAlunoNota],
              nome: [nota.Nome, Validators.required],
              MAC: [nota.MAC, Validators.required],
              NPP: [nota.NPP,  [Validators.required, Validators.min(1)]],
              NPT: [nota.NPT,  [Validators.required, Validators.min(1)]],
              MT: [nota.MT],
              isEditable: [false, [Validators.required]]
            }));
          }); */
this.carregarDisciplinaClasse();
          console.log(response.ConsultaNotasTurma);
        },
        (error) => {
          console.log('Error a efetuar requisicao', error);
        }
      );
  }

  ProfessorListaDeDisciplinaLecionados(id: any, IdTurma) {
    // this.showLoadingView();
    this.professorService
      .ProfessorListaDeDisciplinaLecionados(id, IdTurma).pipe(takeUntil(this.destroySubject))
      .subscribe(
        (response: any) => {
          this.disciplina = response;
        },
        (error) => {
          console.log('Error a efetuar requisicao', error);
        }
      );
  }

  carregaTrimestre() {
    // this.showLoadingView();
    this.trimestreService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.trimestre = response;
        console.log(response);
      },
      (error) => {
        console.log('Error a efetuar requisicao', error);
      }
    );
  }

  carregaDisciplinaDoProfessor(id: any) {
    // this.showLoadingView();
    this.professorService.ProfessorListaDeTurmasLecionadas(id)
    .pipe(takeUntil(this.destroySubject))
    .subscribe(
      (response: any) => {
        //   this.disciplinaProf = response;
        this.Turmas = response;

        console.log(response);

        if (this.disciplinaProf) {
          this.collectionSize = this.disciplinaProf.length;
        }
        console.log('Disciplina carregado com sucesso', response);
        //       this.showContentView();
      },
      (error) => {
        console.log('Error a efetuar requisicao', error);
      }
    );
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
