<div class="card" style="width: 100%;">
  <div class="card-header">
      <span class="cart-span-title">Contactos</span>
      <button type="button" name="" id="5" (click)="onAddContacto(aluno)" title="Inserir Contacto" style="float:right" class="btn btn-primary btn-sm mr-2 " [disabled]="!isReadOnly"> <i class="fa fa-plus"></i></button>
    </div>
  <div class="card-body table-responsive custom-table">
      <table class="table table-hover table-inverse">
          <thead class="thead-inverse">
              <tr>
                  <th>Tipo Contacto</th>
                  <th>Nº do Telefone</th>
                  <th>Pessoa a Contactar</th>
                  <th>Opções</th>
              </tr>
          </thead>
          <!-- aluno.AlunoContactos -->
          <tbody>
              <tr *ngFor="let persDoc of AlunoContactos">
               <!--  {{persDoc.TipoContacto | json}} -->
                  <td scope="row">{{persDoc.TipoContacto.Contacto}}</td>
                  <td>{{persDoc.Numero}}</td>
                  <td>{{persDoc.Descricao}}</td>
                  <td>
                    <!-- (click)="onEditContacto(persDoc)" -->
                      <a name="" id="" class="btn btn-sm btn-success" role="button" (click)="onEditContacto(persDoc)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
<br>
<div class="card" style="width: 100%;">
  <div class="card-header">
      <span class="cart-span-title">Documentos</span>
      <button type="button" name="" id="6" (click)="onAddDocumento(aluno)" title="Inserir Documento" style="float:right" class="btn btn-primary btn-sm mr-2 " [disabled]="!isReadOnly" > <i class="fa fa-plus"></i></button>              </div>
  <div class="card-body table-responsive custom-table">
      <table class="table table-hover table-inverse">
          <thead class="thead-inverse">
              <tr>
                  <th>Tipo Documento</th>
                  <th>Nº do Documento</th>
                  <th>Data Emissão</th>
                  <th>Data validade</th>
                  <th>Opções</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let persDoc of alunoDocumento">
                  <td scope="row">{{persDoc.TipoDocumento.TipoDocumento1}}</td>
                  <td>{{persDoc.Numero}}</td>
                  <td>{{persDoc.DataEmissao | date: "dd/MM/yyyy"}}</td>
                  <td>{{persDoc.DataValidade | date: "dd/MM/yyyy"}}</td>
                  <td>
                    <!-- (click)="ImprimirDocumento(persDoc.NomeFicheiro)" -->
                      <a name="" id="" class="btn btn-sm btn-success" role="button" (click)="onEditDocumento(persDoc)" ><i class="fa fa-eye" aria-hidden="true"></i></a>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
