import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoSecretariaService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/DocumentoSecretaria', params);
    // return this.api.get('/employeeCards.json', params);
  }

  filtro(params: any){
    return this.api.get('/DocumentoSecretaria/Filtro', params);
  }

  filtro2(params: any){
    return this.api.get('/DocumentoSecretaria/FiltroDocSecretaria', params);
  }

  create(formContent: any){
    return this.api.post('/DocumentoSecretaria', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/DocumentoSecretaria', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/DocumentoSecretaria/${id}`);
  }
  delete(id: any) {
    return this.api.apagar('/DocumentoSecretaria' , id);
  }
}
