import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class VendaItemService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/ItensVendas', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/ItensVendas', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/ItensVendas', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/ItensVendas/${id}`);
  }
}
