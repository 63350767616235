import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Matricula, Mensalidade } from '@core/models/index';
import { NgbModal,ModalDismissReasons, NgbActiveModal, NgbDateStruct,} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, } from '@angular/forms';
import { TipoPagamento, Banco } from '@core/models/index';
import { Observable, Subject, of } from 'rxjs';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import { UtilsService, MensalidadeService, MatriculaService, ReportServerService,AuthService, AlunoService } from '@service/index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { BasePage } from 'app/shared/base-page';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportComponent } from '@component/index';
import { CurrencyPipe } from '@angular/common';
import { Publico } from '@shared/publico';
import { FormValidations } from '@shared/form-validations';
import { CustomvalidationService } from '@shared/customvalidation.service';


const fullUrl = `${environment.API_URL_BASE}${environment.API_URL_PATH}`;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BasePage implements OnInit {
  [x: string]: any;
  _saldo: any;
  _valorMaxSaldo: number = 0;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    injector: Injector,
    private _formBuilder: FormBuilder,
    //  private activeModal: NgbActiveModal,
    private utilservice: UtilsService,
    private userService: AuthService,
    private matriculaservice: MatriculaService,
    private mensalidadeService: MensalidadeService,
    private modalService: NgbModal, private customValidator: CustomvalidationService,
    private currentRoute: ActivatedRoute,
    private cp: CurrencyPipe, private alunoservice: AlunoService,
    public activeModal: NgbActiveModal,
    private reportserver: ReportServerService,
    protected sanitizer: DomSanitizer,
    private publico: Publico,
    private http: HttpClient
  ) {
    super(injector);
  //  this.dadosdoaluno();
 this. _valorMaxSaldo =0;
  }



  //@ViewChild('leftSidebar') leftSidebar: SidebarComponent;
  //  @ViewChild('rightSidebar') rightSidebar: SidebarComponent;

  MensalidadeForm = this._formBuilder.group({
    IdTipoPagamento: ['', Validators.required],
    Banco: ['', Validators.required],
    Desconto: [0, Validators.required],
    Borderoux: ['', Validators.required],
    Valor: ['', Validators.required],
    ValorTotal: [0, Validators.required],
    Saldo: [0],
    IdMensalidade: [''],
    Multa: [0, Validators.required],
    Quantidade: ['1'],
    InvoiceId: [],
    DiasAtrazado: [''],
    Juros: [''],
    Mora: [''],
    Documento: [''],
    IdTipoDoc: [''],
    Descricao: [''],
    Serie: [''],
    DataPagamento: [new Date, Validators.required],
    SaldoADescontar: [0,[Validators.required,]],
    MultaCriada: [0,[Validators.required,]],
  },
   /* {
    validator: this.verificasaldo(0, 0),
  } */

  {
    validator: this.customValidator.verificaValorSaldo('Saldo', 'SaldoADescontar'),
  }
  );

  public cartItemList: any = [];
  localmensalidade: Mensalidade[] = [];
  public page = 1;
  public pageSize = 15;
  collectionSize = 0;
  closeResult = '';
  filter = new FormControl('');
  mensalidade2: Mensalidade[] = [];
  searchText: string = '';
  mes: any;
  public products: any = [];
  public grandTotal!: number;
  public totalItem: number = 0;
  currentUser: any;
  profileData: any;
  profileImage = 'assets/img/user.jpg';
  aluno?: any;
  tipoRecibo: any;
  acesso: any;
  Idmatricula: any;
  dados: any;
  mensalidade: Mensalidade[] = [];
  activityHistory: any = [];
  TipoPagamento: Observable<TipoPagamento[]>;
  Bancos: Observable<Banco[]>;
  data: any;
  date;
  pdfSource: any;
  desconto = 0;
  fatura: any;
  checkAnoAnteriordivida: any;
  checkMesAnteriordivida: any; //boolean = false;

  saldo: any = {
    IdAluno: null,
    Valor: 0,
    TipoMovimento: null,
    Documento: null
  }

  multa: any ={
    IdMatricula: null,
    IdMes: 0,
    ValorApagar: 0

  }

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.param()

    this.mes = '';
    this.Idmatricula = this.currentRoute.snapshot.paramMap.get('id');
    this.TipoPagamento = this.utilservice.BuscaTipoPagamentos();
    this.Bancos = this.utilservice.BuscaBanco();
    this.data = Date();
    this.checkMesAnteriordivida = false;
    this.acesso = atob(this.userService.loadUserPermition());

    console.log(this.acesso);

     this.date = new Date((new Date().getTime() - 3888000000));
     this.loadMatricula();

    this.mensalidadeService.getProducts().pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
      this.products = res;
      this.totalItem = res.length;
      this.grandTotal = this.mensalidadeService.getTotalPrice();
      this.MensalidadeForm.get('Valor')?.setValue(this.grandTotal);
      this.MensalidadeForm.get('ValorTotal')?.setValue(this.grandTotal);
    });
  }

  removeItem(item: any) {
    this.mensalidadeService.removeCartItem(item);
    this.MensalidadeForm.get('Multa')?.setValue(0);
    this.MensalidadeForm.get('Desconto')?.setValue(0);
    this.MensalidadeForm.get('ValorADescontar')?.setValue(0);
  }
  emptycart() {
    this.mensalidadeService.removeAllCart();
  }

  fill() {
    for (let i in this.mensalidade) {
    }
  }
  onReport(pdfSource: any) {
    const modalref = this.modalService.open(ReportComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    modalref.componentInstance.pdfSource = pdfSource;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log('Modal close result', this.closeResult);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log('Modal close result', this.closeResult);
      }
    );
  }

  printOrOpenPdf(id: string, tipo: string) {
    console.log(id);

    this.reportserver.getInvoice(id, this.tipoRecibo).subscribe((data: any) => {
      const fileURL = URL.createObjectURL(data);
      this.loadMatricula();
      this.MensalidadeForm.get('Borderoux')?.setValue('0');
      if (tipo == 'Print') {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        //    this.onReport(data)
      } else {
        // window.open(fileURL, "_blank"); //,  "_blank"
        //  const url = window.URL.createObjectURL(data);
        // window.open(url);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `FT_${id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    });
  }

  getCalculadesconto(){
     if (this.MensalidadeForm.get('Desconto')?.value > 0 || (this.MensalidadeForm.get('ValorTotal')?.value > 0) ){
      this.desconto = (this.MensalidadeForm.get('ValorTotal')?.value * (this.MensalidadeForm.get('Desconto')?.value /100))
      this.MensalidadeForm.get('Valor')?.setValue( this.MensalidadeForm.get('ValorTotal')?.value-(this.MensalidadeForm.get('ValorTotal')?.value * (this.MensalidadeForm.get('Desconto')?.value /100)))
     // this.MensalidadeForm.get('ValorTotal')?.setValue(this.MensalidadeForm.get('Valor')?.value );
    }

  }

  getInvoiceId() {
    //  this.showLoadingView();
    this.mes = '';
    this.mensalidadeService.getInvoceId('FR', this.aluno.Anolectivo).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.fatura = response;
        console.log(this.fatura);
        this.MensalidadeForm.get('InvoiceId')?.setValue(response);
        console.log('Invoice carregado com sucesso', response);
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }


  onSubmit() {
    this.mensalidadeService.getInvoceId('FR', this.aluno.Anolectivo).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.fatura = response;
        this.MensalidadeForm.get('InvoiceId')?.setValue(response);
       /*  this.MensalidadeForm.get('Quantidade')?.setValue(this.totalItem);
        this.MensalidadeForm.get('Documento')?.setValue('FR ' + this.aluno.Anolectivo + '/' + response);
        this.MensalidadeForm.get('IdTipoDoc')?.setValue('FR');
        this.MensalidadeForm.get('Serie')?.setValue(this.aluno.Anolectivo);
        this.MensalidadeForm.get('Multa')?.setValue(this.mensalidadeService.getTotalMulta()); */

        this.parcelaMulta(response);
        console.log('Invoice carregado com sucesso', response);
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
      }
    );
  }

  parcelaMulta(dados: any){

    this.MensalidadeForm.get('Quantidade')?.setValue(this.totalItem);
    this.MensalidadeForm.get('Documento')?.setValue('FR ' + this.aluno.Anolectivo + '/' + dados);
    this.MensalidadeForm.get('IdTipoDoc')?.setValue('FR');
    this.MensalidadeForm.get('Serie')?.setValue(this.aluno.Anolectivo);

    if (this.MensalidadeForm.get('Multa')?.value == 0 ){
      this.MensalidadeForm.get('Multa')?.setValue(this.mensalidadeService.getTotalMulta());
    }

    let valor = this.MensalidadeForm.get('Valor')?.value;
    let valoTotal = this.MensalidadeForm.get('ValorTotal')?.value;
    this.MensalidadeForm.get('DataPagmento')?.setValue(new Date(this.MensalidadeForm.get('DataPagmento')?.value).toUTCString());

    if(this.MensalidadeForm.get('SaldoADescontar')?.value > 0 ){
      if(this.MensalidadeForm.get('Saldo')?.value >= this.MensalidadeForm.get('ValorTotal')?.value){
        this._saldo = this.MensalidadeForm.get('SaldoADescontar')?.value - this.MensalidadeForm.get('ValorTotal')?.value
      }
      else { this._saldo = this.MensalidadeForm.get('SaldoADescontar')?.value }
      }
    else
    {
        this._saldo = 0;
    }

    if (valor < (valoTotal - this.desconto - this._saldo)){
      Swal.fire({ title: 'Deseja criar uma parcela para a multa?', icon: 'warning', showCancelButton: true, confirmButtonText: 'Sim', cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          Swal.fire('Parcela criado com sucesso!','','success')
          this.multa.IdMatricula = this.Idmatricula;
          this.multa.ValorApagar = this.MensalidadeForm.get('Multa')?.value;
          this.cartItemList.forEach((m: any) => { this.multa.IdMes = m.Mes.IdMes;});
          this.MensalidadeForm.get('Multa')?.setValue(0);
          this.MensalidadeForm.get('MultaCriada')?.setValue(1);
          this.mensalidadeService.criarMulta(this.multa).pipe(takeUntil(this.destroySubject)).subscribe(response => {
           console.log(response);
         //  this.pagamento(dados);
         })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.MensalidadeForm.get('Multa')?.setValue(0);
        }
        this.pagamento(dados);
      })
    }else{
      this.pagamento(dados);
    }


  }




calculaNovaMulta(){
  if(this.MensalidadeForm.get('Valor')?.value > 0 && this.MensalidadeForm.get('ValorTotal')?.value > 0 && this.MensalidadeForm.get('Multa')?.value > 0){
      this.MensalidadeForm.get('Valor')?.setValue((this.MensalidadeForm.get('Valor')?.value + this.MensalidadeForm.get('Multa')?.value ) - this.mensalidadeService.getTotalMulta())
      this.MensalidadeForm.get('ValorTotal')?.setValue( (this.MensalidadeForm.get('ValorTotal')?.value + this.MensalidadeForm.get('Multa')?.value )  - this.mensalidadeService.getTotalMulta())
    }
    else{
      this.MensalidadeForm.get('Valor')?.setValue(this.mensalidadeService.getTotalPrice()) ;
      this.MensalidadeForm.get('ValorTotal')?.setValue(this.mensalidadeService.getTotalPrice());
    }
  }

  calculaSaldo(Recibo: any){

    console.log(this.aluno);

    this.saldo.IdAluno = this.aluno.Aluno.IdAluno
    this.saldo.Documento = Recibo;

    if(this.MensalidadeForm.get('SaldoADescontar')?.value > 0 ){
            this.saldo.Valor = this.MensalidadeForm.get('SaldoADescontar')?.value;
            this.saldo.TipoMovimento = "S";
    }
    else
    {
      if(this.MensalidadeForm.get('Valor')?.value > this.MensalidadeForm.get('ValorTotal')?.value){
         this.saldo.Valor = this.MensalidadeForm.get('Valor')?.value - this.MensalidadeForm.get('ValorTotal')?.value
        this.saldo.TipoMovimento = "E";
      }

    }

    this.alunoservice.atualizaSaldo(this.saldo).pipe(takeUntil(this.destroySubject)).subscribe(res => {
       console.log(res);

    } )
  }

  pagamento(Factura: any) {
    console.log(this.MensalidadeForm.value);

    let Recibo = 'FR ' + this.aluno.Anolectivo + '/' + Factura;
    Swal.fire({
      title: 'Deseja efetuar o pagamento da Factura nº. FR ' + this.aluno.Anolectivo + '/' + Factura, //this.mensalidadeService.getTotalPrice(),
     // text: 'Total: ' + this.cp.transform(this.mensalidadeService.getTotalPrice(),'Kz ','symbol','1.2-2'), //this.MensalidadeForm.get('Valor')?.value
     text: 'Total: ' + this.cp.transform(this.MensalidadeForm.get('Valor')?.value,'Kz ','symbol','1.2-2'),
     icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Emitir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.MensalidadeForm.value);
        this.MensalidadeForm.get('Desconto')?.setValue(this.desconto);
        this.mensalidadeService.pagamento(this.MensalidadeForm.value).pipe(takeUntil(this.destroySubject)).subscribe(
          (response: any) => {

        //    this.saldo.IdAluno = this.aluno.IdAluno;
        this.calculaSaldo(Recibo)



            this.mensalidadeService.cartItemList.forEach((mensalidade: any) => {
              mensalidade.IdMovimentoCaixa = response.IdMovimentoCaixa;
              this.mensalidadeService.actualizar(mensalidade).pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
                  this.mes = '';
                });
                this.MensalidadeForm.get('Desconto')?.setValue(0);
                this.MensalidadeForm.get('SaldoADescontar')?.setValue(0);

            });
            Swal.fire({
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonColor: 'green',
              cancelButtonColor: '#6c757d',
              denyButtonColor: 'red',
              confirmButtonText: '<i class="fa fa-print" ></i>Imprimr',
              denyButtonText: '<i class="fa fa-file-pdf "></i>Download PDF',
              cancelButtonText: '<i class="fa fa-envelope" ></i>Enviar/E-mail',
              title: 'Factura FR ' + this.aluno.Anolectivo + '/' + Factura, // this.MensalidadeForm.get('IdSerie')?.value,
              text: 'Total Pago: ' + this.cp.transform(this.MensalidadeForm.get('Valor')?.value,'Kz ','symbol',),
              icon: 'success',
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire('Documento impresso com sucesso!', '', 'success');
                this.printOrOpenPdf(Recibo, 'Print');
                this.loadMatricula();
              } else if (result.isDenied) {
                Swal.fire('Documento aberto com sucesso', '', 'info');
                this.printOrOpenPdf(Recibo, 'Open');
                this.loadMatricula();
              } else {
                const { value: email } = await Swal.fire({
                  title: 'Informe o email',
                  input: 'email',
                  inputLabel: 'Informe o email',
                  inputPlaceholder: 'Digite o email ',
                });

                if (email) {
                  this.reportserver.enviarReciboViaEmail(Recibo, email).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => console.log(response));
                  Swal.fire(`Entered email: ${email}`);
                  this.loadMatricula();
                }

                this.loadMatricula();
              }
            });

            //    this.showContentView();
            this.mensalidadeService.removeAllCart();
            //this.loadMatricula();
          },
          (error: any) => {
            console.log('Error create Matricula', error);
            this.showMessage( 'Erro', 'Ocorreu um erro ao realizar o pagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
          }
        );
      }
    });
  }

  /*
  num(){

        var chars = "0123456789";
        var string_length = 7;
        var randomstring = '';
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        return randomstring;
      } */

      confirmBox(){
        Swal.fire({
          title: 'Are you sure want to remove?',
          text: 'You will not be able to recover this file!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your imaginary file is safe :)',
              'error'
            )
          }
        })
      }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'custom-class',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dadosdoaluno() {
    this.matriculaservice.loadById(this.Idmatricula).pipe(takeUntil(this.destroySubject)).subscribe((message: any) => {
        this.aluno = message;
        console.log(this.aluno);

      this.alunoservice.loadSaldoAluno(this.aluno.Aluno.IdAluno).pipe(takeUntil(this.destroySubject)).subscribe((res: any) =>{
        let _salldo = res?.Saldo == null ? 0 : res.Saldo ;
        this.MensalidadeForm.get('Saldo')?.setValue(_salldo);
        this. _valorMaxSaldo = _salldo;});


      });
  }

  ComparaSaldo (formControl: FormControl){

    return  formControl.value > this.MensalidadeForm.controls['Saldo'].value ? { saldoInvalido: true} : null
   // .pipe(map(emailExiste => emailExiste ? { emailInvalido: true} : null));

}

/* matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const fromValue = control.value;
  if(this.MensalidadeForm) {
    const toValue = this.MensalidadeForm.get('Saldo')?.value;
    if (fromValue && toValue && fromValue === toValue) {
      console.log('Control: ', control);
      return { 'fieldMatch' : true };
    }
    console.log('Control: ', control);
    return null;
  }
} */

/* comparison(field: string, predicate: (fieldVal, fieldToCompareVal) => boolean): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const group = control.parent;
    const fieldToCompare = group.get(field);
    const valid = predicate(control.value, fieldToCompare.value);
    return valid ? null : {'comparison': {value: control.value}};
  }
} */

MatchPassword2(password: string, confirmPassword: string) {
  console.log('estou aqui');

  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    const confirmPasswordControl = formGroup.controls[confirmPassword];

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
      return null;
    }

    if (passwordControl.value > confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }
}

 equalsTo(otherField: string){
  const validator = (formControl: FormControl) => {
    if (otherField == null) {
      throw new Error('É necessário informar um campo.');
    }
  console.log(<FormGroup>formControl.root);

    if (!formControl.root || !(<FormGroup>formControl.root).controls) {
      return null;
    }

     const field = (<FormGroup>formControl.root).get(otherField);

    if (!field) {
      throw new Error('É necessário informar um campo válido.');
    }

    if (field.value > formControl.value) {
      return { equalsTo : otherField };
    }

  //  return null;
  };
  return null;
}

verificasaldo(Saldo: number, SaldoADescontar: number) {
  return (formGroup: FormGroup) => {
    const saldo = formGroup.controls[Saldo];
    const saldoADescontar = formGroup.controls[SaldoADescontar];


    console.log(saldo.value);

    if (!saldo || !saldoADescontar) {
      return null;
    }

    if (saldoADescontar.errors && !saldoADescontar.errors.saldoInvalido) {
      return null;
    }

    if (saldo.value !== saldoADescontar.value) {
      saldoADescontar.setErrors({ saldoInvalido: true });
    } else {
      saldoADescontar.setErrors(null);
    }
  }
}

  loadMatricula() {
    //  this.showLoadingView();

    this.mensalidadeService.loadByIdMatricula(this.Idmatricula).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any) => {
        this.localmensalidade = response;
        this.mensalidade = response;
        if (this.localmensalidade && this.localmensalidade.length) {
          this.collectionSize = this.localmensalidade.length;
        }
        this.dadosdoaluno();
        this.showContentView();
      },
      (error: any) => {
        console.log('Error a efetuar requisicao', error);
        // this.showLoadingView();
      }
    );
  }
  filtrarMensalidade(value: string) {
    if (value =='161'){
      this.mensalidade2 = this.localmensalidade.filter((option: any) =>  option.ContaCaixa.IdContaCaixa == value);
      console.log(this.mensalidade2);

    } else{
      this.mensalidade2 = this.localmensalidade.filter((option: any) =>  option.ContaCaixa.IdContaCaixa !== '161');
      console.log(this.mensalidade2);
    }

    // this.options = this.filteredOptions.filter((employee: { Nome: string; }) => employee.Nome.toLowerCase().includes(filter))
  }
  param(){
    this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
    this.tipoRecibo = response[0].TipoRecibo;
}, (error: any) => {
  console.log('Error on request', error);
});
  }

  AnularPagamento(dados: any,Tipo: number) {
  //  let Recibo = 'FR ' + this.aluno.Anolectivo + '/' + Factura;
  console.log(dados);

  let titulo= "Anular"
  let Doc = '0';


  titulo = Tipo==1? 'Eliminar': 'Anular';
   /*  if(Tipo == 1){
      titulo= "Eliminar";
    }else{
      titulo= "Anular";
    }
 */

    if (dados.Situacao == "ABERTA"){
     Doc= '0';
    } else
    {
      Doc=  dados.MovimentosCaixas.Documento;
    }


    Swal.fire({
      title: 'Deseja ' + titulo + ' a Factura nº. ' + Doc, //this.mensalidadeService.getTotalPrice(),
      text: 'Total: ' + this.cp.transform(dados.ValorApagar,'Kz ','symbol','1.2-2'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: titulo,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {

/*         let idMensalidade = 0;

        idMensalidade = Tipo==0? dados.IdMensalidade : 0; */

        this.mensalidadeService.ApagarPagamento(dados.IdMensalidade, Doc, Tipo).pipe(takeUntil(this.destroySubject)).subscribe(
          (response: any) => {
            this.loadMatricula();
            this.filtrarMensalidade('161')
            this.showMessage( 'success', 'Processo de ' + titulo + ' realizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));

          },
          (error: any) => {
            console.log('Error create Matricula', error);
            this.showMessage( 'Erro', 'Ocorreu um erro ao ' + titulo + ' o pagamento Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
          }
        );
      }
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  addtocart(item: any) {
    this.mensalidadeService
      .checkdividaDoAnoAnterior(item.Matricula.IdAluno).pipe(takeUntil(this.destroySubject))
      .subscribe((res: any) => {
        this.checkAnoAnteriordivida = res;
      });

    this.mensalidadeService
      .checkdividaDoMesAnterior(item.IdMensalidade).pipe(takeUntil(this.destroySubject))
      .subscribe((res: any) => {
        this.checkMesAnteriordivida = res;
      });

    if (this.checkMesAnteriordivida) {
      Swal.fire({
        icon: 'warning',
        title: `Caro Operador você não podera liquidar o mês de ${item.Mes.Mes}`,
        text: 'Porque existem dividas do ano passado em Aberto, por favor queira liquidar as dividas ',
      });
    } else {
      this.mes="";
      this.cartItemList.push(item);
      this.cartItemList.forEach((m: any) => {
        this.mes += m.Mes.Mes + ',';
        this.MensalidadeForm.get('Descricao')?.setValue('Renda(s) de : ' + this.mes.substring(0, this.mes.length - 1)
        );
      });
      this.mensalidadeService.addtoCart(item);
    }
  }

  
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
