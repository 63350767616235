<div>
<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Inserir Contacto</h2>
<!--     <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
</div>

<form [formGroup]="contactoForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">   
            <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Tipo Contacto</mat-label>
                    <mat-select  name="IdTipoContacto"  formControlName="IdTipoContacto">
                    <mat-option *ngFor="let tipo of TipoContactos | async" [value]="tipo.IdTipoContacto">
                        {{tipo.Contacto}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="contactoForm.get('IdTipoContacto')?.hasError('required')">Tipo Contacto é obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Número</mat-label>
                    <input matInput formControlName="Numero" maxlength="50" />
                    <mat-icon matSuffix class="secondary-text" >line_style</mat-icon >
                    <mat-error *ngIf="contactoForm.get('Numero')?.hasError('required')"> Número é obrigatório. </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Pessoa a Contactar</mat-label>
                    
                    <input matInput formControlName="Descricao" maxlength="100" />
                    <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                    <mat-error *ngIf="contactoForm.get('Descricao')?.hasError('required')"> Pessoa a Contactar é obrigatório. </mat-error>
                </mat-form-field>
              </div>

        </div>
</div>


 <!-- {{contactoForm.value | json }}  -->



<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')" >Sair</button>
    <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid || !(files.length > 0)">Guardar</button> -->
    <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!contactoForm.valid"  >Guardar</button>
    <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!contactoForm.valid"  >Atualizar</button>
</div>

</form>