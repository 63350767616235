<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">{{Titulo}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>


<!-- <hr> -->

<form [formGroup]="UtilizadorForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
  <div class="modal-body">
      <div class="row">

          <div class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Utilizador</mat-label>
                <input matInput formControlName="Utilizador" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="UtilizadorForm.get('Utilizador')?.hasError('required')"> Utilizador é obrigatório. </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Senha</mat-label>
                  <input matInput formControlName="Senha" maxlength="60" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                  <mat-error *ngIf="UtilizadorForm.get('Senha')?.hasError('required')"> Senha é obrigatório. </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Perfil</mat-label>
                  <mat-select  name="Perfil"  formControlName="Perfil">
                    <mat-option *ngFor="let s of perfil" [value]="s.NivelCodigo">
                      {{s.NivelDescricao}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="UtilizadorForm.get('Perfil')?.hasError('required')">Perfil é obrigatório</mat-error>
                </mat-form-field>
            </div>
           <!--  <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Professor</mat-label>
                  <mat-select  name="IdFunc"  formControlName="IdFunc" [disabled] = "UtilizadorForm.controls['Perfil'].value !=10">
                    <mat-option *ngFor="let s of func" [value]="s.IdFuncionario">
                      {{s.Nome}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="UtilizadorForm.get('IdFunc')?.hasError('required')">Perfil é obrigatório</mat-error>
                </mat-form-field>
            </div> -->


            <div class="row" style="margin-top: 10px;">
              <div class="form-group col-12">
                <span>Ativar /Desativar </span>
                  <mat-slide-toggle formControlName="ContaDesativada"></mat-slide-toggle>
              </div>
<!--                 <div class="form-group col-12">
                  <mat-slide-toggle formControlName="ReceberNotificacao">Receber Notificação</mat-slide-toggle>
              </div> -->
          </div>
           <!--  <div class="col-md-12">

              <mat-form-field appearance="outline" >
                  <mat-label>Situa</mat-label>
                  <input matInput formControlName="ContaDesativada" maxlength="60" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                  <mat-error *ngIf="UtilizadorForm.get('ContaDesativada')?.hasError('required')"> Perfil é obrigatório. </mat-error>
              </mat-form-field>
            </div> -->



          </div>


      <!--  {{ UtilizadorForm.value | json }}  -->
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!UtilizadorForm.valid ">Guardar</button>
      <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!UtilizadorForm.valid">Atualizar</button>
  </div>
</form>




