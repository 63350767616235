import { Router } from '@angular/router';
import { Component, OnInit, Injector } from '@angular/core';
import { AuthService } from '@service/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasePage } from '@shared/base-page';
import { SerialService } from '@service/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BasePage  implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  loginForm: FormGroup = new FormBuilder().group({
    utilizador: new FormControl('', Validators.required),
    senha: new FormControl('', Validators.required)
  });
  constructor( injector: Injector,
    private routerCtrl: Router,
    private authLogin: AuthService,
    private serial: SerialService)
    {
      super(injector);
    }
prazo = 0;
contador= 0;
  ngOnInit(): void {
    this.serial.loadAll({}).subscribe((res:any) => {
      this.prazo = res.Prazo;
      this.contador = 0;
    })
  }

  onSubmit() {
    this.showLoadingView();
    this.authLogin.login(this.loginForm.value).subscribe((response) => {
     // console.log(response);

      if (response.Token) {
        this.authLogin.updatemenu.next();
        this.authLogin.storeUserToken(response.Token, response.Role,  response.Utilizador, this.prazo.toString(),this.contador.toString(), response.IdFunc);
       // this.authLogin.isLoggedIn = true;
      //  this.authLogin.mostrarMenuEmitter.emit(true);
        this.routerCtrl.navigateByUrl("/dashboard");


      }
      this.authLogin.loadMenu(response.Role);
      this.dismissLoadingView();
    }, error => {
      this.showErrorView('Erro ao iniciar sessão', 'Ocorreu um erro ao iniciar sessão verifique sua senha e tenta novamente');
      this.routerCtrl.navigateByUrl("/Login");
      this.routerCtrl.navigate(['/login']);
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
