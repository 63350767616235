import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import { ReportServerService, UtilsService } from '@service/index';
import { Publico } from '@shared/publico';
import { AnoLectivoActual } from '@core/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pesquisa-aluno-divida-turma',
  templateUrl: './pesquisa-aluno-divida-turma.component.html',
  styleUrls: ['./pesquisa-aluno-divida-turma.component.scss']
})
export class PesquisaAlunoDividaTurmaComponent extends BasePage implements OnInit {
  [x: string]: any;
  anoLetivo: Observable<AnoLectivoActual[]>;
  constructor(injector: Injector , public activeModal: NgbActiveModal,private utilservice: UtilsService,
    private _formBuilder: FormBuilder,private reportService: ReportServerService,private publico: Publico,
    ) { super(injector); }


    pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
      AnoLectivo: ['', Validators.required],
    });

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.pesquisaForm  =  this._formBuilder.group({
      DataInicio: [new Date()],
      DataFim: [new Date(), Validators.required],
      AnoLectivo: ['', Validators.required],
    });
    this.anoLetivo = this.utilservice.BuscaAnoLectivo();
  }

  onSubmit() {
    this.pesquisaForm.get('DataInicio')?.setValue(new Date(this.pesquisaForm.get('DataInicio')?.value).toLocaleDateString());
    this.pesquisaForm.get('DataFim')?.setValue(new Date(this.pesquisaForm.get('DataFim')?.value).toLocaleDateString());
       this.reportService.alunoDividaTurma(this.pesquisaForm.value).subscribe((response: Blob | MediaSource) => {
        this.pesquisaForm.get('DataInicio')?.setValue(new Date());
        this.pesquisaForm.get('DataFim')?.setValue(new Date());
        const fileURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'InquilinoDivida.pdf';
          link.click();
          window.URL.revokeObjectURL(link.href);

      }, (error: any) => {
        console.log('Error create employee', error);
        this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });

    }
}

