<div class="container">
  <!--   <div class="jumbotron text-center">
      <h1>
        <span translate>Invoice Report</span>
      </h1>
    </div> -->
    <div class="container">
     <!--  <ngx-extended-pdf-viewer [src]="pdf" [useBrowserLocale]="false"> </ngx-extended-pdf-viewer> -->

     <!-- F:\Sistema 2021\Gescolar\src\assets\sample.pdf -->
     <ngx-extended-pdf-viewer 
     [src]="'F:\Sistema 2021\Gescolar\src\assets\sample.pdf'"
     [useBrowserLocale]="false"
     [minifiedJSLibraries]="false">
</ngx-extended-pdf-viewer>

    </div>
  </div>
