import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@env';

const fullUrl = `${environment.API_URL_BASE}${environment.API_URL_PATH}`;
const fullUrlRecibo = `${environment.API_RECIBO_URL_BASE}${environment.API_URL_PATH}`;
@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) { }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  get<T>(endPoint: string, params:any = {}): Observable<T[]> {
    // Support easy query params for GET requests
    var httpParams: any = new HttpParams();
    if (params) {
      for (let k in params) {
        httpParams = httpParams.set(k, params[k]);
      }
      params = httpParams;
    }
    return this.http.get<T[]>(`${fullUrl}${endPoint}`, { params });
  }

  visualizarRecibo<T>(endPoint: string, params:any = {}): Observable<T[]> {
    // Support easy query params for GET requests
    var httpParams: any = new HttpParams();
    if (params) {
      for (let k in params) {
        httpParams = httpParams.set(k, params[k]);
      }
      params = httpParams;
    }
    return this.http.get<T[]>(`${fullUrlRecibo}${endPoint}`, { params });
  }

  post<T>(endPoint: string, data: any, opts = {}, message = ''): Observable<T> {
    const url = `${fullUrl}${endPoint}`;
    return this.http.post<T>(url, data, opts);
  }

  update<T>(endPoint: string,id: string, data: any): Observable<T> {
    const url = `${fullUrl}${endPoint}/${id}`;
    return this.http.put<T>(url, data);
  }

  actualizar<T>(endPoint: string, data: any): Observable<T> {
    const url = `${fullUrl}${endPoint}`;
    return this.http.put<T>(url, data);
  }

  delete<T>( endPoint: string, id: string): Observable<T> {
    const url = `${fullUrl}${endPoint}/${id}`;
    return this.http.delete<T>(url)
  }
  apagar<T>( endPoint: string, id: string): Observable<T> {
    const url = `${fullUrl}${endPoint}?id=${id}`;
    return this.http.delete<T>(url)
  }
  public log(message: string) {
    console.log(message);
  }
}
