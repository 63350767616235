<br>
<h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Lista de {{Titulo}}</h4>
<breadcrumb #parent>

  <ol class="breadcrumb">
    <!-- *ngIf="!route.terminal" -->
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li  class="breadcrumb-item subtitulo">
        <a  >{{ route.displayName }}</a>  <!-- [routerLink]="[route.url]" -->
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active subtitulo"> você esta em: {{ route.displayName }}</li>
    </ng-template>
  </ol>
</breadcrumb>


<!-- <ol class="breadcrumb">
  <li class="breadcrumb-item"><a >você esta em: Homologação</a></li>
  <li class="breadcrumb-item active">Listagem de Empresas</li>
</ol> -->

<!-- <div class="col-md-12">
  <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Lista de {{Titulo}}</h4>
  <ol class="breadcrumb">
      <li class="breadcrumb-item"><a >você esta em: {{Titulo}}</a></li>
      <li class="breadcrumb-item active">Lista de {{Titulo}}</li>
    </ol>
</div> -->
<br>
<div class="row">
<div class="col-md-4 text-right" >
     <div >
         <input class="form-control ml-2 " #box  (keyup)="EnviarTexto(box.value)" placeholder="Pesquisar..." type="text"  />
     </div>
</div>
<div class="col-md-8 text-right" style="padding-right: 10px;">
      <div  >
          <button mat-raised-button class="btn btn-success" (click)="EnviarDataToParent()" > {{labelButton}}</button>
      </div>
</div>
</div>

