import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DadosPessoaisService } from '@core/services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-funcionario-form',
  templateUrl: './funcionario-form.component.html',
  styleUrls: ['./funcionario-form.component.scss'],
})
export class FuncionarioFormComponent implements OnInit {
  idFunc;
  dados: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    private funcService: DadosPessoaisService,
    private currentRoute: ActivatedRoute
  ) {
    this.idFunc = this.currentRoute.snapshot.paramMap.get('id');
    this.carregarDadosPessoais(this.idFunc);
  }

  ngOnInit(): void {

  }

  //this.currentRoute.snapshot.paramMap.get('id')
  carregarDadosPessoais(id: any) {
    this.funcService.loadById(this.idFunc).pipe(takeUntil(this.destroySubject)).subscribe(
      (response: any[]) => {
        this.dados = response;
      },
      (error: any) => {
        console.log('Error on request', error);
      }
    );
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
