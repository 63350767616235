import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PedagogicoRoutingModule } from './pedagogico-routing.module';
import { CadastroNotaFormComponent } from './cadastro-nota-form/cadastro-nota-form.component';
import { FormularioModule } from '@component/index';


@NgModule({
  declarations: [CadastroNotaFormComponent],
  imports: [
    CommonModule,
    PedagogicoRoutingModule,
    FormularioModule,
  ]
})
export class PedagogicoModule { }
