<form [formGroup]="matriculaForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">

  <!-- <div class="col-md-12 "> -->
    <div class=" col-md-12  ">
      <br>
      <div class="row">
        <div class="col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Turma</mat-label>
            <mat-select name="IdTurma" formControlName="IdTurma">
              <app-consulta-drop-down (Texto)="applyFilter($event)"></app-consulta-drop-down>
              <mat-option *ngFor="let tipo of Turmas " [value]="tipo.IdTurma">
                {{tipo.Turma1}}
              </mat-option>
              <ngb-pagination [(page)]="page2" [pageSize]="pageSize2" [maxSize]="15" [collectionSize]="collectionSize2"
                (pageChange)="pageChanged($event)"></ngb-pagination>
            </mat-select>
            <mat-error *ngIf="matriculaForm.get('IdTurma')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-5">
          <!-- <div> -->
          <ng-multiselect-dropdown [placeholder]="'Disciplinas'" formControlName="dropDisciplina"
            [settings]="dropdownSettings" [data]="disciplina" (onSelect)="onItemSelect($event)"
            (onDeSelect)="onItemDeSelect($event)">
          </ng-multiselect-dropdown>
          <!--  </div> -->
        </div>


        <div class="col-md-2 text-right" style="padding-right: 15px;">
          <div>
            <button type="button" class="btn btn-primary" [disabled]="matriculaForm.value.dropDisciplina.length ==0"
              [disabled]="!isReadOnly" (click)="adicionarDisciplina()"> Incluir Disciplina</button>
          </div>
        </div>
      </div>

    </div>
 <!--  </div> -->

  <hr>

  <table class="content-table">
    <thead>
      <tr>
        <th scope="col">Disciplina</th>
        <th scope="col">Turma</th>
        <th scope="col">Ano Lectivo</th>
        <th scope="col">Opções</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let alu of disciplinaProf | slice: (page-1) * pageSize : page * pageSize">
        <td>{{alu.ListaDeDisciplinas.Disciplina1}}</td>
        <td>{{alu.ListaDeTurmas.Turma1}}</td>
        <td>{{alu.ListaDeTurmas.Ano}}</td>


        <td>
          <button type="button" name="" id="" title="Eliminar Aluno" (click)="confirmBox(alu.Id)"
            class="btn btn-danger btn-sm"> <i class="fas fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

</form>
<div class="d-flex justify-content-between p-2">
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize"></ngb-pagination>

  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"> <!--  (ngModelChange)="loadData()" -->
    <option [ngValue]="5">5 items por pagina</option>
    <option [ngValue]="10">10 items por pagina</option>
    <option [ngValue]="20">20 items por pagina</option>
  </select>
</div>
