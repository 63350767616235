import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatriculaService {

  constructor(private api: ApiService) { }

  private messageSource = new BehaviorSubject<any>(null);
  currentMessage = this.messageSource.asObservable();


  loadAll(params: any){
    return this.api.get('/Matricula', params);
  }

  loadMatriculaByTurma(params: any){
    return this.api.get('/Matricula/MatriculaByTurma?id=' +params);
  }

  filtro(params: any){
    return this.api.get('/Matricula/Filtro', params);
  }

  create(formContent: any){
    return this.api.post('/Matricula', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Matricula',  formContent);
  }
  createServico(formContent: any){
    return this.api.post('/Matricula/Servico', formContent);
  }
  loadById(id: any) {
    return this.api.get(`/Matricula/${id}`);
  }
  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  delete(id: any) {
    return this.api.apagar('/Matricula' , id);
  }
}
