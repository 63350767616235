import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePage } from '@shared/base-page';
import {UtilsService, MatriculaService, ContaCaixaService, MesesService} from '@service/index';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-servico-aluno',
  templateUrl: './servico-aluno.component.html',
  styleUrls: ['./servico-aluno.component.scss']
})
export class ServicoAlunoComponent  extends BasePage implements OnInit  {
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder, private matriculaservice: MatriculaService,
    private contaCaixaService: ContaCaixaService, private mesService: MesesService,
   // private alunoservice: AlunoService,
    private utilservice: UtilsService) {
       super(injector);
       this.dadosDoAluno();
      }

    @Input() public Idmatricula: any;
    @Input() public isReadOnly: boolean = false;
    @Input() public titulo: any  = "Registar Aluno";
    aluno: any;
    contaCaixa: any[] = [];
    mes: any[] = [];
    filteredOptions: any[] = [];
    termoBuscaChanged: Subject<string> = new Subject<string>();
    ServiceForm  =  this._formBuilder.group({
      IdMatricula: ['', Validators.required],
      Tipo: ['', Validators.required],
      Valor: ['', Validators.required],
      MesParcela: ['', Validators.required],
      NumeroParcela: [1, Validators.required],

    });

  ngOnInit(): void {
    this.dadosDoAluno();
    this.loadData();
    this.ServiceForm.get('Tipo')?.valueChanges.subscribe(tipo => {
      this.getvalor(tipo);
    })
    this.ServiceForm.controls.IdMatricula.setValue(this.Idmatricula)
  }

  dadosDoAluno() {
    this.matriculaservice.loadById(this.Idmatricula).pipe(takeUntil(this.destroySubject))
      .subscribe((message: any) => {
        this.aluno = message;
      });
  }

  getvalor(tipo: any){
    this.contaCaixaService.loadById(tipo).pipe(takeUntil(this.destroySubject)).subscribe((res:any) =>{
        this.ServiceForm.controls.Valor.setValue(res.Valor)
    })
  }
	applyFilter(filterValue: string) {
    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {

          this.filtro(filtrarPor);
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }
 filtro(enteredData: string){
  this.contaCaixa = this.filteredOptions.filter((item: any) => {
   return item.Conta.toLowerCase().includes(enteredData.toString().toLowerCase())
  })
}
  loadData() {
    loadingServiceShow(1, 'Tipo', false);

    this.contaCaixaService.loadByTipo('S').pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
      this.contaCaixa = response;
      this.filteredOptions = response;
      loadingServiceHide('Tipo')
    }, (error: any) => {
      console.log('Error on request', error);
    });

    loadingServiceShow(1, 'MesParcela', false);

    this.mesService.loadAll({}).subscribe((response: any[]) => {
      this.mes = response;
loadingServiceHide('MesParcela')
    }, (error: any) => {
      console.log('Error on request', error);
    });

  }
  onSubmit() {
    console.log(this.ServiceForm.value);
    this.matriculaservice.createServico(this.ServiceForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessage('Sucesso', 'Serviço inserido com sucesso', 'success').then(() => this.activeModal.close('Update click'));
    }, (error: any) => {
      this.showMessage('Erro', 'Ocorreu um erro ao criar Serviço Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      console.log('Error on request', error);
    });
  }
  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
