<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Registrar Menu</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>


<!-- <hr> -->

<form [formGroup]="MenuForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
  <div class="modal-body">
      <div class="row">

          <div class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Menu</mat-label>
                <input matInput formControlName="Nome" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="MenuForm.get('Nome')?.hasError('required')"> Menu é obrigatório. </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Path</mat-label>
                  <input matInput formControlName="Path" maxlength="20" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                  <mat-error *ngIf="MenuForm.get('Path')?.hasError('required')"> Path é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>PreIcon</mat-label>
                  <input matInput formControlName="PreIcon" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                  <mat-error *ngIf="MenuForm.get('PreIcon')?.hasError('required')"> PreIcon é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>PostIcon</mat-label>
                  <input matInput formControlName="PostIcon" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                  <mat-error *ngIf="MenuForm.get('PostIcon')?.hasError('required')"> PostIcon é obrigatório. </mat-error>
              </mat-form-field>
            </div>


          </div>
      <!--  {{ MenuForm.value | json }}  -->
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!MenuForm.valid ">Guardar</button>
      <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!MenuForm.valid">Atualizar</button>
  </div>
</form>


