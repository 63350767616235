import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';

const routes: Routes = [
      {path: 'disciplina', loadChildren: () => import( './disciplina/disciplina.module').then(m=>m.DisciplinaModule), canActivate: [RoleGuard ]},
      {path: 'curso', loadChildren: ()=> import('./curso/curso.module').then(m => m.CursoModule), canActivate: [RoleGuard ] },
      {path:'aluno', loadChildren: ()=> import('../pages/aluno/aluno.module').then(m=> m.AlunoModule), canActivate: [RoleGuard ]  },
      {path:'produtos', loadChildren: ()=> import('./produtos/produtos.module').then(m => m.ProdutosModule), canActivate: [RoleGuard ] },
      {path:'turma',loadChildren: () => import('./turma/turma.module').then(m => m.TurmaModule), canActivate:[RoleGuard]},
 //     {path:'funcionario', loadChildren: () => import('./Funcionario/funcionario.module').then(m => m.FuncionarioModule), canActivate:[RoleGuard] },

 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormularioRoutingModule { }
