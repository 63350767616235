<br>
<div class="col-md-12">
  <h4 class="espaco titulo"><mat-icon>location_city</mat-icon>Alterar Senha</h4>
  <ol class="breadcrumb">
      <li class="breadcrumb-item"><a >você esta em: Alterar Senha</a></li>
      <li class="breadcrumb-item active">Alterar Senha</li>
    </ol>
</div>
<br>

<form [formGroup]="UtilizadorForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row">

          <div class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Senha</mat-label>
                <input type="password" matInput formControlName="Senha" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="UtilizadorForm.get('Senha')?.hasError('required') "> Senha é obrigatório. </mat-error>

            <!--     <mat-error  *ngIf="!UtilizadorForm.get('passwordConfirm').hasError('required') &&
                UtilizadorForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                As senhas não são iguais. verifique por favor.
            </mat-error>
 -->
            </mat-form-field>
          </div>
          <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Confirmar Senha</mat-label>
                  <input type="password" matInput formControlName="ConfirmarSenha" maxlength="60" />
                  <mat-icon matSuffix class="secondary-text" >numbers</mat-icon >
                    <mat-error *ngIf="!UtilizadorForm.get('ConfirmarSenha')?.hasError('required')"> Confirmar Senha é obrigatório. </mat-error>
                   <mat-error *ngIf="!UtilizadorForm.get('ConfirmarSenha')?.hasError('required') && UtilizadorForm.hasError('passwordMismatch')"> As Senhas não conferem. </mat-error>


                </mat-form-field>
            </div>

          </div>


      <!--  {{ UtilizadorForm.value | json }}  -->
  </div>

  <div class="modal-footer">
     <!--  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button> -->
      <button type="submit" class="btn btn-success" [disabled]="!UtilizadorForm.valid ">Guardar</button>
  </div>
</form>
