import { Component, Input, OnInit, Injector  } from '@angular/core';
import { NgbModal, NgbActiveModal,  NgbDateStruct, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators,  AbstractControl,FormGroupDirective, ValidatorFn, ValidationErrors, } from '@angular/forms';
import {UtilsService, AlunoContactoService} from '@service/index';
import { Observable, Subject } from 'rxjs';
import {TipoContacto, TipoDocumento} from '@core/models/index';
import { BasePage } from '@shared/base-page';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-contacto-aluno',
  templateUrl: './contacto-aluno.component.html',
  styleUrls: ['./contacto-aluno.component.scss']
})
export class ContactoAlunoComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector ,
              public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder,
              private contactoservice: AlunoContactoService,
              private utilservice: UtilsService) {
                super(injector);
                 }

@Input() public isReadOnly: boolean = false;
@Input() public contacto: any;

contactoForm  =  this._formBuilder.group({
    IdContacto: [''],
    IdTipoContacto: ['', Validators.required],
    Numero: ['', Validators.required],
    Descricao: ['', Validators.required],
    IdAluno: ['', Validators.required]
  });

   TipoContactos: Observable<TipoContacto[]>;

  ngOnInit() {
    this.TipoContactos = this.utilservice.BuscaTipoContacto();
    console.log(this.isReadOnly);
    console.log(this.contacto.IdAluno);
    this.contactoForm.get('IdAluno')?.setValue(this.contacto.IdAluno);
     if (this.isReadOnly) {
      this.loadById();
    }
  }

  onSubmit()
  {
     this.contactoForm.get('IdAluno')?.setValue(this.contacto.IdAluno);
     console.log(this.contactoForm.value);
      this.showLoadingView();
      this.contactoservice.create(this.contactoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Contacto Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log('Error create Contacto', error);
        this.showMessage('Erro', 'Ocorreu um erro ao criar Contacto Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  loadById() {
      this.contactoForm.get('IdAluno')?.setValue(this.contacto.IdAluno);
      this.contactoForm.get('IdTipoContacto')?.setValue(this.contacto.TipoContacto.IdTipoContacto);
      this.contactoForm.get('Numero')?.setValue(this.contacto.Numero);
      this.contactoForm.get('IdContacto')?.setValue(this.contacto.IdContacto);
      this.contactoForm.get('Descricao')?.setValue(this.contacto.Descricao);
  }

  onUpdate() {
      this.showLoadingView();
       this.contactoservice.actualizar(this.contactoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Contacto actualizado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log('Error create Contacto', error);
        this.showMessage('Erro', 'Ocorreu um erro ao actualizar Contacto Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
