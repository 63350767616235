import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClasseService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Classes', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/Classes', formContent);
  }

/*   update(id:any, formContent: any){
    return this.api.update('/Classes', id, formContent);
  } */
  update(formContent: any){
    return this.api.actualizar('/Classes', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Classes/${id}`);
  }

  delete(id: any) {
    return this.api.delete('/Classes' , id);
  }
  
}
