import { Injectable } from '@angular/core';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root'
})
export class DisciplinaService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Disciplina', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/Disciplina', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Disciplina', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Disciplina/${id}`);
  }
}