<!--    <nav>

    <label >GESCOLA - Software de Gestao Académico</label>

    <ul>
      <div class="example-button-container mr-4" style="padding-top: 10px;">
        <b><label class="mr-2">Ano Lectivo: {{anolectivo}}</label></b>
      </div>
      <button mat-mini-fab color="grey" [routerLink]="'propina'" appMyTootip [appTooltip] ="'Pagamentos rapido'"  data-toggle="tooltip"
        aria-label="Example icon button with a home icon">
        <mat-icon>add</mat-icon>
      </button>
      <label for=""class="title " [style.color]="result >= 70 ?  'red': 'black'" style="padding-top: 10px;">{{titulo}}</label>
      <circle-progress
     [backgroundPadding] = "-50"
     [percent] = "result"
     [radius]="20"
     [outerStrokeWidth]="2"
     [innerStrokeWidth]="1"
     [outerStrokeColor]="'#78C000'"
     [innerStrokeColor]="'#C7E596'"
     [subtitleColor]="'#e89a11'"
     subtitleColor=#e89a11
     [titleColor]="'black'"
     [unitsColor]="'black'"
     [titleFontSize]="'10'"
     [showSubtitle]=false
     [showTitle] = true
     [animation]="true"
     [animationDuration]="100" class="mr-4"
   ></circle-progress>
    </ul>

    <button class="btn btn-danger mr-2" (click)="logout()" >Terminar Sessão</button>
   </nav>

 -->

   <div class="d-flex flex-column flex-md-row align-items-right p-3 px-md-4   border-bottom shadow-sm nav">
    <button mat-icon-button  (click)="sidenav.toggle()">  <!-- *ngIf="sidenav.mode === 'over'" -->
      <mat-icon  style="font-size:large;">  <!-- *ngIf="!sidenav.opened" -->
          menu
      </mat-icon>
      <!-- <mat-icon *ngIf="sidenav.opened">
          close
      </mat-icon> -->
  </button>
  <div class="mat-divider mat divider-vertical"></div>
    <h2 class="my-0 mr-md-auto font-weight-normal" style="color: rgb(20, 17, 17); font-weight: 800px;">
      O Inquilino - Software de Gestão de Rendas

    </h2>

    <div class="example-button-container mr-4" style="padding-top: 10px;">
      <b><label class="mr-2">Ano Exercicio: {{anolectivo}}</label></b>
    </div>
    <!-- appMyTootip [appTooltip] ="'Pagamentos rapido'" -->
    <div class="example-button-container mr-4">
      <button mat-mini-fab color="grey" [routerLink]="'pag'"   data-toggle="tooltip"
        aria-label="Example icon button with a home icon">
        <mat-icon>add</mat-icon>
      </button>

    </div>
    <mat-divider  [vertical]="true"  class="mr-4 " ></mat-divider>
   <label for=""class="title " [style.color]="result >= 70 ?  'red': 'black'" style="padding-top: 10px;">{{titulo}}</label>
   <circle-progress
  [backgroundPadding] = "-50"
  [percent] = "result"
  [radius]="20"
  [outerStrokeWidth]="2"
  [innerStrokeWidth]="1"
  [outerStrokeColor]="'#78C000'"
  [innerStrokeColor]="'#C7E596'"
  [subtitleColor]="'#e89a11'"
  subtitleColor=#e89a11
  [titleColor]="'black'"
  [unitsColor]="'black'"
  [titleFontSize]="'10'"
  [showSubtitle]=false
  [showTitle] = true
  [animation]="true"
  [animationDuration]="100" class="mr-4"
></circle-progress>
<mat-divider  [vertical]="true"  class="mr-4 " ></mat-divider>
    <button class="btn btn-danger mr-2" (click)="logout()" >Terminar Sessão</button>
  </div>
<!--

  <button   mat-button class="menu-button">

 <app-propina></app-propina>
    <span>propina</span>
</button> -->


  <mat-sidenav-container  >
    <mat-sidenav  #sidenav="matSidenav" class="mat-elevation-z8">

 <br>
        <button  *ngFor="let route of userRoutes" routerLink="{{route.Menus.Path}}"  mat-button class="menu-button">
          <mat-icon>{{route.Menus.PreIcon}}</mat-icon>
          <span>{{route.Menus.Nome}}</span>
      </button>


<!--  -->


<div *ngIf="perfil=='ADMINISTRADOR' || perfil=='SECRETARIA' || perfil=='FINANÇAS' || perfil=='HOTEL'" >
        <button mat-button [matMenuTriggerFor]="animals" class="menu-button">
          <mat-icon>assessment</mat-icon>
          Relatórios
        </button>



        <mat-menu #animals="matMenu">
         <!--  <button mat-menu-item>Caixa do dia</button> -->
          <button mat-menu-item (click)="pesquisaMovimento()">Movimento do Caixa</button>
          <button mat-menu-item (click)="pesquisaCaixaServico()">Movimento por Serviço</button>
          <button mat-menu-item (click)="resumoClasse('R')">Movimento por Edificio</button>
          <button mat-menu-item [matMenuTriggerFor]="fish">Previsão Geral de Renda</button>

          <button mat-menu-item [matMenuTriggerFor]="dividas">Consulta Dividas</button>

        <!--   <button mat-menu-item >Previsão Mensal</button> -->
          <button mat-menu-item (click)="resumoClasse('B')">Balancete Mensal</button>
          <button mat-menu-item (click)="balanco()">Balanço Anual</button>


          <button mat-menu-item [matMenuTriggerFor]="Aluno">Lista de Inquilino</button>
        </mat-menu>

        <mat-menu #dividas="matMenu">
          <button mat-menu-item (click)="pesquisaRelatorio()">Inquilino com Dividas</button>
          <button mat-menu-item (click)="pesquisaDividaTurma()">Dividas por Apartamento</button>
          <button mat-menu-item (click)="cartaDevedor()">Nota de Combrança</button>
        </mat-menu>

        <mat-menu #fish="matMenu">
          <button mat-menu-item (click)="demostrativo('P')">Consolidado</button>
          <button mat-menu-item (click)="demostrativo('S')">Detalhado</button>
        </mat-menu>
        <mat-menu #Aluno="matMenu">
          <button mat-menu-item (click)="alunoMatriculados('1')">Lista </button>
         <!--  <button mat-menu-item (click)="alunoMatriculados('2')">Confirmaram a matricula</button>
          <button mat-menu-item (click)="alunoMatriculados('3')">Não confirmaram a Matricula</button> -->
        </mat-menu>


        <mat-menu #amphibians="matMenu">
          <button mat-menu-item>Sonoran desert toad</button>
          <button mat-menu-item>Western toad</button>
          <button mat-menu-item>Arroyo toad</button>
          <button mat-menu-item>Yosemite toad</button>
        </mat-menu>

        <mat-menu #reptiles="matMenu">
          <button mat-menu-item>Banded Day Gecko</button>
          <button mat-menu-item>Banded Gila Monster</button>
          <button mat-menu-item>Black Tree Monitor</button>
          <button mat-menu-item>Blue Spiny Lizard</button>
          <button mat-menu-item disabled>Velociraptor</button>
        </mat-menu>

      </div>


<!--       <div *ngIf="perfil=='PROFESSOR' || perfil=='COORDENADOR' || perfil=='PEDAGOGIA'" >
        <button mat-button [matMenuTriggerFor]="Boletim" class="menu-button">
          <mat-icon>assessment</mat-icon>
          Boletim de notas
        </button>
        <button mat-button [matMenuTriggerFor]="Pauta" class="menu-button">
          <mat-icon>assessment</mat-icon>
          Pautas
        </button>
        <button mat-button [matMenuTriggerFor]="PautasemNotas" class="menu-button">
          <mat-icon>assessment</mat-icon>
          Pautas sem notas
        </button>

        <mat-menu #Boletim="matMenu">
           <button mat-menu-item (click)="pesquisaMovimento()">Boletim Individual</button>
           <button mat-menu-item (click)="pesquisaCaixaServico()">Boletim Trimestral</button>
        </mat-menu>
        <mat-menu #Pauta="matMenu">
          <button mat-menu-item (click)="pesquisaMovimento()">Mini Pauta</button>
          <button mat-menu-item (click)="pesquisaCaixaServico()">Pauta Trimestral</button>
          <button mat-menu-item (click)="pesquisaCaixaServico()">Pauta</button>
       </mat-menu>

       <mat-menu #PautasemNotas="matMenu">
        <button mat-menu-item (click)="pesquisaMovimento()">Mini Pauta</button>
        <button mat-menu-item (click)="pesquisaCaixaServico()">Pauta Trimestral</button>
        <button mat-menu-item (click)="pesquisaCaixaServico()">Pauta</button>
     </mat-menu>

      </div>

    <div class="accordion" id="accordionExample">
        <div  id="headingOne">
            <button mat-button class="menu-button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" >
              <mat-icon>assessment</mat-icon>
              Pautas
            </button>
        </div>

        <div id="collapseOne" class="card-header card-header" aria-labelledby="headingOne" data-parent="#accordionExample">
            <button class="menu-button"  mat-menu-item (click)="miniPauta()"><mat-icon>assessment</mat-icon>Mini Pauta</button>
            <button class="menu-button" mat-menu-item (click)="miniPautaGeral()"><mat-icon>assessment</mat-icon>Pauta Trimestral</button>
            <button class="menu-button" mat-menu-item (click)="pauta()"><mat-icon>assessment</mat-icon>Pauta</button>
        </div>

    </div> -->




    </mat-sidenav>

     <mat-sidenav-content>
        <div class="container ">
          <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
  </mat-sidenav-container>





