import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Menus', params);
    // return this.api.get('/employeeCards.json', params);
  }
  loadAcesso(params: any){
    return this.api.get(`/Menus/Acesso?id=${params}`);
    // return this.api.get('/employeeCards.json', params); Acesso?id=1
  }
  create(formContent: any){
    return this.api.post('/Menus', formContent);
  }

  update(formContent: any){
    return this.api.actualizar('/Menus', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/Menus/${id}`);
  }
  delete(id: any) {
    return this.api.apagar(`/Menus`,id);
  }
  menuByRole(id: any) {
    return this.api.get(`/Menus/MenuByRole?perfil=${id}`);
  }
  HaveAccess(role: any, menu: any) {
    return this.api.get('/Menus/HaveAccess?Menu=' + menu + '&Perfil=' + role);
  }
}
