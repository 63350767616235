{{isReadOnly}}

<form [formGroup]="Form" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
  <div class="modal-body">
      <div class="row">

          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Nome</mat-label>
                <input matInput formControlName="Nome" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="Form.get('Nome')?.hasError('required')"> Nome é obrigatório. </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Nacionalidade</mat-label>
                <mat-select  name="Naturalidade"  formControlName="Naturalidade">
                  <mat-option *ngFor="let s of pais" [value]="s.IdPais">
                    {{s.Pais}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="Form.get('Naturalidade')?.hasError('required')">Nacionalidade é obrigatório</mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Pai</mat-label>
                <input matInput formControlName="Pai" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="Form.get('Pai')?.hasError('required')"> Pai é obrigatório. </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Mãe</mat-label>
                <input matInput formControlName="Mae" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="Form.get('Mae')?.hasError('required')"> Mae é obrigatório. </mat-error>
            </mat-form-field>
          </div>


          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Província</mat-label>
                <mat-select  name="IdProvincia"  formControlName="IdProvincia">
                  <mat-option *ngFor="let s of provincias" [value]="s.IdProvincia">
                    {{s.Provincia1}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="Form.get('IdProvincia')?.hasError('required')">Província é obrigatório</mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Munícipio</mat-label>
                <mat-select  name="IdMunicipio"  formControlName="IdMunicipio">
                  <mat-option *ngFor="let s of municipios " [value]="s.IdMunicipio">
                    {{s.Municipio1}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="Form.get('IdMunicipio')?.hasError('required')">Municipio é obrigatório</mat-error>
              </mat-form-field>
          </div>



           <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Genero</mat-label>
                <mat-select  name="Sexo"  formControlName="Sexo">
                  <mat-option *ngFor="let s of sexo | async" [value]="s.IdSexo">
                    {{s.Sexo1}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="Form.get('idSexo')?.hasError('required')">Genero é obrigatório</mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" >
                <mat-label>Data de nascimento</mat-label>
                <input matInput [matDatepicker]="DataNascPicker" readonly name="DataNascimento" formControlName="DataNascimento">
                <mat-datepicker-toggle matSuffix [for]="DataNascPicker"></mat-datepicker-toggle>
                <mat-datepicker #DataNascPicker></mat-datepicker>
                <mat-error *ngIf="Form.get('DataNascimento').hasError('required')"> Data de nascimento é obrigatório.  </mat-error>

            </mat-form-field>
          </div>


      </div>

  </div>

  <div class=" text-right mr-3">
      <button type="button" class="btn btn-outline-dark mr-2" (click)="activeModal.close('Save click')">Sair</button>
      <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!Form.valid">Guardar</button>
      <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!Form.valid">Atualizar</button>
  </div>
</form>

