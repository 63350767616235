import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AlunoService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/Aluno', params);
    // return this.api.get('/employeeCards.json', params);
  }

  filtro(params: any){
    return this.api.get('/Aluno/Filtro', params);
    // return this.api.get('/employeeCards.json', params);
  }

  create(formContent: any){
    return this.api.post('/aluno', formContent);
  }

  atualizaSaldo(formContent: any){
    return this.api.post('/aluno/AtualizaSaldo', formContent);
  }
  loadSaldoAluno(id: any) {
    return this.api.get(`/alunoSaldo/${id}`);
  }

  update(formContent: any){
    return this.api.actualizar('/aluno', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/aluno/${id}`);
  }
  delete(id: any) {
    return this.api.apagar('/Aluno' , id);
  }
}
