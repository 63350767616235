import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from '../services/auth/auth.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';

@Injectable()
export class ErrrorInterceptor implements HttpInterceptor {

  constructor(private accountService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {
          if (err.status == 401) {
              this.router.navigate(['/auth']);
              // auto logout if 401 response returned from api
              this.accountService.logout();
          }
          // const error = err.message || err.statusText;
          // console.log('Error interceptor called', err);
          return throwError(err);
      }))
  }
}