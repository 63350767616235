<mat-nav-list>
    <div class="logo py-4">
     <!--  <img src="./assets/logo.svg" alt="Company Logo" /> -->
     O Inquilino
    </div>
    <mat-divider></mat-divider>
    <h2 matSubheader class="mt-2">APPLICATIONS</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/home">
      <mat-icon>home</mat-icon> Home
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
      <mat-icon>dashboard</mat-icon> Dashboard
    </a>
    <a mat-list-item> <mat-icon>edit</mat-icon> Link 3 </a>

  </mat-nav-list>

