import { CONSTS } from './../../../shared/const';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { User } from '../../models/users';
import { UserToken } from '../../models/userToken';
import { map, take } from 'rxjs/operators';
import { MenuService } from '../menu.service';
import { Menu } from '../../models';

import { environment } from '@env';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentUserSource = new ReplaySubject<User>(1);
  public currentUser$ = this.currentUserSource.asObservable();
  userRoutes: any [] = [];

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  private _updatemenu = new Subject<void>();
  get updatemenu() {
    return this._updatemenu;
  }

  constructor(private handler: HttpBackend,private api: ApiService,
    private router: Router,
    private menuService: MenuService
  ) {}

  login1(user: User) {
    if (user.Utilizador !== '' && user.Senha !== '' ) {
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }

  login(data: any): Observable<UserToken> {
    const newHttpClient = new HttpClient(this.handler);
    var retorno = newHttpClient.post<any>(environment.API_URL_BASE  + '/api/Utilizadores/Login', data, httpOptions);
      this.loggedIn.next(true);
      this.router.navigate(['/']);
       if (retorno) {
        console.log(retorno);
        this.setCurrentUser(retorno)
      }
    return retorno;
  }

  loadMenu(role : any){
    this.menuService.menuByRole(role).subscribe((res: any) => {
         this.userRoutes =  res;
         this.updatemenu.next();
     })
  }

  logout() {
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  obfuscatingData(contsntValue: any): string{
    return btoa(contsntValue);
  }

  revelObfuscatingData(contsntValue: string): string{
    return atob(contsntValue);
  }


  storeUserToken(token: string, permitionRole: string, utilizador: string, prazo: string, contador: string, IdFunc: string) {
    this.loadMenu(permitionRole);
    localStorage.setItem(CONSTS.USER_TOKEN, this.obfuscatingData(token) );
    localStorage.setItem(CONSTS.USER_PERMITION_LABEL, this.obfuscatingData(permitionRole.toUpperCase()));
    localStorage.setItem(CONSTS.USER_NAME, utilizador);
    localStorage.setItem(CONSTS.PRAZO, prazo);
    localStorage.setItem(CONSTS.CONTADOR, contador);
    localStorage.setItem('Func', IdFunc);
  }

  loadUserToken() {
    const content: string | any = localStorage.getItem(CONSTS.USER_TOKEN);
    return this.revelObfuscatingData(content);
  }
 loadMenuUser(){
    return  this.userRoutes ;

 }
  loadUserPermition() {
    return localStorage.getItem(CONSTS.USER_PERMITION_LABEL);
  }

  loadUserName() {
    return localStorage.getItem(CONSTS.USER_NAME);
  }
  getFunc(){
    return localStorage.getItem('Func');
  }

  clearUserToken() {
    localStorage.removeItem(CONSTS.USER_TOKEN);
    localStorage.removeItem(CONSTS.USER_PERMITION_LABEL);
    localStorage.removeItem(CONSTS.USER_NAME);
    localStorage.clear();
  }

  getCurrentUserData(): Observable<User[]> {
    return this.api.get<User>('/Utilizadores');
  }

  public setCurrentUser(user: any) {
  //  localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
  }

  hasLogedUser(): boolean {
    const token = this.loadUserToken();
    // const user = localStorage.getItem('user');
    if (token) {
      return true;
    }
    return false;
  }

}
