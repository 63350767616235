<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>


<hr>

<form [formGroup]="alunoForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Pai</mat-label>
                  <input matInput maxlength="100" formControlName="Pai"/>
                  <mat-icon matSuffix class="secondary-text" >supervised_user_circle</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Pai')?.hasError('required')"> Pai é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Mãe</mat-label>
                  <input matInput formControlName="Mae" maxlength="100" />
                  <mat-icon matSuffix class="secondary-text" >supervisor_account</mat-icon >
                  <mat-error *ngIf="alunoForm.get('Mae')?.hasError('required')"> Mãe é obrigatório. </mat-error>
              </mat-form-field>
            </div>
        </div>


        <!--  {{ alunoForm.value | json }}  -->
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid ">Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid">Atualizar</button>
    </div>
</form>
