import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContaCaixaService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/ContaCaixa', params);
  }

  filtro(params: any){
    return this.api.get('/ContaCaixa/Filtro', params);
  }

  create(formContent: any){
    return this.api.post('/ContaCaixa', formContent);
  }

  update( formContent: any){
    return this.api.actualizar('/ContaCaixa', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/ContaCaixa/${id}`);
  }
  loadByTipo(id: any) {
    return this.api.get(`/ContaCaixa/tipo?id=${id}`);
  }
  apagar(id: any) {
    return this.api.apagar(`/ContaCaixa`, id);
  }
}
