export * from './utils/utils.service';
export * from './api/api.service';
export * from './auth/auth.service'
//export * from './users.service'
export * from './aluno.service';

export * from './aluno-contacto.service';
export * from './aluno-documento.service';
//export * from './bairro.service'
export * from './classe-disciplina.service';
export * from './matricula.service';
export * from './mensalidade.service';
export * from './turma.service';

export * from './disciplina.service';

export * from './nivel-ensino.service';
export * from './curso.service';
export * from './dados-pessoais.service';
export * from './classe.service';
export * from './conta-caixa.service';
export * from './bairro.service'
export * from './movimento-caixa.service';
export * from './banco.service';
export * from './tipo-pagamentos.service';
export * from './tipodoc.service';
export * from './param.service';
export * from './tax-table-entry.service';
export * from './serial.service';
export * from './report-server.service';
export * from './menu.service';
export * from './menu2.service';
export * from './menu-item.service';
export * from './perfil.service';
export * from './user/users.service';
export * from './meses.service';
export * from './venda.service';
export * from './venda-item.service';
export * from './tipo-recibo.service';
export * from './aluno-filhacao.service';
export * from './documento-secretaria.service';
export * from './provincia.service';
export * from './professor.service';
export * from './trimestre.service';
export * from './notas.service';
export * from './tipo-classe.service';
export * from './alert.service';
