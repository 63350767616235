import { Component, OnInit, Injector } from '@angular/core';
import { BasePage } from '@shared/base-page';

@Component({
  selector: 'app-login-layout',
   templateUrl: './login-layout.component.html',

  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent extends BasePage implements OnInit {

  constructor(injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
  }

}
