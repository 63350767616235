import { Component, Injector, OnInit, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal,  ModalDismissReasons,  } from '@ng-bootstrap/ng-bootstrap';
import { Aluno, Matricula, AnoLectivoActual, Pagination, Meses } from '@core/models/index';
import {UtilsService,  MatriculaService,  ReportServerService, TurmaService} from '@service/index';
import {MatriculaFormComponent,  ServicoAlunoComponent} from '@component/index';
import { HttpClient,  } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BasePage } from 'app/shared/base-page';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { debounceTime, map, takeUntil,  } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { Publico } from '@shared/publico';
import Swal, { SweetAlertIcon } from 'sweetalert2';


@Component({
  selector: 'app-transsitar-aluno-classe-list',
  templateUrl: './transsitar-aluno-classe-list.component.html',
  styleUrls: ['./transsitar-aluno-classe-list.component.scss']
})
export class TranssitarAlunoClasseListComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector, private matriculaservice: MatriculaService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private routerCtrl: Router,
              private _formBuilder: FormBuilder, private turmaService: TurmaService,
              private publico: Publico,
              private currentRoute: ActivatedRoute,
              private reportService: ReportServerService,
              private httpClient: HttpClient,
              private utilservice: UtilsService,
              public pipe: DecimalPipe) {super(injector);}


  termoBuscaChanged: Subject<string> = new Subject<string>();
  matriculas : Matricula[] = [];
  localmatriculas : Matricula[] = [];
  employeesToSend: Matricula[] = [];
 // aluno: any[] = [];
  Turmas: any[]=[];
  //Anolectivos: any;
  //anoLetivo: Observable<AnoLectivoActual[]>;
  //data: Aluno[];
 filteredUsers: any[] = [];
 // anolectivoActual: any;
 Meses: Observable<Meses[]>;
  config: any;
  public page = 1;
  public pageSize = 5;
  turmadestino: any[]=[];
  turmadestinolocal: any[]=[];
  idTurma: any;
  idAnolectivo: any;
  titulo: any;
  collectionSize = 0;
  public pagination = {} as Pagination;
  closeResult = '';

  cadastroForm  =  this._formBuilder.group({
    TurmaDestino: ['', Validators.required],
    PrimeiroMes: ['', Validators.required],
    NumeroDeParcelas: ['', Validators.required],


  });

  _employeesToSend: string[] = [];
  isMasterSel:boolean = false;
  isChecked:boolean = false;
  arrayEmployes: boolean[] = [];

  ngOnInit(): void {
    this.idTurma = this.currentRoute.snapshot.paramMap.get('id');
    this.idAnolectivo = this.currentRoute.snapshot.paramMap.get('id2');
    this.loadData();
    this.Meses = this.utilservice.BuscaMeses();
    this.publico.verificaLincenca();
    this.config = {
      ItemsPerPage: 5,
      currentPage: 1,
      page: 1,
      AnoLectivo : this.idAnolectivo,
      IdTurma: this.idTurma ,
      Nome: "",
      IdMatricula:""  }
    this.loadamatriculas();

  }

onAddAluno() {


 /*  let t = {
    IdTurmadestino: this.cadastroForm.controls.TurmaDestino.value,
    Matriculas: this.employeesToSend
  };

  console.log(t); */

 // this.showSwalWithConfirmationAndExecuteFunction('transitaOsAlunos', 'Deseja transitar os alunos selecionado', '', 'warning');
 this.showSwalAndExecuteFunction();

/*    Swal.fire({
    title: 'Deseja fazer a mudança de turma ',
    icon: 'question',
    showConfirmButton: true,
    confirmButtonColor: '#007BFF',
    didOpen: () => {
   //     Swal.hideLoading();
        Swal.fire({
title: 'Deseja fazer a transição de classe do(s) aluno(s) selecionado(s) ?',
//text: "Não podera reverter esta acção!",
icon: 'warning',
showCancelButton: true,
confirmButtonColor: '#3085d6',
cancelButtonColor: '#d33',
confirmButtonText: 'Sim'
}).then((result) => {
if (result.isConfirmed) {

  this.turmaService.transitar(t).subscribe((response) => {
    //  this.showMessage('Sucesso', 'A troca de turma efectuado com sucesso', 'success').then(() => this.activeModal.close('Save click'));


    }, error => {
      console.log(error.message);

      this.showMessage('Erro', 'Ocorreu um erro ao troca aluno(s) Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

Swal.fire('Feito!','Transição de turma efetuado com sucesso.','success'

)
this.filtro();
}
});
    }

   // didClose: () => dialogRef?.close()

}); */

}

transitaOsAlunos(){
  let t = {
    IdTurmadestino: this.cadastroForm.get('TurmaDestino')?.value,
    NumeroDeParcelas: this.cadastroForm.get('NumeroDeParcelas')?.value,
    PrimeiroMes: this.cadastroForm.get('PrimeiroMes')?.value,
    Matriculas: this.employeesToSend
  }

  this.turmaService.transitar(t).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
    Swal.fire('Transferência de apartamento executado com sucesso!');
    }, error => {
      console.log(error.message);
      this.showMessage('Erro', 'Ocorreu um erro ao executar a transferência Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

}



showSwalAndExecuteFunction() {
  Swal.fire({
    title: 'Deseja transferir os Inquilinos selecionado?',
  //  text: `Você deseja executar a função ${functionName}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não'
  }).then((result) => {
    if (result.isConfirmed) {
     // const functionToExecute = this[functionName];
     // functionToExecute();
      this.transitaOsAlunos()
      /* if (typeof functionToExecute === 'function') {
        functionToExecute();
      } else {
        Swal.fire('A função não existe.');
      } */
    }
  });
}


confirmBox(){
  Swal.fire({
    title: 'Deseja transferir o(s) Inquilinos selecionados?',
   // text: 'You will not be able to recover this file!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim!',
    cancelButtonText: 'No'
  }).then((result) => {
    if (result.value) {
      this.transitaOsAlunos();
      Swal.fire(
        'Transitado!',
        'Inquilinos transferidos com sucesso.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
}

  loadamatriculas() {
    this.showLoadingView();
    this.matriculaservice.filtro(this.config).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.localmatriculas = response.data;
        this.matriculas = response.data;
        this.pagination = response.pagination;
        this.config = {
                      currentPage: response.currentPage,
                      page: 1,
                      totalItems: response.total
                      }
       this.collectionSize = response.total;

       if (response.total > 0 ){
        this.aluno = this.matriculas[0].Aluno;
       }

        this.showContentView();
      }, error => {
        console.log('Error a efetuar requisicao', error);
      });
  }

  applyFilter(filterValue: string) {
console.log(filterValue);

    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
          this.config.Nome = filtrarPor;
          this.filtro();
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }

  onAddService(Idmatricula: any) {
    const modalref =  this.modalService.open(ServicoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.Idmatricula = Idmatricula;
    modalref.componentInstance.isReadOnly = false;
    modalref.result.then((result) => {
      this.loadamatriculas();
      this.closeResult = `Closed with: ${result}`;
    }
    , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadamatriculas();
    });
  }

  extrato(matricula: any){
    this.reportService.extrato(matricula).pipe(takeUntil(this.destroySubject)).subscribe((response: Blob | MediaSource) => {
      const fileURL = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'Extrato.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);

    }, (error: any) => {
      console.log('Error create employee', error);
      this.showMessage('Erro', 'Ocorreu um erro ao gerar o relatório, Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

  }

  onKey(value: string) {
    let filter = value.toLowerCase();
    if(value.length === 0){
      this.loadData();
    }
    this.turmadestino =  this.turmadestinolocal.filter((option: any) =>  option.Turma1.toLowerCase().includes(filter) || option.Ano.toLowerCase().includes(filter))
  }

  loadData() {
      this.turmaService.loadById(this.idTurma).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
            this.Turmas = response;
            this.titulo= response.Turma1;
            }, (error: any) => {
            console.log('Error on request', error);
          });

      this.turmaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.turmadestino =  response;
        this.turmadestinolocal = response;
        console.log(response);
        console.log(this.turmadestino);
        })

        this.utilservice.BuscaParametros().pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
          console.log(response[0].Parcela);

          var date = new Date(response[0].DataInicio);
          var month = date.getMonth()+1;
          this.cadastroForm.get('PrimeiroMes')?.setValue(month);
          this.cadastroForm.get('NumeroDeParcelas')?.setValue(response[0].Parcela);
    }, (error: any) => {
      console.log('Error on request', error);
    });

      }

  pageChanged(event: any) {
    this.pagination.page = event;
    this.config.page = event;
    this.filtro();
   }

  filtro(){
    this.config.IdTurma = this.idTurma;
    this.config.AnoLectivo = this.idAnolectivo;
    this.config.ItemsPerPage = this.pageSize;
    this.loadamatriculas();
  }


  getIndexOf(item: any): number {
   /*  console.log(item) */;

    return this.employeesToSend.findIndex(el => el.IdMatricula == item.id);
  }

  verifyItem(item: any): boolean {
    return this.employeesToSend.findIndex(el => el.IdMatricula == item.id) > -1 ? true : false;
  }

  checkAll(event: any) {

    this._employeesToSend.length = 0;

    if (event && event.target.checked) {

      this.matriculas.forEach((item: any) => {
        if(this.getIndexOf(item) < 0){
          this.employeesToSend.push(item);
          this._employeesToSend.push(item.IdMatricula.toString());
          this.arrayEmployes[item.IdMatricula] = true;
        }
      });

      this.isMasterSel = true;
      this.isChecked = true;

    } else {
      this.matriculas.forEach((item: any) => {
        this.employeesToSend.splice(this.getIndexOf(item), 1);
        this._employeesToSend.splice(this.getIndexOf(item.IdMatricula), 1);
        this.arrayEmployes[item.IdMatricula] = false;
      });

      this.isMasterSel = false;
      this.isChecked = false;

    }

  }

  onChecked(item: any, event: any) {
    console.log(event);

    if (event && event.target.checked) {
    this.getIndexOf(item) < 0 ? this.employeesToSend.push(item) : '';
    //  console.log(this.employeesToSend.push(item.IdCartao));
    this._employeesToSend.push(item.IdMatricula);

    console.log(this._employeesToSend);
    console.log('Employee ID Add', item, this.employeesToSend);

    } else {
      this.employeesToSend.splice(this.getIndexOf(item), 1);
      console.log(item.IdMatricula);
      this._employeesToSend.splice(this.getIndexOf(item.IdMatricula), 1);
  //  this._employeesToSend = item.IdCartao
      console.log(this._employeesToSend);

      console.log('Employee ID Remove', item, this.employeesToSend);
    }

    if(this._employeesToSend.length == 0){
      this.isMasterSel = false;
      this.isChecked = false;
    } else
    {
      this.isChecked = true;
    }


    console.log("COUNT" + this._employeesToSend.length);

  }

  onDelete(id: any) {
    console.log(id);

    this.showLoadingView();
    this.matriculaservice.delete(id).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessageWithConfirm('Sucesso', 'Matricula excluido com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      this.loadamatriculas();
    }, (error: any) => {
      this.showMessage('Erro', 'Ocorreu um erro ao excluir Matricula, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
    });
}

  performFilter(filterBy: string): any[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.matriculas.filter((product: any) =>
        product.productName.toLocaleLowerCase().indexOf(filterBy) !== -1);
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  }

