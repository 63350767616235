<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Lançar serviços</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="ServiceForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">

  <div class="row">

    <div class="col-md-12 col-sm-2 afastar">

        <div class="box-flex space col-md-12">
            <div class="header header-document">
                <div class="row hfluid">
                  <div class="col-md-9 col-xs-9 hfluid">
                    <div class="header-flex-title">
                        <div class="doc-header">
                            <div class="doc-type-circle doc-type FT texto-negrito">Turma: {{aluno.Turma.Turma1}} &nbsp; | &nbsp; Ano Lectivo: {{aluno.Anolectivo}}</div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        &nbsp; Nome:
                        <br> <label class="texto-negrito">   &nbsp; {{aluno.Aluno.Nome}}  </label>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>
            </div>


    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" >
            <mat-label>Tipo</mat-label>
            <mat-select data-loader="Tipo" name="Tipo"  formControlName="Tipo">
              <app-consulta-drop-down (Texto)="applyFilter($event)" ></app-consulta-drop-down>
              <mat-option *ngFor="let s of contaCaixa" [value]="s.IdContaCaixa">
                {{s.Conta}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="ServiceForm.get('Tipo')?.hasError('required')">Tipo é obrigatório</mat-error>
          </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline" >
            <mat-label>Mês da Primeira Parcela</mat-label>
            <mat-select data-loader="MesParcela" name="MesParcela"  formControlName="MesParcela">
              <mat-option *ngFor="let s of mes" [value]="s.IdMes">
                {{s.Mes}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="ServiceForm.get('MesParcela')?.hasError('required')">Mês da Parcela é obrigatório</mat-error>
          </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" >
            <mat-label>NumeroParcela</mat-label>
            <input matInput maxlength="100"  formControlName="NumeroParcela" type="number" max="12"/>
            <mat-icon matSuffix class="secondary-text" >supervised_user_circle</mat-icon >
            <mat-error *ngIf="ServiceForm.get('NumeroParcela')?.hasError('required')"> NumeroParcela é obrigatório. </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" >
            <mat-label>Valor</mat-label>
            <input matInput maxlength="100" formControlName="Valor" type="number" />
            <mat-icon matSuffix class="secondary-text" >supervised_user_circle</mat-icon >
            <mat-error *ngIf="ServiceForm.get('Valor')?.hasError('required')"> Endereço é obrigatório. </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  </div>

</div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <button type="submit" class="btn btn-success" [disabled]="!ServiceForm.valid">Guardar</button>
       </div>
</form>

