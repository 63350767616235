import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-propina',
  templateUrl: './modal-propina.component.html',
  styleUrls: ['./modal-propina.component.scss']
})
export class ModalPropinaComponent implements OnInit {

    _opened: boolean = false;
   _modeNum: number = 0;
   _positionNum: number = 0;
   _dock: boolean = false;
   _closeOnClickOutside: boolean = true;
   _closeOnClickBackdrop: boolean = false;
   _showBackdrop: boolean = true;
   _animate: boolean = true;
   _trapFocus: boolean = true;
   _autoFocus: boolean = true;
   _keyClose: boolean = false;
private _autoCollapseHeight: any;
  private _autoCollapseWidth: any;
   myString: string = "right";
  // public _opened: boolean = false;

public _toggleSidebar2() {
this._opened = !this._opened;
}

   _MODES: Array<string> = ['over'];
   _POSITIONS: Array<string> = ['right', 'left',  'top', 'bottom'];

 
   vpos = 'right';
   _toggleOpened(): void {
    this._opened = !this._opened;
  }

   _toggleMode(): void {
    this._modeNum++;

    if (this._modeNum === this._MODES.length) {
      this._modeNum = 0;
    }
  }

   _toggleAutoCollapseHeight(): void {
    this._autoCollapseHeight = this._autoCollapseHeight ? null : 500;
  }

   _toggleAutoCollapseWidth(): void {
    this._autoCollapseWidth = this._autoCollapseWidth ? null : 500;
  }

   _togglePosition(): void {
    this._positionNum++;

    if (this._positionNum === this._POSITIONS.length) {
      this._positionNum = 0;
    }
  }
 ngOnInit(): void {
   console.log(this._closeOnClickOutside);
   
  }
   _toggleDock(): void {
    this._dock = !this._dock;
  }

   _toggleCloseOnClickOutside(): void {
    this._closeOnClickOutside = !this._closeOnClickOutside;
  }

   _toggleCloseOnClickBackdrop(): void {
    this._closeOnClickBackdrop = !this._closeOnClickBackdrop;
  }

   _toggleShowBackdrop(): void {
    this._showBackdrop = !this._showBackdrop;
  }

   _toggleAnimate(): void {
    this._animate = !this._animate;
  }

   _toggleTrapFocus(): void {
    this._trapFocus = !this._trapFocus;
  }

   _toggleAutoFocus(): void {
    this._autoFocus = !this._autoFocus;
  }

   _toggleKeyClose(): void {
    this._keyClose = !this._keyClose;
  }

   _onOpenStart(): void {
    console.info('Sidebar opening');
  }

   _onOpened(): void {
    console.info('Sidebar opened');
  }

   _onCloseStart(): void {
    console.info('Sidebar closing');
  }

   _onClosed(): void {
    console.info('Sidebar closed');
  }

   _onTransitionEnd(): void {
    console.info('Transition ended');
  }

   _onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }


}