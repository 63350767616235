import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { PerfilService, UsersService, DadosPessoaisService} from '@service/index';
import { BasePage } from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-utilizador-form',
  templateUrl: './utilizador-form.component.html',
  styleUrls: ['./utilizador-form.component.scss']
})
export class UtilizadorFormComponent  extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder, private dadospessoaisService: DadosPessoaisService,
    private perfilService: PerfilService,private utilizadorService: UsersService) { super(injector);  }

    UtilizadorForm  =  this._formBuilder.group({
      IdUtilizador: [''],
      Utilizador: ['', Validators.required],
      Senha: ['', Validators.required],
      Perfil: [1],
      ContaDesativada: ['', Validators.required],
      IdFunc: [0 , Validators.required]
    });
      Titulo ="Cadastrar Utilizador"
    @Input() public dados: any;
    @Input() public isReadOnly: boolean = false;
    perfil: any[] =[];
    func: any[] =[];
    vdisable: boolean = true;
    ToDoListForm: any;


  ngOnInit(): void {

      if (this.isReadOnly) {console.log(this.dados);
        this.loadById();
        this.Titulo = "Alterar Utilizador";
      }
      this.listaPerfil();
      this.listaFunc();

      this.UtilizadorForm.get('Perfil')?.valueChanges.subscribe(res =>{
        console.log(res);

        this.vdisable = res == 10? false : true;
      } )
  }

  loadById() {
    this.UtilizadorForm.get('IdUtilizador')?.setValue(this.dados.IdUtilizador);
    this.UtilizadorForm.get('Utilizador')?.setValue(this.dados.Utilizador);
    this.UtilizadorForm.get('IdFunc')?.setValue(1);
    this.UtilizadorForm.get('Senha')?.setValue(this.dados.Senha);
    this.UtilizadorForm.get('Perfil')?.setValue(this.dados.PerfilNavigation.Utilizadores[0].Perfil);
   // this.UtilizadorForm.get('ContaDesativada')?.setValue(this.dados.PerfilNavigation.Utilizadores[0].ContaDesativada == 0 ? false : this.dados.PerfilNavigation.Utilizadores[0].ContaDesativada);
    this.UtilizadorForm.get('ContaDesativada')?.setValue(this.dados.PerfilNavigation.Utilizadores[0].ContaDesativada );
  }

  listaPerfil(){
    this.perfilService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => this.perfil= data );
  }

  listaFunc(){
    this.dadospessoaisService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => this.func= data );
  }

  onSubmit() {
      this.showLoadingView();
      this.UtilizadorForm.get('IdFunc')?.setValue(1);
      console.log('Form Value to submmit', this.UtilizadorForm.value);
      this.utilizadorService.create(this.UtilizadorForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Utilizador criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Utilizador Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.showLoadingView();
      this.utilizadorService.actualizar(this.UtilizadorForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Utilizador atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar perfil, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
