<div id="views-content" class="container-fluid">
  <div class="row">
    <div class="col-md-12 text-center">
      <div id="offboarding">
        <div id="offboarding-container">
          <div id="offboarding-header">
            <i
              style="color: #d32f2f; font-size: 60px"
              class="fa fa-exclamation-triangle"
            ></i>
          </div>
          <div id="offboarding-title">Subscrição Expirou</div>
          <div id="offboarding-subtitle">
            A sua subscrição expirou no dia <b>23/05/2023</b>.<br />
            Para poder continuar a usar o Vendus, por favor, renove a sua
            subscrição. <br /><br />
            <b>Porque não consigo aceder à minha conta?</b><br />
            O último pagamento que realizou manteve a sua conta ativa até ao dia
            23/05/2023. <br /><br />
          </div>
        </div>
        <div id="offboarding-footer">
          <a class="btn btn-lg btn-success" href="/app/registers/"
            >Renovar Subscrição</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="text-center my-4">
    <a class="btn btn-md btn-link text-muted" href="/app/subscription/cancel/"
      >Remover Conta</a
    >
  </div>
</div>
