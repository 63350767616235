<app-cabecario Titulo="Lançamento de Notas" (Texto)="filtro($event)"></app-cabecario>

<form [formGroup]="matriculaForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">

  <!-- <div class="col-md-12 "> -->
    <div class=" col-md-12  ">
      <br>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Turma</mat-label>
            <mat-select name="IdTurma" formControlName="IdTurma">
             <!--  <app-consulta-drop-down (Texto)="applyFilter($event)"></app-consulta-drop-down> -->
              <mat-option *ngFor="let tipo of Turmas " [value]="tipo.IdTurma">
                {{tipo.ListaDeTurmas.Turma1}}
              </mat-option>
              </mat-select>
            <mat-error *ngIf="matriculaForm.get('IdTurma')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Disciplina</mat-label>
            <mat-select name="IdDisciplina" formControlName="IdDisciplina">
             <!--  <app-consulta-drop-down (Texto)="applyFilter($event)"></app-consulta-drop-down> -->
              <mat-option *ngFor="let tipo of disciplina " [value]="tipo.IdDisciplina">
                {{tipo.ListaDeDisciplinas.Disciplina1}}
              </mat-option>
              </mat-select>
            <mat-error *ngIf="matriculaForm.get('IdDisciplina')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Trimestre</mat-label>
            <mat-select name="IdTrimestre" formControlName="IdTrimestre">
             <!--  <app-consulta-drop-down (Texto)="applyFilter($event)"></app-consulta-drop-down> -->
              <mat-option *ngFor="let tipo of trimestre " [value]="tipo.Codigo">
                {{tipo.Descricao}}
              </mat-option>
              </mat-select>
            <mat-error *ngIf="matriculaForm.get('IdTrimestre')?.hasError('required')">Tipo Aluno é obrigatório</mat-error>
          </mat-form-field>
        </div>



      </div>

    </div>
 <!--  </div> -->

 <!--  <hr> -->

<!--   <table class="content-table">
    <thead>
      <tr>
        <th scope="col" style="width: 50px;">#</th>
        <th scope="col" style="width: 400px;">Aluno</th>
        <th scope="col">MAC</th>
        <th scope="col">NPP</th>
        <th scope="col">NPT </th>
        <th scope="col">MT </th>

        <th scope="col">Opções</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let alu of notas ">
        <td>{{alu.IdAlunoNota}}</td>
        <td>{{alu.Nome}}</td>
        <td>{{alu.MAC}}</td>
        <td>{{alu.NPP}}</td>
        <td>{{alu.NTP}}</td>
        <td>{{alu.MT}}</td>


        <td>
          <button type="button" name="" id="" title="Eliminar Aluno" (click)="confirmBox(alu.Id)"
            class="btn btn-danger btn-sm"> <i class="fas fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
 -->
</form>
<!-- <div class="d-flex justify-content-between p-2">
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize"></ngb-pagination>
</div> -->

<form class="content-table" [formGroup]="notaForm">

  <div formArrayName="items" >
      <table class="table  table-bordered">
          <thead class="thead-inverse">
              <tr>
                <th scope="col" style="width: 50px;">#</th>
                <th scope="col" style="width: 550px;">Aluno</th>
                <th scope="col" style="width:80px; ">MAC</th>
                <th scope="col" style="width:80px; ">NPP</th>
                <th scope="col" style="width:80px; ">NPT </th>
                <th scope="col" style="width:80px; ">MT </th>


                <th scope="col" style="width:80px; ">EXAME </th>
                <th scope="col" style="width:80px; ">RECUR. </th>
                <th scope="col" style="width:70px; ">F.Just </th>
                <th scope="col" style="width:70px; ">F.Inj </th>

                <th>Opções</th>
              </tr>
          </thead>

          <tbody>
            <tr *ngFor="let x of notaForm.get('items').controls;let i=index;trackBy:track" [formGroupName]="i">
             <!--  <ng-container  *ngFor="let x of getFormControls.controls; let i=index" >
              <tr [formGroupName]="i"> -->
                  <td>{{x.controls.IdAlunoNota.value}}</td>
                  <td style="width:550px; ">{{x.controls.Nome.value}}</td>

                  <td style="padding: 8px; ">
                    <input type="number" max="x.controls.VALORPROVA.value" required [style.color]="x.controls.MAC.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'" id="{{i}}"class="form-control fonte" formControlName="MAC"  [attr.disabled]="true" >
                    <mat-error *ngIf="getFormControls.controls[i].get('MAC')?.hasError('required')">MAC é obrigatório</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('MAC')?.hasError('max')">MAC não pode ser maior que {{x.controls.VALORPROVA.value}}.</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('MAC')?.hasError('min')">Valor minimo deve ser zero (0)</mat-error>

                  </td>

                  <td style="padding: 8px; ">
                    <input type="number" max="x.controls.VALORPROVA.value" required [style.color]="x.controls.NPP.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'" id="{{i}}"class="form-control fonte" formControlName="NPP"  [attr.disabled]="true">
                    <mat-error *ngIf="getFormControls.controls[i].get('NPP')?.hasError('required')">NPP é obrigatório</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('NPP')?.hasError('max')">NPP não pode ser maior que {{x.controls.VALORPROVA.value}}.</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('NPP')?.hasError('min')">Valor minimo deve ser zero (0)</mat-error>
                  </td>

                  <td style="padding: 8px; ">
                    <input type="number" max="x.controls.VALORPROVA.value" required [style.color]="x.controls.NPT.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'"id="{{i}}"class="form-control fonte" formControlName="NPT"  [attr.disabled]="true">
                    <mat-error *ngIf="getFormControls.controls[i].get('NPT')?.hasError('required')">NPT é obrigatório</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('NPT')?.hasError('max')">NPT não pode ser maior que {{x.controls.VALORPROVA.value}}.</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('NPT')?.hasError('min')">Valor minimo deve ser zero (0)</mat-error>
                  </td>

                  <td style="padding: 8px; ">
                    <input type="number" max="20" [style.color]="x.controls.MT.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'" id="{{i}}"class="form-control fonte" formControlName="MT"  [attr.disabled]="true">
                  </td>



                  <td style="padding: 8px; ">
                    <input type="number" max="x.controls.VALORPROVA.value" [style.color]="x.controls.Exame.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'"id="{{i}}"class="form-control fonte" formControlName="Exame"  [attr.disabled]="true">
                    <mat-error *ngIf="getFormControls.controls[i].get('Exame')?.hasError('required')">Exame é obrigatório</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('Exame')?.hasError('max')">Exame não pode ser maior que {{x.controls.VALORPROVA.value}}.</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('Exame')?.hasError('min')">Valor minimo deve ser zero (0)</mat-error>
                  </td>

                  <td style="padding: 8px; ">
                    <input type="number" max="x.controls.VALORPROVA.value" [style.color]="x.controls.Rec.value <= ((x.controls.VALORPROVA.value)/2 -0.6) ? 'red' : 'black'"id="{{i}}"class="form-control fonte" formControlName="Rec"  [attr.disabled]="true">
                    <mat-error *ngIf="getFormControls.controls[i].get('Rec')?.hasError('required')">Prova de Recurso é obrigatório</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('Rec')?.hasError('max')">Prova de Recurso não pode ser maior que {{x.controls.VALORPROVA.value}}.</mat-error>
                    <mat-error *ngIf="getFormControls.controls[i].get('Rec')?.hasError('min')">Valor minimo deve ser zero (0)</mat-error>
                  </td>

                  <td style="padding: 8px; ">
                    <input type="number"  [style.color]="x.controls.J.value >= 2 ? 'red' : 'black'"id="{{i}}"class="form-control fonte" formControlName="J"  [attr.disabled]="true">
                  </td>

                  <td style="padding: 8px; ">
                    <input type="number" [style.color]="x.controls.I.value >= 2 ? 'red' : 'black'"id="{{i}}"class="form-control fonte" formControlName="I"  [attr.disabled]="true">
                  </td>


                  <td>
                    <button id="E{{i}}" *ngIf="!x.controls.isEditable.value" class="btn btn-sm btn-success"  (click)="editRow(x, i)">Editar</button>
                    <button id="i" *ngIf="x.controls.isEditable.value" [disabled] = "!getFormControls.controls[i].valid" class="btn btn-sm btn-success mr-2"  (click)="doneRow(x, i)" >Guardar</button>
                    <button id="i" *ngIf="x.controls.isEditable.value" class="btn btn-sm btn-danger" (click)="cancelar(x, i)" >Cancelar</button>
                  </td>
              </tr>
          <!--   </ng-container> -->
          </tbody>
      </table>
 </div>
</form>

