import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, ContaCaixaService } from '@service/index';
import { BasePage } from '@shared/base-page';
import { GrupoCxa, TaxType, TipoProduto, TaxTableEntry } from '@core/models/index';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CurrencyPipe, DecimalPipe, formatCurrency } from '@angular/common';


@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.scss']
})
export class ProdutoFormComponent extends BasePage implements OnInit {
  [x: string]: any;

  @Input() public titulo: any;
  @Input() public conta: any;
  @Input() public isReadOnly: boolean = false;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector, public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private contaservice: ContaCaixaService,
    private currencyPipe: CurrencyPipe,
    private _decimalPipe: DecimalPipe,
    private utilservice: UtilsService) { super(injector); }

  //   tipoSistema: Observable<TaxAccountingBasis[]>;
  grupo: Observable<GrupoCxa[]>;
  tipoProduto: Observable<TipoProduto[]>;
  taxtype: Observable<TaxType[]>;

  amount: number = 0.0;
  formattedAmount: string = '';

  tipoImposto: TaxType[];
  imposto: TaxType[];
  vImposto: any;
  vMotivo: any;
  grupo2: any[] = [];
  vdisable: boolean = false;
  //any[] = [];

  ProdutoForm = this._formBuilder.group({
    IdContaCaixa: [''],
    Conta: ['', Validators.required],
    Valor: ['', Validators.required],
    // TipoConta: ['', Validators.required],
    CalcMulta: ['', Validators.required],
    Grupo: ['', Validators.required],
    CalcStock: [''],
    Parcela: [''],
    //  Descricao: ['', Validators.required],
    //  ValorSemIVA: ['', Validators.required],
    TipoProduto: ['', Validators.required],
    Imposto: ['', Validators.required],
    // PgtoNormal: [''],
    MotivoIsencao: [''],
    PrecoCIVA: ['']
  });


  ngOnInit(): void {
    this.loadData();
    this.utilservice.loadcaixa().pipe(takeUntil(this.destroySubject)).subscribe((res: any[]) => {
      this.grupo2 = res
    });

    if (this.isReadOnly) {
      this.loadById();
    }


    // =======================================================================

    /*   this.tipoImposto = this.utilservice.getTipoImposto().filter((item) => {
       return item.Id_Provincia === Number(this.ProdutoForm.controls.Id_Provincia.value);
     }); */

    this.utilservice.getImposto().pipe(takeUntil(this.destroySubject)).subscribe((res: any) => {
      this.vImposto = res
    })


    this.ProdutoForm.get('Imposto')?.valueChanges.subscribe(data => {
      this.vdisable = data > 0 ? true : false;
      this.vMotivo
      this.calculaIva();
      if (this.vdisable) { this.ProdutoForm.controls.MotivoIsencao.setValue("S0") }
      this.utilservice.reason(data).subscribe((res: any) => {
        this.vMotivo = res;
      })
    });



  }
  transform(value: any) {
    console.log(value);

    return formatCurrency(+value, 'pt-PT', "")
  }
  transformAmount(element: HTMLElement) {
    this.formattedAmount = this.currencyPipe.transform(this.amount, '');
    // Remove or comment this line if you dont want to show the formatted amount in the textbox.
    //  element.target.value = this.formattedAmount;
  }
  formatMoney(value: any) {
    const temp = `${value}`.replace(/\,/g, "");
    return this.currencyPipe.transform(temp).replace("$", "");
  }
  transformTotal(): void {
    const value = this.ProdutoForm.controls.Valor.value;
    this.ProdutoForm.controls.Valor.setValue(
      this.formatMoney(value.replace(/\,/g, "")),
      { emitEvent: false }
    );
  }
  public formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }
  loadById() {
    this.ProdutoForm.get('IdContaCaixa')?.setValue(this.conta.IdContaCaixa);
    this.ProdutoForm.get('Conta')?.setValue(this.conta.Conta);
    this.ProdutoForm.get('Imposto')?.setValue(this.conta.Imposto);
    this.ProdutoForm.get('Grupo')?.setValue(this.conta.Grupo.trim());
    this.ProdutoForm.get('TipoProduto')?.setValue(this.conta.TipoProduto);
    this.ProdutoForm.get('CalcMulta')?.setValue(this.conta.CalcMulta);
    this.ProdutoForm.get('PrecoCIVA')?.setValue(this.conta.PrecoCiva);
    this.ProdutoForm.get('MotivoIsencao')?.setValue(this.conta.MotivoIsencao);
    this.ProdutoForm.get('Valor')?.setValue(this.conta.Valor);
    this.ProdutoForm.get('CalcStock')?.setValue(this.conta.CalcStock);
    this.ProdutoForm.get('Parcela')?.setValue(this.conta.Parcela);
    this.ProdutoForm.get('Sigla')?.setValue(this.conta.Sigla);
  }
  calculaIva() {

    if (!isNaN(this.ProdutoForm.get('Valor')?.value) || !isNaN(this.ProdutoForm.get('Imposto')?.value)) {
      this.ProdutoForm.controls.PrecoCIVA.setValue(((this.ProdutoForm.get('Valor')?.value / (1 + this.ProdutoForm.get('Imposto')?.value / 100))))
      this.ProdutoForm.controls.Valor.setValue(this.ProdutoForm.get('Valor')?.value);
    }
  }
  onSubmit() {

    this.showLoadingView();
    this.ProdutoForm.controls.CalcStock.setValue((this.ProdutoForm.get('CalcStock')?.value == true ? 1 : 0))
    this.ProdutoForm.controls.Parcela.setValue((this.ProdutoForm.get('Parcela')?.value == true ? 1 : 0))
    this.contaservice.create(this.ProdutoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
      this.showMessage('Sucesso', 'Produto/Serviço Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
    }, error => {
      this.showMessage('Erro', 'Ocorreu um erro ao criar Produto/Serviço Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
    });

  }

  onUpdate() {
    this.ProdutoForm.controls.CalcStock.setValue((this.ProdutoForm.get('CalcStock')?.value == true ? 1 : 0))
    this.ProdutoForm.controls.Parcela.setValue((this.ProdutoForm.get('Parcela')?.value == true ? 1 : 0))
    this.contaservice.update(this.ProdutoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.showMessage('Sucesso', 'Produto/Serviço atualisar com sucesso', 'success').then(() => this.activeModal.close('Update click'));
    }, error => {
      this.showMessage('Erro', 'Ocorreu um erro ao atualisar Produto/Serviço, Tente novamente', 'error').then(() => this.activeModal.close('Update click'));
    });

  }

  loadData() {
    this.grupo = this.utilservice.listaGrupoCaixa();
    this.tipoProduto = this.utilservice.listaTipoProduto();
    this.taxtype = this.utilservice.listaTipoImposto();
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}



