import { Injectable } from '@angular/core';
import { delay, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env';

/* @Injectable({
  providedIn: 'root'
}) */

export class CrudGenericoService<T, ID> {
  //API_URL= "";
   constructor(protected http: HttpClient,private API_URL: any ) {}
   fullUrl = `${environment.API_URL_BASE}${environment.API_URL_PATH}`;
   fullUrlRecibo = `${environment.API_RECIBO_URL_BASE}${environment.API_URL_PATH}`;
  list() : Observable<T[]> {
    return this.http.get<T[]>(`${this.fullUrl}/${this.API_URL}`);
    /*   .pipe(
        delay(2000),
        tap(console.log)
      );*/
  }


/*   get<T>(endPoint: string, params:any = {}): Observable<T[]> {

    var httpParams: any = new HttpParams();
    if (params) {
      for (let k in params) {
        httpParams = httpParams.set(k, params[k]);
      }
      params = httpParams;
    }
    return this.http.get<T[]>(`${fullUrl}${endPoint}`, { params });
  } */

  loadByID(id: any) {
    return this.http.get<T>(`${this.fullUrl}${this.API_URL}/${id}`).pipe(take(1));
  }

  private create(record: T) {
    return this.http.post(`${this.fullUrl}${this.API_URL}`, record).pipe(take(1));
  }

  private update(record: T, id: ID) {
    return this.http.put(`${this.fullUrl}${this.API_URL}/${id}`, record).pipe(take(1));
  }

  save(record: T, id: ID) {
    if (id) {
      return this.update(record, id);
    }
    return this.create(record);
  }

  remove(id: any) {
    return this.http.delete(`${this.fullUrl}${this.API_URL}/${id}`).pipe(take(1));
  }
}
