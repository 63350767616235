import { FormularioModule } from '../formulario.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrocarAlunoTurmaRoutingModule } from './trocar-aluno-turma-routing.module';
//import { TrocarAlunoTurmaListComponent } from './trocar-aluno-turma-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { FormularioModule } from '../formulario.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TrocarAlunoTurmaRoutingModule,
    NgbModule,
    FormsModule,


  ]
})
export class TrocarAlunoTurmaModule { }
