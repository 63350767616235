import { Component, Input, OnInit, Injector } from '@angular/core';
import { BasePage } from '@shared/base-page';
import {MatSelectModule} from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, Validators,  AbstractControl,
  FormGroupDirective,
  ValidatorFn,
  ValidationErrors, } from '@angular/forms';
//import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal,  NgbDateStruct, NgbModalOptions, NgbModalRef, ModalDismissReasons, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
//import * as _moment from 'moment';
//import { Moment } from 'moment';
import {AlunoService, UtilsService, AlunoContactoService, AlunoDocumentoService, AlunoFilhacaoService} from '@service/index';
import {Sexo, Bairro} from '@core/models/index';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env';
import {DocumentoAlunoComponent, ContactoAlunoComponent,  MatriculaFormComponent} from '../index';
import { formatDate } from '@angular/common';
import { AlunoFilhacaoFormComponent } from '../aluno-filhacao-form/aluno-filhacao-form.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-aluno-form',
  templateUrl: './aluno-form.component.html',
  styleUrls: ['./aluno-form.component.scss']
})
export class AlunoFormComponent extends BasePage implements OnInit {
  [x: string]: any;

  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
              private _formBuilder: FormBuilder,private alunoFilhacaoService: AlunoFilhacaoService,
              private modalService: NgbModal, private documentoservice: AlunoDocumentoService,
              private alunoservice: AlunoService, private contactoservice: AlunoContactoService,
              private utilservice: UtilsService) { super(injector);  }


  alunoForm  =  this._formBuilder.group({
    IdAluno: [''],
    Nome: ['', Validators.required],
    DataNasc: ['', Validators.required],
    Endereco: ['', Validators.required],
   // Telefone: ['', Validators.required],
    idSexo: ['', Validators.required],
    IdFilhacao: [1],
    pathPictur: ["45587d99-8439-4e76-b7fb-28f0968e2513.jpg"],
    idPais: ['', Validators.required],
    idBairro: ['', Validators.required]

  });

  image1:any;
  imageUrl =  './assets/img/perfil.png'; // '/assets/img/Foto.jpg';
  public file: File;
  bairros: any[] = [];
 // sexo: any[] = [];
  pais: any[] = [];
  filhacao: any[] = [];
  filhacao2: any[] = [];
  alunoDocumento: any[] = [];
  AlunoContactos: any[] = [];
  fileToUpload: File | any = null;
  files: File[] = [];
  closeResult = '';
  sexo: Observable<Sexo[]>;
  Bairro2: Observable<Bairro[]>;
  selected = 'option2';
  @Input() public aluno: any;
  @Input() public isReadOnly: boolean = false;
  @Input() public titulo: any  = "Inserir Inquilino";

  //"strictPropertyInitialization": false
  ngOnInit(): void {
    this.sexo = this.utilservice.BuscaSexo();
    this.Bairro2 = this.utilservice.BuscaBairro();
    this.loadData();
    if (this.isReadOnly) {
      this.loadById();
    }
    console.log(this.alunoForm.value);

  }

  loadById() {
    const date = moment(this.aluno.DataNasc).format('DD/MM/yyyy');
    const ngbDate: NgbDateStruct = {
      year: parseInt(date.split('/')[2]),
      month: parseInt(date.split('/')[1]),
      day: parseInt(date.split('/')[0]),
    }
    this.AlunoContactos = this.aluno.AlunoContactos;
    this.alunoDocumento = this.aluno.Alunodocumentos;
    this.alunoForm.get('IdAluno')?.setValue(this.aluno.IdAluno);
    this.alunoForm.get('Nome')?.setValue(this.aluno.Nome);

//this.alunoForm.get('DataNasc')?.setValue(ngbDate);
    this.alunoForm.get('DataNasc')?.setValue(this.aluno.DataNasc);

    this.alunoForm.get('Endereco')?.setValue(this.aluno.Endereco);
    this.alunoForm.get('idSexo')?.setValue(this.aluno.Genero.IdSexo);
    this.alunoForm.get('pathPictur')?.setValue(this.aluno.PathPictur);
    this.alunoForm.get('idPais')?.setValue(24);
    this.alunoForm.get('IdFilhacao')?.setValue(1);
    this.alunoForm.get('idBairro')?.setValue(this.aluno.Bairros.IdBairro);
    this.alunoForm.get('pathPictur')?.setValue("45587d99-8439-4e76-b7fb-28f0968e2513.jpg");
  // console.log( this.alunoForm );

this.alunoForm.valueChanges.subscribe(value => {console.log(value)}
)
   if (this.aluno.PathPictur)
       this.imageUrl = environment.API_URL_BASE + `/image/${this.aluno.PathPictur}`;
    else
      this.imageUrl = './assets/img/perfil.png';

  }

  onKey(value: string) {
    this.filhacao = this.search2(value);
  }

  search2(value: string) {
    let filter = value.toLowerCase();
     return this.filhacao2.filter((option: any) =>  option.Pai.toLowerCase().includes(filter)  ||  option.Mae.toLowerCase().includes(filter)
    );}

  onAddDocumento(documento: any) {
    const modalref =  this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
       this.loadDoc();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.loadDoc();
    });
  }

  onEditDocumento(documento: any){
    const modalref = this.modalService.open(DocumentoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.documento = documento;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadDoc();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.loadDoc();
    });
  }

  onAddContacto(contacto: any) {
    console.log(contacto);
    const modalref =  this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = contacto;
    modalref.result.then((result) => {
      this.loadContacto();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  addFilhacao() {
    const modalref =  this.modalService.open(AlunoFilhacaoFormComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.isReadOnly = false;
    modalref.result.then((result) => {
      this.loadData();
    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadData();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEditContacto(employee: any){
    const modalref = this.modalService.open(ContactoAlunoComponent, { ariaLabelledBy: 'modal-basic-title' });
    modalref.componentInstance.contacto = employee;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadContacto();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadContacto();
    });
  }

  loadContacto() {
      this.contactoservice.loadByIdAluno(this.aluno.IdAluno)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((response) => {
        this.AlunoContactos = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  loadDoc() {
      this.documentoservice.loadByIdAluno(this.aluno.IdAluno)
      .pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.alunoDocumento = response;
      }, error => {
        this.showMessage('error', 'erro ao carregar dados', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  onFileChange(event:any) {
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileToUpload = this.files[0];
      reader.readAsDataURL(this.files[0]);

      reader.onload = () => {

        this.imageUrl = reader.result as string;
         this.alunoForm.patchValue({
          pathPictur: this.fileToUpload.name
        });
      };

    }
  }


  onSubmit() {
    if (this.files.length > 0) {
      this.fileToUpload = this.files[0];
      this.submitFileAndCreteEmployee();
    } else {
      this.showLoadingView();
      this.alunoForm.get('IdFilhacao')?.setValue(1);
      this.alunoForm.get('pathPictur')?.setValue("45587d99-8439-4e76-b7fb-28f0968e2513.jpg");
      this.alunoForm.get('DataNasc')?.setValue(new Date(this.alunoForm.get('DataNasc')?.value).toUTCString());
      this.alunoservice.create(this.alunoForm.value).subscribe((response) => {
        this.showMessage('Sucesso', 'Inquilino Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        console.log(error.message);

        this.showMessage('Erro', 'Ocorreu um erro ao criar Inquilino Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
    }

  }

  onUpdate() {
    if (this.files.length > 0) {
      this.fileToUpload = this.files[0];
      this.submitFileAndUpdateEmployee();
    } else {
      this.showLoadingView();
      this.alunoForm.get('pathPictur')?.setValue("45587d99-8439-4e76-b7fb-28f0968e2513.jpg");
      this.alunoForm.get('DataNasc')?.setValue(new Date(this.alunoForm.get('DataNasc')?.value).toUTCString());
      this.alunoservice.update(this.alunoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response) => {
        this.showMessage('Sucesso', 'Inquilino atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizar Inquilino, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });

    }
  }

  
  submitFileAndUpdateEmployee() {
    this.showLoadingView();
    this.utilservice.fileUpload(this.fileToUpload).pipe(takeUntil(this.destroySubject)).subscribe(response => {
       const fs = response as string;
       fs.replace('"',"");
      this.alunoForm.get('pathPictur')?.setValue(fs);
      this.alunoForm.get('DataNasc')?.setValue(formatDate(this.alunoForm.get('DataNasc')?.value,'yyy-MM-dd','en-Us'));
      this.alunoservice.update(this.alunoForm.value).subscribe((response) => {
        this.showMessage('Sucesso', 'Inquilino atualizdo com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualisar Inquilino, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
    }, error => {
      console.log(error);
      this.showMessage('Erro', 'Ocorreu um erro ao enviar ficheiro para o servidor, Tente novamente', 'error');
    });
  }

  submitFileAndCreteEmployee() {
    this.showLoadingView();
    this.utilservice.fileUpload(this.fileToUpload).pipe(takeUntil(this.destroySubject)).subscribe(response => {
    this.alunoForm.get('pathPictur')?.setValue(response);
    this.alunoForm.get('DataNasc')?.setValue(new Date(this.alunoForm.get('DataNasc')?.value).toUTCString());
    this.alunoservice.create(this.alunoForm.value).subscribe((response) => {
        this.showMessage('Sucesso', 'Inquilino Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, error => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Inquilino Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
    }, error => {
      this.showMessage('Erro', 'Ocorreu um erro ao enviar ficheiro para o servidor, Tente novamente', 'error');
    });

  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

  compareFunctionArea(o1: any, o2: any) {
    return (o1.IdArea == o2);
  }

  compareFunctionFunc(o1: any, o2: any) {
    return o1.IdFuncao == o2;
  }

    // begin Implementation of ng-dropZone
    onSelect(event: any) {
      this.files.push(...event.addedFiles);
    }

    onRemove(event: any) {
      this.files.splice(this.files.indexOf(event), 1);
    }
    // End of Dropzone

    loadData() {
      this.utilservice.loadBairros({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.bairros = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });

      this.alunoFilhacaoService.loadAll({}).subscribe((response: any[]) => {
       this.filhacao = response;
       this.filhacao2 = response;
      }
      , (error: any) => {
        console.log('Error on request', error);
      });

      this.utilservice.loadPais({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any[]) => {
        this.pais = response;
      }, (error: any) => {
        console.log('Error on request', error);
      });
    }

    ngOnDestroy() {
      // Unsubscribe from all observables
      this.destroySubject.next();
      this.destroySubject.complete();
    }

}
