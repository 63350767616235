import { Component,  Injector,  Input,  OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TurmaService,UtilsService, ClasseDisciplinaService, ProfessorService } from '@core/services/index';
import { Subject } from 'rxjs';
import { BasePage } from '@shared/base-page';
import { debounceTime } from 'rxjs/operators';
import { ClasseDisciplina } from '@core/models';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-professor-disciplinas',
  templateUrl: './professor-disciplinas.component.html',
  styleUrls: ['./professor-disciplinas.component.scss'],
  encapsulation: ViewEncapsulation.None // Add this line
})
export class ProfessorDisciplinasComponent extends BasePage implements OnInit {
  [x: string]: any;
  @Input() idFuncionario: any;
  constructor(injector: Injector ,private turmaservice: TurmaService,
                                  private _formBuilder: FormBuilder,
                                  private classedisciplinaService: ClasseDisciplinaService,
                                  private professorService: ProfessorService,
              private utilservice: UtilsService) {
    super(injector);
  }

      matriculaForm  =  this._formBuilder.group({
        IdFuncionario: [''],
        Anolectivo: ['', Validators.required],
        IdAluno: ['', Validators.required],
        IdTurma: ['', Validators.required],
        NumAlunoTurma: [''],
        DataMatricula: [''],
        IdSituacaoMatricula: ['', Validators.required],
        NumParcelas: ['', Validators.required],
        PrimeiroMes: ['', Validators.required],
        IdTipoAluno: ['', Validators.required],
        dropDisciplina: ['', Validators.required],
      });
      Turmas:any;
      localTurmas;
      disciplina: any;
      disciplinaProf: any;
      public page = 1;
      public pageSize = 5;
      public page2 = 1;
      public pageSize2 = 5;
      config: any;
      termoBuscaChanged: Subject<string> = new Subject<string>();
      collectionSize = 0;
      collectionSize2 = 0;
      dropdownSettings = {};
      vdisciplina2: { IdFuncionario: any; IdTurma: any; IdDisciplina: [] }[] = [];
      tableData = {};



  ngOnInit(): void {
    this.fillTurmas();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'IdDisciplina',
      textField: 'Disciplina1',
      selectAllText: 'Selecionar tudo',
      unSelectAllText: 'Desmarcar tudo',
      // itemsShowLimit: 10,
      searchPlaceholderText: 'Consultar',
      allowSearchFilter: true,
    };
    this.matriculaForm.get('IdTurma')?.valueChanges.subscribe(data => {
      console.log(data);
     this.turmaById(data);



    })

    this.carregaDisciplinaDoProfessor(this.idFuncionario)
  }


  onItemSelect(item: any) : void {
     this.vdisciplina2.push({
      IdFuncionario: this.idFuncionario,
      IdTurma: this.matriculaForm.get('IdTurma')?.value,
      IdDisciplina: item.IdDisciplina,
    });

    this.tableData = {
      /* IdFuncionario: this.idFuncionario,
      IdTurma: this.matriculaForm.get('IdTurma')?.value, */
      ClasseDisciplinas: this.vdisciplina2,
    };
    console.log(this.vdisciplina2);
  }

  onItemDeSelect(dado: any) {
    this.vdisciplina2.splice(this.vdisciplina2.indexOf(dado.IdDisciplina), 1);
    this.tableData = {
     /*  IdFuncionario: this.idFuncionario,
      IdTurma: this.matriculaForm.get('IdTurma')?.value, */
      ClasseDisciplinas: this.vdisciplina2,
    };
    console.log(this.vdisciplina2);
  }

  adicionarDisciplina() {
    this.showLoadingView();
    this.professorService.addProfessorDisciplina(this.vdisciplina2).subscribe(
      (response: any) => {
        console.log('Disciplina adicionado', response);
        this.carregaDisciplinaDoProfessor(this.idFuncionario);
        this.matriculaForm.get('dropDisciplina')?.setValue([]);
        this.showMessage( 'Sucesso', 'Disciplina(s) adicionado(s) com sucesso', 'success'
        ).then(() => this.activeModal.close('Save click'));
      },
      (error: any) => {
        console.log('Error create employee', error);
        this.showMessage( 'Erro', 'Ocorreu um erro ao adicionar disciplina(s) Tente novamente', 'error'
        ).then(() => this.activeModal.close('Save click'));
      }
    );
  }


  carregaDisciplinaDoProfessor(id: any) {
    // this.showLoadingView();
     this.professorService.ListaProfessorDisciplina(id).subscribe((response: any) => {
         this.disciplinaProf = response
         ;
         if (this.disciplinaProf) {
          this.collectionSize = this.disciplinaProf.length;
        }
         console.log('Disciplina carregado com sucesso', response
         );
  //       this.showContentView();
       },
       (error) => { console.log('Error a efetuar requisicao', error);
       }
     );
   }

   confirmBox(id: any){
    Swal.fire({
      title: 'Deseja Eliminar esta disciplina?',
     // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.ApagarDisciplinaProfessor(id);

        /* Swal.fire(
          'Transitado!',
          'Os Alunos foram transitado de classe com sucesso.',
          'success'
        ) */
      }

     /*  else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      } */

    })
  }

  ApagarDisciplinaProfessor(id: any) {
    // this.showLoadingView();
     this.professorService.apagarDisciplina(id).subscribe((response: any) => {
      this.carregaDisciplinaDoProfessor(this.idFuncionario)
      this.showMessage( 'Sucesso', 'Disciplina eliminado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
  //       this.showContentView();
       },
       (error) => { console.log('Error a efetuar requisicao', error);
       this.showMessage( 'Erro', 'Ocorreu um erro ao eliminar disciplina Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
       }
     );
   }

   turmaById(id: any) {
    // this.showLoadingView();
     this.turmaservice.loadById(id).subscribe((response: any) => {
       //  this.disciplina = response;

       this.loadClasseDisciplina(response.Classe.IdClasse)
         console.log('Disciplina carregado com sucesso', response
         );
  //       this.showContentView();
       },
       (error) => { console.log('Error a efetuar requisicao', error);
       }
     );
   }

  loadClasseDisciplina(id: any) {
   // this.showLoadingView();
    this.classedisciplinaService.loadByIdClasse(id).subscribe((response: any) => {
        this.disciplina = response
        ;
        console.log('Disciplina carregado com sucesso', response
        );
 //       this.showContentView();
      },
      (error) => { console.log('Error a efetuar requisicao', error);
      }
    );
  }

  applyFilter(filterValue: string) {
    if (this.termoBuscaChanged.observers.length === 0) {
      this.termoBuscaChanged
        .pipe(debounceTime(1000))
        .subscribe((filtrarPor) => {
          this.config.filter = filtrarPor;
          this.filtro();
        });
    }
    this.termoBuscaChanged.next(filterValue);
 }


filtro(){
  this.config.filter = this.config.filter == null ? this.config.filter = "" : this.config.filter
  this.config.ItemsPerPage = this.pageSize;
  this.fillTurmas();
}

fillTurmas() {
this.showLoadingView();
//this.config.itemsPerPage = 25;
this.turmaservice.filtro(this.config).subscribe((response: any) => {
  console.log(response);
    this.localTurmas = response.data;
    this.Turmas = response.data;
  //  this.pagination = response.pagination;
    this.config = {
                  currentPage: response.currentPage,
                  page: 1,
                  filter: response.pagination.filter,
                  totalItems: response.total
                  }
   this.collectionSize2 = response.total;
    this.showContentView();
  }, error => {
    console.log('Error a efetuar requisicao', error);
   // this.showLoadingView();
  });
}

pageChanged(event: any) {
  this.config.page = event;
  if (this.config.filter= null){
    this.config.filter="";
  }
  this.filtro();
 }

}
