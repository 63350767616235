<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>

<!-- <hr> -->

<form [formGroup]="ProdutoForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">

<!--             <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Nome</mat-label>
                  <input id="Nome" type="text" formControlName="idFilhacao" hidden>
                  <input matInput formControlName="Nome" maxlength="50" />
                  <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                  <mat-error *ngIf="ProdutoForm.get('Nome')?.hasError('required')"> Nome é obrigatório. </mat-error>
              </mat-form-field>
            </div> -->
<!--             <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Nacionalidade</mat-label>
                  <mat-select  name="idPais"  formControlName="idPais">
                    <mat-option *ngFor="let s of pais" [value]="s.IdPais">
                      {{s.Nacionalidade}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="ProdutoForm.get('idPais')?.hasError('required')">Nacionalidade é obrigatório</mat-error>
                </mat-form-field>
            </div> -->
            <div class="col-md-12">
              <mat-form-field appearance="outline" >
                  <mat-label>Grupo</mat-label>
                  <mat-select  name="idGrupo"  formControlName="Grupo">
                    <mat-option *ngFor="let g of grupo | async" [value]="g.Codigo">
                      {{g.Descricao}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="ProdutoForm.get('Grupo')?.hasError('required')">Grupo é obrigatório</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Tipo</mat-label>
                    <mat-select  name="TipoProduto"  formControlName="TipoProduto">
                      <mat-option *ngFor="let g of tipoProduto | async" [value]="g.ProductTypeCode">
                        {{g.ProductTypeDescrition}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ProdutoForm.get('TipoProduto')?.hasError('required')">Tipo é obrigatório</mat-error>
                  </mat-form-field>
              </div>

           <!--  <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Tipo Imposto</mat-label>
                    <mat-select  name="Imposto"  formControlName="Imposto">
                      <mat-option *ngFor="let g of taxtype | async" [value]="g.TaxType1">
                        {{g.Descricao}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ProdutoForm.get('Grupo')?.hasError('required')">Grupo é obrigatório</mat-error>
                  </mat-form-field>
              </div> -->

              <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Imposto</mat-label>
                    <mat-select  name="Imposto"  formControlName="Imposto">
                      <mat-option *ngFor="let g of vImposto" [value]="g.PrimaryKey">
                        {{g.Description}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ProdutoForm.get('Imposto')?.hasError('required')">Imposto é obrigatório</mat-error>
                  </mat-form-field>
              </div>


              <div class="col-md-12">
                <mat-form-field appearance="outline"  >
                    <mat-label>Motivo Isenção</mat-label>
                    <mat-select  name="MotivoIsencao"  formControlName="MotivoIsencao" [disabled] = "vdisable" >
                      <mat-option *ngFor="let g of vMotivo" [value]="g.TaxExemptionCode">
                        {{g.TaxExemptionReason1}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ProdutoForm.get('MotivoIsencao')?.hasError('required')">Motivo Isenção é obrigatório</mat-error>
                  </mat-form-field>
              </div>



              <div class="col-md-6">
                <mat-form-field appearance="outline" hidden>
                    <mat-label>Codigo</mat-label>
                    <input matInput formControlName="Conta"  />
                   <!--  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon > -->
                    <mat-error *ngIf="ProdutoForm.get('Conta')?.hasError('required')"> Codigo é obrigatório. </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Descriçao</mat-label>
                    <input matInput formControlName="Conta"  />
                   <!--  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon > -->
                    <mat-error *ngIf="ProdutoForm.get('Conta')?.hasError('required')"> Descrição é obrigatório. </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field appearance="outline" >
                    <mat-label>Valor Final</mat-label>
                    <input matInput formControlName="Valor" (blur)="calculaIva()" (blur)="transform($event)"   />
                   <!--  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon > -->
                    <mat-error *ngIf="ProdutoForm.get('Valor')?.hasError('required')"> Valor Final é obrigatório. </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field appearance="outline"  >
                    <mat-label>Valor S/IVA</mat-label>
                    <input matInput formControlName="PrecoCIVA" (blur)="calculaIva()" />
                   <!--  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon > -->
                    <mat-error *ngIf="ProdutoForm.get('PrecoCIVA')?.hasError('required')"> Descrição é obrigatório. </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field appearance="outline" >
                    <mat-label>Calcula Multa?</mat-label>
                    <mat-select  name="CalcMulta"  formControlName="CalcMulta">
                      <mat-option value="SIM">Sim</mat-option>
                      <mat-option value="NÃO">Não</mat-option>

                      <!-- <mat-option *ngFor="let g of taxtype | async" [value]="g.TaxType1">
                        {{g.Descricao}}
                      </mat-option> -->
                    </mat-select>
                    <mat-error *ngIf="ProdutoForm.get('Grupo')?.hasError('required')">Grupo é obrigatório</mat-error>
                  </mat-form-field>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="form-group col-12">
                    <mat-slide-toggle formControlName="CalcStock">Controlar o stock</mat-slide-toggle>
                </div>
                <div class="form-group col-12">
                    <mat-slide-toggle formControlName="Parcela">Parcela</mat-slide-toggle>
                </div>
            </div>
             <!--  <div class="col-md-4">
              <label for="vehicle1">Controlar o stock</label>
            </div> -->
           <!--    <div class="col-md-5">
                    <input type="checkbox"  id="PgtoNormal" data-toggle="switchbutton" data-onlabel="SIM" data-offlabel="NÃO" data-onstyle="success" data-offstyle="secondary"
                    data-style="mr-1"    formControlName="PgtoNormal">
                </div> -->

               <!--  <div class="col-md-2">
                  <label for="vehicle1">Parcela</label>
                </div>
                  <div class="col-md-1">
                        <input type="checkbox"  id="Parcela" data-toggle="switchbutton" data-onlabel="SIM" data-offlabel="NÃO" data-onstyle="success" data-offstyle="secondary"
                        data-style="mr-1"    formControlName="Parcela">
                    </div> -->
<!--             <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <mat-select name="idBairro" readonly formControlName="idBairro" required>
                  <mat-option>-seleccione-</mat-option>
                  <mat-option *ngFor="let n of Bairro2 | async" [value]="n.IdBairro">
                    {{n.Bairro1}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="ProdutoForm.get('idBairro')?.hasError('required')" > Bairro é obrigatório. </mat-error>
              </mat-form-field>
            </div>


             <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Endereço</mat-label>
                  <input matInput formControlName="Endereco"  />
                  <mat-icon matSuffix class="secondary-text" >location_on</mat-icon >
                  <mat-error *ngIf="ProdutoForm.get('Endereco')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Pai</mat-label>
                  <input matInput maxlength="100" />
                  <mat-icon matSuffix class="secondary-text" >supervised_user_circle</mat-icon >
                  <mat-error *ngIf="ProdutoForm.get('Telefone')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" >
                  <mat-label>Mãe</mat-label>
                  <input matInput  maxlength="100" />
                  <mat-icon matSuffix class="secondary-text" >supervisor_account</mat-icon >
                  <mat-error *ngIf="ProdutoForm.get('Telefone')?.hasError('required')"> Endereço é obrigatório. </mat-error>
              </mat-form-field>
            </div> -->

        </div>

        <!--  {{ ProdutoForm.value | json }}  -->
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!ProdutoForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" >Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!ProdutoForm.valid">Atualizar</button>
    </div>
</form>
