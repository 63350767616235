<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<!-- <hr> -->

<form [formGroup]="ClasseForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
    <div class="modal-body">
        <div class="row">

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Edifício</mat-label>
                    <input matInput formControlName="Classe" maxlength="50" />
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="ClasseForm.get('Classe')?.hasError('required')"> Edificio é obrigatório.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo Imóvel</mat-label>
                    <mat-select name="IdNivelEnsino" formControlName="IdNivelEnsino">
                        <mat-option *ngFor="let s of nivelensino | async" [value]="s.IdNivelEnsino">
                            {{s.NivelEnsino}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ClasseForm.get('IdNivelEnsino')?.hasError('required')">Tipo Imóvel é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>

           <!--  <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Curso</mat-label>
                    <mat-select name="IdCurso" formControlName="IdCurso">
                        <mat-option *ngFor="let s of curso | async" [value]="s.IdCurso">
                            {{s.Curso1}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ClasseForm.get('IdCurso')?.hasError('required')">Curso é obrigatório</mat-error>
                </mat-form-field>
            </div> -->

         <!--    <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Opção do Curso</mat-label>
                    <mat-select name="CodigoOpcao" formControlName="CodigoOpcao">
                        <mat-option *ngFor="let s of opcao | async" [value]="s.CodigoOpcao">
                            {{s.DescricaoOpcao}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ClasseForm.get('CodigoOpcao')?.hasError('required')">Opção é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
 -->
         <!--    <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Língua Estrangeira</mat-label>
                    <mat-select name="CodigoLingua" formControlName="CodigoLingua">
                        <mat-option *ngFor="let s of lingua | async" [value]="s.CodigoLingua">
                            {{s.DescricaoLingua}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ClasseForm.get('CodigoLingua')?.hasError('required')">Língua Estrangeira é
                        obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                  <mat-label>Tipo Classe</mat-label>
                  <mat-select name="IdTipoClasse" formControlName="IdTipoClasse">
                      <mat-option *ngFor="let s of tipoClasse " [value]="s.IdTipoClasse">
                          {{s.TipoClasse}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="ClasseForm.get('IdTipoClasse')?.hasError('required')">Tipo Classe é obrigatório
                  </mat-error>
              </mat-form-field>
          </div> -->

<!--             <div class="col-md-12">
                <div class="card" style="width: 100%;">
                    <div class="card-header">
                        <span class="cart-span-title">Classe Disciplinas</span>


                    </div>

                    <div class="card-body table-responsive ">
                    <br>
                  <form [formGroup] ="dropform">
                    <div class="row">
                      <div class="col-md-9 text-right">
                        <div>
                          <ng-multiselect-dropdown  [placeholder]="'Pesquisar'" formControlName="dropDisciplina"
                            [settings]="dropdownSettings" [data]="disciplina" [disabled]="!isReadOnly" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                          </ng-multiselect-dropdown>
                        </div>

                      </div>

                      <div class="col-md-3 text-right" style="padding-right: 15px;">
                        <div>
                          <button type="button" class="btn btn-primary" [disabled]="dropform.value.dropDisciplina.length ==0" [disabled]="!isReadOnly" (click)="adicionarDisciplina()"> Incluir Disciplina</button>
                        </div>
                      </div>
                    </div>
                  </form>
                        <form class="content-table" [formGroup]="ToDoListForm">

                            <div formArrayName="items">
                                <table class="table  table-inverse">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th>Disciplina</th>
                                            <th>Ordem</th>
                                            <th>Opções</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let x of ToDoListForm.get('items').controls;let i=index;trackBy:track" [formGroupName]="i">
                                            <td><input class="form-control" formControlName="completed" style="width:540px;" disabled readonly></td>
                                            <td><input id="{{i}}"class="form-control" formControlName="Ordem" style="width:60px; position: center;" readonly></td>
                                            <td>
                                                <mat-icon id="E{{i}}" *ngIf="!x.controls.isEditable.value" class="edit"  (click)="toggleReadOnly(i)" (click)="editRow(x)">edit</mat-icon>
                                                                                                <mat-icon id="i" *ngIf="x.controls.isEditable.value" class="delete" (click)="deleteRow(i, x.controls.IdClasseDisciplina.value)">delete_forever</mat-icon>
                                                <mat-icon id="i" *ngIf="x.controls.isEditable.value" class="done" (click)="desabilitar(i)" (click)="doneRow(x)">done</mat-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                           </div>
                        </form>
                    </div>
                </div>

            </div> -->

        </div>
        <!--  {{ ClasseForm.value | json }}  -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button> <!-- (click)="activeModal.close('Save click')" -->
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!ClasseForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" class="btn btn-success" *ngIf="!isReadOnly"  [disabled]="!ClasseForm.valid">Guardar</button>
        <!-- *ngIf="!isReadOnly" [disabled]="!ClasseForm.valid " -->
        <button type="submit" class="btn btn-success" *ngIf="isReadOnly"  [disabled]="!ClasseForm.valid">Atualizar</button>
        <!-- *ngIf="isReadOnly"  [disabled]="!ClasseForm.valid" -->
    </div>
</form>

