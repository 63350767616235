
import { DecimalPipe } from '@angular/common';
import { Component, Injector, OnInit, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TipoDoc } from '@core/models/index';
import { TipodocService } from '@service/index';
import { BasePage } from '@shared/base-page'
//import { TipoDocumentoFormComponent } from '../tipo-pagamento-form/tipo-pagamento-form.component';
import { TipodocFormComponent } from '../tipodoc-form/tipodoc-form.component';
import { Publico } from '@shared/publico';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tipodoc-list',
  templateUrl: './tipodoc-list.component.html',
  styleUrls: ['./tipodoc-list.component.scss']
})
export class TipodocListComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector,
    private modalService: NgbModal,
    private publico: Publico,
    public activeModal: NgbActiveModal,
    public pipe: DecimalPipe,
    private tipoDocService: TipodocService
  ) {
    super(injector);
  }

  tipoDoc: TipoDoc[] = [];
  localTipoDocumento: TipoDoc[] = [];
  public page = 1;
  public pageSize = 5;
  collectionSize = 0;
  closeResult = '';
  filter = new FormControl('');

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.loadData();
    this.filter.valueChanges.pipe(
      startWith(' '),
      map(text => this.search(text, this.pipe))
    ).subscribe(res => {
      console.log(res);
      this.tipoDoc = res;
      this.collectionSize = res.length;
    });
  }

  loadData() {
    this.showLoadingView();
    this.tipoDocService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.localTipoDocumento = response;
      this.tipoDoc = response;
      if (this.localTipoDocumento && this.localTipoDocumento.length) {
        this.collectionSize = this.localTipoDocumento.length;
      }
      this.showContentView();
    }, error => {
      console.log('Error a efetuar requisicao', error);
      // this.showLoadingView();
    });
  }

  search(text: string, pipe: PipeTransform): TipoDoc[] {
    return this.localTipoDocumento.filter(disc => {
      const term = text.toLowerCase();
      return disc.Cabecario.toLowerCase().includes(term)
          || disc.Descricao.toLowerCase().includes(term)

        ;
    });
  }

  onAdd() {
    this.modalService.open(TipodocFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadData();
    }
      , (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.loadData();
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEdit(employee: any) {
    const modalref = this.modalService.open(TipodocFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalref.componentInstance.data = employee;
    modalref.componentInstance.title = 'Alterar';
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log('Modal close result', this.closeResult);
      this.loadData();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log('Modal close result', this.closeResult);
      this.loadData();
    });
  }

  onDelete(id: any) {
    this.showLoadingView();
    this.tipoDocService.delete(id).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      this.loadData();
      this.showMessageWithConfirm('Sucesso', 'Tipo de pagamento apagado com sucesso', 'success').then(() => { this.activeModal.close('Update click') });
    }, (error: any) => {
      this.loadData();
      this.showMessage('Erro', 'Ocorreu um erro ao apagar Tipo de Pagamento, Tente novamente', 'error').then(() => this.activeModal.close('Update click'));
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
