import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
@Injectable({
  providedIn: 'root'
})
export class TipoReciboService {

  constructor(private api: ApiService) { }

  loadAll(params: any){
    return this.api.get('/TipoRecibo', params);
  }

  update(formContent: any){
    return this.api.actualizar('/TipoRecibo', formContent);
  }

  loadById(id: any) {
    return this.api.get(`/TipoRecibo/${id}`);
  }
}

