import {  Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService, CursoService } from '@service/index';
import {BasePage} from '@shared/base-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-curso-form',
  templateUrl: './curso-form.component.html',
  styleUrls: ['./curso-form.component.scss']
})
export class CursoFormComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector , public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
    private cursoService: CursoService,
    private utilservice: UtilsService) { super(injector);  }

     CursoForm   =  this._formBuilder.group({
      IdCurso: [''],
      Curso1: ['', Validators.required],
      Coordenador: ['', Validators.required]
    });

    @Input() public curso: any;
    @Input() public title: string = 'Registrar';
    @Input() public isReadOnly: boolean = false;

  ngOnInit(): void {
       if (this.isReadOnly) {
        this.loadById();
      }
  }

  loadById() {
    this.CursoForm.get('IdCurso')?.setValue(this.curso.IdCurso);
    this.CursoForm.get('Curso1')?.setValue(this.curso.Curso1);
    this.CursoForm.get('Coordenador')?.setValue(this.curso.Coordenador);
  }

  onSubmit() {
      this.showLoadingView();
      this.cursoService.create(this.CursoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Curso Criado com sucesso', 'success').then(() => this.activeModal.close('Save click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao criar Curso Tente novamente', 'error').then(() => this.activeModal.close('Save click'));
      });
  }

  onUpdate() {
      this.showLoadingView();
      this.cursoService.update(this.CursoForm.value).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.showMessage('Sucesso', 'Curso atualizado com sucesso', 'success').then(()=> this.activeModal.close('Update click'));
      }, (error: any) => {
        this.showMessage('Erro', 'Ocorreu um erro ao atualizado Curso, Tente novamente', 'error').then(()=> this.activeModal.close('Update click'));
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}
