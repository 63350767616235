import { CUSTOM_ELEMENTS_SCHEMA, forwardRef, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { LoginLayoutComponent } from './pages/layout/login-layout/login-layout.component';
import { HomeLayoutComponent } from './pages/layout/home-layout.component';
//import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
//import { NavComponent } from './pages/nav/nav.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginLayoutComponent } from './pages/layout/login-layout.component';

import { JwtInterceptor } from '@core/index';
import { CurrencyPipe, DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';

import { SidebarModule } from 'ng-sidebar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BreadcrumbModule } from 'angular-crumbs';
import { ModalPropinaComponent } from './pages/modal-propina/modal-propina.component';
import { PersonSearchPipe } from './pipe/person-search.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import localePt from '@angular/common/locales/pt';
import { SidenavComponent } from './pages/sidenav/sidenav.component';
import { HeaderComponent } from './pages/header/header.component';

registerLocaleData(localePt);
import * as moment from 'moment';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { CaixaComponent } from './components/caixa/caixa.component';
import { BloqueioComponent } from './components/aluno-form/bloqueio/bloqueio.component';
import { Publico } from './shared/publico';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlteraSenhaComponent } from './pages/login/altera-senha.component';
import { AlunoFilhacaoListComponent } from './components/aluno-filhacao-list/aluno-filhacao-list.component';
//import { DocumentosSecretariaListComponent } from './components/DocumentosSecretaria/documentos-secretaria-list/documentos-secretaria-list.component';
//import { TrocarAlunoTurmaListComponent } from './components/trocar-aluno-turma-list/trocar-aluno-turma-list.component';
import { TranssitarAlunoClasseListComponent } from './components/transsitar-aluno-classe-list/transsitar-aluno-classe-list.component';
import { ConsultaDropDownComponent } from './shared/consulta-drop-down/consulta-drop-down.component';
import { PropinaComponent } from './components/propina/propina.component';
import { PagamentosComponent } from './components/pagamentos/pagamentos.component';
import { MyTootipDirective } from './shared/my-tootip.directive';
import { TooltipModule } from './shared/tooltip/tooltip.module';
import { FuncionarioFormComponent } from './components/Funcionario/funcionario-form/funcionario-form.component';
import { FuncionarioListComponent } from './components/Funcionario/funcionario-list/funcionario-list.component';
import { FuncionarioDadosPessoaisComponent } from './components/Funcionario/funcionario-dados-pessoais/funcionario-dados-pessoais.component';
import { ProfessorDisciplinasComponent } from './components/Funcionario/professor-disciplinas/professor-disciplinas.component';
import { DocumentosContactosComponent } from './components/Funcionario/documentos-contactos/documentos-contactos.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { NavComponent } from './pages/nav/nav.component';
import { FormularioModule } from './components';
import { PedagogicoModule } from './pages/pedagogico/pedagogico.module';
import { DisciplinaModule } from './components/disciplina/disciplina.module';
//import { FuncionarioRoutingModule } from './components/Funcionario/func-routing.module';
//import { TrocarAlunoTurmaListComponent } from './components/trocar-aluno-turma-list/trocar-aluno-turma-list.component';

const  MODULE = [ BrowserModule, AppRoutingModule, TooltipModule, BreadcrumbModule, BrowserAnimationsModule,MatSnackBarModule,
  FormularioModule, HttpClientModule, NgCircleProgressModule.forRoot(), NgMultiSelectDropDownModule.forRoot(),
  FormsModule, FontAwesomeModule, NgbModule, PedagogicoModule, SidebarModule.forRoot(), TabsModule.forRoot()];

const COMPONENTS = [AppComponent, NavComponent ,DashboardComponent, LayoutComponent, LoginComponent, LoginLayoutComponent,
  HomeLayoutComponent,  ProfileComponent, ModalPropinaComponent, PersonSearchPipe, SidenavComponent, HeaderComponent,
   BloqueioComponent,  AlteraSenhaComponent, AlunoFilhacaoListComponent,HomeComponent,
   TranssitarAlunoClasseListComponent,  ConsultaDropDownComponent, PropinaComponent, PagamentosComponent,MyTootipDirective,
   FuncionarioFormComponent, FuncionarioListComponent, FuncionarioDadosPessoaisComponent, ProfessorDisciplinasComponent, DocumentosContactosComponent
];


@NgModule({  declarations: [COMPONENTS],

  imports: [MODULE],
  providers: [MatNativeDateModule, CurrencyPipe, Publico, MatSnackBar,
    { provide: LocationStrategy, useClass: HashLocationStrategy,  },
     {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    /* {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListDisciplinaComponent),
      multi: true,
    }, */

    {provide: MAT_DATE_LOCALE, useValue: 'pt-PT'},

      NgbActiveModal,
      DecimalPipe,
      DatePipe,
  ],

  schemas:  [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[BrowserAnimationsModule],
  entryComponents: [HomeComponent],
 // providers: [CurrencyPipe],

  bootstrap: [AppComponent],
})
export class AppModule {}


