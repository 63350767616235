import { DecimalPipe } from '@angular/common';
import {  Component, Injector, OnInit, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Disciplina } from '@core/models/index';
import { DisciplinaService } from '@service/index';

import {BasePage} from '@shared/base-page'
import { DisciplinaFormComponent } from '../disciplina-form/disciplina-form.component';
import { Publico } from '@shared/publico';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-disciplina-list',
  templateUrl: './disciplina-list.component.html',
  styleUrls: ['./disciplina-list.component.scss']
})
export class DisciplinaListComponent extends BasePage implements OnInit {
  [x: string]: any;
  private destroySubject: Subject<void> = new Subject();
  constructor(injector: Injector,
    private modalService: NgbModal,
    public pipe: DecimalPipe,
    private publico: Publico,
    private disciplinaService: DisciplinaService
    ) {
    super(injector);
  }

  disciplina : Disciplina[] = [];
  localDisciplina : Disciplina[] = [];
  public page = 1;
  public pageSize = 5;
  collectionSize = 0;
  closeResult = '';
  filter = new FormControl('');

  ngOnInit(): void {
    this.publico.verificaLincenca();
    this.loadDisciplina();

    this.filter.valueChanges.pipe(
      startWith(' '),
      map(text => this.search(text, this.pipe))
    ).subscribe(res => {
      this.disciplina = res;
      this.collectionSize = res.length;
    });
  }

  loadDisciplina() {
  this.showLoadingView();
    this.disciplinaService.loadAll({}).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
        this.localDisciplina = response;
        this.disciplina = response;
        if (this.localDisciplina && this.localDisciplina.length) {
          this.collectionSize = this.localDisciplina.length;

        }
        this.showContentView();
      }, error => {
        console.log('Error a efetuar requisicao', error);
       // this.showLoadingView();
      });
  }

  search(text: string, pipe: PipeTransform): Disciplina[] {
    return this.localDisciplina.filter(disc => {
      const term = text.toLowerCase();
      return disc.Disciplina1.toLowerCase().includes(term)
           || disc.Sigla.toLowerCase().includes(term)

         ;
    });
  }

  onAddDisciplina() {
    this.modalService.open(DisciplinaFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadDisciplina();

    }
     , (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadDisciplina();

    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEditDisciplina(employee: any){
    const modalref = this.modalService.open(DisciplinaFormComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalref.componentInstance.disciplina = employee;
    modalref.componentInstance.isReadOnly = true;
    modalref.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadDisciplina();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.loadDisciplina();
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}

