<div>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">Documento</h2>
    <!--     <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button> -->
    </div>
    
    <form [formGroup]="documentoForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
        <div class="modal-body">
            <div class="row">   
                <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Tipo Documento</mat-label>
                        <mat-select  name="IdTipoDocumento"  formControlName="IdTipoDocumento">
                        <mat-option *ngFor="let tipo of TipoDocumentos | async" [value]="tipo.IdTipoDocumento">
                            {{tipo.TipoDocumento1}}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="documentoForm.get('IdTipoContacto')?.hasError('required')">Tipo Contacto é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Número</mat-label>
                        <input matInput formControlName="Numero" maxlength="50" />
                        <mat-icon matSuffix class="secondary-text" >line_style</mat-icon >
                        <mat-error *ngIf="documentoForm.get('Numero')?.hasError('required')"> Número é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-6">
                    <label for="DataEmissao">Data de Emissão</label>
                    <div class="input-group">
                        <input id="DataEmissao" formControlName="DataEmissao" class="form-control" placeholder="yyyy-mm-dd" name="DataEmissao" ngbDatepicker #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
                                <i class="fas fa-calendar    "></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label for="DataValidade">Data de Validade</label>
                    <div class="input-group">
                        <input id="DataValidade" formControlName="DataValidade" class="form-control" placeholder="yyyy-mm-dd" name="DataValidade" ngbDatepicker #dp2="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp2.toggle()" type="button">
                                <i class="fas fa-calendar    "></i>
                            </button>
                        </div>
                    </div>
                </div>
                <br >
                  <div class="col-md-12">
                    <mat-form-field appearance="outline" >
                        <mat-label>Local de Emissão</mat-label>
                        
                        <input matInput formControlName="LocalEmissao" maxlength="100" />
                        <mat-icon matSuffix class="secondary-text" >location_on</mat-icon >
                        <mat-error *ngIf="documentoForm.get('LocalEmissao')?.hasError('required')"> Local de Emissão é obrigatório. </mat-error>
                    </mat-form-field>
                  </div>
    
            </div>
    </div>
    
    

   <!-- {{documentoForm.value | json }}   -->
    
    
    
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')" >Sair</button>
        <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!alunoForm.valid || !(files.length > 0)">Guardar</button> -->
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!documentoForm.valid"  >Guardar</button>
        <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!documentoForm.valid"  >Atualizar</button>
    </div>
    
    </form>
</div>