<br>
<div class="container-fluid">


  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Entradas do Dia</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.totalEntradas | currency:'KZ':'symbol'}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x text-success"></i>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">Saídas do Dia</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.totalSaidas | currency:'KZ':'symbol'}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x text-danger"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card  shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold  text-uppercase mb-1">Saldo do Dia</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.saldoDia | currency:'KZ':'symbol'}} </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x "></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card  shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold  text-uppercase mb-1">Saldo do Mês</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.saldoMes | currency:'KZ':'symbol'}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x "></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">% Inadimplência</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">87,45 %</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-danger"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
   <!--  <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">Alunos Inadimplêntes</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">4 </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-danger"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Earnings (Monthly) Card Example -->
    <!-- <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Vencimentos Dia</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">KZ 0 </div>
            </div>
            <div class="col-auto" align="center">
              <i class="fas fa-clipboard-list fa-2x text-primary"></i>

            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">Total Vencimento KZ</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">KZ 0,00</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-danger"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Total de Inquilinos</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.totalAlunos }}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-info"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-secondary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">Total de Apartamentos</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa?.totalTurmas}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Earnings (Monthly) Card Example -->

    <!-- Earnings (Monthly) Card Example -->
   <!--  <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Total Professores</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa.totalProfessores}} </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-success"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Earnings (Monthly) Card Example -->
   <!--  <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Disciplinas</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{movimentoCaixa.totalDisciplinas}}</div>
            </div>
            <div class="col-auto" align="center">
              <i class="fas fa-clipboard-list fa-2x text-primary"></i>

            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Pending Requests Card Example -->





  </div>
  <!-- {{ movimentoCaixa.Movimento | json }} -->
  <table class="content-table responsive">
    <thead>
        <tr>
            <th scope="col">Nº Documento</th>
            <th scope="col">Descrição</th>
            <th scope="col">Tipo Pagamento</th>

            <th scope="col">Valor</th>
            <th scope="col">Operador</th>
            <th scope="col">Opções</th>
        </tr>
    </thead>
<!-- | slice: (page-1) * pageSize : page * pageSize -->
    <tbody>
      <tr *ngFor="let persDoc of mov | slice: (page-1) * pageSize : page * pageSize">
      <!--   <tr *ngFor="let m of mov.Mensalidades"> -->
         <!--   {{ persDoc | json}}  -->

<!--             <td>{{persDoc.Mensalidades[0].Matricula.Aluno.Nome }}</td>
            <td> {{ persDoc.Mensalidades[0].Matricula.Turma.Turma1}} </td>
            <td> {{ persDoc.Mensalidades[0].ContaCaixa.Conta}} </td>
            <td>{{persDoc.Mensalidades[0].Multa | currency:'KZ':'symbol'}}</td> -->
            <td>{{persDoc.Documento }}</td>
            <td> {{ persDoc.Descricao}} </td>

            <td>{{ persDoc.TipoPagamentos.TipoPagamento1}}</td>
            <td> {{ persDoc.Valor | currency:'KZ':'symbol' }} </td>
            <td> {{ persDoc.Utilizadores.Utilizador}} </td>

            <td>
                <!-- *ngIf=" permitions.VALIDANTE | authcheck " -->


                <button type="button" name=""  id="" title="Anular" (click)="AnularPagamento(persDoc, 0)" class="btn "> <i class="fas fa-trash"></i></button>
            </td>
        </tr>
    </tbody>

</table>
<div class="alert alert-danger" style="text-align: center;" *ngIf="!mov.length">
  <strong>Nenhum registo encontrado.</strong>
</div>

<!-- <div *ngIf="mov.length === 0">No data</div> -->
<div class="d-flex justify-content-between p-2">

 <ngb-pagination
[(page)]="page"
[maxSize]="15"
[pageSize]="pageSize"
[collectionSize]="mov.length"></ngb-pagination>

    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadMov()">
        <option [ngValue]="5">5 items por pagina</option>
        <option [ngValue]="10">10 items por pagina</option>
        <option [ngValue]="20">20 items por pagina</option>
    </select>
</div>
  </div>

