import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aluno-filhacao-list',
  templateUrl: './aluno-filhacao-list.component.html',
  styleUrls: ['./aluno-filhacao-list.component.scss']
})
export class AlunoFilhacaoListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
