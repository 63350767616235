import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { CaixaPesquisa, CaixaServico, DemostrativoMensal, ListaNominal, ReportPesquisa, ResumoMensal, AlunoConfirmados } from '../models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/pdf', 'Access-Control-Allow-Origin': '*', })

};
const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' , 'Access-Control-Allow-Origin': '*'});
@Injectable({
  providedIn: 'root'
})
export class ReportServerService {
  reportServer: string | null = `${environment.API_RECIBO_URL_BASE}${environment.serverUrl}`;
  srvURL: string = '';

  constructor(private httpClient: HttpClient) { }


  getInvoice(id: any, Recibo?: any): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/Recibo?recibo=' + id +'&TipoRecibo='+ Recibo;
    return this.httpClient.get(this.srvURL, { headers, responseType: 'blob'  });
  }

  getReciboVenda(id: any, Recibo?: any): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/ReciboVenda?recibo=' + id +'&TipoRecibo='+ Recibo;
    return this.httpClient.get(this.srvURL, { headers, responseType: 'blob'  });
  }


  miniPauta(form: any): Observable<any> {
    this.srvURL = this.reportServer  + '/Reports/MiniPauta';
    return this.httpClient.post(this.srvURL, form, {responseType: 'blob'  });
  }

  miniPautaGeral(form: any): Observable<any> {
    this.srvURL = this.reportServer  + '/Reports/MiniPautaGeral';
    return this.httpClient.post(this.srvURL, form, { responseType: 'blob'  });
  }

  pauta(form: any): Observable<any> {
    this.srvURL = this.reportServer  + '/Reports/Pauta';
    return this.httpClient.post(this.srvURL, form, { responseType: 'blob'  });
  }

  extrato(matricula: any): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/Extrato?matricula=' + matricula ;

    return this.httpClient.get(this.srvURL, {  responseType: 'blob' });
  }

  balanco(ano: any): Observable<any> {
    console.log(ano);

    this.srvURL = this.reportServer + '/Reports/balanco?Ano=' + ano;

    return this.httpClient.get(this.srvURL, {  responseType: 'blob' });
  // return this.httpClient.get(this.srvURL, httpOptions);

  }

  balancete(data: ResumoMensal): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/Balancete';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  alunoConfirmaMatricula(data: AlunoConfirmados): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/AlunosMatriculados';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  alunoDividaTurma(data: ReportPesquisa): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/DividaAlunoPorTurma';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  cartaDevedor(data: ReportPesquisa): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/CartaDevedor';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  movimentoCaixa(data: CaixaPesquisa): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/MovimentoCaixa';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  caixaServico(data: CaixaServico): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/CaixaServico';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  demostrativoMensal(data: DemostrativoMensal): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/DemostrativoMensal';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  resumoClasse(data: ResumoMensal): Observable<any> {
    this.srvURL = this.reportServer + '/Reports/ResumoClasse';
    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  alunoDivida(data: ReportPesquisa): Observable<any> {
    console.log(data);
    this.srvURL = this.reportServer + '/Reports/DividaAluno';

    return this.httpClient.post(this.srvURL, data, {  responseType: 'blob' });
  }

  listaNominal(data: ListaNominal): Observable<any> {
/*     console.log(data);

    this.srvURL = this.reportServer + '/Reports/ListaNominal';
console.log(this.srvURL); */

    return this.httpClient.post( this.reportServer + '/Reports/ListaNominal', data, {  responseType: 'blob' });
  }

enviarReciboViaEmail(id: any, email:string){
  return this.httpClient.get(`${this.reportServer}/Reports/EmailRecibo?recibo=${id}&email=${email}` );
}


}
