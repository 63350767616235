<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">{{titulo}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>


<form [formGroup]="cadastroForm" (ngSubmit)="isReadOnly ? onUpdate() :onSubmit()">
  <div class="modal-body">
      <div class="row">

        <div *ngIf="!isReadOnly" class="col-md-12">
          <mat-form-field appearance="outline" >
              <mat-label>Ano Lectivo</mat-label>
              <mat-select  name="AnoLectivo"  formControlName="AnoLectivo" (change)="handleDocForm($event)">
              <mat-option *ngFor="let s of anoLetivo "  [value]="s.Codigo">{{s.Descricao}}</mat-option>
              </mat-select>
              <mat-error *ngIf="cadastroForm.get('AnoLectivo')?.hasError('required')">Ano Lectivo é obrigatório</mat-error>
          </mat-form-field>
        </div>
        <div  *ngIf="isReadOnly" class="col-md-12">
          <mat-form-field appearance="outline" >
              <mat-label>Ano Lectivo</mat-label>
                <input matInput formControlName="AnoLectivo" maxlength="50"  readonly/>
            </mat-form-field>

        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" >
              <mat-label>Matricula</mat-label>
              <mat-select  name="IdMatricula"  formControlName="IdMatricula">
                <app-consulta-drop-down (Texto)="onKey($event)" ></app-consulta-drop-down>
                <mat-option *ngFor="let s of matriculas" [value]="s.IdMatricula">{{s.Aluno.Nome}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="cadastroForm.get('Matricula')?.hasError('required')">Matricula é obrigatório</mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" >
              <mat-label>Tipo Documento</mat-label>
              <mat-select  name="IdTipoDoc"  formControlName="IdTipoDoc">
              <mat-option *ngFor="let s of tipoDocumentos" [value]="s.Codigo">
               <b>  {{s.Descricao}} </b>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="cadastroForm.get('IdTipoDoc')?.hasError('required')">Tipo Documento é obrigatório</mat-error>
            </mat-form-field>
        </div>

          <div *ngIf="visto" class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Embaixada</mat-label>
                <input matInput formControlName="Embaixada" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="cadastroForm.get('Embaixada')?.hasError('required')"> Embaixada é obrigatório. </mat-error>
            </mat-form-field>
          </div>

          <div  *ngIf="guia"  class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Encarregado</mat-label>
                <input matInput formControlName="Encarregado" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="cadastroForm.get('Encarregado')?.hasError('required')"> Encarregado é obrigatório. </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="guia" class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Transferência para </mat-label>
                <input matInput formControlName="Transferencia" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="cadastroForm.get('Transferencia')?.hasError('required')"> Transferência é obrigatório. </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="guia" class="col-md-12">
        <mat-form-field appearance="outline">
          <textarea matInput placeholder="Digite os documentos em anexos" formControlName="Obs" cdkTextareaAutosize
            cdkAutosizeMinRows="6"></textarea>
            <mat-error *ngIf="cadastroForm.get('Obs')?.hasError('required')"> Documentos em anexo é obrigatório. </mat-error>
        </mat-form-field>
      </div>


          <div *ngIf="certificado" class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Classificação</mat-label>
                <mat-select  name="Classificacao"  formControlName="Classificacao">
                  <mat-option value="Aprovado">Aprovado</mat-option>
                  <mat-option value="Reprovado">Reprovado</mat-option>
                </mat-select>
                <mat-error *ngIf="cadastroForm.get('Classificacao')?.hasError('required')">NClassificação é obrigatório</mat-error>
              </mat-form-field>
          </div>
          <div *ngIf="certificado" class="col-md-12">
            <mat-form-field appearance="outline" >
                <mat-label>Livro </mat-label>
                <input matInput formControlName="Livro" maxlength="50" />
                <mat-icon matSuffix class="secondary-text" >account_circle</mat-icon >
                <mat-error *ngIf="cadastroForm.get('Livro')?.hasError('required')"> Livro é obrigatório. </mat-error>
            </mat-form-field>
          </div>


          <div class="row" style="margin-top: 3px;">
            <div class="form-group col-12">
              <span>Ativo / Ex. Aluno </span>
                <mat-slide-toggle formControlName="Esteve"></mat-slide-toggle>
            </div>
          </div>

      </div>


      <!--  {{ cadastroForm.value | json }}  -->
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Sair</button>
      <!-- <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!cadastroForm.valid || !(files.length > 0)">Guardar</button> -->
      <button type="submit" *ngIf="!isReadOnly" class="btn btn-success" [disabled]="!cadastroForm.valid ">Guardar</button>
      <button type="submit" *ngIf="isReadOnly" class="btn btn-success" [disabled]="!cadastroForm.valid">Atualizar</button>
  </div>
</form>


