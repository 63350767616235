<div class="container">
    <div class="row">
        <div class="col-md-12 text-center">
            <button class="btn  btn-primary  mt-3" id="modal_view_left" data-toggle="modal"  data-target="#get_quote_modal">Open left modal</button>

            <button class="btn  btn-success  mt-3" id="modal_view_right" data-toggle="modal" data-target="#information_modal">Open right modal</button>
        </div>
    </div>
</div>

<!-- left modal -->
<div class="modal modal_outer left_modal fade" id="get_quote_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" >
        <div class="modal-dialog" role="document">
          <form method="post"  id="get_quote_frm">
            <div class="modal-content ">
                <!-- <input type="hidden" name="email_e" value="admin@filmscafe.in"> -->
                <div class="modal-header">
                  <h2 class="modal-title">Get a quote</h2>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body get_quote_view_modal_body">

                   <div class="form-row">

                      <div class="form-group col-sm-6">
                        <label for="name">Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" required="" id="name" name="name">
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="email">Email <span class="text-danger">*</span></label>
                        <input type="email" required="" class="form-control" id="email" name="email">
                      </div>
                      <div class="form-group  col-sm-12">
                        <label for="contact">Mobile Number <span class="text-danger">*</span></label>
                        <input type="email" required="" class="form-control" id="contact" name="contact">
                      </div>

                      <div class="form-group  col-sm-12">
                        <label for="message">Type Message</label>
                        <textarea class="form-control" id="message" name="message" rows="4"></textarea>
                      </div>
                    </div>

                </div>
                <div class="modal-footer">
                  <button type="reset" class="btn btn-light mr-auto" data-dismiss="modal"><i class="fas fa-window-close mr-2"></i> Cancel</button>
                  <button type="submit" class="btn btn-primary" id="submit_btn">Submit</button>
                </div>

            </div><!-- //modal-content -->
          </form>
        </div><!-- modal-dialog -->
</div><!-- modal -->
<!-- //left modal -->

<!-- left modal -->
<div class="modal modal_outer right_modal fade" id="information_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" >
        <div class="modal-dialog" role="document">
           <form method="post"  id="get_quote_frm">
            <div class="modal-content ">
                <!-- <input type="hidden" name="email_e" value="admin@filmscafe.in"> -->
                <div class="modal-header">
                  <h2 class="modal-title">Information:</h2>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body get_quote_view_modal_body">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni numquam accusantium dolore ipsum! Aut distinctio maxime obcaecati, sapiente nisi laudantium dignissimos optio, ea ex quas laboriosam ab officia odit, sequi.</p><br><br>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni numquam accusantium dolore ipsum! Aut distinctio maxime obcaecati, sapiente nisi laudantium dignissimos optio, ea ex quas laboriosam ab officia odit, sequi.</p><br><br>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni numquam accusantium dolore ipsum! Aut distinctio maxime obcaecati, sapiente nisi laudantium dignissimos optio, ea ex quas laboriosam ab officia odit, sequi.</p><br><br>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni numquam accusantium dolore ipsum! Aut distinctio maxime obcaecati, sapiente nisi laudantium dignissimos optio, ea ex quas laboriosam ab officia odit, sequi.</p>


                </div>

            </div><!-- modal-content -->
          </form>
        </div><!-- modal-dialog -->
</div><!-- modal -->