<!-- <div class="back-image">
  <img src="src/assets/img/bk.png" alt="background image">
</div>


<div class="container ">
    <div class="offset-2 col-8 mt-4 main login">
        <div class="card text-center" style="width: 36rem;">
            <div class="card-body">
                <h5 class="card-title">SGAFP</h5>
                <p class="card-text">Sistema de Gestão Administrativo, Financeiro e Pedagogico Escolar </p>
                <div class="form-modal">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <input type="utilizador" class="form-control" formControlName="utilizador" name="utilizador" id="utilizador" aria-describedby="helpId" placeholder="utilizador">
                        </div>
                        <br>
                        <div class="form-group">
                            <input type="password" class="form-control" formControlName="senha" name="senha" id="senha" aria-describedby="helpId" placeholder="Palavra passe">
                        </div>
                        <br>
                        <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div> -->

 <!--  <div class="container">
    <div class="screen">
      <div class="screen__content">
        <form class="login">
          <div class="login__field">
            <i class="login__icon fas fa-user"></i>
            <input type="text" class="login__input" placeholder="User name / Email">
          </div>
          <div class="login__field">
            <i class="login__icon fas fa-lock"></i>
            <input type="password" class="login__input" placeholder="Password">
          </div>
          <button class="button login__submit">
            <span class="button__text">Log In Now</span>
            <i class="button__icon fas fa-chevron-right"></i>
          </button>
        </form>
        <div class="social-login">
          <h3>log in via</h3>
          <div class="social-icons">
            <a href="#" class="social-login__icon fab fa-instagram"></a>
            <a href="#" class="social-login__icon fab fa-facebook"></a>
            <a href="#" class="social-login__icon fab fa-twitter"></a>
          </div>
        </div>
      </div>
      <div class="screen__background">
        <span class="screen__background__shape screen__background__shape4"></span>
        <span class="screen__background__shape screen__background__shape3"></span>
        <span class="screen__background__shape screen__background__shape2"></span>
        <span class="screen__background__shape screen__background__shape1"></span>
      </div>
    </div>
  </div>
 -->

<!--
 <div class="container" style="color: aqua; background-color: rgba(75, 197, 75, 0.19);background-image: url('src/assets/img/1.jpg') ;" >
  <div class="box " style="color: aqua;">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="top">
          <span class="" style="margin: center; text-align: center;"><h1>Sistema de Gestão Administrativo, Financeiro e Pedagogico</h1></span>
          <header>Login</header>
      </div>
      <br>

      <div class="input-field">
        <input type="utilizador" class="input" formControlName="utilizador" name="utilizador" id="utilizador" aria-describedby="helpId" placeholder="utilizador">
          <i class='bx bx-user' ></i>
      </div>
<br>
      <div class="input-field">
          <input type="password" class="input" formControlName="senha" name="senha" id="senha" aria-describedby="helpId" placeholder="Palavra passe">
          <i class='bx bx-lock-alt'></i>
      </div>
<br>
      <div class="input-field">
          <input type="submit" class="submit" [disabled]="!loginForm.valid">
      </div>
</form>
  </div>
</div>
 -->


 <!--  <div class="container ">
   <div class="box">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="top">
        <div class="top">Sistema de Gestão Escolar</div>
        <div class="header">Seja bem-vindo</div>
      </div>

      <div class="input-field">
        <input type="text" class="input" formControlName="utilizador" placeholder="Utilizador">
        <i class='bx bx-user icon'></i>
      </div>

      <div class="input-field">
        <input type="password" class="input" formControlName="senha" placeholder="Palavra-passe">
        <i class='bx bx-lock-alt icon'></i>
      </div>

      <div class="input-field">
        <button type="submit" class="submit" [disabled]="!loginForm.valid">Login</button>
      </div>
    </form>
   </div>
</div>
 -->
 <!-- <div class="container d-flex justify-content-center align-items-center" style="height: 100vh;">
  <div class="box">
    <div class="student-image">
      <img src="../assets/img/benysys.jpg" alt="Aluno" style="width: 75px;">
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="top">
        <div class="top">Sistema de Gestão Escolar</div>
        <div class="header">Seja bem-vindo</div>
      </div>

      <div class="input-field">
        <input type="text" class="input" formControlName="utilizador" placeholder="Utilizador">
        <i class='bx bx-user icon'></i>
      </div>

      <div class="input-field">
        <input type="password" class="input" formControlName="senha" placeholder="Palavra-passe">
        <i class='bx bx-lock-alt icon'></i>
      </div>

      <div class="input-field">
        <button type="submit" class="submit" [disabled]="!loginForm.valid">Login</button>
      </div>
    </form>
  </div>
</div>
 -->


 <div class="container d-flex justify-content-center align-items-center" style="height: 100vh; background-color: rgba(255, 255, 255, 0.8);">
  <div class="box">
    <div class="student-image">
      <img src="../assets/img/benysys.jpg " alt="Aluno" style="width: 75px;">
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="top">
        <div class="top">Sistema de Gestão de Inquilinos</div>
        <div class="header">Seja bem-vindo</div>
      </div>
      <div ><img src="/assets/gif/Book.gif" class="center" style="padding-bottom: 20px;"/></div>

      <div class="input-field">
        <input type="text" class="input" formControlName="utilizador" placeholder="Utilizador">
        <i class='bx bx-user icon'></i>
      </div>

      <div class="input-field">
        <input type="password" class="input" formControlName="senha" placeholder="Palavra-passe">
        <i class='bx bx-lock-alt icon'></i>
      </div>

      <div class="input-field">
        <button type="submit" class="submit" [disabled]="!loginForm.valid">Login</button>
      </div>
    </form>
  </div>
</div>
